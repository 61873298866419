import "styled-components/macro";
import { DialogueData } from "../../logic/dialogs/DialogSystem";
import {  useSystemContext, useTheme } from "../../logic/SystemContextComponents";
import Button from "../common/Button";
import {MenuHeader} from "../common/MenuHeader";
import { css } from "styled-components";
import { FileDownloadButton } from "./FileDownloadButton";
import { DialogComponentFileItemsContents } from "./DialogComponentFileItems";
import { IMAGE_HEIGHT_FILE_VIEWER_PAGE } from "../../config/TextureHeights";
import { useFileMetadata } from "../../logic/files/FileRepositoryUtilities";
import { DialogMarketplaceSidePanelContents } from "./DialogMarketplaceSidePanelContents";
import { useComponentDidMount, useComponentDidUnmount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import LightingEditor from "./LightingEditor";

let originalCameraFar=2000;

export function DialogComponent({
    data,
    close,
}:{
    data: DialogueData,
    close: ()=>void,
}){
    const {translate,client,threeSystem} = useSystemContext();
    const systemContext = useSystemContext();
    const theme = useTheme();
    let {fileId,text,boothEntity,lights} = data;
    const fileName = useFileMetadata(fileId)?.filename;
    let resolvedText = text || boothEntity?.resolvedDescription || "";

    useComponentDidMount(()=>{
        originalCameraFar=threeSystem.camera.far;
        threeSystem.camera.far = 0.001;
        threeSystem.camera.updateProjectionMatrix();
    })
    useComponentDidUnmount(()=>{
        threeSystem.camera.far = originalCameraFar;
        threeSystem.camera.updateProjectionMatrix();
    });

    let items:JSX.Element[] = [];
    if(fileId){
        items.push(
            <DialogComponentFileItemsContents fileId={fileId} lights={lights} />
        );
    }
    if(boothEntity?.record.signageFileId){
        const src = client.urls.storage.files.byId(boothEntity.record.signageFileId).renders.byIndex(0).get({
            maxHeight: IMAGE_HEIGHT_FILE_VIEWER_PAGE
        });
        const css = Layout.BoothLogoItem + '' + theme.Dialog.Item;
        items.push(
            <img css={css} alt="logo" src={src}/>
        );
    }
    if(resolvedText){
        const css = Layout.TextItem + '' + theme.Dialog.Item;
        items.push(
            <div css={css}>{resolvedText}</div>
        );
    }
    if(data.editLights){
        items.push(<LightingEditor objectRecord={data.editLights.objectRecord} save={data.editLights.save} systemContext={systemContext}/>)   
    }

    let insideContents = <>
        <div css={Layout.TopArea} className="(DialogComponent.TopArea)">
            {
                fileName &&
                <p>{fileName}</p>
            }
            {
                boothEntity &&
                <MenuHeader>{translate("t$boothInformation")}</MenuHeader>
            }
            <div css={Layout.TopAreaRow}>
                {
                    fileId &&
                    <FileDownloadButton fileId={fileId} fileName={fileName}/>
                }
                <Button onClick={()=>{close()}}> {translate("t$close")} </Button>
            </div>
        </div>

        <div css={Layout.BottomArea} className="(DialogComponent.BottomArea)">

            <div css={Layout.ItemsArea} className="(DialogComponent.ItemsArea)">
                {items}
            </div>
            {!!data.boothSubObjectEntity?.record.marketplaceOfferIds?.length &&
            <div css={Layout.SidePanelArea} className="(DialogComponent.SidePanelArea)">
                <DialogMarketplaceSidePanelContents offerIds={data.boothSubObjectEntity.record.marketplaceOfferIds} />
            </div>}
        </div>
    </>;

    if( data.contents ){
        insideContents = <>{data.contents}</>;
    }

    const backgroundThemeCss = data.seeThrough ? theme.Dialog.Background.SeeThrough : theme.Dialog.Background.Normal;
    return (
    <div css={Layout.Root+''+backgroundThemeCss} className="(DialogComponent)">
        <div
            css={Layout.Inside}
            className="(DialogComponent.Inside)"
        >
            {insideContents}
        </div>
    </div>
    );
}

/**
 * Root
 *   Inside
 *     TopArea
 *     BottomArea
 *       ItemsArea
 *       SidePanelArea
 */
const Layout = {
    Root: css`
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
    `,
    Inside: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    `,
    TopArea: css`
        width: 100%;
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > * {
            margin-bottom: .5em;
        }
    `,
    TopAreaRow: css`
        width:100%;
        display: flex;
        flex-direction: row;
        /* overflow-x: scroll; */
        justify-content:center;
    `,
    BottomArea: css`
        /** flex-grow and flex-shrink don't work unless a height is set on this div, although it doesn't use it*/
        height: 1px;
        flex-grow: 1;
        flex-shrink: 1;
        width: calc( 120rem * .85 );
        max-width: calc( 100% - 2em );
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    `,
    ItemsArea: css`
        min-width: 50%;
        height:100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        & > * {
            margin: 1em 0;
        }
    `,
    SidePanelArea: css`
        max-width: 50%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    TextItem: css`
        width: 100%;
        min-height: 10em;
        padding: 4em;
        font-size: 1.5em;
    `,
    BoothLogoItem: css`
        height: 10em;
        /* max-height: 10em; */
        width: auto;
    `,
}