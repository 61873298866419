import { useSystemContext } from "../../logic/SystemContextComponents";
import { FileNameToExtension, MakeFileUrlWithExtension } from "../../logic/utility/Files";
import Button from "../common/Button";


export function FileDownloadButton({
    fileId,
    fileName,
}:{
    fileId:string,
    fileName?:string
}){

    const {client,translate} = useSystemContext();

    const fileUrl = fileId && MakeFileUrlWithExtension(fileId,fileName);
    
    return (
    <a
            download
            target="_blank"
            rel="noreferrer"
            href={fileUrl}
    >
        <Button onClick={()=>{}}>
            {translate("t$download")}
        </Button>
    </a>
    );
}