import { useState } from "react";
import { useComponentDidMount, useResult, useResultOnMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext } from "../SystemContextComponents";
import { MapEntity } from "./MapEntity";

export function useMapRepository(){
    return useSystemContext().mapRepository;
}

export function useLiveMaps(){
    const repository = useMapRepository();
    return useResultOnMount(
        ()=>repository.getLiveMapsAsync()
    );
}