import { useState } from "react";

export const IS_MOBILE =navigator.userAgent.toLowerCase().includes("mobile");

/** Currently, this always returns the same value, but if there's a situation in the future where you want to watch for a change, you can do it here. */
export function useIsMobileDevice(){
    const isCurrentlyMobile =navigator.userAgent.toLowerCase().includes("mobile");
    return isCurrentlyMobile;
    // const [isMobile,setIsMobile] = useState(isCurrentlyMobile);
    // return isMobile;
}