import "styled-components/macro";
import { css, CSSProp } from "styled-components";
import { useTheme } from "../../logic/SystemContextComponents";

export function Checkbox({
    value,
    setValue,
    layout
}:{
    value:boolean,
    setValue:(checked:boolean)=>void,
    layout?:CSSProp
}){
    const theme = useTheme();
    // Note that there is no layout for the internals of a checkbox. The internals of this component are entirely theme-dependent. We merely provide a default appearance here.
    // To style this component, you probably want to hide the "checkbox" (Checkbox.Contents)
    // and make sure you set the width and height to use the entire container.
    return <div onClick={()=>setValue(!value)} css={theme.Checkbox.Container+``+layout}>
        <input type="checkbox" css={theme.Checkbox.Contents} checked={value} />
    </div>
}