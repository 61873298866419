import { BoothObjectLightItem } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";

export type LightingPresetId = "0"|"1" | "2" | "3" | "4";

export const LightingPresets:{
    [key in LightingPresetId]:BoothObjectLightItem[]|undefined
} = {
    0: undefined,
    1: Make1(),
    2: Make2(),
    3: Make3(),
    4: Make4(),
};


function Make1():BoothObjectLightItem[]{
    return [
        {
            type:"ambient",
            intensity:1,
        },
        {
            type:"directional",
            intensity:.2,
            transform: {
                position: [-10,10,0],
            },
            pointAt:[0,0,0],
        },
        {
            type:"directional",
            intensity:.2,
            transform: {
                position: [10,10,0],
            },
            pointAt:[0,0,0],
        },
    ];
}

function Make4():BoothObjectLightItem[]{
    return [
        {
            type:"ambient",
            intensity: .8,
        },
        {
            type:"directional",
            intensity:.5,
            transform:{
                position:[0,0,100],
            },
            pointAt: [0,0,0],
        }
    ];
}

function Make2(){
    const lights:BoothObjectLightItem[] = [];
    for(const r of [.5,2,4]){
        const delta = Math.PI/(2*r);
        for(let t=0;t<Math.PI*2;t+=delta){
            const x = r*Math.cos(t);
            const y = r*Math.sin(t);
            lights.push({
                type:"point",
                intensity: .3,
                distance: 10,
                transform:{
                    position:[x,y,2]
                }
            })
        }
    }
    return lights;
}


function Make3():BoothObjectLightItem[]{
    return [
        {
            type:"ambient",
            intensity:3,
        }
    ];
}