import "styled-components/macro";

import { Link, LinkProps, NavLinkProps, useNavigate } from "react-router-dom";
import { CSSProp } from "styled-components";
import { useNavigateWithScene } from "../../logic/Routing";
import Button from "./Button";

/**
 * Similar to react-router-dom's Link, but also allows scene navigation logic.
*/
export function LinkAnchor({
    to,
    navigateScene,
    children,
    onClick,
}:{
    to?:string,
    navigateScene?:boolean,
    children:any,
    onClick?:()=>void|Promise<void>,
}
){
    const navigateWithRouterOnly = useNavigate();
    const navigateWithScene = useNavigateWithScene();

    return <Link
        to={to||""}
        onClick={async ()=>{
            if( onClick ){
                await onClick();
            }
            if( to ){
                if( navigateScene ){
                    navigateWithScene(to);
                }else{
                    // navigateWithRouterOnly(to)
                }
            }
        }}
    >
        {children}
    </Link>
}