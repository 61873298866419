import "styled-components/macro";


import * as Three from "three";
import React from "react";
import { Box3, DirectionalLight, PointLight, Vector3, WebGLRenderer } from "three";
import Resources from "../../logic/Resources";
import { lerp } from "three/src/math/MathUtils";
import { BoothObjectLightItem } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";


// TODO: get this dynamically
const size = [800,800];

const defaultCameraDistanceFromOrigin = 1.5;

/* Given a model via URL, renders a canvas that renders the model. The user can drag/mousewheel to inspect the model. */
export default class ModelViewerComponent extends React.Component<{
    modelFileUrl:string,
    lights?:BoothObjectLightItem[],
}>{
    containerElement?:HTMLDivElement|null;

    renderer?:WebGLRenderer;
    scene = new Three.Scene();
    camera = new Three.PerspectiveCamera( 70, 1 );
    
    horizontalRotationHelper = new Three.Object3D();
    verticalRotationHelper = new Three.Object3D();

    isMouseDown = false;
    /** A number between -1 and 1, so the user can zoom in /out of the model. A higher value means you're zoomed in closer. */
    zoom = 0;


    state = {
        loading:true,
    }

    threeJsAnimationLoop( time:number ){
        this.renderer?.render( this.scene, this.camera );
    }

    async componentDidMount(){
        await this.loadModelAsync();
        this.initializeThreeJs();
    }

    async loadModelAsync(){
        try{
            const gltf = await Resources.LoadGLTFAsync( this.props.modelFileUrl );
            const gltfScene = gltf.scene.clone();

            // Get the extents of the model so that we can scale & center it
            const boundingBox = new Box3().setFromObject( gltfScene );
            const boundingBoxSize = boundingBox.getSize( new Vector3() );
            const boundingBoxSizeLargestDimension = Math.max( ...boundingBoxSize.toArray() );
            const modelScale = 1.0 / boundingBoxSizeLargestDimension;
            
            gltfScene.scale.setScalar( modelScale );

            this.horizontalRotationHelper.add( gltfScene );
            this.verticalRotationHelper.add( this.horizontalRotationHelper );
            this.scene.add( this.verticalRotationHelper );

            // Start with a bit of rotation
            this.horizontalRotationHelper.rotateY( Math.PI/4 );
            this.verticalRotationHelper.rotateX( Math.PI/4 );
     
        }catch( error ){
            console.log("ModelViewerComponent: Unable to load GLTF file: "+this.props.modelFileUrl );
        }

        this.setState({loading:false});
    }

    initializeThreeJs(){
        // TODO: get this dynamically (clientWidth is 0 when this code runs, currently)
        // const size = [this.containerElement?.clientWidth||0,this.containerElement?.clientHeight||0];

        this.camera = new Three.PerspectiveCamera( 70, size[0]/size[1] );
        this.camera.position.z = defaultCameraDistanceFromOrigin;

        const renderer = new Three.WebGLRenderer({alpha:true,antialias:true});
        renderer.setSize( size[0],size[1] );
        renderer.setClearColor("#ffffff", .2);

        // const renderer = new Three.WebGLRenderer({alpha:false,antialias:true});
        // renderer.setSize( size[0],size[1] );
        // renderer.setClearColor("#ffffff");

        this.containerElement?.appendChild( renderer.domElement );

        // Disables the browser's right click menu from appearing when we click the threejs container element.
        renderer.domElement.oncontextmenu = ()=>false;

        this.renderer = renderer;

        renderer.setAnimationLoop( time=>this.threeJsAnimationLoop(time) )

        const lights = this.props.lights || [];
        for(const light of lights){
            const color = light.color ? light.color : 0xFFFFFF;
            const {intensity,distance,transform} = light;
            const pointAt = light.pointAt as [number,number,number]|undefined;
            const decay = undefined;
            let threeLight;
            switch(light.type){
                case "ambient":
                    threeLight = new Three.AmbientLight(color,intensity);
                    break;
                case "point":
                    threeLight = new Three.PointLight(color,intensity,distance,decay);
                    break;
                case "directional":
                    threeLight = new Three.DirectionalLight(color,intensity);
                    if( pointAt ){
                        threeLight.target.position.set(...pointAt);
                    }
                    break;
            }
            this.scene.add(threeLight);   
            if(transform?.position){
                threeLight.position.set(...transform.position as [number,number,number]);
            }
        }

        if(!lights.length){
            const light = new Three.AmbientLight(0xFFFFFF, 3);
            this.scene.add(light);   
        }

        // for(const r of [.5,2,4]){
        //     const delta = Math.PI/(2*r);
        //     for(let t=0;t<Math.PI*2;t+=delta){
        //         const x = r*Math.cos(t);
        //         const y = r*Math.sin(t);
        //         const pointLightIntensity = .3;//lerp(.3,1,delta/(Math.PI*2));
        //         const pointLightDistance = 10;
        //         const pointLight = new PointLight(0xFFFFFF,pointLightIntensity,pointLightDistance);
        //         pointLight.position.set(x,y,2);
        //         this.scene.add(pointLight);
        //     }
        // }

        // const pointLightIntensity = .5;//lerp(.3,1,delta/(Math.PI*2));
        // const pointLightDistance = 10;
        // const pointLight = new PointLight(0xFFFFFF,pointLightIntensity,pointLightDistance);
        // pointLight.position.set(0,0,2);
        // this.scene.add(pointLight);

        // const directionalLightIntensity = 1;//lerp(.3,1,delta/(Math.PI*2));
        // const directionalLight = new DirectionalLight(0xFFFFFF,directionalLightIntensity);

        // directionalLight.position.set(0,0,2);
        // directionalLight.rotateX(-Math.PI/4);
        // this.scene.add(directionalLight);
    }

    onMouseDown( event:React.MouseEvent ){
        this.isMouseDown = true;
    }
    onMouseMove( event:React.MouseEvent ){
        if( !this.isMouseDown ){
            return;
        }

        this.horizontalRotationHelper.rotateY(
            10 * event.movementX / window.innerWidth
        );
        this.verticalRotationHelper.rotateX(
            10 * event.movementY / window.innerHeight
        );
        // this.scene.rotateY(
        //     event.movementY / window.innerHeight
        // );
    }
    onMouseUp( event:React.MouseEvent ){
        this.isMouseDown = false;
    }
    onWheel( event:React.WheelEvent ){
        
        const zoomSpeed = .001;
        this.zoom += -event.deltaY * zoomSpeed;
        this.zoom = Math.max(-1, this.zoom );
        this.zoom = Math.min(1, this.zoom );
        this.camera.position.z = defaultCameraDistanceFromOrigin - defaultCameraDistanceFromOrigin * this.zoom;

        event.stopPropagation();
    }

    render(){
        return this.state.loading ? <>Loading...</> : <div
            style={{
                width:"auto",
                height:"80vh",
            }}
            ref={ ref=>this.containerElement=ref }
            onMouseDown={ event=>this.onMouseDown(event) }
            onMouseMove={ event=>this.onMouseMove(event) }
            onMouseUp={ event=>this.onMouseUp(event) }
            onWheel={ event=>this.onWheel(event) }
        />
    }
}
