import "styled-components/macro";

import {NavigationBreadcrumbTrail} from "./NavigationBreadcrumbTrail";
import {AccountInfo} from "./AccountInfo";
import { css } from "styled-components/macro";
import { useIsLoggedIn, useMySystemAccount } from "../../logic/accounts/SessionState";
import { useSystemContext } from "../../logic/SystemContextComponents";
import { FloorSceneNode } from "../../logic/scenes/FloorSceneNode";
import { MainMenuSceneNode } from "../../logic/scenes/MainMenuSceneNode";
import Button from "../common/Button";
import { useDialogSystem } from "../../logic/dialogs/DialogSystem";
import { SettingsDialogContents } from "./SettingsDialogContents";

export default function TopMenuPanelContentsComponent(){
    const systemAccount = useMySystemAccount();
    const dialogSystem = useDialogSystem();
    const isLoggedIn = useIsLoggedIn();
    const {scene,translate} = useSystemContext();
    
    let leftContents:JSX.Element|undefined;
    // if(
        // (scene instanceof FloorSceneNode) ||
        // (scene instanceof MainMenuSceneNode) ||
    // ){
        leftContents = <NavigationBreadcrumbTrail/>
    // }

    return <div css={Layout.Root}>
        <div css={Layout.LeftContents}>
            {leftContents}
        </div>
        <div css={Layout.RightContents}>
            {(isLoggedIn&&systemAccount) && <>
                <AccountInfo/>
                <Button layout={Layout.Button} onClick={()=>{
                    dialogSystem.showDialog({
                        contents: <SettingsDialogContents/>
                    });
                }}>
                    {translate("t$settingsButton")}
                </Button>
            </>}
        </div>
    </div>
}

const Layout = {
    Root: css`
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:space-between;
        /* display: grid; */
        /* grid-template-columns: 3fr 1fr; */
        /* grid-template-rows: 100%; */
        padding-left: 2vmin;
        padding-right: 2vmin;
        height: 100%;

        & > * { 
            height: 3em;
            max-height: 100%;
        }
    `,
    LeftContents: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        & > * {
            min-height: 2em;
            height: 50%;
            max-height: 100%;
        }
    `,
    RightContents: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        & > * {
            height: 100%;
            margin-left: 1em;
        }
    `,
    Button: css`
        &&{
            height: 70%;
        }
    `,
};