import { DoubleSide, Mesh, MeshBasicMaterial, Object3D, PlaneGeometry, Texture } from "three";
import Object3DNode from "../logic/nodes/Object3DNode";
import Resources from "../logic/Resources";
import SystemContext from "../logic/SystemContext";

export class IntroBillboardNode extends Object3DNode{
    private readonly textureUrl;
    private _displayMesh;
    private _displayNode;
    private _loadedTexture?:Texture;

    constructor( textureUrl:string, depthTest:boolean, aspectRatio:number, systemContext:SystemContext ){
        super(
            new Object3D(),
            systemContext
        );
        this.textureUrl=textureUrl;
        this._displayMesh = new Mesh(
            new PlaneGeometry( aspectRatio,1 ),
            new MeshBasicMaterial({
                side:DoubleSide,
                transparent:true,
                // to prevent from going invisible from a certain distance/angle
                depthTest,
            })
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
        );
        // this._displayNode.object3D.rotateX(-Math.PI/2);

        this._loadAsync();
    }

    async _loadAsync(){
        try{
            const texture = await Resources.LoadTextureAsync( this.textureUrl );
            this._displayMesh.material.map = texture;
            this._displayMesh.material.needsUpdate = true;
            this.addChild( this._displayNode );
        }catch(e){
            console.error(e);
        }
    }

    disposeHook(){
        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }
    }
}