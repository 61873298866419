import { Euler, Object3D, Quaternion, Vector2, Vector3 } from "three";
import Object3DNode from "../nodes/Object3DNode";
import Resources from "../Resources";
import SystemContext from "../SystemContext";
import BoothSubObjectNode from "./subObjects/BoothSubObjectNode";
import KioskNode from "./KioskNode";
import BoothSkySignNode from "./BoothSkySignNode";
import assert from "assert";
import { BoothEntity } from "./BoothEntity";
import { ExpoBoothType } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothTypes";
import Time from "../../libraries/l2-common/l2-common-ts/common/utility/Time";
import { LAYER_BOOTH_SURFACE } from "../ThreeSystemLayers";
import { ExpoBoothRecordObjectRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import { BoothSubObjectEntity } from "./BoothSubObjectEntity";
import { FloorSceneNode } from "../scenes/FloorSceneNode";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";

/** If the player is closer than this (using manhattan distance), the booth will load its contents. */
const LOAD_IF_PLAYER_NEARBY_THRESHOLD_MANHATTAN = 20;

export default class BoothNode extends Object3DNode{

    private _teleportPointNode?:Object3DNode;
    private _booth?:BoothEntity;
    /**
     * Children of the booth are all placed inside this container, so that external changes
     * to this BoothNode's object3D's transform don't overwrite the adjustments we're making internally.
     */
    private _boothModelContainer?:Object3DNode;
    /** There is a bug where the subobjects for red booths using v1 data (the boothEntity from unity) are rotated 90 degrees to far. Thus we correct by using this container. */
    private _containerForSubObjects:Object3DNode;
    
    // get loaded(){
    //     return !!this._booth;
    // }

    get teleportPosition(){
        if( !this._teleportPointNode ){
            throw new Error("Cannot get teleport point: Booth hasn't finished loading yet.");
        }
        return this._teleportPointNode.object3D.getWorldPosition( new Vector3() );
    }
    // get teleportRotationEuler(){ return new Euler().setFromQuaternion( this.object3D.getWorldQuaternion( new Quaternion() ) ); }

    /** There are 2 layers of loading:
     * Layer 1: Just the booth mesh
     * Layer 2: contains the parts of the booth (eg textures related to the company) that use a lot of memory
     * 
     * Layer 2 is only loaded when the player is nearby.
     */
    private _isLayer2Loaded = false;

    constructor( boothEntityOrBoothId:BoothEntity|string, systemContext:SystemContext ){
        super( new Object3D(), systemContext );
        
        this._containerForSubObjects = new Object3DNode(new Object3D(), systemContext );
        // this._boothModelContainer.addChild( this._containerForSubObjects );
        this.addChild( this._containerForSubObjects );

        if( boothEntityOrBoothId instanceof BoothEntity ){
            this._initializeByBooth(boothEntityOrBoothId);
        }else{
            this._initializeByBoothIdAsync(boothEntityOrBoothId);
        }
    }

    static MakePlaceholder( boothTypeId:ExpoBoothType, systemContext:SystemContext ){
        const boothEntity = new BoothEntity({
            typeId:boothTypeId,
            creationTimeSeconds: Time.CurrentUnixSeconds(),
            id:"",
            ownerAccountId:"",
            slug:""
        });
        return new BoothNode(boothEntity, systemContext);
    }

    private async _maybeLoadLayer2Async(){
        if( ! this._booth ){
            return;
        }
        if( this._isLayer2Loaded ){
            return;
        }

        for( const subObjectData of this._booth.subObjects ){
            const subObjectNode = await BoothSubObjectNode.MaybeMakeFromEntityAsync( subObjectData, this.systemContext );
            if( subObjectNode ){
                this._containerForSubObjects.addChild( subObjectNode );
            }
        }

        this._isLayer2Loaded = true;
    }

    private async _maybeUnloadLayer2Async(){
        if( ! this._isLayer2Loaded ){
            return;
        }

        this._containerForSubObjects.destroyAllChildren();

        this._isLayer2Loaded = false;
    }

    updateHook(){
        const {player,scene} = this.systemContext;
        if(!player){
            return;
        }
        const playerPosition = player.object3D.getWorldPosition( new Vector3() );
        const boothPosition = this.object3D.getWorldPosition( new Vector3() );

        // TODO: move to setting on the scene
        const hideWhenOutOfRange = scene instanceof FloorSceneNode;
        
        if(
            !hideWhenOutOfRange ||
            boothPosition.manhattanDistanceTo( playerPosition ) < LOAD_IF_PLAYER_NEARBY_THRESHOLD_MANHATTAN
        ){
            this._maybeLoadLayer2Async();
        }else{
            this._maybeUnloadLayer2Async();
        }
    }

    /** alters the internal record, creates and returns the node of the object */
    async addSubObjectByRecordAsync(record:ExpoBoothRecordObjectRecord){
        if(!this._booth){
            throw new Error("BoothNode's booth is not yet loaded.");
        }
        const entity = this._booth.addSubObjectByRecord( record );
        const node = await BoothSubObjectNode.MaybeMakeFromEntityAsync(
            entity,
            this.systemContext
        );
        if( node ){
            this._containerForSubObjects.addChild( node );
        }
        return node;
    }

    /** removes, from the booth, the 3d node & the record for this sub object */
    removeSubObjectNodeByEntity(entity:BoothSubObjectEntity){
        
        const record = this._booth?.record;
        if(!record||!record.objects){
            return;
        }
        const index = record.objects.indexOf( entity.record );
        record.objects.splice(index,1);

        const target = this._containerForSubObjects.children.find(a=>(
            a instanceof BoothSubObjectNode &&
            a.boothSubObjectEntity===entity
        ));
        if(!target){
            return;
        }
        target.destroy();
    }

    private async _initializeByBoothIdAsync(boothId:string){
        const booth = await this.systemContext.boothRepository.getByIdAsync( boothId );
        if(booth){
            this._initializeByBooth(booth);
        }
    }

    private _initializeByBooth(booth:BoothEntity){
        this._booth = booth;
        this._initializeKiosk(booth);
        this._initializeBoothModelContainer(booth);
        assert( this._boothModelContainer );
        this._initializeTeleportPoint(booth,this._boothModelContainer);
        this._initialLoadSignageAsync(booth);
        this._initialLoadBoothSceneAsync(booth,this._boothModelContainer);
    }

    private _initializeKiosk(booth:BoothEntity){
        const kiosk = new KioskNode( booth, this.systemContext );
        kiosk.object3D.scale.setScalar(1);
        this.addChild( kiosk );
        const layout = BoothFeatureLayouts[booth.record.typeId];
        kiosk.maybeSetTransform( layout.kiosk );
    }

    private _initializeBoothModelContainer(booth:BoothEntity){
        this._boothModelContainer = new Object3DNode(new Object3D(), this.systemContext );;
        const layout = BoothFeatureLayouts[booth.record.typeId];
        this._boothModelContainer.maybeSetTransform(layout.boothModelContainer);
        
        // We put everything into a container, so that external changes to this BoothNode's object3D's transform don't overwrite the adjustments we're making internally.
        this.addChild( this._boothModelContainer );
    }

    private _initializeTeleportPoint(booth:BoothEntity,boothModelContainer:Object3DNode){
        this._teleportPointNode = new Object3DNode( new Object3D() , this.systemContext );
        const layout = BoothFeatureLayouts[booth.record.typeId];
        boothModelContainer.addChild( this._teleportPointNode );
        this._teleportPointNode.maybeSetTransform( layout.teleportPoint );
    } 

    private _initialLoadSignageAsync(booth:BoothEntity) {
        if (booth.record.signageFileId) {
            const skySign = new BoothSkySignNode(booth, this.systemContext);
            skySign.object3D.position.y = 5 * (BOOTH_SCALE / .66);
            this.addChild(skySign);
        }
    }

    private async _initialLoadBoothSceneAsync(booth:BoothEntity,boothModelContainer:Object3DNode){
        const boothScene = (
            await Resources.LoadBoothGLTFByBoothIdAsync( booth.record.typeId )
        ).scene.clone();
        boothScene.layers.enable( LAYER_BOOTH_SURFACE );
        boothScene.traverse( a=>a.layers.enable(LAYER_BOOTH_SURFACE) );
        boothScene.traverse( a=>a.updateMatrix() );
        boothScene.traverse( a=>a.updateMatrixWorld() );
        
        const boothSceneObject = new Object3DNode(boothScene, this.systemContext);
        boothModelContainer.addChild( boothSceneObject );
    }

}


type BoothFeatureLayout = {
    teleportPoint?:RightHanded3DTransform,
    kiosk?:RightHanded3DTransform,
    boothModelContainer?:RightHanded3DTransform,
};

const KIOSK_Y = 1.75;

// // const BOOTH_SCALE = .66;
const BOOTH_SCALE = 1.0;
// // 1 unit = 1m, but the model was slightly big before (we didn't notice b/c the player in the Unity version was enlarged)
// this._boothModelContainer.object3D.scale.setScalar( BOOTH_SCALE );

const BoothFeatureLayouts:{[key in ExpoBoothType]:BoothFeatureLayout} = {
    custom: {
        
    },
    simpleRed: {
        teleportPoint: {position:[0,0,5]},
        kiosk: {
            position: [ 1.5, KIOSK_Y, -3.5 ],
            rotation: [ 0, 45, 0 ],
        },
        boothModelContainer:{
            rotation: [0, 90, 0]
        }
    },
    simpleBlue: {
        teleportPoint: {position:[0,0,5]},
        kiosk: {
            position: [ .5, KIOSK_Y, -1.75 ],
            rotation: [ 0, 45, 0 ]
        },
        boothModelContainer:{
            rotation: [0, 90, 0],
        }
    },
    simpleYellow:{
        teleportPoint:{
            position: [-5,0,0],
        },
        kiosk:{
            position: [2, KIOSK_Y, -2],
            rotation: [0, 45, 0],
        },
        boothModelContainer:{
            rotation: [0, 180, 0],
        }
    },
    simpleWhite:{
        teleportPoint:{
            position: [0,0,5],
        },
        kiosk:{
            position: [1.5, KIOSK_Y, 2.25],
            rotation: [0, -45, 0],
        },
        boothModelContainer:{
            rotation: [0, 180, 0]
        }
    }
};
