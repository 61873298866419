import { useState } from "react";
import { IMAGE_HEIGHT_FILE_VIEWER_PAGE } from "../../config/TextureHeights";
import { FileMetadataRecord } from "../../libraries/l2-common/l2-common-ts/definitions/storage/FileMetadataRecord";
import { useAsyncEffect, useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext } from "../SystemContextComponents";

/* Fetches the file's metadata from the repository */
export function useFileMetadata(fileId?:string){
    const {fileRepository} = useSystemContext();
    const [metadata,setMetadata] = useState<FileMetadataRecord>();

    useAsyncEffect(async ()=>{
        if(!fileId){
            return;
        }
        const metadata = await fileRepository.getMetadataByIdAsync(fileId);
        setMetadata(metadata);
    }, [
        fileId
    ]);

    return metadata;
}

export function useFileRenderUrls(fileId?:string){
    const {client} = useSystemContext();
    const [renderUrls,setRenderUrls] = useState<string[]>();

    useAsyncEffect(async ()=>{
        if(!fileId){
            return;
        }
        const count = await client.api.storage.files.byId(fileId).renders.count.getAsync();
        
        const pageUrls = [];
        for( let index=0; index<count; ++index ){
            const pageUrl = client.urls.storage.files.byId(fileId).renders.byIndex(index).get({
                maxHeight: IMAGE_HEIGHT_FILE_VIEWER_PAGE
            });
            pageUrls.push( pageUrl );
        }

        setRenderUrls(pageUrls);
    }, [
        fileId
    ]);

    return renderUrls;
}