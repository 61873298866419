import { css } from "styled-components";

type HelpfulTypes="color"|"border"|"border-right"|"border-left"|"background-color";

/**
 * Returns a string that's safe to pass into styled-component's css template function, without breaking due to a comma being added.
 * MAKE SURE you add a semicolon after calling this function!
* */
export function CssLines(...lineOfCss:(HelpfulTypes|string)[]){
    return lineOfCss.map(line=>`; `+line+`; `).join("");
}