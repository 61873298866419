import assert from "assert";
import { Euler, Mesh, Object3D, sRGBEncoding, Texture } from "three";
import * as Three from "three";
import Object3DNode from "../../../nodes/Object3DNode";
import SystemContext from "../../../SystemContext";
import Node from "../../../nodes/Node";
import Resources from "../../../Resources";
import FileDisplayerNodeLoadingWidget from "./FileDisplayerNodeLoadingWidget";
import NodeMessageControls from "../../../nodes/NodeMessageControls";
import { FileLike, IsRemoteFileId, RemoteFileId } from "./FileLike";
import LocalFileWrapper from "../../../files/FileWrapper";

interface Options{
    /** Displays with transparency and disables collisions on the displayer. (You'll need to implement your own collisions elswhere if you want them.) */
    cutout?:boolean,
}

/** Displays a file in a panel.  */
export default class FileDisplayerNode extends Object3DNode{
    _displayMesh;
    _displayNode:Object3DNode;
    readonly file;
    private readonly _options;

    private _loadedTexture?:Texture;

    public hideUntilLoaded(){
        this._hideUntilLoaded = true;
        if( !this._loadedTexture ){
            this._displayNode.object3D.visible = false;
        }
    }
    private _hideUntilLoaded:boolean = false;

    private readonly _textureMaxHeight:number;
    
    constructor(
        file:FileLike|undefined,
        textureMaxHeight:number,
        systemContext:SystemContext,
        messageTargetNode?:Node,
        options?:Options
    ){
        super(
            new Three.Object3D(),
            systemContext
        );
        this._options=options;

        this._textureMaxHeight=textureMaxHeight;
        this.file = file;

        this._displayMesh = new Mesh(
            new Three.BoxGeometry( .01,1,1 ),
            new Three.MeshStandardMaterial({
                transparent:options?.cutout,
            })
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
            messageTargetNode||this
        );
        if( options?.cutout ){
            this._displayNode.collisionInfo = {ignoreCollisions:true};
        }
        this.addChild( this._displayNode );

        this._loadAsync();
    }

    private async _loadAsync(){
        if( ! this.file ){
            return;
        }
        if( IsRemoteFileId(this.file) ){
            const fileId = this.file as RemoteFileId;
            const {urls} = this.systemContext.client;
            const useRender = !this._options?.cutout;
            let textureUrl;
            if(useRender){
                textureUrl = urls.storage.files.byId( fileId ).renders.byIndex(0).get({
                    maxHeight: this._textureMaxHeight
                });
            }else{
                textureUrl = urls.storage.files.byId( fileId ).get();
            }
            this.loadTextureByUrlAsync(textureUrl);
        }else if(this.file instanceof LocalFileWrapper){
            const textureUrl = await this.file.maybeGetThumbnailUrlAsync();
            this.loadTextureByUrlAsync(textureUrl);
        }
    }

    async setTextureUrlAsync(textureUrl:string){
        await this.loadTextureByUrlAsync(textureUrl);
    }
    private async loadTextureByUrlAsync(textureUrl:string){
        const loadingWidget = new FileDisplayerNodeLoadingWidget( this.systemContext );
        this._displayNode.addChild( loadingWidget );

        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }

        try{
            const texture = await Resources.LoadTextureAsync( textureUrl );
            
            this._loadedTexture = texture;
            
            this._displayMesh.material.map = texture;
            this._displayMesh.material.needsUpdate = true;

            assert( texture.image.width );
            assert( texture.image.height );
            this._updateScaleAccordingToAspectRatio(
                texture.image.width / texture.image.height
            );

            if( this._hideUntilLoaded ){
                this._displayNode.object3D.visible = true;
            }
        }catch(error){
            // Sometimes the fileId will no longer be on the server, eg it was deleted.
            return;
        }finally{
            loadingWidget.destroy();
        }
    }

    disposeHook(){
        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }
    }

    /** Wiggles the mesh while it is loading. */
    updateHook( deltaTime:number ){
        // if( !this._loadedTexture ){
        //     this._accumulatedAnimationTime += deltaTime;
        //     this._displayMesh.rotation.x = .02 * Math.sin(
        //         this._accumulatedAnimationTime * 5
        //     );
        //     // this._displayMesh.rotateX( 5 * deltaTime );
        // }else{
        //     this._displayMesh.rotation.x = 0;
        // }
    }

    _updateScaleAccordingToAspectRatio( aspectRatio:number ){
        if( aspectRatio < 1 ){
            this._displayNode.object3D.scale.set(
                1, 1, aspectRatio
            );
        }else if( aspectRatio > 1 ){
            this._displayNode.object3D.scale.set(
                1, 1/aspectRatio, 1
            );
        }else{
            this._displayNode.object3D.scale.set(
                1, 1, 1
            );
        }
    }

    /** Makes the node a bit darker. */
    dim(){
        this._displayMesh.material.color.set(0xDDDDDD);
    }

    /** If previously dimmed, makes the node light again. */
    lightUp(){
        this._displayMesh.material.color.set(0xFFFFFF);
    }
    
}
