import { Dispatch, useState } from "react";
import { symbolName } from "typescript";
import { DoesNotExistResult, NotFoundResult } from "../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { ExpoAccountAnnexRecord } from "../libraries/l2-common/l2-common-ts/definitions/expo/ExpoAccountAnnexRecord";
import { useComponentDidMount, useComponentDidUnmount } from "../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import SessionState from "./accounts/SessionState";
import SystemContext from "./SystemContext";
import { useSystemContext } from "./SystemContextComponents";

export type NumberSettingId = (
    "walkSpeedUnitsPerSecond"|
    "cameraMouseSensitivityFactor"|
    "loopVolumeScale"|
    "oneshotVolumeScale"
);
export type BooleanSettingId = (
    "showControlsHelpUi"
);

export type SettingId = NumberSettingId|BooleanSettingId;

type UpdateListenerCallback = (value:any)=>void;

type SettingsData = {
    [key in SettingId]: string | number | boolean;
};

const DefaultData:SettingsData = {
    walkSpeedUnitsPerSecond:10,
    cameraMouseSensitivityFactor:5,
    showControlsHelpUi:true,
    loopVolumeScale:1,
    oneshotVolumeScale:1,
}

/** Values are centrally retrieved/set via this object. */
export class Settings{
    private _systemContext;

    /* Contains the remote settings, as they were the last time we fetched them. */
    private _remoteSettingsCache?:ExpoAccountAnnexRecord["settings"];

    private _data = {...DefaultData};
    private _listeners:{
        settingId:SettingId,
        callback:UpdateListenerCallback,
    }[]=[];
    
    get localSettingsAreDifferentFromCachedRemote(){
        return JSON.stringify(this._data)!==JSON.stringify(this._remoteSettingsCache);
    }

    public constructor( systemContext:SystemContext, sessionState:SessionState ){
        this._systemContext = systemContext;

        if( sessionState.isLoggedIn ){
            this.loadAsync();
        }
        sessionState.onLoggedInListeners.push(
            async ()=>{
                await this.loadAsync();
            }
        );
    }

    async saveAsync(){
        await this._systemContext.sessionState.patchMyAccountAnnexRecordAsync({
            settings: this._data
        });
        this._remoteSettingsCache = {...this._data};
    }

    async loadAsync(){
        // fetch remote data
        const annex = await this._systemContext.sessionState.getMyAccountAnnexRecordAsync_();
        this._remoteSettingsCache = {...annex?.settings};
        // apply any remotely saved data
        for( const settingId of Object.keys(annex?.settings||{}) ){
            const settingValue = annex?.settings[settingId];
            this.update(settingId as SettingId,settingValue);
        }
    }

    get(id:SettingId){
        return this._data[id];
    }
    
    getNumber(id:NumberSettingId){
        return this.get(id) as number;
    }

    getBoolean(id:BooleanSettingId){
        return this.get(id) as boolean;
    }

    update(id:SettingId,value:any){
        this._data[id] = value;
        const triggeredListeners = this._listeners.filter(
            a=>a.settingId===id
        );
        for(const listener of triggeredListeners){
            listener.callback(value);
        }
    }

    updateNumber(id:NumberSettingId,value:number){
        this.update(id,value);
    }

    updateBoolean(id:BooleanSettingId,value:boolean){
        this.update(id,value);
    }

    addUpdateListener(settingId:SettingId,callback:UpdateListenerCallback){
        this._listeners.push({settingId,callback});
    }

    removeUpdateListener(id:SettingId,callback:UpdateListenerCallback){
        this._listeners = this._listeners.filter(
            a=>!(a.settingId===id&&a.callback===callback)
        );
    }

    get ids(){
        return Object.keys(this._data) as SettingId[];
    }

    get all(){
        return Object.keys(this._data).map(id=>({
            id,
            value: this.get(id as SettingId),
            type: typeof( this.get(id as SettingId) )
        })) as {
            id:SettingId,
            value: string|number|boolean,
            type: "string"|"number"|"boolean",
        }[];
    }

    static get Defaults(){
        return DefaultData;
    }

}

export function useAllSettings(){
    const systemContext = useSystemContext();
    const {settings} = systemContext;
    return settings.all;
}

export function useNumberSetting(id:NumberSettingId){
    return useSetting(id) as [number,Dispatch<number>];
}
export function useBooleanSetting(id:BooleanSettingId){
    return useSetting(id) as [boolean,Dispatch<boolean>];
}

function useSetting(id:SettingId){
    const systemContext = useSystemContext();
    const {settings} = systemContext;
    const initialValue = settings.get(id);
    const [stateValue,setStateValue] = useState(initialValue);
    const listener = (value:any)=>setStateValue(value);
    
    useComponentDidMount( ()=>{
        settings.addUpdateListener(id,listener);
    } );
    
    useComponentDidUnmount( ()=>{
        settings.removeUpdateListener(id,listener);
    } );

    const setValue = (value:any)=>{
        settings.update(id, value);
        setStateValue(value);
    };  

    return [stateValue,setValue] as [any, Dispatch<any>];
}