import "styled-components/macro";

import { CSSProp,css } from "styled-components";
import { useState } from "react";
import LocalFileWrapper from "./FileWrapper";
import { useSystemContext, useTheme } from "../SystemContextComponents";
import { useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import Files from "../../libraries/l2-common/l2-common-ts/common/files/Files";
import Button from "../../components/common/Button";
import { IMAGE_HEIGHT_FILE_UPLOAD_INPUT } from "../../config/TextureHeights";
import { FileLike, IsRemoteFileId, RemoteFileId } from "../booths/subObjects/types/FileLike";


/*
An input component that lets you select a file from your computer,
and will attempt to show a preview, if possible.
*/
export function SingleFileInput({
    value,
    setValue,
    layout,
}: {
    value?: FileLike|undefined;
    setValue: (value: LocalFileWrapper|undefined) => void;
    layout?: CSSProp;
}) {
    const {client} = useSystemContext();
    const remoteFileId = IsRemoteFileId(value) ? value as RemoteFileId : undefined;
    const localFileWrapperOrEmpty = (!IsRemoteFileId(value)) ? value as LocalFileWrapper : undefined;            
    
    const initialBackgroundImageUrl = remoteFileId&& client.urls.storage.files.byId(remoteFileId).renders.byIndex(0).get({
        maxHeight:IMAGE_HEIGHT_FILE_UPLOAD_INPUT,
    });

    const {translate} = useSystemContext();
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(initialBackgroundImageUrl||"");
    const theme = useTheme();
    
    useComponentDidUpdate(async () => {
        if (!localFileWrapperOrEmpty) {
            return;
        }
        const maybeThumbnailUrl = await localFileWrapperOrEmpty.maybeGetThumbnailUrlAsync();
        if (maybeThumbnailUrl) {
            setBackgroundImageUrl(maybeThumbnailUrl);
        }
    });
    
    async function onClickAsync() {
        const maybeFile = await Files.PromptSingleFileUploadDialogAsync_();
        setValue(maybeFile && LocalFileWrapper.FromFile(maybeFile));
    }
    
    return (
    <div
        css={theme.Card.Normal+``+Layout.Root+layout}
    >
        <div
            onClick={onClickAsync}
            css={Layout.MainContents({ backgroundImageUrl })}
        >
            <div css={Layout.PromptText}>
                {!value && translate("t$localFileInputSquarePromptText")}
            </div>
        </div>
        <div css={Layout.CornerButtonContainer}>
            <Button
                type="Icon"
                layout={Layout.CornerButton}
                onClick={() => {
                    if( window.confirm(
                        translate("t$confirmDeleteThis")
                    ) ){
                        setValue(undefined);
                        setBackgroundImageUrl("");
                    }
                }}
            >
                [x]
            </Button>
        </div>
    </div>
    );
}
        
const Layout = {
    Root: css`
        overflow:hidden;
    `,
    // MainContentsContainer: css`
    //     width: 100%;
    //     height: 100%;
    // `,
    /* Note: Firefox's CSS inspector won't show the rules for this element, as the background-image has a data-url, making the rules very, very long */
    MainContents: ({
        backgroundImageUrl
    }:{
        backgroundImageUrl:string
    })=>css`
        width: 100%;
        height: 100%;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        background-image: url(${backgroundImageUrl});
        background-size: cover;
        background-position: center center;
        &:hover{
            cursor: pointer;
        }
    `,
    
    PromptText: css`
        margin-bottom: 10%;
    `,
    
    
    CornerButtonContainer: css`
        position: relative;
        top:-100%;
        width:100%;
        height:100%;
        display: flex;
        pointer-events: none;
        flex-direction: row;
        align-items: flex-end;
        justify-content:flex-end;
        & > * {
            pointer-events: all;
        }
    `,
    
    CornerButton: css`
        width: 1em;
        height: 1em;
    `
};
