import { useState } from "react";
import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents"
import { useComponentDidMount, useComponentDidUnmount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";

import MOUSE_ICON from "../../images/icons/mouse--cc0.png";
import MOUSE_ICON_LMB from "../../images/icons/mouse--cc0--lmb.png";
import MOUSE_ICON_RMB from "../../images/icons/mouse--cc0--rmb.png";
import MOUSE_ICON_BOTH from "../../images/icons/mouse--cc0--both.png";
import { MaybeGetMouseButtonIdFromMouseEvent, MOUSE_BUTTON_LEFT, MOUSE_BUTTON_RIGHT } from "../../logic/input/InputSystem";


/**
 * Shows a mouse icon and the buttons pop up/down along with the actual physical mouse buttons.
 */
export function HelpMenuDisplayMouse({
    layout,
}:{
    layout?:CSSProp,
}){
    const theme = useTheme();
    const [lmbPushed,setLmbPushed] = useState(false);
    const [rmbPushed,setRmbPushed] = useState(false);

    function onMouseDown(event:MouseEvent){
        const buttonId = MaybeGetMouseButtonIdFromMouseEvent( event );
        if( buttonId===MOUSE_BUTTON_LEFT ){
            setLmbPushed(true);
        }
        if( buttonId===MOUSE_BUTTON_RIGHT ){
            setRmbPushed(true);
        }
    }
    function onMouseUp(event:MouseEvent){
        const buttonId = MaybeGetMouseButtonIdFromMouseEvent( event );
        if( buttonId===MOUSE_BUTTON_LEFT ){
            setLmbPushed(false);
        }
        if( buttonId===MOUSE_BUTTON_RIGHT ){
            setRmbPushed(false);
        }
    }

    useComponentDidMount( ()=>{
        document.addEventListener("mousedown", onMouseDown);
        document.addEventListener("mouseup", onMouseUp);
    } );

    useComponentDidUnmount( ()=>{
        document.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mouseup", onMouseUp);
    } );

    const src = (
        (!lmbPushed&&!rmbPushed) ?
            MOUSE_ICON :
        (lmbPushed&&rmbPushed) ?
            MOUSE_ICON_BOTH :
        (lmbPushed) ?
            MOUSE_ICON_LMB :
        (rmbPushed) ?
            MOUSE_ICON_RMB :
        undefined
    );
    const css = layout + '' + Layout;
    return <img css={css} src={src}/>
}

const Layout = css`
`;