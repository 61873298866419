import "styled-components/macro";
import { css } from "styled-components/macro";
import Button from "../components/common/Button";
import { useNavigateWithScene } from "../logic/Routing";
import { useSystemContext } from "../logic/SystemContextComponents";
import { IntroSceneNode } from "./IntroSceneNode";


export function IntroSceneOverlayContentsComponent(){
    const scene = useSystemContext().scene;
    const navigateWithScene = useNavigateWithScene();

    if(!(scene instanceof IntroSceneNode)){
        return <></>;
    }
    const mapId = scene.mapEntity.record.id;

    function goToMapScene(){
        navigateWithScene("/maps/"+mapId)
    }

    return <div css={layout}>
        <div css={buttonsLayout}>
            <Button type="Loud" onClick={()=>scene.swoopDown(goToMapScene)}>Enter</Button>
            <Button type="Quiet" onClick={goToMapScene} >Skip Intro</Button>
        </div>
    </div>;
}

const layout = css`
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:flex-end;
    padding: 2em;
`;


const buttonsLayout = css`
    width:50%;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
`;