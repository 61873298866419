import "styled-components/macro";

import { css } from "styled-components";
import Button from "../../components/common/Button";
import { MenuHeader } from "../../components/common/MenuHeader";
import { MenuSubHeader } from "../../components/common/MenuSubHeader";
import { Vector3Input } from "../../components/common/Vector3Input";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";
import { useForceUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import BoothSubObjectNode from "../booths/subObjects/BoothSubObjectNode";
import { useDialogSystem } from "../dialogs/DialogSystem";
import { BoothEditorSceneNode } from "../scenes/BoothEditorSceneNode";
import { useSystemContext } from "../SystemContextComponents";
import LocalFileWrapper from "../files/FileWrapper";
import { Fragment, useState } from "react";
import { SingleFileInput } from "../files/SingleFileInput";
import FileDisplayerNode from "../booths/subObjects/types/FileDisplayerNode";
import { TEXTURE_HEIGHT_FILE_VIEWER_BOOTH_OBJECT } from "../../config/TextureHeights";
import { FileLike } from "../booths/subObjects/types/FileLike";
import CutoutNode from "../booths/subObjects/types/CutoutNode";
import { isBreakStatement } from "typescript";
import { LightingPresetId, LightingPresets } from "./LightingPresets";
import { Offers } from "./Offers";
import { OfferEntity } from "../../marketplace/OfferEntity";


/**
 * A dialog that lets you edit a booth object.
 */
export function BoothObjectEditDialogContents({
    boothSubObjectNode:objectNode
}:{
    boothSubObjectNode:BoothSubObjectNode
}){
    const {scene,translate,client} = useSystemContext();

    const forceUpdate = useForceUpdate();
    const dialogSystem = useDialogSystem();
    const objectEntity = objectNode.boothSubObjectEntity;
    const objectRecord = objectEntity.record;

    const transform = objectEntity.record.transform as RightHanded3DTransform;
    function setTransform(value:RightHanded3DTransform){
        objectRecord.transform = value;
        objectNode.updateViaSubObjectEntity(objectEntity);
        forceUpdate();
    }

    let [file,setFile_]=useState<FileLike|undefined>(
        objectEntity.primaryFile
    );

    // TODO: do this in a nicer way    
    async function setFileAsync(value:FileLike|undefined){
        
        let url:string|undefined;
        if( value instanceof LocalFileWrapper ){
            url = await value.maybeGetThumbnailUrlAsync();
            delete objectEntity.record.fileIds;
            objectEntity.localFiles = [value];
        }

        const valueIsFileId = typeof (value) === "string";
        if( valueIsFileId ){
            url = client.urls.storage.files.byId(value).renders.byIndex(0).get({
                maxHeight:TEXTURE_HEIGHT_FILE_VIEWER_BOOTH_OBJECT,
            });
        }

        if(url){
            const fileDisplayerNode = objectNode.findOneInDescendants( a=>a instanceof FileDisplayerNode ) as FileDisplayerNode|undefined;
            fileDisplayerNode?.setTextureUrlAsync(url);

            const cutoutNode = objectNode.findOneInDescendants( a=>a instanceof CutoutNode ) as CutoutNode|undefined;
            cutoutNode?.setTextureUrlAsync(url);
        }

        setFile_(value);
    }

    if(! (scene instanceof BoothEditorSceneNode)){
        return <></>;
    }

    function deleteObject(){
        const boothEditorScene = scene as BoothEditorSceneNode;
        boothEditorScene.boothNode?.removeSubObjectNodeByEntity( objectEntity );
        dialogSystem.closeDialog();
    }

    function showLightsDialog(){
        if(!objectRecord?.fileIds){
            return;
        }
        const fileId = objectRecord.fileIds[0];
        const lights = objectRecord.modelViewerLighting;
        dialogSystem.showDialog({ editLights:{objectRecord,save:(lights)=>{
            objectRecord.modelViewerLighting=lights;
            objectNode.updateViaSubObjectEntity(objectEntity);
            forceUpdate();
        }} });
    
    }

    function setLightingByPresetId(presetId:LightingPresetId){
        const preset = LightingPresets[presetId];
        if(!preset){
            delete objectRecord.modelViewerLighting;
        }else{
            objectRecord.modelViewerLighting = [...preset];
        }
        objectNode.updateViaSubObjectEntity(objectEntity);
        forceUpdate();
    }

    function showFileDialog(){
        if(!objectRecord?.fileIds){
            return;
        }
        const fileId = objectRecord.fileIds[0];
        const lights = objectRecord.modelViewerLighting;
        dialogSystem.showDialog({ fileId , lights, boothSubObjectEntity:objectEntity });
    }

    return <div css={Layout.Root}>
        <MenuHeader>Edit Object</MenuHeader>
        <MenuSubHeader>Transform</MenuSubHeader>
        {([
                ["Position","position",0],
                ["Rotation","rotation",0],
                ["Scale","scale",1],
            ] as const).map(([text,key,defaultComponentValue])=>(
        <Fragment key={key}>
            {text}:
            <Vector3Input
                value={transform[key]}
                setValue={value=>{
                    transform[key] = value;
                    setTransform({...transform});
                }}
                defaultComponentValue={defaultComponentValue}
                decimalPlaces={2}
            />
        </Fragment>))}
        <Button onClick={()=>{
            scene.playerBoothEditorBehavior?.freeMoveBoothSubObjectByNode(
                objectNode
            );
            dialogSystem.closeDialog();
        }}>Move object in 3D</Button>
        <MenuHeader>More</MenuHeader>
        <Button onClick={() => {
            if( window.confirm(
                translate("t$confirmDeleteThis")
            ) ){
                deleteObject();
            }
          }}>
              Delete this object
        </Button>
        <MenuHeader>File</MenuHeader>
        <SingleFileInput
            value={file}
            setValue={value=>setFileAsync(value)}
            layout={Layout.SingleFileInput}
        />
        {/* <MultiFileInput
                files={files}
                setFiles={setFiles}
                layout={Layout.MultiFileInput}
        /> */}
        {
            (!!objectEntity.primaryFile) && <>
                <Button onClick={()=>showFileDialog()}>Preview in File Viewer</Button>
            </>
        }
        {
            objectRecord.typeId==="modelViewer" && <>
                <MenuHeader>Lighting</MenuHeader>
                <Button onClick={()=>showLightsDialog()}>Edit Lighting</Button>
                {/* <Button onClick={()=>setLightingByPresetId("0")}>No Lighting</Button> */}
                {/* <Button onClick={()=>setLightingByPresetId("1")}>Type 1</Button> */}
                {/* <Button onClick={()=>setLightingByPresetId("2")}>Type 2</Button> */}
                {/* <Button onClick={()=>setLightingByPresetId("3")}>Type 3</Button> */}
            </>
        }
        {
            objectRecord.typeId==="fileViewer" && <>
            <MenuHeader>Marketplace Offer Link</MenuHeader>
            <Offers node={objectNode} onLink={(offer:OfferEntity)=>{
                const id = offer.record.id;
                if( objectRecord.marketplaceOfferIds?.includes(id) ){
                    objectRecord.marketplaceOfferIds = objectRecord.marketplaceOfferIds.filter(a=>a!==id);
                }else{
                    objectRecord.marketplaceOfferIds = objectRecord.marketplaceOfferIds ?? [];
                    objectRecord.marketplaceOfferIds.push(id);
                }
                objectNode.updateViaSubObjectEntity(objectEntity);
                forceUpdate();
            }}/>
            {/* <Button onClick={()=>setLightingByPresetId("0")}>No Lighting</Button> */}
            {/* <Button onClick={()=>setLightingByPresetId("1")}>Type 1</Button> */}
            {/* <Button onClick={()=>setLightingByPresetId("2")}>Type 2</Button> */}
            {/* <Button onClick={()=>setLightingByPresetId("3")}>Type 3</Button> */}
        </>
    }

        <MenuSubHeader>Done</MenuSubHeader>
        <Button onClick={()=>{
            dialogSystem.closeDialog();
        }}>Done</Button>

    </div>;
}

const Layout = {
    Root: css`
        width: 100%;
        height: 90vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        & > *{
            margin-bottom: .5em;
        }
    `,
    MultiFileInput:css`
        width: 60%;
    `,
    SingleFileInput:css`
        width: 10em;
        height: 10em;
    `,
}