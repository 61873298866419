import SystemContext from "../SystemContext";
import NodeMessageControls from "./NodeMessageControls";

/**
 * The base unit in this game system,
 * functionally, very similar to Godot's Node class
 * Entities provide node behavior (such as nodes in Godot, gameobjects in Unity)
 * and can provide Component behavior (eg Unity) by acting on their parents
 */
export default class Node{
    // [key:string]:any

    _parent?:Node;
    private _children:Node[] = [];
    readonly systemContext:SystemContext;
    get children(){
        return this._children;
    }

    constructor( systemContext:SystemContext ){
        this.systemContext = systemContext;
    }

    /**
     * Disposes and unparents the node and all of its children.
     * */
    destroy(){
        this.disposeHook();
        for(const child of this._children){
            child.destroy();
        }
        this._parent?.removeChild( this );
    }

    destroyAllChildren(){
        while( this._children.length ){
            // Note that destroying a child also unparents it
            this._children[0].destroy();
        }
    }

    addChild( node:Node ){
        this._children.push( node );
        node._parent = this;
        this.addedChildHook( node );
    }

    removeChild( node:Node ){
        const index = this._children.indexOf( node );
        if( index < 0 ){
            throw new Error("Tried to remove non-existent child.");
        }
        this._children.splice( index, 1 );
        node._parent = undefined;
        this.removedChildHook( node );
        // node.removedFromTreeHook();
    }

    update( deltaTime:number ){
        this.updateHook( deltaTime );
        for(const child of this._children){
            child.update( deltaTime );
        }
    }

    sendMessage( messageName:string, parameters?:any[] ){
        
        this._sendMessage(
            messageName,
            new NodeMessageControls(),
            parameters
        );
    }
    _sendMessage( messageName:string, messageControls:NodeMessageControls, parameters?:any[] ){
        if( messageControls.shouldStop ){
            return;
        }
        if( typeof (this as any)[messageName] === "function" ){
            (this as any)[messageName](
                messageControls,
                ...(parameters||[])
            );
        }
        if( messageControls.shouldSendToChildren ){
            for(const child of this._children){
                child._sendMessage(
                    messageName,
                    messageControls,
                    parameters
                );
            }
        }
    }

    /** Called right before all children of this object are also destroyed. */
    disposeHook(){}
    updateHook( deltaTime:number ){}
    addedChildHook( child:Node ){}
    removedChildHook( child:Node ){}


    /* Returns first node that passes the classifier.
       By default, does a breadth-first search */
    findOneInDescendants( classifier:(node:Node)=>boolean ){
        let queue = [...this.children];
        while( true ){
            const first = queue.pop();
            if( !first ){
                return undefined;
            }
            if( classifier(first) ){
                return first;
            }
            for( const child of first.children ){
                queue.push( child );
            }
        }
    }

    get descendants(){
        const result = [...this._children];
        for(const child of this._children){
            result.push( ...child.descendants );
        }
        return result;
    }
}