import "styled-components/macro";

import {NavigationBreadcrumbTrail} from "../topmenu/NavigationBreadcrumbTrail";
import {AccountInfo} from "../topmenu/AccountInfo";
import { css } from "styled-components/macro";
import { useIsLoggedIn, useMySystemAccount } from "../../logic/accounts/SessionState";
import Button from "../common/Button";
import { HelpMenuDisplayButton } from "./HelpMenuDisplayButton";
import { MenuHeader } from "../common/MenuHeader";
import { HelpMenuDisplayMouse } from "./HelpMenuDisplayMouse";


export default function HelpMenuPanelContentsComponent({
    close
}:{
    close:()=>void
}){
    return <div css={Layout.Root}>

        <div css={Layout.Title}>
            <MenuHeader>Controls</MenuHeader>
            <Button onClick={close} layout={Layout.HideButton}>Hide</Button>
        </div>

        <div css={Layout.WASD}>
            <div/>
            <div/>
            <HelpMenuDisplayButton keyboardEventKeys={["w", "ArrowUp"]} text="W"/>
            <div/>
            <div/>

            <div/>
            <HelpMenuDisplayButton keyboardEventKeys={["a", "ArrowLeft"]} text="A"/>
            <HelpMenuDisplayButton keyboardEventKeys={["s", "ArrowDown"]} text="S"/>
            <HelpMenuDisplayButton keyboardEventKeys={["d", "ArrowRight"]} text="D"/>
            <div/>
        </div>

        <div css={Layout.TopRowCell}>
            <HelpMenuDisplayButton layout={Layout.SpaceButton} keyboardEventKey=" " text="SPACE"/>
        </div>
        <div css={Layout.TopRowCell}>
            <HelpMenuDisplayMouse/>
        </div>

        <div css={Layout.BottomRowCell}>
            <div>Move</div>
        </div>
        <div css={Layout.BottomRowCell}>
            <div>Run</div>
        </div>
        <div css={Layout.BottomRowCell}>
            <div>Click (left) / Rotate (right)</div>
        </div>
    </div>
}

const Layout = {
    Root: css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 2em;
        padding: 1em;
        height: 100%;
        width:100%;

        & > * {
            height: 100%;
        }
    `,

    TopRowCell:css`
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    `,
    BottomRowCell: css`
        display: flex;
        align-items:center;
        justify-content:center;
    `,
    
    Title: css`
        grid-row:1/3;
        grid-column: 1/2;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-around;
    `,
    WASD: css`
        display: grid;
        grid-template-columns: 1fr repeat(3, 2em) 1fr;
        & > * {
            width:100%;
            height: 100%;
        }
    `,

    HideButton: css`
        width: 3em;
        height: 2em;
    `,
    SpaceButton:css`
        width:10em;
        max-width: 80%;
    `,
};