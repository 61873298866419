/**
 * An instance of this class is passed as an argument to message listeners, so that the listener
 * can stop the message if needed, for optimization.
 */
export default class NodeMessageControls{
    private _shouldStop = false;
    private _shouldSendToChildren = true;
    get shouldSendToChildren(){ return this._shouldSendToChildren };
    get shouldStop(){ return this._shouldStop };

    stop(){
        this._shouldStop = true;
    }

    /** Prevents the message from being sent to the current Node's children */
    skipSendingToChildren(){
        this._shouldSendToChildren = false;
    }


}