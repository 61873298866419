import assert from "assert";
import SystemContext from "../SystemContext";
import {MapEntity} from "./MapEntity";

export default class MapRepository{
    _systemContext;

    constructor( systemContext:SystemContext ){
        this._systemContext = systemContext;
    }

    async maybeGetByIdAsync(id:string){
        const liveMaps = await this.getLiveMapsAsync();
        
        return liveMaps.find(
            (map:MapEntity)=>map.record.id===id
        );
    }

    async getLiveMapsAsync(): Promise<MapEntity[]> {
        
        if( process.env.REACT_APP__IS_DEMO ){
            return [ MapEntity.DEMO ];
        }

        const jsonObjectArray = await this._systemContext.api.expo.maps.getAsync({
            visibility: "public"
        });

        const result = jsonObjectArray.map(
            (jsonObject:any)=>new MapEntity( jsonObject )
        );

        return result;
    }



}