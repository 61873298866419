import * as Three from "three";
import { Raycaster } from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import Node from "../nodes/Node";
import { ExpoBoothObjectType } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothObjectTypes";
import { ExpoBoothRecordObjectRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import BoothSubObjectNode from "../booths/subObjects/BoothSubObjectNode";
import { BoothEditorSceneNode } from "../scenes/BoothEditorSceneNode";
import { Cursor3DBehaviorNode } from "../player/Cursor3DBehaviorNode";
import { radToDeg } from "three/src/math/MathUtils";
import { Modulo } from "../utility/Math";
import NodeMessageControls from "../nodes/NodeMessageControls";
import { BoothObjectEditDialogContents } from "./BoothObjectEditDialogContents";
import { MOUSE_BUTTON_LEFT } from "../input/InputSystem";

const PLACE_OBJECT_MOUSE_BUTTON = MOUSE_BUTTON_LEFT;

/**  */
export default class BoothEditorPlayerBehaviorNode extends Object3DNode{

    _currentMouseOveredEntities:Node[]=[];

    _raycaster = new Raycaster();

    _editingBoothSubObjectNode?:BoothSubObjectNode;
    _cursor?:Cursor3DBehaviorNode;
    _openObjectEditDialogOnSuccessfulPlace?:boolean=false;
    
    constructor( systemContext:SystemContext, cursor:Cursor3DBehaviorNode ){
        super( new Three.Object3D(), systemContext );

        this._cursor=cursor;

    }

    async startPlaceObjectAsync(
        type:ExpoBoothObjectType,
        openObjectEditDialogOnSuccessfulPlace?:boolean
    ){
        const scene = this.systemContext.scene as BoothEditorSceneNode;
        const position = this._cursor?.position.toArray();
        const record:ExpoBoothRecordObjectRecord = {
            transform: {
                position
            },
            typeId: type,
        };

        const node = await scene.boothNode?.addSubObjectByRecordAsync(record);

        if(node){
            this.freeMoveBoothSubObjectByNode(
                node,
                openObjectEditDialogOnSuccessfulPlace
            );
        }
    }

    freeMoveBoothSubObjectByNode(
        node:BoothSubObjectNode,
        openObjectEditDialogOnSuccessfulPlace?:boolean,
    ){
        this._openObjectEditDialogOnSuccessfulPlace = !!openObjectEditDialogOnSuccessfulPlace;
        this._editingBoothSubObjectNode = node;
    }

    updateHook( deltaTime:number ){
        if( !this._editingBoothSubObjectNode ){
            return;
        }
        const cursor = this._cursor;
        const entity = this._editingBoothSubObjectNode.boothSubObjectEntity;
        const position = cursor?.position.toArray()||[0,0,0];
        if( !cursor?.isInValidPosition ){
            position[1]=-9999;
        }
        if( cursor?.normal ){
            const {x,z} = cursor.normal;
            const rotationY = -Math.atan2(z, x);
            const rotationYDegrees = Modulo( Math.round(radToDeg(rotationY)), 360);
            entity.record.transform.rotation = [0,rotationYDegrees,0];
        }
        entity.record.transform.position = position;
        this._editingBoothSubObjectNode.updateViaSubObjectEntity(entity);

        if( this.systemContext.inputSystem.isMouseButtonJustDown( PLACE_OBJECT_MOUSE_BUTTON ) ){
            this._finishPlaceObject();
        }
    }

    _finishPlaceObject(){
        if(!this._editingBoothSubObjectNode){
            return;
        }
        const placeWasSuccessful = this._cursor?.isInValidPosition;
        if( placeWasSuccessful ){
            if( this._openObjectEditDialogOnSuccessfulPlace ){
                this._openBoothObjectDialog( this._editingBoothSubObjectNode );
            }
        }else{
            const scene = this.systemContext.scene as BoothEditorSceneNode;
            const entity = this._editingBoothSubObjectNode.boothSubObjectEntity;
            scene.boothNode?.removeSubObjectNodeByEntity( entity );
        }
        delete this._editingBoothSubObjectNode;
    }

    Message__ActOnObject(controls:NodeMessageControls, target:Node){
        controls.stop();

        // don't click on objects if you're currently moving one
        // (stops accidental actions when you're placing an object)
        if( this._editingBoothSubObjectNode ){
            return;
        }

        if(target instanceof BoothSubObjectNode){
            this._openBoothObjectDialog(target);
        }
    }

    private _openBoothObjectDialog(boothSubObjectNode:BoothSubObjectNode){
        this.systemContext.dialogSystem.showDialog({
            seeThrough:true,
            contents: <BoothObjectEditDialogContents
                            boothSubObjectNode={boothSubObjectNode}
                        />
        });
    }

}