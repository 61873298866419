import assert from "assert";
import TokenDaoInterface from "./TokenDaoInterface";
// import https from "https";
import FormData from "form-data";
import ApiErrors from "../../l2-common-ts/common/errors/ApiErrors";
import ApiError from "../../l2-common-ts/common/errors/ApiError";
import { IsStringWithTranslations, StringWithTranslationsSchema } from "../../l2-common-ts/definitions/general/StringWithTranslations";
import { HttpStatusCodes } from "../../l2-common-ts/common/errors/HttpStatusCodes";
import { type } from "os";


// this version of axios is used across the entire project
const globalAxiosSingleton = require("axios");


/** Will be undefined when running in a browser environment. */
let createReadStream: any | undefined;

try {
    createReadStream = require("fs").createReadStream;
} catch (e) {
}

/** Will be undefined when running in a browser environment. */
let https: any | undefined;
try {
    https = require("https");
} catch (e) {
}

export interface WebClientConfig {
    tokenDao: TokenDaoInterface,
    baseUrl?: string,
    allowSelfSignedCertificates?: boolean,
    verbose?: boolean,
}

/**
 * Makes web calls for the Liberty Client
 */
export default class WebClient {
    private _tokenDao: TokenDaoInterface;
    readonly baseUrl;
    private _axios;

    verbose?: boolean;



    constructor({ tokenDao, baseUrl, allowSelfSignedCertificates, verbose }: WebClientConfig) {
        this._tokenDao = tokenDao;
        baseUrl = baseUrl || "";
        if (baseUrl.length) {
            assert(baseUrl.endsWith("/"), `Invalid base URL (needs to end with "/"): ` + baseUrl);
        }
        this.baseUrl = baseUrl;

        this.verbose = verbose;

        // this instance of axios is local to this class,
        // since we're putting our token in the header,
        // this keeps it safe from accidentaly use by other libraries
        this._axios = globalAxiosSingleton.create(
            allowSelfSignedCertificates ?
                {
                    httpsAgent: new https.Agent({
                        rejectUnauthorized: false
                    })
                } :
                undefined
        );
    }


    async getAsync(url: string) {
        return await this.requestAsync({ method: "get", url });
    }

    async existsAsync(url:string){
        try{
            await this.getAsync(url);
            return true;
        }catch(e){
            if(e instanceof ApiError && e.httpStatus===HttpStatusCodes.NotFound404){
                return false;
            }
            throw e;
        }
    }

    async postAsync(url: string, body: any) {
        return await this.requestAsync({ method: "post", url, body });
    }

    async deleteAsync(url: string) {
        return await this.requestAsync({ method: "delete", url });
    }

    async patchAsync(url: string, body: any) {
        return await this.requestAsync({ method: "patch", url, body });
    }

    async requestAsync(
        {
            method,
            url,
            body,
            blobOrlocalFilePath,
            blobOrBlobsOrLocalFilePathOrPaths,
            additionalHeaders,
        }:{
            method: string,
            url: string,
            body?: any,
            blobOrlocalFilePath?: Blob | string,
            blobOrBlobsOrLocalFilePathOrPaths?: Blob | Blob[] | string | string[],
            additionalHeaders?: { [header: string]: string }
        }
    ) {
        const {
            localFilePathsToUpload,
            blobsToUpload
        } = requestAsync__parseArguments({
            blobOrlocalFilePath,
            blobOrBlobsOrLocalFilePathOrPaths,
        });

        url = this.baseUrl + url;

        const axiosRequestConfig: any = {
            method,
            url,
            headers: {},
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        };
        if (body) {
            axiosRequestConfig.data = body;
            if( typeof(body)==="string" || typeof(body)==="number" ){
                axiosRequestConfig.headers["Content-Type"] = "text/plain";
            }
        }

        if (localFilePathsToUpload.length || blobsToUpload.length) {
            
            const formData = new FormData();

            if (localFilePathsToUpload.length && !createReadStream) {
                throw new Error("You cannot use file paths to upload files in the browser. Please use Blobs instead of file paths.");
            }
            for (const localFilePath of localFilePathsToUpload) {
                formData.append("", createReadStream(localFilePath));
            }

            for(const blob of blobsToUpload){
                formData.append("", blob);
            }

            /* If there is any body data (eg metadata for the file/files),
            put it into the form, as we're using that as the body for this request */
            if (body) {
                for (const [key, value] of Object.entries(body)) {
                    formData.append(key, value)
                }
            }

            // set the request body to the formdata
            axiosRequestConfig.data = formData;
            // add the form data headers
            axiosRequestConfig.headers = {
                ...axiosRequestConfig.headers,
                "Content-Type": "multipart/form-data",
            }
        }


        // if we have logged in, add that token to our requests
        if (this._tokenDao.hasToken()) {
            axiosRequestConfig.headers["Authorization"] = "Bearer " + this._tokenDao.getToken();
        }

        // if there are any other provided headers, add them
        if (additionalHeaders) {
            for (const header of Object.keys(additionalHeaders)) {
                axiosRequestConfig.headers[header] = additionalHeaders[header];
            }
        }

        if (this.verbose) {
            console.log("WebClient: request:" + JSON.stringify(axiosRequestConfig, null, 4));
        }

        let result: any;
        try {
            result = await this._axios(axiosRequestConfig);
        } catch (error:any) {
            error = maybeTransformAxiosError(error);
            throw error;
        }

        // if the server sent back a newer token for us, use it
        if (result.headers["Authorization"]) {
            const header = result.headers["Authorization"];
            const [type, credential] = header.split(" ");
            if (type === "Bearer") {
                this._tokenDao.setToken(credential);
            } else {
                console.log("Warning: a request from the server came back with the unreadable Authorization header: " + header + ". Expected to receive a token from the server in this type of situation.");
            }
        }

        if (this.verbose) {
            console.log("WebClient: result.data:" + JSON.stringify(result.data, null, 4));
        }
        return result.data;
    }

}

/**
 * Transforms an axios error into a result to return or into an error to throw.
 */
function maybeTransformAxiosError(e: any) {
    if (e.response && e.response.data && e.response.status) {
        const { data, status: statusCode } = e.response;
        const message = (
            data.message && IsStringWithTranslations(data.message)
        ) ? data.message : {};
        const typeId = data.errorTypeId || "none";
        return new ApiError(statusCode, message, typeId);
    }

    return e;
}



function requestAsync__parseArguments(
    {
        blobOrlocalFilePath,
        blobOrBlobsOrLocalFilePathOrPaths,
    }:{
        blobOrlocalFilePath?: Blob | string,
        blobOrBlobsOrLocalFilePathOrPaths?: Blob | Blob[] | string | string[],
    }
){

    let localFilePathsToUpload: string[] = [];
    let blobsToUpload: Blob[] = [];
    if (blobOrBlobsOrLocalFilePathOrPaths) {
        if (
            typeof(blobOrBlobsOrLocalFilePathOrPaths) === "string"
        ) {
            localFilePathsToUpload.push(blobOrBlobsOrLocalFilePathOrPaths);
        } else if(
            blobOrBlobsOrLocalFilePathOrPaths instanceof Blob
        ){
            blobsToUpload.push(blobOrBlobsOrLocalFilePathOrPaths);
        }else if(
            Array.isArray(blobOrBlobsOrLocalFilePathOrPaths)
        ){
            const first = blobOrBlobsOrLocalFilePathOrPaths[0];
            if(
                typeof(first)==="string"
            ){
                localFilePathsToUpload = blobOrBlobsOrLocalFilePathOrPaths as string[];
            }else{
                blobsToUpload = blobOrBlobsOrLocalFilePathOrPaths as Blob[];
            }
        }
    }
    if (blobOrlocalFilePath) {
        if(typeof(blobOrlocalFilePath)==="string"){
            localFilePathsToUpload.push(blobOrlocalFilePath);
        }else if(blobOrlocalFilePath instanceof Blob){
            blobsToUpload.push(blobOrlocalFilePath);
        }
    }
    return {
        localFilePathsToUpload,
        blobsToUpload
    }
}


