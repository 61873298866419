import * as Three from "three";
import { Euler, Quaternion, Vector2, Vector3 } from "three";
import { CollisionRaycaster } from "../collisions/CollisionRaycaster";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import { MakeRandomVector3 } from "../utility/Three";
import PersonNode from "./PersonNode";

type State = "idle"|"move";

const RAYCASTER_NEAR = 1;
const RAYCASTER_FAR = 100;
const COLLISION_DISK_RADIUS = .5;
const COLLISION_DISK_Y = .5;

const WALK_SPEED = 1;
const MIN_SLEEP_TIME = 10;
const MAX_SLEEP_TIME = 60;

/** the number of places the mob will choose from when starting to walk */
const N_WALK_DESTINATION_CANDIDATES = 4;

/** If the player is closer than this (using manhattan distance), will go out of sleep mode. */
const SLEEP_THRESHOLD_MANHATTAN = 50;


export class MobNode extends Object3DNode{
    private _personNode:PersonNode;
    private _rotationY=0;
    private _state:State="idle";
    private _idle_timeUntilMove=0;
    private _move_destination!:Vector3;
    private _raycaster;
    /* the mob will "sleep" when the player isn't nearby */
    private _sleeping = false;

    constructor( systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );

        this._personNode = new PersonNode(
            systemContext,
            this,
            {
                useCollisions: false,
            }
        );
        this.addChild( this._personNode );

        this._raycaster = new CollisionRaycaster(
            this._personNode,
            RAYCASTER_NEAR,
            RAYCASTER_FAR
        );

        // this._addBeacon();

        this._startIdleState();
        this._idle_timeUntilMove = 5*Math.random();
    }

    /* Creates a giant beam that makes it easier to find the mob */
    private _addBeacon() {
        const characterBeacon = Object3DNode.MakePlaceholderNode(this.systemContext, this);
        characterBeacon.object3D.scale.set(.25, 30, .25);
        characterBeacon.object3D.position.set(0, .75, 0);
        this.addChild(characterBeacon);
    }

    updateHook( deltaTime:number ){
        this._updateSleep();
        
        if(this._state==="idle"){
            this._updateIdleState(deltaTime);
        }else{
            this._updateMoveState(deltaTime);
        }
    }

    _updateSleep(){
        const {player} = this.systemContext;
        if(!player){
            return;
        }
        const playerPosition = player.object3D.getWorldPosition( new Vector3() );
        const boothPosition = this.object3D.getWorldPosition( new Vector3() );
        const distanceToPlayer = boothPosition.manhattanDistanceTo(playerPosition);

        this._sleeping = (
            distanceToPlayer>=SLEEP_THRESHOLD_MANHATTAN
        );
        this._personNode.visible= !this._sleeping;
    }

    _startIdleState(){
        this._state = "idle";
        this._move( new Vector3(), false, 0 );
        this._idle_timeUntilMove = MIN_SLEEP_TIME+(MAX_SLEEP_TIME-MIN_SLEEP_TIME)*Math.random();
    }

    _updateIdleState(deltaTime:number){
        
        this._idle_timeUntilMove -= deltaTime;
        if(this._sleeping){
            return;
        }
        if( this._idle_timeUntilMove <= 0 ){
            this._startMoveState();
            return;
        }
    }

    _startMoveState(){
        this._state = "move";
        // pick a move target

        let placesToGoCandidates:{
            destination:Vector3,
            distance:number,
        }[] = [];
        for(let i=0; i<N_WALK_DESTINATION_CANDIDATES; ++i){
            const candidate = this._makeWalkToLocationCandidate();
            placesToGoCandidates.push(candidate);
        }
        // sort descending by distance
        placesToGoCandidates = placesToGoCandidates.sort(
            (a,b)=>b.distance-a.distance
        );
        this._move_destination = placesToGoCandidates[0].destination;
    }

    _updateMoveState( deltaTime:number ){
        const direction = new Vector3().subVectors(
            this._move_destination,
            this.object3D.position
        );


        if( direction.manhattanLength() < 1 ){
            this._startIdleState();
            return;
        }

        const walkDirection = new Vector3(
            direction.x,
            0,
            direction.z
        ).normalize();

        const run = false;
        this._move(walkDirection,run,deltaTime);
    }

    /** Makes a random location that this mob might walk to, based on its current position. */
    private _makeWalkToLocationCandidate() {
        const {scene} = this.systemContext.threeSystem;

        const position = this.object3D.position;
        let direction = MakeRandomVector3();
        direction.y = 0;
        direction.normalize();
        let offset = new Vector3().copy(direction);
        offset.multiplyScalar(RAYCASTER_FAR);

        const raycastFrom = new Vector3(0, COLLISION_DISK_Y, 0).add(position);
        this._raycaster.set(
            raycastFrom,
            direction
        );
        const hits = this._raycaster.getIntersections(scene);

        let distance = RAYCASTER_FAR;
        let destination = new Vector3().addVectors(
            position, offset
        );
        if (hits.length) {
            distance = hits[0].distance - COLLISION_DISK_RADIUS;
            const offsetToHit = new Vector3().copy(direction).normalize().multiplyScalar(distance);
            destination = new Vector3().addVectors(
                position,
                offsetToHit
            );
        }
        return { distance, destination };
    }

    private _move(normalizedDirection:Vector3, run:boolean, deltaTime:number){
        this._personNode?.walk({
            normalizedDirection,
            walkSpeed:WALK_SPEED,
            runSpeed:WALK_SPEED*2,
            run,
            deltaTime
        });
    }
    
}