import assert from "assert";
import React, { ReactNode, useState } from "react";
import { useLocation,useParams } from "react-router-dom";

/** Calls the method at the "componentDidMount" time, and also works with async functions.
 * 
 * This lets you write this:
 * UseComponentDidMountAsync( async()=>{
 *     ...
 * } )
 * 
 * instead of this:
 * React.useEffect( ()=>{
 *     async()=>{
 *      //componentDidMount
 *      ...
 *     }
 * }, [] );
 * 
*/
export function useComponentDidMount( callbackCanBeAsync:any ){
    React.useEffect( ()=>{
        // componentDidMount timing is here
        callbackCanBeAsync();
    }, [] );
}

// type ResultGetter<Type> = ()=>Type|Promise<Type>|undefined|Promise<undefined>;

type ResultGetter<Type> = ()=>Promise<Type|undefined>|Type|undefined;

/*
    Lets you use the result of a (async) function in a hook-like way, instead of having to create a local state variable.
*/
export function useResult<Type>( getterCanBeAsync:ResultGetter<Type>, dependencies?: React.DependencyList ){
    const [value, setValue] = useState<Type>();
    useAsyncEffect( async ()=>{
        const value = await getterCanBeAsync();
        setValue(value);
    }, dependencies );
    return value;
}


export function useResultOnMount<Type>( getterCanBeAsync:ResultGetter<Type> ){
    return useResult<Type>( getterCanBeAsync, [] );
}

/** Works with async functions */
export function useAsyncEffect( callbackCanBeAsync:any, dependencies?: React.DependencyList ){
    React.useEffect( ()=>{
        callbackCanBeAsync();
    }, dependencies );
}

export function useComponentDidUpdate( callbackCanBeAsync:any ){
    return useAsyncEffect(callbackCanBeAsync);
}

/** Returns a new function that, when invoked, will cause something similar to forceUpdate in a class-based component. */
export function useForceUpdate(){
    const [,forceUpdate] = React.useReducer(_=>!_,false);
    return forceUpdate;
}

export function useComponentDidUnmount( callbackCanBeAsync:any ){
    React.useEffect( ()=>{
        return ()=>{
            // componentDidUnmount timing is here
            callbackCanBeAsync();
        }
    }, [] );
}

/** 
 * @returns a new URLSearchParams instance with the current url's query parameters
 */
export function useQueryParameters(){
    const {search} = useLocation();
    return new URLSearchParams(search);
}

/**
 * @returns A single query parameter, by name.
 */
export function useQueryParameter(parameterName:string){
    const parameters =useQueryParameters();
    const value = parameters.get(parameterName);
    if(value===null){
        return undefined;
    }
    return value;
}
export function useQueryParameterInt(parameterName:string){
    const string = useQueryParameter(parameterName);
    if(!string){
        return undefined;
    }
    const value = parseInt(string);
    if(isNaN(value)){
        return undefined;
    }
    return value;
}


export function useUrlParameter(parameterName:string){
    const parameters:any = useParams();
    if( parameters[parameterName]===undefined ){
        throw new Error("Missing expected URL parameter: "+parameterName);
    }
    return parameters[parameterName] as string;
}

export function useMaybeUrlParameter(parameterName:string){
    const parameters:any = useParams();
    if( parameters[parameterName]===undefined ){
        return undefined;
    }
    return parameters[parameterName] as string;
}


export function useStateWithLocalStorage({localStorageKey,initialValue}:{localStorageKey:string,initialValue:string}){
    if(localStorage.getItem(localStorageKey)){
        initialValue = ""+localStorage.getItem(localStorageKey);
    }
    const [value,setValue] = useState(initialValue);
    useComponentDidMount(()=>{
        if(localStorage.getItem(localStorageKey)){
            setValue(""+localStorage.getItem(localStorageKey));
        }
    })
    useComponentDidUpdate(()=>{
        localStorage.setItem(localStorageKey, value);
    })
    return [value,setValue] as [typeof value,typeof setValue];
}  

/**
 * @returns
 *  [
 *      <a component or undefined, depending on the value of the condition>,
 *      <a setter, that you pass the condition to>
 * ]
 */
export function useConditionalComponent(
    component:JSX.Element,
    defaultValue?:boolean
){
    const [value,setValue] = useState<boolean>(defaultValue||false);
    return [
        value?component:undefined,
        setValue
    ] as const;
}


/**
 * Causes the callback to be repeatedly called, starting from when it is mounted, and ending shortly after it is unmounted.)
 * @param callback Return "STOP" if you want the loop to stop.
 */
export function useLoop( callback:()=>void|undefined|"STOP"|Promise<void|undefined|"STOP">, intervalMs:number ){
    let shouldStop = false;
    const loop = async ()=>{
        const result = await callback();
        if( result==="STOP"){
            shouldStop=true;
        }
        if( ! shouldStop ){
            setTimeout( loop, intervalMs );
        }
    }
    useComponentDidMount( ()=>{
        loop();
    } );
    useComponentDidUnmount( ()=>{
        shouldStop=true;
    } );
}