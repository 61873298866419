import { NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { ChatMessageRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";
import { PREFERRED_LANGUAGE_IDS_TODO } from "../../logic/messages/ChatRoomEntity";
import SystemContext from "../../logic/SystemContext";

export default class ChatMessageEntity{
    readonly record;
    readonly _systemContext;
    constructor(record:ChatMessageRecord,systemContext:SystemContext){
        this.record=record;
        this._systemContext=systemContext;
    }

    async makeResolvedOwnerNameAsync(){
        const {accountRepository} = this._systemContext;
        const ownerRecord = await accountRepository.getPublicRecordByIdAsync( this.record.ownerAccountId );

        if( ownerRecord instanceof NotFoundResult ){
            return "(not found)";
        }

        const resolvedName = ownerRecord ? (
            ownerRecord.properName ?
            MaybeResolveStringWithTranslations(ownerRecord.properName,PREFERRED_LANGUAGE_IDS_TODO) :
            ownerRecord.slug
        ) : undefined;

        return resolvedName;
    }

    get resolvedBody(){
        return this.record.body ? (
            MaybeResolveStringWithTranslations(
                this.record.body,
                PREFERRED_LANGUAGE_IDS_TODO
            ) || "(no body)"
        ) : "(no body)"
    }
}
