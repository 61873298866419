import React, { useContext, useReducer, useState } from "react";

import SystemContext from "./SystemContext";
import NeumorphicTheme from "../theme/NeumorphicTheme";
import { Theme } from "../theme/Theme";


let counterForForceUpdate=0;

interface ContextInterface {
    systemContext:SystemContext,
    forceSystemContextUpdate: ()=>void,
    theme: Theme,
}
const NoContext = null;

export const SystemContextReactContext = React.createContext<ContextInterface | typeof NoContext>(NoContext);


// Initializing this inside the module file to prevent double-initialization.
const SYSTEM_CONTEXT = new SystemContext( window );

export function SystemContextProviderComponent( props: {children:any} ){
    const [systemContext,]    = useState<SystemContext>( SYSTEM_CONTEXT );
    // A simple boolean isn't enough to force update, b/c sometimes the close event gets called twice in close succession. So we're using a counter number instead.
    const [,forceUpdate]      = useReducer(_=>(++counterForForceUpdate)%64, counterForForceUpdate );
    systemContext.forceReactUpdate = forceUpdate;

    const [theme,] = useState<Theme>(NeumorphicTheme);
    

            // By uncommenting this, you can show that the initializations are called twice, which is why
            // we keep SYSTEM_CONTEXT in a local variable instead of initializing it inside of
            // React.useState()
            // Note: According to this article, the double-render is a feature of <React.StrictMode> in index.tsx, to help check for unmounting errors: https://mariosfakiolas.com/blog/my-react-components-render-twice-and-drive-me-crazy/
            /** const [,]    = React.useState<boolean>( (()=>{
                // This initializer gets called twice, despite the SystemContextProviderComponent component only being used as the root element of index.tsx
                console.log("Normally we would initialize the SystemContext object here.");
                return true;
            })() ); **/
    return <SystemContextReactContext.Provider
        value={{
            systemContext,
            forceSystemContextUpdate: forceUpdate,
            theme,
        }}
    >
        {props.children}
    </SystemContextReactContext.Provider>;

}


function useSystemContextReactContext() {
    const context = useContext(SystemContextReactContext);
    if (context === NoContext) {
        throw new Error("Missing context provider.");
    }
    return context;
}


export function useTheme(){
    const context = useSystemContextReactContext();
    return context.theme;
}

export function useSystemContext(){
    const context = useSystemContextReactContext();
    return context.systemContext;
}

export function useForceSystemContextUpdate(){
    const context = useSystemContextReactContext();
    return context.forceSystemContextUpdate;
}


