import assert from "assert";
import CameraAnimationPlayerNode from "../logic/camera/CameraAnimationPlayerNode";
import { MapEntity } from "../logic/maps/MapEntity";
import { SceneNode } from "../logic/scenes/SceneNode";
import SystemContext from "../logic/SystemContext";
import { IntroAvatarNode } from "./IntroAvatarNode";
import { IntroCameraSwoopDownAnimation as IntroCameraSwoopDownAnimation } from "./IntroCameraSwoopDownAnimation";
import { libertyVenueBuildingAvatarStartPosition } from "./IntroConstants";
import { IntroEnvironmentNode } from "./IntroEnvironmentNode";

const swoopDownAnimation = new IntroCameraSwoopDownAnimation();

export class IntroSceneNode extends SceneNode{
    readonly mapId;
    private _mapEntity!:MapEntity;
    get mapEntity(){ return this._mapEntity; }

    private readonly _swoopDownPlayer;
    private _environment?:IntroEnvironmentNode;
    private readonly _avatar;
    private _onSwoopDownComplete?:()=>void|Promise<void>
    
    constructor(mapId:string,context:SystemContext){
        super(context);
        this.mapId = mapId;

        const camera = context.threeSystem.camera;
        // camera.far = 2000 * 2;

        const swoopDownPlayer = new CameraAnimationPlayerNode({
            animation:swoopDownAnimation,
            parent: this,
            systemContext: this.systemContext,
            onComplete: ()=>{
                this._onSwoopDownComplete?.call(null);
                delete this._onSwoopDownComplete;
            }
        });
        this.addChild(swoopDownPlayer);
        swoopDownPlayer.elapsedTime = 0;
        this._swoopDownPlayer=swoopDownPlayer;

        this._avatar = new IntroAvatarNode(this.systemContext);
        this._avatar.object3D.position.set(...libertyVenueBuildingAvatarStartPosition);
        this.addChild(this._avatar);
    }

    async loadAsync(){
        const context = this.systemContext;
        const liveMaps = await context.mapRepository.getLiveMapsAsync();
        const mapEntity = liveMaps.find(
            map=>map.record.id===this.mapId
        );
        assert( mapEntity );

        this._mapEntity = mapEntity;

        this._environment = new IntroEnvironmentNode(this.mapEntity,context);
        this.addChild(this._environment);
        await this._environment.loadAsync();
    }

    async unloadAsync(){
        
    }

    swoopDown(onSwoopDownComplete?:()=>void|Promise<void>){
        this._onSwoopDownComplete = onSwoopDownComplete;
        this._environment?.removeBuildingRotation();
        this._swoopDownPlayer.play();
        this._avatar.startMoving();
    }

    walkIn(){

    }
}