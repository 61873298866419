import { z } from "zod";
import { LanguageId, LanguageIdSchema } from "./LanguageId";


// TODO: find a way to put LangaugeId into the key validation
export const StringWithTranslationsSchema = z.object({
}).catchall(
    z.string()
).describe(
    `The keys of this object are LanguageIds`
);

export interface StringWithTranslations {
    [key:LanguageId]: string
}

export function IsStringWithTranslations(specimen:any){
    const keys = Object.keys(specimen);
    return keys.every(
        key=>LanguageIdSchema.safeParse(key).success && typeof(specimen[key])==="string"
    );
}