import { css } from "styled-components";


export const PanelContentsComponentLayout = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

