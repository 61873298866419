import { useSystemContext } from "../SystemContextComponents";

/* returns undefined if the file has no extension */
export function FileNameToExtension(fileName:string){
    const fileNameSplit = fileName.split(".");
    const type = fileNameSplit[fileNameSplit.length-1];
    return type ? "."+type : undefined;
}
export function MakeFileUrlWithExtension(fileId:string,fileName?:string){
    const {client} = useSystemContext();
    const extension = fileName ? FileNameToExtension(fileName) : undefined;
    const apiPath = client.urls.storage.files.byId(fileId).get();
    const fileUrl = apiPath+(extension||"");
    return fileUrl;
}