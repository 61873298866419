import { MaybeResolveStringWithTranslations } from "../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { StringWithTranslations } from "../libraries/l2-common/l2-common-ts/definitions/general/StringWithTranslations";
import { MarketplaceOfferRecord } from "../libraries/l2-common/l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
import { HOMEPAGE_URL } from "../logic/Environment";

export class OfferEntity{
    readonly record;
    constructor(record:MarketplaceOfferRecord){
        this.record=record;
    }

    get title(){
        return resolve__TODO(this.record.title);
    }


    get description(){
        return resolve__TODO(this.record.description);
    }

    get attributes(){
        return this.record.attributes.map(a=>({
            name: resolve__TODO(a.name),
            value: resolve__TODO(a.value)||"-",
        }));
    }

    // TODO: pull from somewhere
    get marketplaceUrl(){
        return HOMEPAGE_URL+"marketplace/offer/"+this.record.id;
    }
}


function resolve__TODO(value:StringWithTranslations){
    return MaybeResolveStringWithTranslations(value,["en"])||"";
}