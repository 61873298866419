import "styled-components/macro";
import { css } from "styled-components/macro";
import { useResultOnMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext, useTheme } from "../../logic/SystemContextComponents";
import Button from "../common/Button";
import LinkButton from "../common/LinkButton";
import { MenuHeader } from "../common/MenuHeader";

export function DialogMarketplaceSidePanelContents(
    {
        offerIds
    }:{
        offerIds:string[]
    }
){
    const theme = useTheme();
    const context = useSystemContext();
    const client = context.client;
    const offers = useResultOnMount( async ()=>{
        const result = [];
        for(const id of offerIds){
            const offer = await context.offerRepository.getByIdAsync(id);
            if(!offer){
                continue;
            }
            result.push(offer);
        }
        return result;
    });
    
    return <div css={Layout.Root}>
        {offers?.map(offer=><div css={Layout.Item+""+theme.Card.Normal}>
            <div css={Layout.ItemTextBlock}>
                <MenuHeader>
                {offer.title}
                </MenuHeader>
            </div>
            {offer.record.attachmentFileIds.map(attribute=>{
                const src = client.urls.storage.files.byId(attribute).get();
                return <img css={Layout.ItemImage} src={src} />;
            })}
            <div css={Layout.ItemTextBlock}>
                Title: {offer.title} 
            </div>
            {offer.attributes.map(attribute=>
            <div css={Layout.ItemTextBlock}>
                {attribute.name}: {attribute.value}
            </div>)}
            <div css={Layout.ItemTextBlock}>
                Description: {offer.description}
            </div><Button>
            <a href={offer.marketplaceUrl} target="_blank">
                Open in Marketplace
            </a></Button>
            {/* <div css={Layout.ItemTextBlock}>
                Data: {JSON.stringify(offer.record)}
            </div> */}
        </div>)}
    </div>
}

const Layout = {
    Root: css`
        width:100%;
        height: 100%;
        max-height: 80vh;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        padding-right: 1em;
    `,
    Item: css`
        margin-bottom: 1em;
        padding: 2em;
        width: 100%;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* cursor:pointer; */
        &:hover{
            /* background-color: #ffffff44; */
        }
    `,
    ItemImage: css`
        height: 20em;
        width: auto;
        border-radius: 1em;
        margin-bottom: 2em;
    `,
    ItemTextBlock: css`
        width:100%;
        max-width: 45%;
        margin-bottom: 1em;
    `,
}