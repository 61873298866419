import { System } from "typescript";
import { map } from "zod";
import SystemContext from "../SystemContext";
import { SceneNode } from "./SceneNode";
import assert from "assert";
import FloorNode from "../floors/FloorNode";
import { MapEntity } from "../maps/MapEntity";
import BoothNode from "../booths/BoothNode";
import { BoothEntity } from "../booths/BoothEntity";
import { UseUrlResourceId } from "../utility/URLs";
import FloorEnvironmentNode from "../floors/FloorEnvironmentNode";
import { Cursor3DBehaviorNode } from "../player/Cursor3DBehaviorNode";
import BoothEditorPlayerBehaviorNode from "../boothEditor/BoothEditorPlayerBehaviorNode";
import { PlayerNode } from "../player/PlayerNode";

const BackgroundMap = new MapEntity({
    id:"background",
    title:{en:""},
    venueId: "liberty",
    creationTimeSeconds:0,
    ownerAccountId:"",
    slug:"",
    visibility: "public",
});
const BackgroundFloor = 1;

export class BoothEditorSceneNode extends SceneNode{
    private _boothEntity?:BoothEntity;
    get boothEntity(){ return this._boothEntity; }

    private _boothNode?:BoothNode;
    get boothNode(){ return this._boothNode; }

    private _boothId?:string;
    get boothid(){ return this._boothId; }

    private _playerNode!:PlayerNode;
    get playerNode(){ return this._playerNode; }

    public playerBoothEditorBehavior?:BoothEditorPlayerBehaviorNode;

    constructor(context:SystemContext){
        super(context);
    }

    async loadAsync(){
        const context = this.systemContext;
        const threeSystem = context.threeSystem;

        threeSystem.camera.position.set(-8,8,8);
        threeSystem.camera.position.set(-20,20,10);
        threeSystem.camera.lookAt(0,0,0);

        const background = new FloorEnvironmentNode("liberty",1,context);
        await background.loadAsync();
        this.addChild( background );

        const player = new PlayerNode( context );
        this._playerNode= player;
        player.object3D.position.x = 30;
        const cursor = new Cursor3DBehaviorNode(context,player);
        player.addChild( cursor );
        this.playerBoothEditorBehavior = new BoothEditorPlayerBehaviorNode(context, cursor);
        player.addChild( this.playerBoothEditorBehavior );
        this.addChild( player );

        const boothId = UseUrlResourceId("booths");
        if( boothId ){
            await this.loadBoothAsync(boothId);
        }
    }

    async unloadAsync(){
        
    }

    async loadBoothAsync(boothId:string){
        const context = this.systemContext;

        if( this.boothEntity ){
            await this.unloadBoothAsync();
        }

        this._boothId = boothId;

        const boothEntity = await context.boothRepository.getByIdAsync(boothId);

        if(!boothEntity){
            throw new Error("Booth not found by id: "+boothId);
        }

        this._boothEntity = boothEntity;

        const boothNode = new BoothNode( boothEntity, context );
        this.addChild( boothNode );
        this._boothNode = boothNode;

        if(this._playerNode){
            this._playerNode.object3D.position.copy( boothNode.teleportPosition );
        }
    }

    async unloadBoothAsync(){
        const booths = this.children.filter(
            a=> a instanceof BoothNode
        ) as BoothNode[];
        for(const booth of booths){
            booth.destroy();
        }
        delete this._boothEntity;
    }

}