import "styled-components/macro";

import { css } from "styled-components";
import { useContext, useState } from "react";
import Button from "../../components/common/Button";
import { MarketplaceOfferRecord } from "../../libraries/l2-common/l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
import { useComponentDidMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext } from "../SystemContextComponents";
import { Offer } from "./Offer";
import { OfferEntity } from "../../marketplace/OfferEntity";
import BoothSubObjectNode from "../booths/subObjects/BoothSubObjectNode";

export function Offers({
    node: node,
    onLink
}:{node:BoothSubObjectNode,onLink:(offer:OfferEntity)=>any}){

    const [offers,setOffers] = useState<MarketplaceOfferRecord[]>([]);
    const {client,sessionState} = useSystemContext();

    async function fetchOffers(){
        const id = await sessionState.getMyAccountIdAsync();
        setOffers(( await client.api.marketplace.offers.getAsync() ).filter(
            a=>a.ownerAccountId===id
        ));
    }


    return <>
        <Button onClick={()=>fetchOffers()}>Fetch My Offers</Button>
    <div css={Layout.Root}>
        {[...offers,...offers,...offers,...offers,...offers,].map(a=><Offer includes={node.boothSubObjectEntity.record.marketplaceOfferIds?.includes(a.id)} onLink={onLink} key={JSON.stringify(a)} offerRecord={a}/>)}
    </div></>;
}

const Layout = {
    
    Root: css`
        width:50%;
        height: 100%;
        max-height: 20em;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        overflow-y: scroll;
        padding-right: 1em;
    `,
}