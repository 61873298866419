export default {
    /** always ends with a "/" */
    staticContentUrl: "https://static.liberty-hjc.com/expo-app/",
    /** always ends with a "/" */
    apiUrl: "https://api.liberty-hjc.com/",
    // these keys are for expo.liberty-hjc.com
    captchaV2SiteKey: "6LeV2rckAAAAAHFWO4fPEUazdj99w-_LnyQjjQk3",
    captchaV3SiteKey: "6Ldm27ckAAAAACjAh-VXL4UYJtcY08ZsnVB8miER",
    // these keys are for maruket.com
    // captchaV2SiteKey: "6LeoNochAAAAADgkVJ6nYINaJ5WtQT3SiC3sk8zR",
    // captchaV3SiteKey: "6LetOochAAAAAFD_h0rg0JAfkeclcqvzK47TYh8K",
}