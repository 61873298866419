import { useState } from "react";
import "styled-components/macro";
import { css } from "styled-components/macro";
import Button from "../../components/common/Button";
import { MenuHeader } from "../../components/common/MenuHeader";
import { MenuSubHeader } from "../../components/common/MenuSubHeader";
import { StringWithTranslationsEditBox } from "../../components/common/StringWithTranslationsEditBox";
import { AreRecordsEqual, MakeCopyOfRecordObject as MakeCopyOfRecord } from "../../libraries/l2-common/l2-common-ts/common/utility/Objects";
import { ExpoBoothObjectType } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothObjectTypes";
import { AccessGroups } from "../../libraries/l2-common/l2-common-ts/definitions/system/AccessGroup";
import { useForceUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useMySystemAccount } from "../accounts/SessionState";
import { BoothSubObjectTypeUsesFiles } from "../booths/BoothSubObjectEntity";
import { FileLike } from "../booths/subObjects/types/FileLike";
import DocumentTitle from "../dom/DocumentTitle";
import LocalFileWrapper from "../files/FileWrapper";
import { SingleFileInput } from "../files/SingleFileInput";
import { useNavigateWithScene } from "../Routing";
import { BoothEditorMainMenuSceneNode } from "../scenes/BoothEditorMainMenuSceneNode";
import { BoothEditorSceneNode } from "../scenes/BoothEditorSceneNode";
import { useSystemContext } from "../SystemContextComponents";

/**
 * TODO: remove this functionality after
 * boothHasChanges is correctly detected
 * */
const ALLOW_SAVING_WITHOUT_CHANGES = true;

const availableSubObjectTypes:ExpoBoothObjectType[] = [
    "modelViewer",
    "fileViewer",
    "cutout",
    // "table"
];

// TODO: refactor file so that it's not so long
export function BoothEditorMenuComponent(){
    const navigateWithScene = useNavigateWithScene();
    // TODO: this also needs to get called when the user moves objects around (consider watching for changes with useLoop)
    const forceUpdate = useForceUpdate();
    const systemContext = useSystemContext();
    const myAccount = useMySystemAccount();
    const {scene,translate,client,sessionState} = systemContext;
    const [savedBoothRecord,setSavedBoothRecord] = useState(
        MakeCopyOfRecord((scene as any)?.boothEntity?.record)
    );

    // TODO: refactor
    const [signageFile,setSignageFile] = useState<FileLike|undefined>((scene as any)?.boothEntity?.record.signageFileId as string|undefined);
    const [savedSignageFile,setSavedSignageFile] = useState(signageFile);

    if(! (scene instanceof BoothEditorSceneNode)){
        return <></>;
    }
    const {boothEntity} = scene;
    if(!boothEntity){
        return <></>;
    }
    const boothRecord = boothEntity.record;
    const boothHasChanges = (
        !AreRecordsEqual(boothRecord,savedBoothRecord) ||
        !AreRecordsEqual(savedSignageFile,signageFile)
    );
    const canSave = (
        (
        boothHasChanges ||
            ALLOW_SAVING_WITHOUT_CHANGES
        ) &&
        sessionState.isLoggedIn &&
        (
            myAccount?.id===boothRecord.ownerAccountId ||
            // TODO: replace with "can act as owner" logic instead of hard-coding the group check
            myAccount?.accessGroups.includes(AccessGroups.Admin)
        )
    );

    async function saveAsync(){
        if(!boothEntity){
            return;
        }
        console.log("save: "+boothEntity?.resolvedTitle+" : "+boothEntity?.subObjects.map(a=>a.localFiles.length).join(","))
    
        // upload files
        let fileNumber = 0;
        // maybe upload signage file
        if( signageFile instanceof LocalFileWrapper ){
            const filename = (
                signageFile.file.name ||
                boothEntity.resolvedTitle+" Sign File "+(++fileNumber)
            );
            const fileId = await client.api.storage.files.postAsync(
                signageFile.blob,
                {
                    filename,
                    permissions: "anyone",
                }
            );
            boothRecord.signageFileId = fileId;
        }
        // maybe upload individual files
        for( const subObject of boothEntity.subObjects ){
            console.log(subObject.localFiles);
            // upload local files to server
            for(const file of subObject.localFiles){
                const filename = (
                    file.file.name ||
                    boothEntity.resolvedTitle+" File "+(++fileNumber)
                );
                console.log("UPLOADING "+filename)
                const fileId = await client.api.storage.files.postAsync(
                    file.blob,
                    {
                        filename,
                        permissions: "anyone",
                    }
                );
                // since the file was uploaded, we need to add it into the record
                if( subObject.record.fileIds ){
                    subObject.record.fileIds.push(fileId)
                }else{
                    subObject.record.fileIds = [fileId];
                }
                console.log(subObject.record);
            }
        }
        console.log("SAVE",boothRecord);
        // save booth data
        await client.api.expo.booths.byId(boothRecord.id).patchAsync(boothRecord);
        setSavedBoothRecord( MakeCopyOfRecord(boothRecord) );
        setSavedSignageFile(signageFile);
    }

    const title = boothEntity.resolvedTitle+(boothHasChanges?"*":"")+" | Booth Editor | Liberty";
 
    return <div css={Layout.Root}>
        <DocumentTitle title={title}/>
        <MenuHeader>{translate("t$boothEditor_title")}</MenuHeader>
        <MenuSubHeader>{translate("t$boothEditor_field_title")}</MenuSubHeader>
        <StringWithTranslationsEditBox
                value={boothRecord.title}
                setValue={value=>{
                    boothRecord.title = value;
                    forceUpdate();
                }}
        />
        <MenuSubHeader>{translate("t$boothEditor_field_description")}</MenuSubHeader>
        <StringWithTranslationsEditBox
                value={boothRecord.description}
                setValue={value=>{
                    boothRecord.description = value;
                    forceUpdate();
                }}
        />
        <MenuSubHeader>{translate("t$boothEditor_field_signage")}</MenuSubHeader>
        {/* <div>{boothRecord.signageFileId||"(none)"}</div> */}
        <SingleFileInput
            value={signageFile}
            setValue={value=>{
                setSignageFile(value);
            }}
        />
        <MenuSubHeader>{translate("t$boothEditor_field_type")}</MenuSubHeader>
        <div>{boothRecord.typeId}</div>
        <MenuSubHeader>{translate("t$boothEditor_field_objects")}</MenuSubHeader>
        {availableSubObjectTypes.map(subObjectType=>
            <Button key={subObjectType} onClick={async ()=>{
                await scene.playerBoothEditorBehavior?.startPlaceObjectAsync(
                    subObjectType,
                    BoothSubObjectTypeUsesFiles(subObjectType)
                );
                forceUpdate();
            }}>
                {subObjectType}
            </Button>
        )}
        <MenuSubHeader>{translate("t$boothEditor_saveLoadHeader")}</MenuSubHeader>
        <Button disabled={!canSave} onClick={()=>saveAsync()}>
            {translate("t$boothEditor_saveBooth")}
        </Button>
        {/* <Button onClick={()=>{
            if( !boothHasChanges || window.confirm(translate("t$boothEditor_promptExitNoSave")) ){
                navigateWithScene("/editor");
            }
        }}>
            {translate("t$boothEditor_exit")}
        </Button> */}
    </div>
}

const Layout = {
    Root: css`
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        & > * {
            width: 90%;
            margin-bottom: .5em;
        }
    `,
};

