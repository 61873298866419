import { useState } from "react";
import { ChatRoomRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatRoomRecord";
import { useComponentDidMount, useResult, useResultOnMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useChatRoomRepository } from "./MessageRepositoryUtilities";

export function useMyChatRooms(){
    const repository = useChatRoomRepository();
    return useResultOnMount(
        ()=>repository.getMyRoomsAsync()
    );
}

export function useFullChatRoom(roomId:string){
    const repository = useChatRoomRepository();
    return useResultOnMount(
        ()=>repository.getFullRoomByIdAsync(roomId)
    );
}

export function useResolvedChatRoomTitle(roomId:string){
    const repository = useChatRoomRepository();
    return useResultOnMount(
        ()=>repository.makeResolvedTitleByIdAsync(roomId)
    );
}