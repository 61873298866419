import assert from "assert";
import { ChatMessageRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";

import MessageRepository from "./MessageRepository";
import MessageSystem, { useMessageSystem } from "./MessageSystem";

const REFRESH_INTERVAL = 5000;

// Sanity check, to make sure we aren't accidentally spawning multiple copies of this later
let instanceCount = 0;

export default class MessageWatcher {

    private _messageSystem;
    private _listeners: any = [];
    private _recognizedMessageIds: any = [];
    private readonly _repository: MessageRepository;

    constructor(messageSystem:MessageSystem) {
        this._messageSystem = messageSystem;
        this._repository = this._messageSystem.messageRepository;
    }

    start() {
        assert( instanceCount===0 );
        ++instanceCount;
        this._mainLoopAsync();
    }

    /** callback receives an array of new message(s) */
    addListener(repository: MessageRepository, listenerCallback: (newMessages: ChatMessageRecord[])=>void ) {
        this._listeners.push(listenerCallback);
    }

    removeListener(repository: MessageRepository, listenerCallbackToRemove: (newMessages: ChatMessageRecord[])=>void ) {
        this._listeners = this._listeners.filter(
            (callback: any) => callback !== listenerCallbackToRemove
        );
    }

    private async _mainLoopAsync() {
        await this._checkForNewMessagesAsync( this._repository );
        setTimeout(
            () => { this._mainLoopAsync(); },
            REFRESH_INTERVAL
        );
    }

    private async _checkForNewMessagesAsync( repository: MessageRepository ) {
        const currentMessages = await repository.getAllAsync();
        if(!currentMessages){
            return;
        }
        const recognizedMessageIds = this._recognizedMessageIds;
        const newMessages = [];
        for (const message of currentMessages) {
            if (!recognizedMessageIds.includes(message.id)) {
                recognizedMessageIds.push(message.id);
                newMessages.push(message);
            }
        }
        if (newMessages.length) {
            for (const listenerCallback of this._listeners) {
                listenerCallback(newMessages);
            }
        }
    }


}
export function useMessageWatcher(){
    return useMessageSystem().messageWatcher;
}