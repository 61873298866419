import { Euler, Object3D } from "three";
import * as Three from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import NodeMessageControls from "../nodes/NodeMessageControls";
import Resources from "../Resources";
import FloatParentBehaviorNode from "../effects/FloatParentBehaviorNode";

import {BoothEntity} from "./BoothEntity";
import FileDisplayerNode from "./subObjects/types/FileDisplayerNode";
import { TEXTURE_HEIGHT_SIGN_ABOVE_BOOTH } from "../../config/TextureHeights";


/** A floating panel above the booth which shows the avatar for that booth.  */
export default class BoothSkySignNode extends Object3DNode{

    _boothEntity:BoothEntity;

    
    constructor( boothEntity:BoothEntity, systemContext:SystemContext ){
        super(
            new Three.Object3D(),
            systemContext
        );

        this._boothEntity = boothEntity;
        
        if( this._boothEntity.record.signageFileId ){
            const avatarNode = new FileDisplayerNode(
                this._boothEntity.record.signageFileId,
                TEXTURE_HEIGHT_SIGN_ABOVE_BOOTH,
                systemContext,
                this
            );
            avatarNode.hideUntilLoaded();
            this.addChild( avatarNode );
        }

        this.addChild(
            new FloatParentBehaviorNode( 0.1, 10.0, systemContext )
        );

        this.object3D.scale.setScalar(3);
    }


    _onSignInteract( messageControls:NodeMessageControls ){
       // For now, do nothing on click. Perhaps later this can be a way to teleport to the booth.
    }
    
}