import "styled-components/macro";

import React from "react";
import MessageRepository from "../../logic/messages/MessageRepository";
import { useSystemContext, useTheme } from "../../logic/SystemContextComponents";
import {TextLineInput} from "../common/TextLineInput";
import Button from "../common/Button";
import DivThatAutoScrollsToBottom from "../common/DivThatAutoScrollsToBottom";
import { css } from "styled-components/macro";
import { useComponentDidMount, useComponentDidUnmount, useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { ChatMessageRecord, ChatMessageRecordPartForPost } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";
import ChatMessageEntity from "./ChatMessageEntity";
import { ChatRoomMessageItemComponent } from "./ChatRoomPanelMessageItem/ChatRoomMessageItemComponent";

const CURRENT_LANGUAGE = 'en';

// A conversation between you & someone else, where you can send messages
export function ChatRoomPanelComponent( {
    roomId, messageRepository, myId
}: {
    roomId?: string, messageRepository: MessageRepository, myId?:string
} ){
    // const theme = useTheme();
    const [,forceUpdate] = React.useReducer(_=>!_,false);
    
    const systemContext = useSystemContext();
    const {messageSystem:{draftMessageRepository,messageWatcher,roomRepository},translate} = systemContext;

    const [shownRoomId, setShownRoomId] = React.useState<string>();
    const [ messages,setMessages ] = React.useState<ChatMessageEntity[]>();
    const [ title,setTitle ] = React.useState<string>();
    const [draftMessage, setDraftMessage] = React.useState<ChatMessageRecordPartForPost>();
    const [waiting,setWaiting] = React.useState(false);
    const [sending,setSending] = React.useState(false);

    /** Sends the current draft message, then deletes it. */
    async function sendDraftMessageAsync(){
        if( sending || !draftMessage ||!roomId || !messages){
            return;
        }
        setSending( true );


        // send the message
        const message = await messageRepository.addByPartAsync_(
            draftMessage
        );
        // delete the draft from the repository since we just sent it
        draftMessageRepository.deleteByRoomId( roomId );
        // show the message locally
        const entity = new ChatMessageEntity(message,systemContext);
        setMessages( [ ...messages, entity ] )

        // now get a new draft message for us to work on
        const newDraftMessage = draftMessageRepository.getDraftForRoomId( roomId );
        setDraftMessage( newDraftMessage );

        setSending( false );
    }

    const newMessageListener = async (newMessages:ChatMessageRecord[])=>{
        // note that the local variable "message" may be out of date when this function is called,
        // thus we get a fresh copy of messages
        const latestMessages = await messageRepository.getAllAsync();
        if( latestMessages ){
            setMessages( latestMessages.map(
                a=>new ChatMessageEntity(a,systemContext)
            ) );
        }
    };

    useComponentDidMount( ()=>{
        messageWatcher.addListener( messageRepository, newMessageListener );
    });

    useComponentDidUnmount( ()=>{
        messageWatcher.removeListener( messageRepository, newMessageListener );
    });

    // if the room changed, we need to refresh things
    useComponentDidUpdate( async ()=>{
        if( waiting || !roomId || roomId===shownRoomId ){
            return;
        }
        setWaiting(true);

        // grab the draft message & store a reference for later
        const draftMessage = await draftMessageRepository.getDraftForRoomId( roomId );
        setDraftMessage( draftMessage );

        const messages = await messageRepository.getManyByRoomIdAsync( roomId );
        setMessages( messages?.map(
            a=>new ChatMessageEntity(a,systemContext)
        ) );
        
        const title = await roomRepository.makeResolvedTitleByIdAsync(roomId);
        setTitle(title);
        
        setShownRoomId( roomId );
        setWaiting(false);
    });



    if( ! roomId ){
        return <div></div>
    }

    if( waiting ){
        return <div>{translate("t$loadingDotDotDot")}</div>
    }


    return <div css={Layout.Root}>
        <DivThatAutoScrollsToBottom layout={Layout.MessagesContainer} >{
            messages?.length ? messages.map(
                messageData=><ChatRoomMessageItemComponent message={messageData}/>
            ) : <div>
                {translate("t$chatEmptyRoomMessage")}
            </div>
        }</DivThatAutoScrollsToBottom>
        <div css={Layout.DraftMessage}>
            <TextLineInput
                layout={Layout.DraftMessageInputField}
                disabled={sending}
                type="text"
                value={
                    draftMessage?.body ?
                    draftMessage.body[CURRENT_LANGUAGE] :
                    ""
                }
                setValue={async (value)=>{
                    if( draftMessage ){
                        draftMessage.body = {[CURRENT_LANGUAGE]: value};
                        forceUpdate();
                    }
                }} onEnterKeyDown={ ()=>sendDraftMessageAsync() }
            />
            <Button disabled={sending} onClick={ ()=>sendDraftMessageAsync() }>
                {translate("t$draftMessageSend")}
            </Button>
        </div>
    </div>
}


const Layout = {
    Root: css`
        display: flex;
        flex-direction: column;
        align-items:center;
    `,
    MessagesContainer: css`
        width: 100%;
        height: 85%;
        /* flex-grow: 1; */
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-items: center;
    `,
    DraftMessage: css`
        padding: 1em;
        width: 100%;
        height: 15%;
        /* min-height: 20em; */
    `,
    DraftMessageInputField: css`
        width: 100%;
        height: 5vmin;
    `,
};