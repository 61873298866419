import "styled-components/macro";

import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useSystemContext } from '../../logic/SystemContextComponents';
import { useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { PREFERRED_LANGUAGE_IDS_TODO } from "../../logic/messages/ChatRoomEntity";
import { BreadcrumbTrailCrumbData } from "../common/BreadcrumbTrailCrumb";
import BreadcrumbTrail from "../common/BreadcrumbTrail";
import { CSSProp } from "styled-components/macro";
import { MainMenuSceneNode } from "../../logic/scenes/MainMenuSceneNode";
import { FloorSceneNode } from "../../logic/scenes/FloorSceneNode";
import { BoothEditorMainMenuSceneNode } from "../../logic/scenes/BoothEditorMainMenuSceneNode";
import { BoothEditorSceneNode } from "../../logic/scenes/BoothEditorSceneNode";
import { UrlToResourceId } from "../../logic/utility/URLs";

export function NavigationBreadcrumbTrail({
    layout
}:{
    layout?:CSSProp
}){

    const systemContext = useSystemContext();
    const {mapRepository,boothRepository,scene} = systemContext;

    const {pathname} = useLocation();
    const mapId = UrlToResourceId("/"+pathname,"maps");
    const boothId = UrlToResourceId("/"+pathname,"booths");

    // At first, we'll show the ID of the map/booth,
    // but after we load the names of them, we'll use that instead
    const [mapName,setMapName] = React.useState<string|undefined>( mapId );
    const [boothName,setBoothName] = React.useState<string|undefined>( boothId );

    // we track these so that we know when to refresh the name values
    const [currentlyNamedMapId,setCurrentlyNamedMapId] = React.useState<string|undefined>();
    const [currentlyNamedBoothId,setCurrentlyNamedBoothId] = React.useState<string|undefined>();

    // asynchronously populate the map & booth name fields
    useComponentDidUpdate( async ()=>{
        if( mapId!==undefined && mapId!==currentlyNamedMapId ){
            setCurrentlyNamedMapId(mapId);
            setMapName( undefined );
            const map = await mapRepository.maybeGetByIdAsync( mapId );
            setMapName( map?.resolvedTitle );
        }
        if( boothId!==undefined && boothId!==currentlyNamedBoothId ){
            setCurrentlyNamedBoothId(boothId);
            setBoothName( undefined );
            const booth = await boothRepository.getByIdAsync( boothId );
            setBoothName( booth?.resolvedTitle );
        }
    } );

    // TODO: move this logic into Routing.ts
    const isExpo = (
        (scene instanceof MainMenuSceneNode) ||
        (scene instanceof FloorSceneNode)
    );
    const isEditor = (
        (scene instanceof BoothEditorMainMenuSceneNode) ||
        (scene instanceof BoothEditorSceneNode)
    );
    const trail:BreadcrumbTrailCrumbData[] = [];
    trail.push({
        text: "Home",
        to: "/",
        navigateScene:true,
        // onClick:()=>systemContext.loadSceneAsync( new MainMenuSceneNode(systemContext) )
    });

    if( isExpo ){
        trail.push({
            text: "Exhibitions",
            to: "/",
            navigateScene:true,
            // onClick:()=>systemContext.loadSceneAsync( new MainMenuSceneNode(systemContext) )
        });
        if(mapId!==undefined){
            trail.push({
                text: mapName||mapId,
                to: "/maps/"+mapId,
            });
        }
        if(boothId!==undefined){
            trail.push({
                text: boothName||boothId,
                to: "/maps/"+mapId+"/booths/"+boothId,
            });
        }
    }
    if( isEditor ){
        trail.push({
            text: "Editor",
            to: "/editor/",
            navigateScene:true,
        });
        if(boothId!==undefined){
            trail.push({
                text: boothName||boothId,
                to: "/editor/booths/"+boothId,
                navigateScene:true,
            });
        }
    }

    return <BreadcrumbTrail data={trail} layout={layout}/>;
}