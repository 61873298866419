import { BoxGeometry, Color, DoubleSide, Mesh, MeshPhongMaterial, MeshStandardMaterial, NormalAnimationBlendMode, Object3D, PlaneGeometry, Texture } from "three";
import Object3DNode from "../logic/nodes/Object3DNode";
import Resources from "../logic/Resources";
import SystemContext from "../logic/SystemContext";
import textureUrl from "../images/exterior/cloud-2421760_960_720--pixabay.png";
import RotateParentBehaviorNode from "../logic/effects/RotateParentBehaviorNode";


/** A circle that spins and glows, placed around the building exterior so that it looks "high tech" */
export class IntroCloudNode extends Object3DNode{
    private _displayMesh;
    private _displayNode;
    private _loadedTexture?:Texture;

    constructor( systemContext:SystemContext, opacity:number ){
        super(
            new Object3D(),
            systemContext
        );
        this._displayMesh = new Mesh(
            new PlaneGeometry( 1,1 ),
            new MeshStandardMaterial({
                transparent:true,
                opacity,
            })
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
        );
        this._displayNode.object3D.rotateX(-Math.PI/2);

        this._loadAsync();
    }

    async _loadAsync(){
        const texture = await Resources.LoadTextureAsync( textureUrl );
        this._displayMesh.material.map = texture;
        this._displayMesh.material.needsUpdate = true;
        this.addChild( this._displayNode );
    }

    disposeHook(){
        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }
    }
}