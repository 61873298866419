import { MouseEventHandler, useState } from "react";
import "styled-components/macro";

import { css, CSSProp } from "styled-components/macro";
import { MakeMouseNormalizedDeviceCoordinate } from "../libraries/l2-common/l2-common-ts/common/utility/Dom";
import { INPUT_BACKWARD, INPUT_FORWARD, INPUT_LEFT, INPUT_RIGHT } from "../logic/input/InputSystem";
import { useSystemContext, useTheme } from "../logic/SystemContextComponents"
import { CssLines } from "../theme/CssPropHelpers";

export function MobileMovementJoystick({
    layout
}:{
    layout?:CSSProp
}){
    const systemContext = useSystemContext();
    const [isMouseButtonDown,setIsMouseButtonDown] = useState(false);
    const [mouseCoordinates,setMouseCoordinates] = useState<[number,number]>([0,0]);
    const theme = useTheme();
    const PressedThemeKey = isMouseButtonDown ? "Pressed" : "Unpressed";
    const madeLayout = MakeLayout(mouseCoordinates);
    const [previousKeys,] = useState<[undefined|string,undefined|string]>([undefined,undefined])

    const startPress = (
        event:{clientX:number,clientY:number,target:any}
    )=>{
        setIsMouseButtonDown(true);
        updateCoordinates(event);
    };

    const endPress = ()=>{
        setIsMouseButtonDown(false);
        setStickCoordinates([0,0]);
    }

    const setStickCoordinates = (
        coordinates:[number,number]
    )=>{
        setMouseCoordinates(coordinates);

        const DEAD_ZONE = .2;
        const currentKeys = [
            coordinates[0]<-DEAD_ZONE?INPUT_LEFT:coordinates[0]>DEAD_ZONE?INPUT_RIGHT:undefined,
            coordinates[1]<-DEAD_ZONE?INPUT_BACKWARD:coordinates[1]>DEAD_ZONE?INPUT_FORWARD:undefined
            // coordinates[0]<-DEAD_ZONE?"JoystickLeft":coordinates[0]>DEAD_ZONE?"JoystickRight":undefined,
            // coordinates[1]<-DEAD_ZONE?"JoystickDown":coordinates[1]>DEAD_ZONE?"JoystickUp":undefined
        ];
        const input = systemContext.inputSystem;
        // when the polarity changes on an axis,
        // trigger a new keypress
        for(let axis=0; axis<2; ++axis){
            if( currentKeys[axis] !== previousKeys[axis] ){
                input.triggerInputKeyUp(previousKeys[axis]);
                input.triggerInputKeyDown(currentKeys[axis]);
            }
            previousKeys[axis]=currentKeys[axis];
        }
    }

    const updateCoordinates = (
        event:{clientX:number,clientY:number,target:any}
    )=>{
        const domElement = event.target as HTMLElement;
        const coordinates = MakeMouseNormalizedDeviceCoordinate(event,domElement);
        setStickCoordinates(coordinates);
    }


    return <div
        css={theme.Joystick.Background+''+madeLayout.Background+layout}
        onMouseDown={startPress}
        onMouseUp={endPress}
        onMouseOut={endPress}
        onMouseMove={e=>{
            if(isMouseButtonDown){
                updateCoordinates(e);
            }
        }}
    >
        <div css={theme.Joystick.Foreground[PressedThemeKey]+''+madeLayout.Foreground[PressedThemeKey]}>

        </div>
    </div>
}


const MakeLayout = (mouseCoordinates:[number,number])=>{

    // map NDC (-1 to 1) to percent (0 to 100), note we have to flip y axis
    const xyPercent = [mouseCoordinates[0],-mouseCoordinates[1]].map(
        a=> 100 * (1+a)/2
    );
    
    return {
        Background: css`
            position: relative;
        `,
        Foreground: {
            Unpressed: css`
                pointer-events: none;
                width: 50%;
                height: 50%;
                position: relative;
                ${CssLines(
                    // -50/2 to center the stick (which is 50% in diameter)
                    `top: ${xyPercent[1]-50/2}%;`,
                    `left: ${xyPercent[0]-50/2}%;`,
                )};
            `,
            Pressed: css`
                pointer-events: none;
                width: 66%;
                height: 66%;
                position: relative;
                ${CssLines(
                    // -55/2 to center the stick (which is 55% in diameter)
                    `top: ${xyPercent[1]-66/2}%;`,
                    `left: ${xyPercent[0]-66/2}%;`,
                )};
            `,
        }
    };
};