import { css } from "styled-components";
import { CssLines } from "./CssPropHelpers";

// const controlBackgroundColor = `#ddd`;
const controlBackgroundColor = `#f0f0f2`;
const textFieldBackgroundColor = `#f2f2f2`;

const mainTextColor = `#111144`;
const loudTextColor = `#5555FF`;
const disabledTextColor = `#aaaaa`;
// const neuShadowColor = "#aaa8";
const neuShadow = "#bbbbbb88";
// const neuHighlightColor = "#fff8";
const neuLight = "#ffffffdd";

const mainTextCss = `
    color: ${mainTextColor};
`;
const disabledTextCss = `
    color: ${disabledTextColor};
`;
const loudTextCss = `
    color: ${loudTextColor};
`;
const textFieldCss = `
    background: ${textFieldBackgroundColor};
    color: ${mainTextColor};
`;

const controlBaseCss = `
    border: none;
    background: ${controlBackgroundColor};
`;

function MakeBoxShadow(ems:number,concave?:boolean){
    const em1 = ems+"em";
    const em2 = 2*ems+"em";
    return concave?`
    box-shadow:
        -${em1} -${em1} ${em2} ${neuShadow},
        ${em1} ${em1} ${em2} ${neuLight},
        inset 0px 0px ${em1} ${neuLight};
    `:`
    box-shadow:
        ${em1} ${em1} ${em2} ${neuShadow},
        -${em1} -${em1} ${em2} ${neuLight},
        inset 0px 0px ${em1} ${neuLight};
    `;
}

const ButtonBase = (disabled?:boolean)=>css`
    border: none;
    ${CssLines(disabled?disabledTextCss:mainTextCss)};
    border-radius: 1em;
    ${CssLines(controlBaseCss)};
    ${CssLines(MakeBoxShadow(.2))};

    :hover{
        ${CssLines("cursor: "+(disabled ? "not-allowed" : "pointer"))};
        ${CssLines(disabled?"":loudTextCss)};
    }
`;

const PushedButtonBase = css`
    @keyframes push {
        0% {
            ${CssLines(MakeBoxShadow(.2))};
            transform: translateY(0);
        }
        25% {
            box-shadow: none;
        }
        95% {
            box-shadow: none;
        }
        100% {
            box-shadow: none;
            ${CssLines(`border: 1px ${neuShadow} solid;`)};
            transform: translateY(1%);
        }
    }
    animation: push .5s;
    animation-timing-function: cubic-bezier(0,.99,0.99);
    box-shadow: none;
    ${CssLines(`border: 1px ${neuShadow} solid;`)};
    transform: translateY(1%);
`;

const KeyboardKeyBase = css`
    border: none;
    ${CssLines(mainTextCss)};
    ${CssLines(controlBaseCss)};
    ${CssLines(MakeBoxShadow(.2))};
    border-radius: .1em;
`;

export default {

    Background: css`
        ${CssLines(
            "background: linear-gradient(90deg,"+
            [
                    controlBackgroundColor,
                    controlBackgroundColor,
                    "#ddddf2ff",
                    controlBackgroundColor,
                    "#ddddf2ff",
                    controlBackgroundColor,
                    controlBackgroundColor,
                ].join(", ")+
            ");"
        )};
    `,

    Dialog: {
        Background: {
            Normal: css`
                ${CssLines(
                    "background: linear-gradient(180deg,"+
                    [
                        controlBackgroundColor,
                        controlBackgroundColor,
                        controlBackgroundColor+"ee",
                        controlBackgroundColor,
                        controlBackgroundColor,
                    ].join(", ")+
                    ");"
                )}
            `,
            /** Used when the user needs to clearly see what's going on behind the dialog. eg when using the dialogue to make changes to the 3D world. */
            SeeThrough: css`
                ${CssLines(
                    "background: "+controlBackgroundColor+"aa;"
                )};
            `,
        },
        Item: css`
            background-color: white;
            ${CssLines(MakeBoxShadow(.5))};
            border-radius:.75em;
        `,
    },

    MenuHeader: css`
        ${CssLines(loudTextCss)};
        font-weight: bold;
    `,
    MenuSubHeader: css`
        ${CssLines(mainTextCss)};
        /* font-weight: bold; */
    `,
    

    Button: {
        /** Just a regular button in the app. */
        Normal: (disabled?:boolean)=>({
            Default: ButtonBase(disabled)+''+css``,
            Pushed: ButtonBase()+''+PushedButtonBase,
        }),
        /** A button that user doesn't typically want to press, such as "cancel", "delete" or "sign out" */
        Quiet: (disabled?:boolean)=>({
            Default: ButtonBase(disabled)+''+css``,
            Pushed: ButtonBase()+''+PushedButtonBase,
        }),
        /** When you have an action that needs to stand out among the other buttons / rest of the page, such as a landing page CTA button */
        Loud: (disabled?:boolean)=>({
            Default: ButtonBase(disabled)+''+css``,
            Pushed: ButtonBase()+''+PushedButtonBase,
        }),
        /**
         * An icon button is designed to be as minimal as possible, and usually won't have any styling at all.
         * These buttons often appear inside of widgets such as searchbars, to the right of an editable list, as the hamburger button in the header, etc...,
         * */
        Icon: (disabled?:boolean)=>({
            Default: ButtonBase(disabled)+''+css`

            `,
            Pushed: ButtonBase()+''+PushedButtonBase,
        }),
    },

    Card: {
        Normal: css`
            ${CssLines(controlBaseCss)};
            ${CssLines(MakeBoxShadow(.5))};
            border-radius:.75em;
        `,
        Image: css`
            ${CssLines(controlBaseCss)};
            ${CssLines(MakeBoxShadow(.5))};
            border-radius:.75em;
            ${CssLines(`
                color: ${neuLight};
                text-weight: bold;
                text-shadow: 0 0 .5em black, 0 0 .5em black, 0 0 1em black;
                /* text-transform: uppercase; */
            `)}
        `,
    },

    Breadcrumb: css`
        ${CssLines(controlBaseCss)};
        ${CssLines(textFieldCss)};
        ${CssLines(MakeBoxShadow(.2,true))};
        /* ${CssLines("background: "+neuShadow+";")}; */
        border-radius: .5em;
        :hover{
            cursor: pointer;
            ${CssLines(loudTextCss)};
        }
    `,

    /** A raised item that looks like a keyboard key */
    KeyboardKey: {
        Normal: KeyboardKeyBase+''+css`
        `,
        Pushed: KeyboardKeyBase+''+css`
            @keyframes push {
                0% {
                    ${CssLines(MakeBoxShadow(.2))};
                    transform: translateY(0);
                }
                25% {
                    box-shadow: none;
                }
                95% {
                    box-shadow: none;
                }
                100% {
                    box-shadow: none;
                    ${CssLines(`border: 1px ${neuShadow} solid;`)};
                    transform: translateY(1%);
                }
            }
            animation: push .5s;
            animation-timing-function: cubic-bezier(0,.99,0.99);
            box-shadow: none;
            ${CssLines(`border: 1px ${neuShadow} solid;`)};
            transform: translateY(1%);

            ${CssLines(loudTextCss)};
        `,
    },

    Input: css`
        ${CssLines(controlBaseCss)};
        ${CssLines(textFieldCss)};
        ${CssLines(MakeBoxShadow(.2,true))};
        border-radius: .5em;
    `,
    Dropdown: css`
        ${CssLines(controlBaseCss)};
        ${CssLines(textFieldCss)};
        ${CssLines(MakeBoxShadow(.2,true))};
        border-radius: .5em;
    `,

    Panel: css`
        ${CssLines(controlBaseCss)};
        box-shadow:
            inset 0 0 2rem #eee;
    `,
    CenterPanel: css`
        ${CssLines(controlBaseCss)};
        box-shadow:
            inset 0 0 2rem #eee;
        border-radius: .5em;
    `,

    Checkbox:{
        Container: css`
        `,
        Contents: css`
        `,
    },

    Joystick:{
        Background: css`
            ${CssLines(controlBaseCss)};
            ${CssLines(MakeBoxShadow(.2,true))};
            border-radius: 100vmax;
        `,
        Foreground: {
            Unpressed: css`
                ${CssLines(MakeBoxShadow(.2,false))};
                ${CssLines(
                    `background: ${textFieldBackgroundColor};`,
                )};
                transition: background .1s, box-shadow .5s, left .5s, top .5s;
                border-radius: 100vmax;
            `,
            Pressed: css`
                ${CssLines(MakeBoxShadow(.2,false))};
                ${CssLines(
                    `background: #ffffff88;`,
                    `box-shadow: 0 0 3em ${loudTextColor}88;`
                )};
                transition: background .1s, box-shadow .2s;
                border-radius: 100vmax;
            `,
        }
    }
}