export default class Extensions{
    static IsVideo( extension:string ){
        extension = extension.startsWith(".") ? extension : "." + extension;
        extension = extension.toLocaleLowerCase();
        return [
            ".mp4",
            ".avi",
            ".flv",
            ".webm"
        ].includes(extension);
    }
    static IsModel( extension:string ){
        extension = extension.startsWith(".") ? extension : "." + extension;
        extension = extension.toLocaleLowerCase();
        return [
            ".gltf",
            ".glb"
        ].includes(extension);
    }
}