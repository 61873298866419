import { useCallback } from "react";
import config from "../../config/config";
import { useComponentDidMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";

const CATPCHA_WIDGET_DOM_ID = "google-recaptcha";

const origin = window.location.origin;

const SITE_KEY = config.captchaV3SiteKey;

/**
 * This is a version of useReCaptcha from the main site,
 * but refactored so that everything is contained in 1 file.
 * 
 * TODO: There is a lot of unused/mystery code in here. This file should be rewritten.
 * 
 * /

/**
 * Returns a component that you need to place in the document,
 * and two functions that can be used to generate a captcha token.
 * 
 * @param actionId A unique identifer for what this captcha is used for,
 * eg "contactForm" or "signUp"
 */
export default function useReCaptcha(actionId:string){
    if(actionId.includes("-")){
        throw new Error("useReCaptcha: actionId can only contain alphabet and underscores.")
    }

    const elementId = "recaptcha--"+actionId;
    const containerElementId = elementId+"--container";

    

    const load = useCallback(() => {
        if (document.getElementById(CATPCHA_WIDGET_DOM_ID)) {
            // 既に読み込みが開始されている場合は何もしない
            return;
        }

        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.async = true;
        script.type = "text/javascript";
        script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
        script.id = CATPCHA_WIDGET_DOM_ID;
        head.appendChild(script);
    }, []);

    const ready = useCallback(() => {
        // @ts-ignore
        return new Promise<ReCaptchaV2.ReCaptcha>((resolve) => {
            load();

            // reCAPTCHA がまだ読み込まれていない場合に ready 関数だけ事前に用意しておく
            // `window.___grecaptcha_cfg.fns` にコールバック関数を push しておくと reCAPTCHA が ready 状態になった時に呼び出してくれる
            // See: https://developers.google.com/recaptcha/docs/loading#loading_recaptcha_asynchronously
            // @ts-ignore
            if (typeof window.grecaptcha?.ready === "undefined") {
                // @ts-ignore
                window.grecaptcha = window.grecaptcha || {};
                // @ts-ignore
                window.grecaptcha.ready = (cb: () => void) => {
                    // @ts-ignore
                    window.___grecaptcha_cfg = window.___grecaptcha_cfg ?? {};
                    // @ts-ignore
                    window.___grecaptcha_cfg.fns = window.___grecaptcha_cfg.fns ?? [];
                    // @ts-ignore
                    window.___grecaptcha_cfg.fns.push(cb);
                };
            }

            // @ts-ignore
            window.grecaptcha.ready(() => resolve(window.grecaptcha));
        });
    }, [load]);

    const executeV3 = useCallback(
        // @ts-ignore
        async (action: ReCaptchaV2.Action): Promise<string> => {
            const grecaptcha = await ready();
            const token = grecaptcha.execute(SITE_KEY, action);
            return token;
        },
        [ready]
    );

    const renderV2 = useCallback(
        async (container: any, parameters: any): Promise<number> => {
            const grecaptcha = await ready();
            const widgetId = grecaptcha.render(container, parameters);
            return widgetId;
        },
        [ready]
    );

    const executeV2 = useCallback(async (): Promise<void> => {
        const grecaptcha = await ready();
        grecaptcha.execute();
    }, [ready]);

    const resetV2 = useCallback(async (): Promise<void> => {
        const grecaptcha = await ready();
        grecaptcha.reset();
    }, [ready]);


    useComponentDidMount(() => {
        load();
    })

    async function resetRecaptchaV2(onComplete:(token:string)=>Promise<void>){
        const options = {
            sitekey: SITE_KEY,
            size: "invisible",
            callback: onComplete
        }
        try{
            await renderV2(actionId,options)
        }catch{
            const container = document.getElementById(
                containerElementId
            );
            container?.removeChild(
                container.firstElementChild as Node
            );
            const newRecaptcha = document.createElement("div");
            newRecaptcha.id = elementId;
            container?.appendChild( newRecaptcha );
            await renderV2(elementId,options);
        }
    }

    const ReCaptchaComponent = (props:any)=><div id={containerElementId}>
        <div id={elementId} />
    </div>;

    const getTokenV3 = async ()=>{
        return executeV3({action:actionId});
        
    }
    const getTokenV2 = async ()=>{
        const promise = new Promise((accept,reject)=>{
            resetRecaptchaV2( async token=>accept(token) );
        })
        await executeV2();
        return promise;
    }

    return {
        ReCaptchaComponent,
        getTokenV2,
        getTokenV3,
    };
    
}