import { useNavigate } from "react-router";
import { IntroSceneNode } from "../intro/IntroSceneNode";
import { PUBLIC_URL_NO_SLASH, PUBLIC_URL_WITH_SLASH } from "./Environment";
import { BoothEditorMainMenuSceneNode } from "./scenes/BoothEditorMainMenuSceneNode";
import { BoothEditorSceneNode } from "./scenes/BoothEditorSceneNode";
import { FloorSceneNode } from "./scenes/FloorSceneNode";
import { MainMenuSceneNode } from "./scenes/MainMenuSceneNode";
import { ModelTestSceneNode } from "./scenes/ModelTestSceneNode";
import SystemContext from "./SystemContext";
import { useSystemContext } from "./SystemContextComponents";
import { UrlToResourceId } from "./utility/URLs";

/** Like calling useNavigate from react-router, but also changes the scene accordingly */
export function useNavigateWithScene(){
    const navigate = useNavigate();
    const systemContext = useSystemContext();
    return async (path:string)=>{
        navigate(path);
        const scene = RoutingPathToSceneNode(path,systemContext);
        await systemContext.loadSceneAsync( scene );
    }
}

/**
 * Returns undefined if the URL isn't a path on this app.
*/
export function UrlToSceneNode( url:string, systemContext:SystemContext ){
    const path = UrlToRoutingPath(url);
    if(path===undefined){
        return undefined;
    }
    return RoutingPathToSceneNode( path, systemContext );
}

function RoutingPathToSceneNode( path:string, systemContext:SystemContext ){
    if( path.startsWith("/") ){
        path = path.slice(1);
    }
    if( !path.endsWith("/") ){
        path += "/";
    }
    let scene = new MainMenuSceneNode(systemContext);
    
    if( path.startsWith("maps/") && path.includes("/intro/") ){
        const mapId = UrlToResourceId("/"+path,"maps")||"";
        scene = new IntroSceneNode(mapId,systemContext);
    }else if( path.startsWith("editor/booths/") ){
        scene = new BoothEditorSceneNode(systemContext);
    }else if( path.startsWith("editor/") ){
        scene = new BoothEditorMainMenuSceneNode(systemContext);
    }else if( path.startsWith("maps/") ){
        const mapId = UrlToResourceId("/"+path,"maps")||"";
        scene = new FloorSceneNode(mapId, 1, systemContext);
    }else if( path.startsWith("testModel/") ){
        const testModelId = path.split("testModel/?url=")[1];
        scene = new ModelTestSceneNode( testModelId, systemContext );
    }
    
    return scene;
}

/**
 * Maps "foo://bar.com/a/b/c" to "a/b/c"
 * Returns undefined if the URL isn't a path on this app.
*/
function UrlToRoutingPath( url:string ){
    if(!url.startsWith(PUBLIC_URL_NO_SLASH)){
        return undefined;
    }
    // situation where the url is just "foo.com" instead of "foo.com/" or "foo.com/a"
    if(url===PUBLIC_URL_NO_SLASH){
        return "";
    }
    let path = url.slice(PUBLIC_URL_WITH_SLASH.length);
    return path;
}
