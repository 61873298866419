import { Fragment, useState } from "react";
import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents"
import { BreadcrumbTrailCrumb,BreadcrumbTrailCrumbData } from "./BreadcrumbTrailCrumb";


export default function BreadcrumbTrail(
    {data,layout}:
    {
        data:BreadcrumbTrailCrumbData[],
        layout?:CSSProp,
    }
){
    const theme = useTheme();

    return <div css={Layout.Root}>
        {data.map((item,i)=>
            <Fragment key={i+"__"+item.text}>
                {item!==data[0] && <div css={Layout.Joiner}>
                    {" > "}
                </div>}
                <BreadcrumbTrailCrumb data={item}/>
            </Fragment>
        )}
    </div>
}

const Layout = {
    Root: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > * {
            margin-right: 1em;
            height: 100%;   
        }
    `,
    Joiner: css`
        display:flex;
        align-items:center;
        justify-content:center;
    `,
};