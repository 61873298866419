import { BoxGeometry, Color, DoubleSide, Mesh, MeshPhongMaterial, MeshStandardMaterial, NormalAnimationBlendMode, Object3D, PlaneGeometry, PointLight, Side, Texture, Vector3 } from "three";
import Object3DNode from "../logic/nodes/Object3DNode";
import Resources from "../logic/Resources";
import SystemContext from "../logic/SystemContext";
import textureUrl from "../images/exterior/column--ffffff--1080.png";
import { lerp } from "three/src/math/MathUtils";
import { libertyVenueBuildingScale } from "./IntroConstants";


/** The special effects that "blast" out of the liberty building exterior. */
export class IntroBlastNode extends Object3DNode{
    private _displayMesh;
    private _displayNode;
    private _loadedTexture?:Texture;
    private _speedUnitsPerSecond;
    private _distanceToRespawnAfterReaching;
    private _movementDirection;
    private _originalPosition;

    constructor( systemContext:SystemContext, speedUnitsPerSecond:number, distanceToRespawnAfterReaching:number, movementDirection:[number,number,number], position:[number,number,number] ){
        super(
            new Object3D(),
            systemContext
        );
        this._originalPosition = [...position] as [number,number,number];
        this.object3D.position.set( ...position );
        this._distanceToRespawnAfterReaching=distanceToRespawnAfterReaching;
        this._speedUnitsPerSecond=speedUnitsPerSecond;
        this._movementDirection = movementDirection;
        this._displayMesh = new Mesh(
            new PlaneGeometry( .1,10 ),
            new MeshPhongMaterial({
                transparent:true,
                // opacity: lerp(.3,.7,Math.random()),
                emissive: new Color("#5555ff"),
                emissiveIntensity: 1,
            })
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
        );
        if( movementDirection[1]===0 ){
            this._displayNode.object3D.rotateX(-Math.PI/2);
            const movementAngle = Math.atan2(movementDirection[2],movementDirection[0])
            this._displayNode.object3D.rotateZ(Math.PI/2-movementAngle);
        }
        this.resetPosition();

        this._loadAsync();

        this._addLight();
    }

    randomizePosition() {
        const distance = lerp(
            -this._distanceToRespawnAfterReaching,
            this._distanceToRespawnAfterReaching,
            Math.random()
        );
        const offset = new Vector3(...this._movementDirection).multiplyScalar(distance);
        this.object3D.position.copy(
            new Vector3(...this._originalPosition)
        ).add(offset);
    }

    private _addLight(){
        const lightColor = 0x5555FF;
        const scale = libertyVenueBuildingScale/700;
        const lightDistance = 20*scale;
        const pointLight = new Object3DNode(
            new PointLight(lightColor, .5, lightDistance),
            this.systemContext
        );
        this.addChild(pointLight);
    }

    async _loadAsync(){
        const texture = await Resources.LoadTextureAsync( textureUrl );
        this._displayMesh.material.map = texture;
        this._displayMesh.material.needsUpdate = true;
        this.addChild( this._displayNode );
    }

    updateHook(deltaTime: number): void {
        this._updateMove(deltaTime);
    }

    private _updateMove(deltaTime: number) {
        const offset = this._movementDirection.map(
            a => a * this._speedUnitsPerSecond * deltaTime
        );
        const next = this.object3D.position.add(new Vector3(...offset));
        this.object3D.position.copy(next);
        const distanceSquared = new Vector3().copy(this.object3D.position).sub(
            new Vector3(...this._originalPosition)
        ).lengthSq();
        if (distanceSquared > Math.pow(this._distanceToRespawnAfterReaching, 2)) {
            this.resetPosition();
        }
    }

    resetPosition(){
        this.object3D.position.set(...this._originalPosition);
        // const distance = lerp(
        //     -this._distanceToRespawnAfterReaching,
        //     0,// TODO: instead of 0, use the length of the displayMesh/-2
        //     Math.random()
        // );
        // const offset = new Vector3(...this._movementDirection).multiplyScalar(distance);
        // this.object3D.position.copy(
        //     new Vector3(...this._originalPosition)
        // ).add(offset);
    }

    disposeHook(){
        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }
    }
}