import "styled-components/macro";

import assert from "assert";
import { Link } from "react-router-dom";
import {MapEntity} from "../../logic/maps/MapEntity";
import { useSystemContext, useTheme } from "../../logic/SystemContextComponents";

import IMG_LIBERTY from "../../images/venues/liberty1.png";
import { css } from "styled-components";
import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { CssLines } from "../../theme/CssPropHelpers";
import LinkButton from "../common/LinkButton";
import { FloorSceneNode } from "../../logic/scenes/FloorSceneNode";
import { LinkAnchor } from "../common/LinkAnchor";

/** Shows one map in the list that you can click on. */
export default function MapSelectPanelItemComponent( {mapEntity}:{mapEntity:MapEntity} ){
    const systemContext = useSystemContext();
    const theme = useTheme();
    
    const backgroundCss = css`
    ${CssLines(
        `background-image: ${ `url("${IMG_LIBERTY}")` };`
        )}
        `;
        
        
        return <div css={theme.Card.Image+''+Layout.Root+backgroundCss}>
        <LinkAnchor
        // layout={Layout.Button}
        to={"/maps/"+mapEntity.record.id+"/intro/"}
        navigateScene
        // onClick={()=>{
        //     assert( mapEntity.record.id );
        //     const scene = new FloorSceneNode(mapEntity.record.id, 1, systemContext);
        //     systemContext.loadSceneAsync(scene);
        // }}
        >
        {mapEntity.resolvedTitle}
        </LinkAnchor>
        </div>;
        
    }
    
const Layout = {
    Root: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        height: 10em;
        width: 50%;
        min-width:  20vw;
        max-width: 100%;
        background-position: center;
        background-size: cover;
        
        & > *{
            height: 100%;
            padding: 2em;
            font-size: 1.5em;
            display:flex;
            align-items: center;
            justify-content: center;
        }
    `,
    Button: css`
        width: 80%;
        height: 2rem;
        max-height: 50%;
    `
};