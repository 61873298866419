import * as Three from "three";
import { Vector3 } from "three";
import assert from "assert";


import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";


/** Causes the parent of this node to slowly float up and down. */
export default class FloatParentBehaviorNode extends Object3DNode{
    private _elapsedTime = 0;
    periodSeconds:number;
    magnitudeUnits:number;
    private parentInitialPosition?:Vector3;
    
    constructor( magnitudeUnits:number, periodSeconds:number, systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );

        this.magnitudeUnits=magnitudeUnits;
        this.periodSeconds=periodSeconds;

        
    }

    
    updateHook( deltaTime:number ){
        assert( this._parent instanceof Object3DNode );

        if( ! this.parentInitialPosition ){
            this.parentInitialPosition = this._parent.object3D.position.clone();
        }


        this._elapsedTime = ( this._elapsedTime+deltaTime ) % this.periodSeconds;

        this._parent.object3D.position.y = this.parentInitialPosition.y + this.magnitudeUnits * Math.sin(this._elapsedTime*(Math.PI*2)/this.periodSeconds);
    }



}