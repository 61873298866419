import { css, CSSProp } from "styled-components/macro";
import { useTheme } from "../../logic/SystemContextComponents";

/**
 * 
 * @param props.valueToTextMap if omitted, text will be same as value
 * @returns 
 */
export default function DropdownInput({
    allValues,
    value,
    setValue,
    valueToText,
    valueToTextMap,
    isValueEnabled,
    layout,
    valuesBlacklist,
}:{
    allValues:(string|undefined)[],
    value:string|undefined,
    setValue:(value:string)=>void,
    valueToText?:(value:string)=>string,
    valueToTextMap?:{
        [value:string]: Text|string
    },
    isValueEnabled?:(value:string|undefined)=>boolean,
    layout?:CSSProp,
    valuesBlacklist?:(string|undefined)[],
}){
    const theme = useTheme();
    if(valuesBlacklist){
        allValues = allValues.filter( a=>!valuesBlacklist.includes(a) );
    }
    if(!allValues.includes(value)){
        allValues.unshift(value);
    }
    if(value===undefined){
        value = "";
    }
    return <select
        css={Layout.Root+``+theme.Dropdown+layout}
        onChange={e=>setValue(e.target.value)}
        defaultValue={value}
    >{allValues.map((key,i)=>
        <option
                css={Layout.Option}
                key={key+`:`+i+`:`+value}
                value={key}
                disabled={isValueEnabled&&!isValueEnabled(key)}
        >
            {
                valueToText ? valueToText(key||"")
                : valueToTextMap ? valueToTextMap[key||""]
                : (key||"")
            }
        </option>)}
    </select> 
}


const Layout = {
    Root: css`
    `,
    Option:css`
    `,
};
