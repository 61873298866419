import "styled-components/macro";

import {MenuHeader} from "../common/MenuHeader";
import { css } from "styled-components/macro";
import { useMyChatRooms } from "../../logic/messages/RoomRepositoryUtilities";
import { useSystemContext } from "../../logic/SystemContextComponents";
import { ChatRoomMiniDisplayComponent } from "./ChatRoomMiniDisplayComponent";

export function ChatRoomsPanelComponent(
    {setActiveRoomId}:
    {
        setActiveRoomId:(value:string)=>void
    }
){
    const {translate} = useSystemContext();

    const roomIds = useMyChatRooms()?.map(a=>a.id);
    const waiting = !roomIds;

    return <div css={Layout.Root}>
        <MenuHeader>{translate("t$conversations")}</MenuHeader>
        <div css={Layout.Displays}>
        {
            waiting ? 
            translate("t$loadingDotDotDot") :
            roomIds?.map(
                roomId => <ChatRoomMiniDisplayComponent
                    key={roomId}
                    roomId={roomId}
                    onClick={()=>{setActiveRoomId(roomId)}}
                    layout={Layout.MiniDisplay}
                />
            )
        }
        </div>
    </div>
}

const Layout = {
    Root: css`
        padding: 1vmin;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        & > * {
            width: 100%;
        }
    `,
    Displays: css`
        width: 100%;
        flex-grow: 1;
        margin: .5em;
        overflow-y: scroll;
    `,
    MiniDisplay: css`
        margin-top: 2em;
        width: 100%;
    `,
};