import { Object3D } from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";

/**
 * A type of node that will be added at the top-level
 * of the tree, and will have special loading logic called before it is added.
 * */
export abstract class SceneNode extends Object3DNode{
    constructor( systemContext:SystemContext ){
        super(
            new Object3D(),
            systemContext
        );
    }
    /**
     * Put loading logic here. This will be called after the scene's node has been added to the tree.
    */
    abstract loadAsync():Promise<any>;
    /**
     * Put cleanup logic here. This node will be removed from the scene after unloading has finished.
     * */
    abstract unloadAsync():Promise<any>;
}