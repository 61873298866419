import * as Three from "three";
import { Camera, Euler, MOUSE, Object3D, Quaternion, Vector2, Vector3 } from "three";


import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import InputMap from "../input/InputMap";
import { MOUSE_BUTTON_LEFT, MOUSE_BUTTON_RIGHT } from "../input/InputSystem";
import { IS_MOBILE } from "../../mobile/MobileDeviceDetection";

const ROTATE_CAMERA_MOUSE_BUTTON = IS_MOBILE ? MOUSE_BUTTON_LEFT : MOUSE_BUTTON_RIGHT;

/** To stop the player from looking for far down/up that they do a handstand */
const CAMERA_ROTATION_HORIZONTAL_AXIS_MIN = -1.5;
const CAMERA_ROTATION_HORIZONTAL_AXIS_MAX = 1.5;

/** Causes the threejs camera to lock to the position/rotation of whatever the parent of this node is. */
export default class FirstPersonCameraBehaviorNode extends Object3DNode{

    /** The rotation of the scene camera, y-axis only. Useful for figuring out which direction the player should move in.  */
    get cameraRotationYAxis(){ return this._cameraRotationYAxis; }
    set cameraRotationYAxis(value:number){
        this._cameraRotationYAxis=value;
    }
    /** This value is mutated by mouse movement, then determines the scene's camera angle. */
    _cameraRotationYAxis = 0;
    /** This value is mutated by mouse movement, then determines the scene's camera angle. */
    _cameraRotationHorizontalAxis = 0;
    /** The offset from the target, eg if the target is the player's root object, you might want the offset to be +1.5, so it's near the player's head. */
    _offsetPosition;

    /**
     * @param parent The object that the camera will be positioned at. Typically this will be the player.
     * @param offsetPosition The offset from the target, eg if the target is the player's root object, you might want the offset to be +1.5, so it's near the player's head.
     * @param systemContext 
     */
    constructor( parent:Object3DNode, offsetPosition:Vector3, systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );

        this._offsetPosition = offsetPosition;

        const parentWorldRotationQuaternion = new Quaternion();
        parent.object3D.getWorldQuaternion( parentWorldRotationQuaternion );
    }

    updateHook( deltaTime:number ){
        if( !this._parent || !(this._parent instanceof Object3DNode) ){
            return;
        }

        this._updateInput( this._parent );

        this._updateCameraTransform( this._parent );
    }


    // TODO: change mouse coordinates to be 0 to 1 instead, and change coefficient thusly
    _updateInput( target:Object3DNode ){
        const mouseDragDelta = this.systemContext.inputSystem.maybeGetMouseDragDelta( ROTATE_CAMERA_MOUSE_BUTTON );
        if( ! mouseDragDelta ){
            return;
        }

        this.systemContext.inputSystem.clearMouseDragDelta( ROTATE_CAMERA_MOUSE_BUTTON );

        const cameraMouseSensitivityFactor = this.systemContext.settings.getNumber("cameraMouseSensitivityFactor");

        this._cameraRotationHorizontalAxis += mouseDragDelta.y*cameraMouseSensitivityFactor;
        
        this._cameraRotationYAxis -= mouseDragDelta.x*cameraMouseSensitivityFactor;
        // Clamp horizontal axis
        this._cameraRotationHorizontalAxis = Math.max(
            CAMERA_ROTATION_HORIZONTAL_AXIS_MIN,
            Math.min(
                CAMERA_ROTATION_HORIZONTAL_AXIS_MAX,
                this._cameraRotationHorizontalAxis
            )
        )
    }

    _updateCameraTransform( target:Object3DNode ){

        const camera:Camera = this.systemContext.threeSystem.camera;

        const targetWorldPosition:Vector3 = new Vector3();
        target.object3D.getWorldPosition( targetWorldPosition );
        targetWorldPosition.add( this._offsetPosition );

        camera.position.copy( targetWorldPosition );

        const targetWorldQuaternion:Quaternion = new Quaternion();
        target.object3D.getWorldQuaternion( targetWorldQuaternion );

        camera.rotation.set( 0,0,0 );
        camera.rotateY( this._cameraRotationYAxis );
        camera.rotateX( this._cameraRotationHorizontalAxis );       
    }

}