
import { DirectionalLight, AmbientLight, PointLight, Vector3, Euler, Mesh, BoxGeometry, MeshBasicMaterial, Object3D } from "three";
import { degToRad, lerp } from "three/src/math/MathUtils";
import FloatParentBehaviorNode from "../logic/effects/FloatParentBehaviorNode";
import RotateParentBehaviorNode from "../logic/effects/RotateParentBehaviorNode";
import SkyboxNode from "../logic/floors/SkyboxNode";
import { MapEntity } from "../logic/maps/MapEntity";
import Object3DNode from "../logic/nodes/Object3DNode";
import Resources from "../logic/Resources";
import SystemContext from "../logic/SystemContext";
import { IntroBillboardNode } from "./IntroBillboardNode";
import { IntroBlastNode } from "./IntroBlastNode";
import { IntroCloudNode } from "./IntroCloudNode";
import { libertyVenueBuildingDoorPosition, libertyVenueBuildingScale } from "./IntroConstants";
import { IntroTechCircleNode } from "./IntroTechCircleNode";
import TREE_MAP_TEXTURE_URL from "../maps/liberty tree map.png";
import { TextureUrlToCanvasImageDataAsync } from "../logic/utility/Images";
import config from "../config/config";

const EXTERIOR_ROTATION_SPEED_SECONDS = Math.PI * .01;

const TEXTURES_PATH = `${config.staticContentUrl}textures/`;

const PEOPLE_FILES = [
    // "people/4i001--512.png",
    "people/4i002--512.png",
    "people/4i003--512.png",
    "people/4i004--512.png",
    "people/4i005--512.png",
    "people/5r001--512.png",
    "people/5r002--512.png",
    "people/5r003--512.png",
    "people/5r005--512.png",
    "people/5r004--512.png",
];

const TREE_FILES = [
    "trees/2t18_メタセコイヤ_Dawn Redwood --255.png",
    "trees/2t20_イチョウ_ginkgo--255.png",
    "trees/2t23_キンモクセイ_Fragrant orange-colored--olive--255.png",
    "trees/2t26_シラカシ_Bamboo-leafed oak--255.png",
    "trees/2t32_サクラ_Japanese cherry--255.png",
    "trees/2t33_ケヤキ_Zelkova--255.png",
    "trees/2t35_ハナミズキ_Dogwood --255.png",
    "trees/T1_008_クスノキ_Camphor tree--255.png",
];



export class IntroEnvironmentNode extends Object3DNode{
    private readonly data;
    private buildingRotator?:RotateParentBehaviorNode;

    constructor(mapEntity:MapEntity,systemContext:SystemContext){
        super(
            new Object3D(),
            systemContext
        );
        this.data = {mapEntity};
    }

    async loadAsync(){
        this._addLights();
        await this._addEnvironmentAsync();

        if( this.data.mapEntity.record.venueId==="liberty" ){
            const MODELS_PATH = `${config.staticContentUrl}models/`;
            const buildingGltf = await Resources.LoadGLTFAsync(MODELS_PATH+"Liberty_Bld_6.gltf");
            const buildingNode = new Object3DNode(
                buildingGltf.scene,
                this.systemContext
            );
            buildingNode.object3D.scale.setScalar(libertyVenueBuildingScale);
            // this.buildingRotator = new RotateParentBehaviorNode( EXTERIOR_ROTATION_SPEED_SECONDS, this.systemContext, buildingNode );
            // buildingNode.addChild(this.buildingRotator);
            this.addChild( buildingNode );
        }
    }



    async _addLoadingEnvironent(){
        const environmentNode = new Object3DNode(
            new Mesh(
                new BoxGeometry( 200,.01,200 ),
                new MeshBasicMaterial()
            ),
            this.systemContext
        );
        this.addChild(environmentNode);
    }

    async _addEnvironmentAsync(){
        const skyboxNode = new SkyboxNode(this.systemContext,{rotating:false});
        const scale = libertyVenueBuildingScale/100;
        skyboxNode.object3D.scale.setScalar(scale);
        skyboxNode.object3D.position.z += scale*80;
        skyboxNode.object3D.position.y -= scale*100;
        this.addChild( skyboxNode );

        this._addCircles(scale);

        this._addClouds(scale);
        this._addVerticalBlasts();
        this._addHorizontalBlasts();
        this._addPeople();
        await this._addTreesAsync();
    }
    private _addClouds(scale: number) {
        const heightStart = 3;
        const heightEnd = 6;
        const PI = Math.PI;
        for(let height=heightStart; height<=heightEnd; ++height){
            const cloudScale = scale * 50*3;
            const cloudY = scale * height;
            const heightPercent = (height-heightStart)/(heightEnd-heightStart);
            const opacity = lerp(0,.5, heightPercent);
            const cloud = new IntroCloudNode(this.systemContext,opacity);
            cloud.object3D.scale.setScalar(cloudScale);
            cloud.object3D.position.y = cloudY;
            cloud.object3D.rotateY( lerp(-PI,PI,heightPercent) );
            this.addChild(cloud);
            const floatMagnitude= scale*.5*heightPercent;
            const floatPeriodSeconds = 5+5*heightPercent;
            cloud.addChild(
                new FloatParentBehaviorNode(floatMagnitude,floatPeriodSeconds,this.systemContext)
            );
        }
    }

    private _addVerticalBlasts(){
        const scale = libertyVenueBuildingScale/100;
        // const rooftopHeight = scale * 10;
        const rooftopRadius = scale * 4;
        const yToRespawnAfterReaching = 100*scale;
        
        const n = 16;
        for(let i=0; i<n; ++i){
            const evenlyDistributedAngle = i * (Math.PI * 2 / n);
            const randomAngle = lerp(0, Math.PI * 2, Math.random());
            const theta = evenlyDistributedAngle+randomAngle;
            const radius = rooftopRadius*Math.random();
            const x = radius*Math.cos(theta);
            const z = radius*Math.sin(theta);
            const speed = scale * lerp(10,20,Math.random()); 
            const blast = new IntroBlastNode(
                this.systemContext,
                speed
                ,yToRespawnAfterReaching,
                [0,1,0],
                [x,0,z]
            );
            const blastScale = scale*2;
            blast.object3D.scale.setScalar(blastScale);
            blast.randomizePosition();
            this.addChild(blast);
        }
    }

    private _addHorizontalBlasts(){
        const scale = libertyVenueBuildingScale/100;
        const rooftopHeight = scale * 10;
        const rooftopRadius = scale * 4;
        const distanceToRespawnAfterReaching = 100*scale;
        
        const n = 8;
        for(let i=0; i<n; ++i){
            const y = scale*lerp(10,10.5,Math.random());
            const evenlyDistributedAngle = i * (Math.PI * 2 / n);
            const randomAngle = lerp(0, Math.PI * 2, Math.random());
            const theta = evenlyDistributedAngle+randomAngle;
            const x = Math.cos(theta);
            const z = Math.sin(theta);
            const position = [
                -x*distanceToRespawnAfterReaching/2,
                y,
                -z*distanceToRespawnAfterReaching/2
            ] as [number,number,number];
            const speed = scale * lerp(50,80,Math.random()); 
            const blast = new IntroBlastNode(
                this.systemContext,
                speed
                ,distanceToRespawnAfterReaching,
                [x,0,z],
                position,
            );
            const blastScale = scale*2;
            blast.object3D.scale.setScalar(blastScale);
            blast.randomizePosition();
            this.addChild(blast);
        }
    }
    

    private _addCircles(scale: number) {
        const innerCircleScale = scale * 50;
        const circleY = scale * 10;
        {
            const circle = new IntroTechCircleNode(this.systemContext, {
                rotateRadiansPerSecond: Math.PI / 8,
                thickness: "normal",
                text:"Liberty Business Exhibition"
            });
            circle.object3D.scale.setScalar(innerCircleScale);
            circle.object3D.position.y = circleY;
            this.addChild(circle);
        }
        {
            const circle = new IntroTechCircleNode(this.systemContext, {
                rotateRadiansPerSecond: -Math.PI / 32,
                thickness: "thinner",
                text:"Liberty Business Exhibition"
            });
            circle.object3D.scale.setScalar(innerCircleScale * (56 / 30) * 1.5);
            circle.object3D.position.y = circleY;
            this.addChild(circle);
        }
        {
            const circle = new IntroTechCircleNode(this.systemContext, {
                rotateRadiansPerSecond: -Math.PI / 64,
                thickness: "thinner",
                text:"|||"
            });
            circle.object3D.scale.setScalar(innerCircleScale * .8);
            circle.object3D.rotateY(Math.PI/2);
            circle.object3D.position.y = circleY+scale*.5;
            this.addChild(circle);
        }
        {
            const circle = new IntroTechCircleNode(this.systemContext, {
                rotateRadiansPerSecond: Math.PI / 32,
                thickness: "thinner",
                text:"|||"
            });
            circle.object3D.scale.setScalar(innerCircleScale * .5);
            circle.object3D.position.y = circleY+scale*1;
            this.addChild(circle);
        }
    }
    private _addPeople(){
        const scale = libertyVenueBuildingScale/100;
        
        const origin = [...libertyVenueBuildingDoorPosition]
        origin[1] = scale * 9.54;
        origin[2] += 2*scale;
        
        const n = 50;
        for(let i=0; i<n; ++i){
            const xyz = [...origin] as [number,number,number];
            // xyz[1] = scale*lerp(10,10.5,Math.random());
            const evenlyDistributedAngle = i * (Math.PI * 2 / n);
            const randomAngle = lerp(0, Math.PI * 2, Math.random());
            const theta = evenlyDistributedAngle+randomAngle;
            const radius = [
                lerp(2,20,Math.random()),
                lerp(2,9,Math.random())
            ];
            let xFactor = Math.cos(theta);
            // a value between 0 and 1, that makes it so no people land in the middle
            const middleGapWidth = .2;
            if(xFactor<0){
                xFactor-=middleGapWidth/2;
            }else{
                xFactor+=middleGapWidth/2;
            }
            xyz[0] += radius[0]*xFactor;
            xyz[2] += radius[1]*Math.sin(theta);
            const textureUrl = TEXTURES_PATH+PEOPLE_FILES[i%PEOPLE_FILES.length];
            const person = new IntroBillboardNode(
                textureUrl,
                false,
                0.398963731,
                this.systemContext,
            );
            const personScale = scale*.24;
            xyz[1] += personScale/2;
            person.object3D.position.set(...xyz);
            person.object3D.scale.setScalar(personScale);
            this.addChild(person);
        }
    }
    private async _addTreesAsync(){
        const scale = libertyVenueBuildingScale/100;
        
        const origin = [0,0,0] as [number,number,number];
        origin[1] = scale * 9.54;

        const treeMapScale = scale*50*.77
        const treeMapOrigin = [0,origin[1],-scale*1.0] as const;
        
        const [treeMap,treeMapWidthPx,treeMapHeightPx] = await TextureUrlToCanvasImageDataAsync(TREE_MAP_TEXTURE_URL);

        function getRandomPosition(){
            let tries = 1000000;
            while(--tries>0){
                const xPx = Math.floor( lerp(0,treeMapWidthPx,Math.random()) );
                const yPx = Math.floor( lerp(0,treeMapHeightPx,Math.random()) );
                const pxIndex = yPx*4 * treeMapWidthPx + xPx*4 + 0;
                const pxRed = treeMap[pxIndex];
                const canPlaceTreeHere = pxRed > 128 ;
                if(!canPlaceTreeHere){
                    continue;
                }
                return [
                    treeMapOrigin[0]+(-.5+xPx/treeMapWidthPx)*treeMapScale,
                    treeMapOrigin[2]+(-.5+yPx/treeMapHeightPx)*treeMapScale,
                ];
            }
            throw new Error();
            
        }
        
        const n = 500;
        for(let i=0; i<n; ++i){
            const xyz = [...origin] as [number,number,number];
            const evenlyDistributedAngle = i * (Math.PI * 2 / n);
            const randomAngle = lerp(0, Math.PI * 2, Math.random());
            const theta = evenlyDistributedAngle+randomAngle;
            const radius = lerp(8,13,Math.random())*scale;
            xyz[0] += radius*Math.cos(theta);
            xyz[2] += radius*Math.sin(theta);
            const textureUrl = TEXTURES_PATH+TREE_FILES[i%TREE_FILES.length];
            const personScale = scale;
            xyz[1] += personScale/2;
            const xz = getRandomPosition();
            xyz[0]=xz[0];
            xyz[2]=xz[1];
            const tree = new IntroBillboardNode(
                textureUrl,
                xyz[2]<0,
                0.584313725,
                this.systemContext,
            );
            tree.object3D.position.set(...xyz);
            tree.object3D.scale.setScalar(personScale);
            this.addChild(tree);
        }
    }

    async removeBuildingRotation(){
        this.buildingRotator?.reset();
        this.buildingRotator?.destroy();
    }


    /** Note: these are lights specifically for the "liberty" venue */
    _addLights(){
        const lightColor = 0xFFFFFF;

        const scale = libertyVenueBuildingScale/100;

        const directionalLight = new DirectionalLight(0xFFFFFF, .8);
        directionalLight.position.set(5*scale,30*scale,1*scale);
        directionalLight.target.position.set(0,0,0)
        const light = new Object3DNode( directionalLight, this.systemContext );
        this.addChild( light );
        const lightTarget = new Object3DNode( directionalLight.target, this.systemContext );
        this.addChild( lightTarget );
        
        const ambientLight = new Object3DNode(
            new AmbientLight(lightColor, .5),
            this.systemContext
        );
        this.addChild( ambientLight );

        // main light
        {
            // const lightDistance = 100*libertyVenueBuildingScale;
            // const pointLight = new Object3DNode(
            //     new PointLight(lightColor, 1, lightDistance),
            //     this.systemContext
            // );
            // const position = new Vector3(0,libertyVenueBuildingScale,0);
            // pointLight.object3D.position.copy( position );
            // this.addChild(pointLight);
        }

        // door light
        {
            const lightDistance = 1*scale;
            const pointLight = new Object3DNode(
                new PointLight(lightColor, 1, lightDistance),
                this.systemContext
            );
            const position = new Vector3(...libertyVenueBuildingDoorPosition);
            position.z +=1*scale;
            pointLight.object3D.position.copy( position );
            this.addChild(pointLight);
        }

        // Light up the people in the center
        for(let angleDegrees=0; angleDegrees<360; angleDegrees+=360/5){
            continue;
            const lightDistance = 20*scale;
            const pointLight = new Object3DNode(
                new PointLight(lightColor, 30*scale, lightDistance),
                this.systemContext
            );
            const position = new Vector3(0,20*scale,-20*scale);
            position.applyEuler( new Euler(0,degToRad(angleDegrees),0) );
            pointLight.object3D.position.copy( position );
            this.addChild(pointLight);


            const preview = Object3DNode.MakePlaceholderNode( this.systemContext );
            preview.object3D.scale.setScalar( lightDistance );
            preview.object3D.position.copy( position );
            // this.addChild( preview );
        }
    }
}



