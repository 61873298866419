import { Object3D } from "three";
import { TEXTURE_HEIGHT_ADVERTISEMENT } from "../../config/TextureHeights";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";
import { VenueAdMapping, VenueBoothMapping } from "../../libraries/l2-common/l2-common-ts/resources/exhibition/Venues";
import { AdEntity } from "../ads/AdEntity";
import FileDisplayerNode from "../booths/subObjects/types/FileDisplayerNode";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";


export default class AdvertisementNode extends Object3DNode{

    constructor(
        ad:AdEntity,
        systemContext:SystemContext
    ){
        super(
            new Object3D(),
            systemContext
        );
        const displayNode = new FileDisplayerNode(
            (ad.record.fileIds||[])[0],
            TEXTURE_HEIGHT_ADVERTISEMENT,
            systemContext,
            this
        );
        this.addChild( displayNode );
    }

}