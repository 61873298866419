import * as Three from "three";
import { Camera, Euler, Vector3 } from "three";
import { degToRad } from "three/src/math/MathUtils";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import { CameraAnimation, CameraAnimationFrame } from "./CameraAnimation";

export default class CameraAnimationPlayerNode extends Object3DNode{

    private _elapsedTime:number=0;
    private readonly _animation:CameraAnimation;
    private _playing:boolean=false;
    private readonly _onComplete;

    constructor({
        onComplete,
        animation,
        systemContext
    }:{
        onComplete?:()=>void|Promise<void>,
        animation:CameraAnimation,
        parent:Object3DNode, 
        systemContext:SystemContext
    }){
        super( new Three.Object3D(), systemContext );
        this._animation = animation;
        this._onComplete=onComplete;
    }

    get elaspedTime(){
        return this.elapsedTime;
    }
    set elapsedTime(elapsedTime:number){
        this._elapsedTime=elapsedTime;
        this._updatePlayingAnimation(0);
    }

    play(){
        this._playing = true;
    }

    updateHook( deltaTime:number ){
        if( this._playing){
            this._updatePlayingAnimation( deltaTime );
        }
    }

    _updatePlayingAnimation( deltaTime:number ){
        const camera:Camera = this.systemContext.threeSystem.camera;

        this._elapsedTime += deltaTime;
        if( this._elapsedTime > this._animation.duration ){
            this._playing=false;
            this._onComplete?.call(null);
            return;
        }
        const postTransform = this._animation.elapsedTimeToTransform(this._elapsedTime);

        applyFrameToCamera(postTransform,camera);
    }

}

function applyFrameToCamera(frame:CameraAnimationFrame,camera:Camera){
    if( frame.position){
        camera.position.copy( new Vector3(...frame.position) );
    }
    if( frame.rotation ){
        const rotationRadians = (
            frame.isRotationRadians ? frame.rotation :
            frame.rotation.map( a=>degToRad(a) )
        );
        const euler = new Euler(...rotationRadians);
        camera.rotation.copy( euler );
    }
    if( frame.lookAt ){
        camera.lookAt(...frame.lookAt);
    }
}