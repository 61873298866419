import * as Three from "three";
import SystemContext from "../SystemContext";
import ThreeSystem from "../ThreeSystem";

import Node from "../nodes/Node";


interface StringToStringMapping {
    [index: string]: string
}


export default class InputMap{

    _keyboardEventKeyToInputKeyMapping : StringToStringMapping;
    
    /**
 * Maps KeyboardEvent.key strings to InputKey strings.
 * For example, might map "W" to "forward"
 */
    constructor( keyboardEventKeyToInputKeyMapping:StringToStringMapping ){
        this._keyboardEventKeyToInputKeyMapping = keyboardEventKeyToInputKeyMapping;
    }

    getInputKeyByKeyboardEventKey( keyboardEventKey:string ):string{
        return this._keyboardEventKeyToInputKeyMapping[
            keyboardEventKey
        ];
    }

    getAllInputKeys(){
        const result:string[] = [];
        for( const inputKey of Object.values(this._keyboardEventKeyToInputKeyMapping) ){
            if( ! result.includes( inputKey ) ){
                result.push( inputKey );
            }
        }
        return result;
    }

}