import * as Three from "three";
import { Raycaster, Vector2 } from "three";
import Object3DNode, { OBJECT3DNODE_OF_OBJECT3D_KEY } from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import Node from "../nodes/Node";
import { MOUSE_BUTTON_LEFT } from "../input/InputSystem";

const INTERACT_MOUSE_BUTTON = MOUSE_BUTTON_LEFT;

/**
 * Allows a node to receive "ActOnObject" messages when clicking on various objects.
 */
export class ClickToActOnObjectBehaviorNode extends Object3DNode{

    _currentMouseOveredEntities:Node[]=[];
    _raycaster = new Raycaster();
    _actOnObjectMessageReceiver;
    
    constructor( systemContext:SystemContext, actOnObjectMessageReceiver:Node ){
        super( new Three.Object3D(), systemContext );

        this._actOnObjectMessageReceiver = actOnObjectMessageReceiver;
    }
    
    updateHook( deltaTime:number ){
        const {camera,scene} = this.systemContext.threeSystem;

        const vectorScreenSpace = this.systemContext.inputSystem.getMousePosition() || new Vector2();

        this._raycaster.setFromCamera( vectorScreenSpace, camera );

        // Figure out which entities the mouse is over this frame
        // AND process click events
        const intersectedObjectsSortedByDistance = this._raycaster.intersectObjects( scene.children, true );
        const entitiesMousedOverThisFrame:Node[] = [];
        const isMouseButtonJustDown = this.systemContext.inputSystem.isMouseButtonJustDown(INTERACT_MOUSE_BUTTON);
        for(const {object} of intersectedObjectsSortedByDistance){
            const object3dNode = (object as any)[OBJECT3DNODE_OF_OBJECT3D_KEY] as Object3DNode|undefined;
            if( !object3dNode ){
                continue;
            }

            const messageTargetNode = object3dNode.messageTargetNode||object3dNode;
            entitiesMousedOverThisFrame.push( messageTargetNode );

            if( !isMouseButtonJustDown ){
                continue;
            }
            
            for(const target of [messageTargetNode,...messageTargetNode.descendants]){
                this._actOnObjectMessageReceiver.sendMessage(
                    "Message__ActOnObject",
                    [target]
                );
            }
        }

        // Process mouse exit events
        for( let i=this._currentMouseOveredEntities.length-1; i>=0; --i ){
            const node = this._currentMouseOveredEntities[i];
            if( !entitiesMousedOverThisFrame.includes(node) ){
                // remove the Node from the array
                this._currentMouseOveredEntities.splice(i,1);
                node.sendMessage("Message__Unhover");
            }
        }

        // Process mouse enter events
        for(const node of entitiesMousedOverThisFrame){
            if( ! this._currentMouseOveredEntities.includes(node) ){
                this._currentMouseOveredEntities.push( node );
                node.sendMessage("Message__Hover");
            }
        }
    }

}