

type ElementComparisonCallback = (a:any,b:any)=>boolean;

export default class Arrays{
    static Intersection<T>(a:T[], b:T[], comparisonCallback?:ElementComparisonCallback){
        return a.filter(
            aa=>b.some(
                bb=> comparisonCallback ? comparisonCallback(aa,bb) : aa===bb
            )
        );
    }
    static HasIntersection<T>(a:T[],b:T[], comparisonCallback?:ElementComparisonCallback){
        return a.some(
            aa=>b.some(
                bb=> comparisonCallback ? comparisonCallback(aa,bb) : aa===bb
            )
        );
    }
    /* Returns the set of items in A that are not in B (ie "set difference of A and B", ie the "relative complement of B in A") */
    static Difference<T>(a:T[],b:T[]){
        return a.filter( aa=>!b.includes(aa) );
    }
}

export class UniqueArrays{

    static ArrayToUniqueArray<T>(array:T[]){
        const result:T[] = [];
        for(const element of array){
            this.MaybePush(result,element);
        }
        return result;
    }

    /** Pushes to the array IF the array doesn't have the value, yet */
    static MaybePush<T>(array:T[],value:T){
        if(!array.includes(value)){
            array.push(value);
        }
    }

    /**
     * Returns a new array.
     * If the old array includes the value, the new array doesn't include the value.
     * If the old array doesn't include the value, the new array includes the value.
     */
    static Toggle<T>(array:T[],value:T){
        if( array.includes(value) ){
            return array.filter(a=>a!=value);
        }else{
            return [...array, value];
        }
    }
}

export function GetRandomArrayElement(array:any[]){
    const index = Math.floor(Math.random()*array.length)
    return array[index];
}