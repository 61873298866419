import "styled-components/macro";

import { useSystemContext, useTheme } from "../../logic/SystemContextComponents";

import UserAvatarComponent from "../account/UserAvatarComponent";
import { css, CSSProp } from "styled-components";
import { useLatestMessagesByRoomId } from "../../logic/messages/MessageRepositoryUtilities";
import { useAccountPublic } from "../../logic/accounts/AccountRepositoryUtilities";
import { NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { useResolvedChatRoomTitle } from "../../logic/messages/RoomRepositoryUtilities";
import ChatMessageEntity from "./ChatMessageEntity";
import { useResult, useResultOnMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { ChatMessageDisplayComponent } from "./ChatMessageDisplayComponent";

/**
 * Summarizes a conversation, by showing the title, latest message, etc... according to the space available
 */
export function ChatRoomMiniDisplayComponent( {
    roomId,
    onClick,
    layout,
}: {
    roomId: string,
    onClick:()=>void,
    layout?:CSSProp,
} ){
    const theme = useTheme();
    const systemContext = useSystemContext();

    const latestMessages = useLatestMessagesByRoomId(roomId);
    const latestMessageRecord = latestMessages?.slice(-1)[0];
    let latestAccount = useAccountPublic(latestMessageRecord?.ownerAccountId);
    if( latestAccount instanceof NotFoundResult ){
        latestAccount = undefined;
    }
    const chatTitle = useResolvedChatRoomTitle(roomId);

    const latestMessageEntity = (
        latestMessageRecord &&
        new ChatMessageEntity(latestMessageRecord,systemContext)
    );

    const resolvedOwnerName = useResult(async ()=>{
        if(!latestMessageEntity){
            return undefined;
        }
        return await latestMessageEntity.makeResolvedOwnerNameAsync();
    });

    return <div css={theme.Card.Normal+''+Layout.Root+layout} onClick={onClick} >
        <div css={Layout.ChatTitle}>
            <u>{chatTitle}:{" "}</u>
        </div>
        <ChatMessageDisplayComponent
            layout={Layout.MessagePreview}
            avatarFileId={latestAccount?.avatarFileId}
            messageBody={latestMessageEntity?.resolvedBody}
            ownerName={resolvedOwnerName}
        />
    </div>;
}

const Layout = {
    Root:css`
        cursor: pointer;
        display:flex;
        flex-direction: column;
        padding: .5em;
    `,
    ChatTitle:css`
    `,
    MessagePreview:css`
        width:100%;
        max-height: 5em;
        overflow:hidden;
    `,
};