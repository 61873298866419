import { Vector3 } from "three";
import FirstPersonCameraBehaviorNode from "../camera/FirstPersonCameraBehaviorNode";
import ThirdPersonFollowCameraBehaviorNode from "../camera/ThirdPersonFollowCameraBehaviorNode";
import { FloorSceneNode } from "../scenes/FloorSceneNode";
import SystemContext from "../SystemContext";


export function MaybeTeleportPlayerToBoothById( boothId:string, systemContext:SystemContext ){
    const {scene,player,threeSystem} = systemContext;

    if(!(scene instanceof FloorSceneNode) || !player){
        return;
    }
    const floorScene = scene;

    //TODO: conditionally move to the appropriate floor when teleporting to a booth

    const boothMapping = floorScene.mapEntity.getMappingsByFloorAndType(floorScene.floorIndex,"booth").find(
        mapping=>mapping.id===boothId
    );
    if( !boothMapping ){
        throw new Error("Player tried to teleport to non-existent booth (mapping) by id: "+boothId);
    }
    
    const boothNode = floorScene.floorNode.boothNodesById.get( boothMapping.id );
    if( ! boothNode ){
        throw new Error("Player tried to teleport to non-existent booth (node) by id: "+boothId);
    }

    player.object3D.position.copy( boothNode.teleportPosition );

    // rotate camera toward booth
    const cameraBehavior = player.findOneInDescendants(a=>(
        a instanceof FirstPersonCameraBehaviorNode ||
        a instanceof ThirdPersonFollowCameraBehaviorNode
    )) as (
        FirstPersonCameraBehaviorNode|
        ThirdPersonFollowCameraBehaviorNode
    );
    const boothXyz = boothNode.object3D.getWorldPosition( new Vector3() );
    const playerXyz = player.object3D.getWorldPosition( new Vector3() );
    const directionXyz = new Vector3().subVectors(boothXyz,playerXyz);
    const rotationY = Math.atan2(
        -directionXyz.x,
        -directionXyz.z
    );
    cameraBehavior.cameraRotationYAxis = rotationY;
}
