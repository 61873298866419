import "styled-components/macro";

import React from "react";
import ExhibitionMapBoothItemComponent from "./BoothItemComponent";
import {useSystemContext} from "../../logic/SystemContextComponents";
import LoadingWidgetPanelContents from "../common/LoadingWidgetPanelContents";
import {MenuHeader} from "../common/MenuHeader";
import { css } from "styled-components/macro";
import { useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { BoothEntity } from "../../logic/booths/BoothEntity";
import { FloorSceneNode } from "../../logic/scenes/FloorSceneNode";

export default function MapPanelContentsComponent(){
    const {scene,boothRepository,translate} = useSystemContext();
    const [shownFloorScene,setFloorScene] = React.useState<FloorSceneNode>();
    const {mapEntity,floorIndex} = shownFloorScene||{};

    const [booths, setBooths] = React.useState<Map<string,BoothEntity>>(new Map<string,BoothEntity>());
    const [waiting, setWaiting] = React.useState(false);

    useComponentDidUpdate( ()=>{
        (async ()=>{
            setWaiting(true);

            const newFloorScene = (scene instanceof FloorSceneNode)?scene:undefined;
            const isFloorChanged = (newFloorScene!==shownFloorScene);
            if(!isFloorChanged){
                setWaiting(false);
                return;
            }
            setFloorScene(newFloorScene);

            if(newFloorScene===undefined){
                setWaiting(false);
                return;
            }
            
            const {mapEntity,floorIndex} = newFloorScene;
            const boothMappings = mapEntity.getMappingsByFloorAndType(floorIndex,"booth")||[];
            const boothIds = boothMappings.map(
                mapping => mapping.id
            );
            const boothEntities = await boothRepository.maybeGetManyByIdsAsync( boothIds );
            setBooths( boothEntities );

            setWaiting(false);
        })();
    } );

    if( waiting || !floorIndex ){
        return <LoadingWidgetPanelContents />
    }

    const boothMappings = mapEntity?.getMappingsByFloorAndType(floorIndex,"booth")||[];

    if(!mapEntity){
        return <></>;
    }
    return <div css={Layout}>
        <MenuHeader>{translate("t$booths")}</MenuHeader>
        {boothMappings.map(
            (boothMapping,i)=>{
                const boothEntity = booths.has(boothMapping.id) && booths.get(boothMapping.id);
                return boothEntity && <ExhibitionMapBoothItemComponent
                    key={i+"__"+boothEntity.record.id}
                    mapping={boothMapping}
                    map={mapEntity}
                    booth={boothEntity}
                />;
            }
        )}
    </div>
}

const Layout = css`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;