import "styled-components/macro";

import React from "react";
import { SystemContextReactContext } from "../logic/SystemContextComponents";
import SystemContext from "../logic/SystemContext";



export default class ThreeSystemComponent extends React.Component{
    _element:HTMLDivElement|null|undefined;

    static contextType = SystemContextReactContext;

    componentDidMount(){
        const systemContext:SystemContext = this.context.systemContext;
        const {threeSystem} = systemContext;
        this._element?.appendChild( threeSystem.renderer.domElement );
        threeSystem.rendererContainerDiv = this._element;
        threeSystem.renderer.domElement.tabIndex=0;
        threeSystem.renderer.domElement.focus();    
        // Disables the browser's right click menu from appearing when we click the threejs container element.
        threeSystem.renderer.domElement.oncontextmenu = ()=>false;
    }

    
    render(){
        return <div
            style={{
                width:window.innerWidth,
                height:window.innerHeight,
                position: "absolute",
            }}
            ref={ ref=>this._element=ref }
        />
    }
}
