import { lerp } from "three/src/math/MathUtils";
import { CameraAnimation, CameraAnimationFrame } from "../logic/camera/CameraAnimation";
import { introAnimationDuration, libertyVenueBuildingDoorPosition, libertyVenueBuildingScale } from "./IntroConstants";

const scale = libertyVenueBuildingScale;

export class IntroCameraSwoopDownAnimation extends CameraAnimation{
    constructor(){
        super(introAnimationDuration);
    }
    elapsedTimeToTransform(elapsedTime:number):CameraAnimationFrame{
        const elapsedPercent = Math.min( elapsedTime/this.duration, 1 );
        const remainingPercent = 1.0 - elapsedPercent;

        const f = (x:number,y:number,t:number)=>lerp(x,y,easeOut(t));
        
        let position = [
            0,
            f(.3*scale, libertyVenueBuildingDoorPosition[1], elapsedPercent),
            f(.4*scale, libertyVenueBuildingDoorPosition[2]+.02*scale, elapsedPercent)
        ] as [number,number,number];

        // const y = .15;
        // const z = .15;
        // const y = .1;
        // const z = .08;

        // position = [
        //     0,
        //     f(y*scale, libertyVenueBuildingDoorPosition[1], elapsedPercent),
        //     f(z*scale, libertyVenueBuildingDoorPosition[2]+.02*scale, elapsedPercent)
        // ] as [number,number,number];

        // position = [
        //     0,
        //     .3*scale,
        //     0
        // ];


        const result:CameraAnimationFrame = {
            position,
            lookAt: libertyVenueBuildingDoorPosition
        };
        return result;
    }
}

function easeOut(t:number){
    return 1-Math.pow(1-t,5);
}