import { Camera, Object3D, Raycaster, Scene, Vector3 } from "three";
import Node from "../nodes/Node";
import Object3DNode, { OBJECT3DNODE_OF_OBJECT3D_KEY } from "../nodes/Object3DNode";


/**
 * Similar to ThreeJS's raycaster,
 * but this one can ignore collisions
 * on irrelevant objects.
 * */
export class CollisionRaycaster{
    private _raycaster;
    private _selfEntityNode;
    public get layers(){
        return this._raycaster.layers;
    }

    public set far(value:number){
        this._raycaster.far = value;
    }

    constructor(selfEntityNode:Node, near:number, far:number){
        this._selfEntityNode=selfEntityNode;
        this._raycaster = new Raycaster(
            new Vector3(),
            new Vector3(),
            near,
            far,
        );
    }

    /**
     * Updates the ray with a new origin and direction.
     * @param origin The origin vector where the ray casts from.
     * @param direction The normalized direction vector that gives direction to the ray.
     */
    set(position:Vector3,direction:Vector3){
        this._raycaster.set(position,direction);
    }

    /**
     * @param screenXyNdc in Normalized Device Coordinates
    */
    setFromCamera(screenXyNdc:{x:number,y:number},camera:Camera){
        this._raycaster.setFromCamera(screenXyNdc,camera);
    }

    /**
     * 
     * @returns The things the raycaster is intersecting, sorted by distance. Doesn't include things that have been ignored.
     */
    getIntersections(scene:Scene){
        const hits = this._raycaster.intersectObjects(
            scene.children, true
        ).filter(
            hit=>{
                let object3dNode = getObject3dNodeOfObject3d(hit.object);
                if( !object3dNode ){
                    return true;
                }
                const {messageTargetNode: entityNode} = object3dNode;
                if(entityNode===this._selfEntityNode){
                    return false;
                }
                const ignoresCollisions = object3dNode.collisionInfo?.ignoreCollisions;
                return !ignoresCollisions;
            }
        );
        return hits;
    }


}


function getObject3dNodeOfObject3d(object:Object3D) {
    // work up the tree to find object3dNode associated with this object3d
    let object3dNode: Object3DNode | undefined;
    let currentObject = object;
    while(!object3dNode) {
        object3dNode = (currentObject as any)[OBJECT3DNODE_OF_OBJECT3D_KEY];
        if( !currentObject.parent){
            break;
        }
        currentObject = currentObject.parent
    }
    return object3dNode;
}
