export async function TextureUrlToCanvasImageDataAsync(url:string,maxWidth?:number,maxHeight?:number){
    const smoothIfShrinking = false;
    
    return new Promise(
        (accept,reject)=>{
            const image = new Image();
            image.crossOrigin = "anonymous";
            // document.body.appendChild(image);

            image.onload = ()=>{
                const canvas = document.createElement("canvas");
                let [width,height,willShrink] = MaybeReduceDimensions([image.width,image.height],[maxWidth,maxHeight]);
                // document.body.appendChild(canvas);
                canvas.width =  width;
                canvas.height = height;
                const context = canvas.getContext("2d");
                if(!context){
                    return reject(new Error("Context was null."));
                }
                context.imageSmoothingEnabled = smoothIfShrinking&&willShrink;
                context.drawImage(image,0,0,image.width,image.height,0,0,width,height);
                const data = context.getImageData(0,0,width,height).data;
                // document.body.removeChild(canvas);
                // document.body.removeChild(image);
                return accept([data,canvas.width,canvas.height]);
            };

            image.src = url;
        }
    ) as Promise<[Uint8ClampedArray,number,number]>;

}

function MaybeReduceDimensions(widthHeight: [number,number], maxWidthHeight: [number|undefined, number|undefined] ): [number,number,boolean] {
    if( maxWidthHeight.every(a=>!a) ){
        return [...widthHeight,false];
    }
    const usage = [0,1].map(i=>maxWidthHeight[i]?(widthHeight[i]/(maxWidthHeight as any)[i]):0);
    const scale = 1.0 / Math.max(...usage);
    if( scale >= 1.0 ){
        return [...widthHeight,false];
    }
    return [...widthHeight.map(
        a=>Math.round(a*scale)
    ),true] as [number,number,boolean];
}
