import SystemContext from "../SystemContext";
import { SceneNode } from "./SceneNode";

/* The scene that's loaded when the app shows a 404 page. */
export class NotFoundSceneNode extends SceneNode{

    constructor(context:SystemContext){
        super(context);
    }

    async loadAsync(){
    }

    async unloadAsync(){
    }

}