import * as Three from "three";
import { Camera, Euler, Mesh, MOUSE, Object3D, Quaternion, Raycaster, Vector2, Vector3 } from "three";


import Object3DNode, { OBJECT3DNODE_OF_OBJECT3D_KEY } from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import InputMap from "../input/InputMap";
import FileViewerNode from "../booths/subObjects/types/FileViewerNode";
import Node from "../nodes/Node";
import { CollisionRaycaster } from "../collisions/CollisionRaycaster";
import { LAYER_BOOTH_SURFACE, LAYER_INTERACTABLE, LAYER_NONCOLLIDABLE } from "../ThreeSystemLayers";

import CURSOR_TEXTURE from "../../images/icons/cursor--3d.png";
import Resources from "../Resources";
import { MOUSE_BUTTON_LEFT } from "../input/InputSystem";

const INTERACT_MOUSE_BUTTON = MOUSE_BUTTON_LEFT;


// the normals for some faces on the booths are wrong,
// so we can't use this feature, yet
const POINT_CURSOR_VIA_NORMALS = false;
const POINT_CURSOR_VIA_CAMERA = !POINT_CURSOR_VIA_NORMALS;

/** Causes the threejs camera to lock to the position/rotation of whatever the parent of this Node is. */
export class Cursor3DBehaviorNode extends Object3DNode{

    _currentMouseOveredEntities:Node[]=[];

    private _cursor!:Object3DNode;
    private _cursorMeshes:Three.Mesh<any, any>[]=[];

    _raycaster;

    private _normal?:Vector3;
    get normal(){
        return this._normal
    }

    get position(){
        return this._cursor.object3D.position;
    }
    
    private _isInValidPosition:boolean=false;
    get isInValidPosition(){
        return this._isInValidPosition;
    }

    constructor(
        systemContext:SystemContext,
        playerNode:Object3DNode,
    ){
        super( new Three.Object3D(), systemContext );

        this._raycaster = new CollisionRaycaster( playerNode, 0, 30 );
        this._raycaster.layers.set(LAYER_BOOTH_SURFACE);

        this._addMesh(systemContext);
    }

    private _addMesh(systemContext: SystemContext) {
        
        this._cursor = new Object3DNode(new Object3D(),systemContext);
        // we add 2 cursor sprites
        const rotations = POINT_CURSOR_VIA_NORMALS ? [0,Math.PI/2] : [0]
        for(const rotation of rotations){
            const cursorMesh = new Mesh(
                new Three.PlaneGeometry(.2,.2),
                new Three.MeshToonMaterial({ transparent: true, side:Three.DoubleSide })
            );
            const cursorSprite = new Object3DNode(
                cursorMesh,
                systemContext
            );
            this._cursorMeshes.push(cursorMesh);
            if( POINT_CURSOR_VIA_CAMERA ){
                // make the cursor sprite point at its origin
                cursorSprite.object3D.position.y-=.1;
                // move the cursor closer to the camera
                cursorSprite.object3D.position.z+=.1;
            }
            if( POINT_CURSOR_VIA_NORMALS ){
                // make the cursor sprite point at its origin
                cursorSprite.object3D.position.z-=.1;
                // rotate it so that it's pointing forward in 3D space
                cursorSprite.object3D.rotateX(Math.PI/2);
            }
            cursorSprite.object3D.rotateY(rotation);
            this._cursor.addChild(cursorSprite);
            cursorSprite.object3D.layers.set(LAYER_NONCOLLIDABLE);
        }

        // this._cursor.addChild( Object3DNode.MakePlaceholderNode(systemContext) );

        // this._cursor = Object3DNode.MakePlaceholderNode(systemContext);
        // this.addChild(this._cursor);
        this._setTexturesAsync();
        
        this._cursor.object3D.layers.set(LAYER_NONCOLLIDABLE);
        systemContext.threeSystem.rootNode.addChild( this._cursor );

    }

    disposeHook(){
        console.log("Cursord3DBehaviorNode: dispose()")
        this.systemContext.threeSystem.rootNode.removeChild( this._cursor );
    }

    // TODO: Adjust the aspect ratio of the texture to match the scale of this object
    async _setTexturesAsync(){
        
        const texture = await Resources.LoadTextureAsync( CURSOR_TEXTURE );
        for(const cursorMesh of this._cursorMeshes ){
            cursorMesh.material.map = texture;
            cursorMesh.material.needsUpdate = true;
        }
        
    }
    
    updateHook( deltaTime:number ){
        const {camera,scene} = this.systemContext.threeSystem;

        const vectorScreenSpace = this.systemContext.inputSystem.getMousePosition() || new Vector2();

        this._raycaster.setFromCamera( vectorScreenSpace, camera );

        const [firstHit] = this._raycaster.getIntersections( scene );
        if(!firstHit){
            // this._cursor.object3D.position.y = -9999;
            this._cursor.object3D.visible = false;
            this._isInValidPosition = false;
            return;
        }else{
            this._cursor.object3D.visible = true;
            this._isInValidPosition = true;
        }
        this._cursor.object3D.position.copy( firstHit.point );
        this._setNormal(firstHit.face?.normal);

        if(POINT_CURSOR_VIA_CAMERA){
            const cameraWorldXyz = camera.getWorldPosition(new Vector3());
            this._cursor.object3D.lookAt( cameraWorldXyz );
        }

        // const cameraWorldDirection = camera.getWorldDirection(new Vector3());
        // const rotationY = Math.atan2(
        //     cameraWorldDirection.x,
        //     cameraWorldDirection.z
        // )+Math.PI;
        // this._cursor.object3D.rotation.y = rotationY;
    }

    _setNormal(normal?:Vector3){
        this._normal = normal;
        if(normal && POINT_CURSOR_VIA_NORMALS){
            const worldPosition = this._cursor.object3D.getWorldPosition(new Vector3());
            this._cursor.object3D.lookAt(worldPosition.sub(normal));
        }
    }

    

}