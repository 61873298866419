import "styled-components/macro";

import {useSystemContext} from "../../logic/SystemContextComponents";
import { css } from "styled-components/macro";
import LoadingWidgetPanelContents from "../../components/common/LoadingWidgetPanelContents";
import { MenuHeader } from "../../components/common/MenuHeader";
import { useMyBooths } from "../booths/BoothRepositoryUtilities";
import { BoothEditorBoothSelectPanelItemComponent } from "./BoothEditorBoothSelectPanelItemComponent";
import DocumentTitle from "../dom/DocumentTitle";

export function BoothEditorBoothSelectPanelContentsComponent(){
    const {translate} = useSystemContext();
    const myBooths = useMyBooths();

    if( !myBooths ){
        return <LoadingWidgetPanelContents/>
    }

    return <div css={Layout}>
        <DocumentTitle title="Booth Editor | Liberty"/>
        <MenuHeader>{translate("t$boothEditor_myBooths")}</MenuHeader>
        {myBooths.map(
                (boothEntity)=><>
                    <BoothEditorBoothSelectPanelItemComponent key={boothEntity.record.id} boothEntity={boothEntity} />
                </>
        )}
    </div>
}

const Layout = css`
    height:100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
    & > * {
        margin-bottom: 1em;
    }
`;