import { System } from "typescript";
import { map } from "zod";
import SystemContext from "../SystemContext";
import { SceneNode } from "./SceneNode";
import assert from "assert";
import FloorNode from "../floors/FloorNode";
import { MapEntity } from "../maps/MapEntity";
import {PlayerNode} from "../player/PlayerNode";
import { UseUrlResourceId } from "../utility/URLs";
import { MaybeTeleportPlayerToBoothById } from "../utility/GameLogicHelpers";
import { ExpoBehaviorNode } from "../player/ExpoBehaviorNode";

export class FloorSceneNode extends SceneNode{
    readonly mapId;
    readonly floorIndex;
    private _mapEntity!:MapEntity;
    get mapEntity(){ return this._mapEntity; }
    private _floorNode!:FloorNode;
    get floorNode(){ return this._floorNode; }
    private _playerNode!:PlayerNode;
    get playerNode(){ return this._playerNode; }

    constructor(mapId:string,floorIndex:number,context:SystemContext){
        super(context);
        this.mapId=mapId;
        this.floorIndex=floorIndex;
    }

    async loadAsync(){
        const context = this.systemContext;
        const threeSystem = context.threeSystem;

        threeSystem.camera.position.set(-8,8,8);
        threeSystem.camera.position.set(-20,20,10);
        threeSystem.camera.lookAt(0,0,0);


        const liveMaps = await context.mapRepository.getLiveMapsAsync();
        const mapEntity = liveMaps.find(
            map=>map.record.id===this.mapId
        );
        assert( mapEntity );

        this._mapEntity = mapEntity;
        
        this._floorNode = new FloorNode(mapEntity,this.floorIndex,context);
        await this._floorNode.loadAsync();
        this.addChild( this._floorNode );

        this._playerNode = new PlayerNode( context );
        this._playerNode.object3D.position.x = 30;
        this._playerNode.addChild( new ExpoBehaviorNode(context) );
        this.addChild( this._playerNode );


        const boothId = UseUrlResourceId("booths");
        if( boothId ){
            MaybeTeleportPlayerToBoothById( boothId, context );
        }
    }

    async unloadAsync(){
        
    }

}