import "styled-components/macro";

import { useForceSystemContextUpdate, useSystemContext } from '../../logic/SystemContextComponents';
import Button from "../common/Button";
import UserAvatarComponent from "../account/UserAvatarComponent";
import { useMySystemAccount } from "../../logic/accounts/SessionState";
import { css, CSSProp } from "styled-components";


export function AccountInfo(
    {
        layout
    }:{
        layout?:CSSProp
    }
){
    const {translate,sessionState} = useSystemContext();
    const forceSystemContextUpdate = useForceSystemContextUpdate();

    const systemAccount = useMySystemAccount();
    
    if( ! systemAccount ){
        return <> {translate("t$loadingDotDotDot")} </>
    }

    return <div css={Layout.Root+''+layout}>
        
        {/* <div>
            {translate("t$loggedInAs")}:
        </div>
            <UserAvatarComponent layout={Layout.Avatar} avatarFileId={systemAccount.avatarFileId}/>
        <div>
        </div>
        <div>
            <b>{systemAccount.slug}</b>
        </div>
        
        <Button layout={Layout.Button} onClick={()=>{
            sessionState.logOut();
            forceSystemContextUpdate();
        }}>
            {translate("t$logout")}
        </Button> */}

        <Button layout={Layout.Button} onClick={()=>{
            sessionState.logOut();
            forceSystemContextUpdate();
        }}>
            <UserAvatarComponent layout={Layout.Avatar} avatarFileId={systemAccount.avatarFileId}/>
            <b>{systemAccount.slug}</b>
        </Button>

    </div>;
}

const Layout = {
    Root: css`
        overflow:hidden;

        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items:center;

        & > * {
            height: 70%;
        }
    `,
    Button: css`
        width: 10em;
        max-width: 100%;
        max-height: 100%;
    `,
    Avatar: css`
        height: 100%;
        margin-right: 1em;
    `,
};