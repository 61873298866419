import { time } from "console";
import { string } from "zod";

export default class Time{
    static CurrentUnixSeconds(){
        return Math.floor(Date.now() / 1000);
    }
    static DaysToSeconds(days:number){
        return this.HoursToSeconds( 24*days );
    }
    // builds a time value according to the specified hours,
    // for example, 1 hour = 3600 seconds
    static HoursToSeconds(hours:number){
        return this.MinutesToSeconds( 60*hours );
    }
    static MinutesToSeconds(hours:number){
        return hours * 60;
    }

    static SecondsToHours(seconds:number){
        return this.SecondsToMinutes(seconds)/60;
    }
    static SecondsToMinutes(seconds:number){
        return seconds/60;
    }

    // // if you await this function, the thread will sleep
    // static async Sleep_async( ms:number ){
    //   const end_time = new Date().getTime() + ms;
    //   while( new Date().getTime() < end_time ){ ; }
    // }
    /** You can await this to approximately "sleep" */
    static SleepAsync(ms:number){
        return new Promise(
            resolve=>setTimeout(resolve, ms)
        );
    }

    /** Provides a friendly string to show how long ago a timestamp was. Default is in english, but you can provide your own translations. Note that numerals are prepended to some of the strings, so please phrase the translations with that in mind. */
    static TimeAgo(timestampUnixSeconds:number, strings:{
        justNow:string,
        minuteAgo:string,
        minutesAgo:string,
        hourAgo:string,
        hoursAgo:string,
        dayAgo:string,
        daysAgo:string
    }={
        justNow:"just now",
        minuteAgo:"minute ago",
        minutesAgo:"minutes ago",
        hourAgo:"hour ago",
        hoursAgo:"hours ago",
        dayAgo:"day ago",
        daysAgo:"days ago"
    }) {
        const seconds_ago = this.CurrentUnixSeconds() - timestampUnixSeconds;
        if (seconds_ago < 60) {
            return strings.justNow;
        }
        if (seconds_ago < 60 * 2) {
            return 1 + " " + strings.minuteAgo
        }
        if (seconds_ago < 60 * 60) {
            return (
            Math.floor(seconds_ago / 60) + " " + strings.minutesAgo
            );
        }
        if (seconds_ago < 60 * 60 * 2) {
            return 1 + " " + strings.hourAgo;
        }
        if (seconds_ago < 60 * 60 * 24) {
            return (
            Math.floor(seconds_ago / (60 * 60)) +
            " " +
            strings.hourAgo
            );
        }
        if (seconds_ago < 60 * 60 * 24 * 2) {
            return 1 + " " + strings.dayAgo;
        }
        return (
            Math.floor(seconds_ago / (60 * 60 * 24)) +
            " " +
            strings.daysAgo
        );
    }

    /** eg 2022-07-25--11:25:03 */
    static SecondsToDateTimeDisplayString(unix_time:number){
        const d = new Date(unix_time*1000);
        return Time.SecondsToDateDisplayString(unix_time) + "--" +
            ( "0"+d.getUTCHours() ).slice(-2) + ":" + 
            ( "0"+d.getUTCMinutes() ).slice(-2) + ":" + 
            ( "0"+d.getUTCSeconds() ).slice(-2)
        ;
    }

    /** eg 2022-07-25 */
    static SecondsToDateDisplayString(unix_time:number){
        const d = new Date(unix_time*1000);
        return d.getFullYear() + "-" +
            ( "0"+(d.getMonth()+1) ).slice(-2) + "-" +
            ( "0"+d.getDate() ).slice(-2)
        ;
    }
}