import { useState } from "react";
import { ChatMessageRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";
import { useComponentDidMount, useComponentDidUnmount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext } from "../SystemContextComponents";
import { useMessageWatcher } from "./MessageWatcher";

export function useChatRoomRepository(){
    const context = useSystemContext();
    return context.messageSystem.roomRepository;
}
export function useChatMessageRepository(){
    const context = useSystemContext();
    return context.messageSystem.messageRepository;
}
export function useChatDraftMessageRepository(){
    const context = useSystemContext();
    return context.messageSystem.draftMessageRepository;
}

/** The messages for the room, updated as they come in
 *  (the array is in chronological order)
 */
export function useLatestMessagesByRoomId(roomId:string){
    const messageRepository = useChatMessageRepository();
    const messageWatcher = useMessageWatcher();
    const [latestMessages,setLatestMessages] = useState<ChatMessageRecord[]>();

    const updateMessages = async (newMessages:ChatMessageRecord[])=>{
        setLatestMessages([
            ...(latestMessages||[]),
            ...newMessages
        ]);
    };

    useComponentDidMount( async ()=>{
        const latestMessages = await messageRepository.getAllAsync();
        setLatestMessages(latestMessages);
        messageWatcher.addListener( messageRepository, updateMessages );
    });

    useComponentDidUnmount( ()=>{
        messageWatcher.removeListener( messageRepository, updateMessages );
    });

    return latestMessages?.filter(
        a=>a.roomId===roomId
    );
}
