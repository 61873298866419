import { DoesNotExistResult } from "../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { MarketplaceOfferRecord } from "../libraries/l2-common/l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
import SystemContext from "../logic/SystemContext";
import { OfferEntity } from "./OfferEntity";

export class MarketplaceOfferRepository{
    private _api;

    private _recordByIdCache = new Map<string, MarketplaceOfferRecord>();
    
    constructor( systemContext:SystemContext ){
        this._api = systemContext.api;
    }

    async getByIdAsync( id:string ){
        if( this._recordByIdCache.has(id) ){
            const record = this._recordByIdCache.get(id);
            return new OfferEntity(
                record as MarketplaceOfferRecord
            );
        }
        const record = await this._api.marketplace.offers.byId(id).getAsync();
        if( record instanceof DoesNotExistResult ){
            return undefined;
        }
        return new OfferEntity( record );
    }    



}