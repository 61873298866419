import { Euler, Object3D, RectAreaLight, Vector3 } from "three";
import assert from "assert";
import * as Three from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import {MapEntity} from "../maps/MapEntity";
import BoothNode from "../booths/BoothNode";
import Resources from "../Resources";
import AdvertisementNode from "../advertisements/AdvertisementNode";
import FloatParentBehaviorNode from "../effects/FloatParentBehaviorNode";
import SkyboxNode from "./SkyboxNode";
import { degToRad } from "three/src/math/MathUtils";
import { AdRepository } from "../ads/AdRepository";
import { Venues } from "../../libraries/l2-common/l2-common-ts/resources/exhibition/Venues";
import { ExpoBoothType } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothTypes";
import { MobNode } from "../player/MobNode";
import FloorEnvironmentNode from "./FloorEnvironmentNode";

export default class FloorNode extends Object3DNode{
    _map;
    readonly floorIndex:number;
    public boothNodesById = new Map<string,BoothNode>();

    /** Creates a new FloorNode. Note that it will be empty until you call this.loadAsync() */
    constructor( mapEntity:MapEntity, floorIndex:number, systemContext:SystemContext ){
        super(
            new Three.Object3D(),
            systemContext
        );
        assert( mapEntity );
        this._map = mapEntity;
        this.floorIndex = floorIndex;
    }
    
    async loadAsync(){
        
        const environment = new FloorEnvironmentNode("liberty",1,this.systemContext);
        this.addChild( environment );
        await environment.loadAsync();

        await this._addBoothPlaceholdersAsync();

        await this._addBoothsAsync();

        await this._addAdsAsync();

        this._addMobs();
    }

    async _addBoothsAsync(){
        if( ! this._map.record.venueId ){
            return;
        }
        const {boothRepository} = this.systemContext;

        const mapBoothMappings = this._map.getMappingsByFloorAndType(this.floorIndex,"booth");
        // cache the booths to minimize API calls
        await boothRepository.cacheManyByIdsAsync(
            mapBoothMappings.map( a=>a.id )
        );

        const venue = Venues.find( a=>a.id===this._map.record.venueId );
        if(!venue){
            return;
        }

        for(const mapMapping of mapBoothMappings ){
            const booth = boothRepository.getByIdAsync(mapMapping.id);
            const venueMapping = venue?.boothMappings.find(
                mapping=>mapping.address===mapMapping.address
            );

            if(!booth ||!venueMapping){
                continue;
            }

            const boothNode = new BoothNode( mapMapping.id, this.systemContext );
            boothNode.maybeSetPosition( venueMapping.transform );
            boothNode.maybeSetRotation( venueMapping.transform );

            if( boothNode ){
                this.addChild( boothNode );

                this.boothNodesById.set(
                    mapMapping.id,
                    boothNode
                );
            }
        }
    }

    /** Ads the 3d ad panel entities into the scene. */
    async _addAdsAsync(){
        const {adRepository} = this.systemContext;

        const venue = Venues.find( a=>a.id===this._map.record.venueId );
        if(!venue){
            return;
        }

        const mapAdMappings = this._map.getMappingsByFloorAndType(this.floorIndex, "ad");

        // cache to minimize API calls
        await adRepository.cacheManyByIdsAsync(
            mapAdMappings.map( a=>a.id )
        );


        for(const mapMapping of mapAdMappings ){
            const ad = await adRepository.getByIdAsync(mapMapping.id);
            const venueMapping = venue.adMappings.find(
                mapping=>mapping.address===mapMapping.address
            );

            if(!ad ||!venueMapping){
                continue;
            }
            
            const adNode = new AdvertisementNode(
                ad,
                this.systemContext
            );
            adNode.maybeSetPosition( venueMapping.transform );            
            adNode.maybeSetRotation( venueMapping.transform );
            venueMapping.transform.scale = [8,8,8];
            adNode.maybeSetScale( venueMapping.transform );
            
            this.addChild( adNode );
        }
    }

    private async _addMobs(){
        const mobLimit = 50;
        let booths = this.children.filter(a=>a instanceof BoothNode) as BoothNode[];
        booths = booths.sort( (a,b)=>Math.random()-.5 );
        booths = booths.slice(0,mobLimit)
        for(const booth of booths){
            const {teleportPosition} = booth;
            const mob = new MobNode(this.systemContext);
            mob.object3D.position.copy(teleportPosition);
            this.addChild(mob);
        }
    }

    private async _addBoothPlaceholdersAsync(){
        const venue = Venues.find( a=>a.id===this._map.record.venueId );
        if(!venue){
            return;
        }
        
        // add placeholders for the other booths, too
        for( const venueMapping of venue.boothMappings ){
            const isAlreadyPlaced = this._map.getMappingsByFloorAndType(this.floorIndex,"booth").some(
                boothEntry=>boothEntry.address===venueMapping.address
            );
            if( isAlreadyPlaced ){
                continue;
            }

            // const footprint = venueMapping.footprintMeters;
            // TODO: get biggest placeholder available for footprint, etc...
            const boothType:ExpoBoothType = "simpleRed";

            const boothNode = BoothNode.MakePlaceholder(boothType, this.systemContext);
            boothNode.maybeSetPosition( venueMapping.transform );
            boothNode.maybeSetRotation( venueMapping.transform );
            
            this.addChild( boothNode );
        }
    }



}