import ApiError from "../../l2-common-ts/common/errors/ApiError";

/*
    A result that's returned when something does not exist,
    for example, if the resource is not found (404)
    or if the resource has been deleted (410)
*/
export class DoesNotExistResult{
    readonly error;
    constructor(error:ApiError){
        this.error = error;
    }
}
/*
    A result that's returned when the resource is not found (404)
*/
export class NotFoundResult extends DoesNotExistResult{}
/*
    A result that's returned when the resource has been deleted (410)
*/
export class GoneResult extends DoesNotExistResult{}

// for code generation
export const NotFoundResultClassName = "NotFoundResult";
export const GoneResultClassName = "GoneResult";