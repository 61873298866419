import "styled-components/macro";
import { css } from "styled-components/macro";

import { useTheme } from "../../logic/SystemContextComponents";

export function MenuHeader(
    props:any
){
    const theme = useTheme();
    return <div css={Layout+''+theme.MenuHeader+''+(props.layout||'')} {...props}/>
}


const Layout = css`
    font-size: 1.618em;
    word-wrap: break-word;
    text-align: center;
    hyphens: auto;
`;