import * as Three from "three";
import { Vector3 } from "three";
import assert from "assert";


import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";


/** Causes the parent of this node to slowly rotate. */
export default class RotateParentBehaviorNode extends Object3DNode{
    private radiansPerSecondClockwise:number;
    private parentOriginalRotationY?:number;
    
    constructor( radiansPerSecondClockwise:number, systemContext:SystemContext, parent:Object3DNode ){
        super( new Three.Object3D(), systemContext );
        this.radiansPerSecondClockwise=radiansPerSecondClockwise;

        this.parentOriginalRotationY = parent.object3D.rotation.y;
    }
    
    updateHook( deltaTime:number ){
        assert( this._parent instanceof Object3DNode );


        this._parent.object3D.rotateY(-this.radiansPerSecondClockwise*deltaTime);
    }


    /** Returns the parent to the original rotation */
    reset(){
        assert( this._parent instanceof Object3DNode );
        assert( this.parentOriginalRotationY!==undefined, "updateHook() not called yet." );

        this._parent.object3D.rotation.y = this.parentOriginalRotationY;
    }

}