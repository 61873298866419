import * as Three from "three";
import { AnimationAction, AnimationClip, AnimationMixer, Euler, Object3D, Vector3 } from "three";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils'

import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import { lerp } from "three/src/math/MathUtils";
import { CollisionRaycaster } from "../collisions/CollisionRaycaster";
import Resources from "../Resources";
import { GetRandomArrayElement } from "../../libraries/l2-common/l2-common-ts/common/utility/Arrays";
import config from "../../config/config";

const WALK_SPEED_MIN_UNITS_PER_SECOND = 0;

// If you're moving more than 4 m/s, the walk animation looks silly.
// But since moving at walking speed in-game is tedious,
// this ends up meaning that we we usually be running.
const RUN_ANIMATION_SPEED_THRESHOLD = 4;

const WALK_SPEED_ACCELERATION_UNITS_PER_SECOND_SQUARED = 60;

// Used for walking
const COLLISION_DISK_Y = .5;
const COLLISION_DISK_RADIUS = 1;            

const MODELS_PATH = `${config.staticContentUrl}models/`;
const COMBINED_MODEL_URL = MODELS_PATH + "Characters_Combined_04.gltf";

const LOD_LAYER = "lod0";


export default class PersonNode extends Object3DNode{
    private _torsoRotation = new Euler();
    private _desiredTorsoRotation = new Euler();
    private _currentWalkSpeed = WALK_SPEED_MIN_UNITS_PER_SECOND;
    /** Tracks the model's speed in space. used for animation */
    private _latestSpeed = 0;

    private _raycaster;
    private _entityNode;
    private _useCollisions;

    private _containerForModelRotationAndScale;

    private _gltfScene:any;
    private _gltfObjectNode?:Object3DNode;
    
    private _mixer?:AnimationMixer;
    private _currentPlayingAction?:AnimationAction;
    private _animationActions = {

    } as {
        idle?:AnimationAction,
        walk?:AnimationAction,
        run?:AnimationAction
    };


    private _modelName:string;
    private _visibleMeshes:string[];

    /** tracked in a separate value so that we can set/get visible before _gltfObjectNode has finished loading */
    private _visible=true;
    set visible(value:boolean){
        this._visible = value;
        if(!this._gltfObjectNode){
            return;
        }
        this._gltfObjectNode.object3D.visible = value;
    }
    get visible(){
        return this._visible;
    }

    /**
     * Contains a person that can walk around.
     * You can use this node as a child when creating players and NPCs
    * */
    constructor( systemContext:SystemContext,
        /** When something collides with the person's mesh
         * (eg being clicked on, run into),
         * it will treat this node as the thing that was collided into  */
        entityNode:Object3DNode,
        options?:{
            hideModel?:boolean,
            useCollisions?:boolean,
        }
    ){
        super( new Three.Object3D(), systemContext );


        this._entityNode = entityNode;
        const near = 0;
        let far = COLLISION_DISK_RADIUS*10;
        this._raycaster = new CollisionRaycaster(
            entityNode,
            near,
            far
        );

        this._useCollisions = true;
        if(options?.useCollisions!==undefined){
            this._useCollisions=options.useCollisions;
        }


        this._containerForModelRotationAndScale = new Object3DNode(new Object3D(), this.systemContext );
        this.addChild( this._containerForModelRotationAndScale );

        // this._addPlaceholder();

        const {modelName,visibleMeshes} = MakeRandomCostume();
        this._modelName = modelName;
        this._visibleMeshes = visibleMeshes;
        
        const hideModel = options?.hideModel;
        if(!hideModel){
            this._loadAsync()
        }
    }

    private _addPlaceholder() {
        const characterPlaceholder = Object3DNode.MakePlaceholderNode(this.systemContext, this);
        characterPlaceholder.object3D.scale.set(.25, 1.5, .25);
        characterPlaceholder.object3D.position.set(0, .75, 0);
        this.addChild(characterPlaceholder);
    }

    private async _loadAsync(){
        this._containerForModelRotationAndScale.object3D.scale.setScalar(
            1
        );
        this._containerForModelRotationAndScale.object3D.position.set(
            0,0,0
        );

        const gltf = await Resources.LoadGLTFAsync(COMBINED_MODEL_URL);
        // does not work for this model...
        // const scene = gltf.scene.clone();        
        // console.log("Clone")
        // ...see:
        // https://discourse.threejs.org/t/how-to-clone-a-model-thats-loaded-with-gltfloader/23723/7
        // https://discourse.threejs.org/t/how-i-can-import-the-skeleton-utils-in-typescript-since-version-0-132/32752
        // ...so instead:
        const scene = (SkeletonUtils as any).clone( gltf.scene );
        const objectNode = new Object3DNode(
            scene,
            this.systemContext,
            this._entityNode
        );
        this._gltfObjectNode = objectNode;
        // in case visible was changed before we finished loading, we need to update visibility now
        this._gltfObjectNode.object3D.visible = this._visible;
        this._gltfScene = scene;
        this._containerForModelRotationAndScale.addChild( objectNode );
        this._visible = true;

        this._initMeshes();
        this._initAnimations( gltf.animations );
    }

    private _initMeshes(){
        const scene = this._gltfScene;
        const armatures = scene.children;
        for(const armature of armatures){
            const [modelName,] = armature.name.split("--");
            const meshes = armature.children;
            for( const mesh of meshes ){
                const [modelName2,partName,lodLayer] = mesh.name.split("--");
                mesh.visible = (
                    modelName===this._modelName &&
                    lodLayer===LOD_LAYER &&
                    this._visibleMeshes.some(a=>a===partName)
                );
            }
        }
    }

    private _initAnimations( animations:AnimationClip[] ){
        this._mixer = new AnimationMixer(this.object3D);
        for(const animation of animations){
            const [modelName,animationName] = animation.name.split("--");
            if(modelName!==this._modelName){
                continue;
            }
            const action = this._mixer.clipAction( animation );
            action.setLoop( Three.LoopRepeat, Infinity );
            (this._animationActions as any)[animationName] = action;
        }
        if(
            !this._animationActions.idle ||
            !this._animationActions.walk ||
            !this._animationActions.run
        ){
            throw new Error("Unable to find some animations.");
        }
    }

    updateHook( deltaTime:number ){
        this._updateTorsoRotation();
        this._updateAnimation(deltaTime);
    }

    _updateAnimation(deltaTime:number){
        if(!this._mixer ){
            return;
        }
        this._mixer.update(deltaTime);
        const speed = this._latestSpeed;
        let nextAction:AnimationAction|undefined;
        const {walk,idle,run} = this._animationActions;
        if( speed>RUN_ANIMATION_SPEED_THRESHOLD ){
            nextAction = run;
        }else if( speed > 0 ){
            nextAction = walk;
        }else{
            nextAction = idle;
        }
        if( nextAction !== this._currentPlayingAction ){
            this._mixer.stopAllAction();
            this._currentPlayingAction = nextAction;
            nextAction?.play();
        }
        if(this._currentPlayingAction){
            if( this._currentPlayingAction===walk ){
                this._currentPlayingAction.timeScale = speed/2.0;
            }else if( this._currentPlayingAction===run ){
                // technically / 4.0 aligns with the animation perfectly,
                // but when the player is at a high speed, it looks weird
                // this._currentPlayingAction.timeScale = speed/4.0;
                this._currentPlayingAction.timeScale = speed/8.0;
            }else{
                this._currentPlayingAction.timeScale = 1.0;
            }
        }
    }

    _updateTorsoRotation(){
        //TODO: figure out which direction is closest an
        const currentRotationY = this._torsoRotation.y;
        const desiredRotationY =this._desiredTorsoRotation.y;
        const differenceInRadians = Math.abs( desiredRotationY - currentRotationY );
        let newRotationY;
        if( differenceInRadians < Math.PI ){
            // if the direction to move doesn't cross the 0/360 point
            newRotationY = lerp(currentRotationY,desiredRotationY,.5);
        }else if( currentRotationY < desiredRotationY ){
            // if we have to move downward
            newRotationY = lerp(currentRotationY,desiredRotationY-Math.PI*2,.5);
            while( newRotationY < 0 ){
                newRotationY += Math.PI*2;
            }
        } else{
            // if we have to move upward
            newRotationY = lerp(currentRotationY,desiredRotationY+Math.PI*2,.5);
            while( newRotationY >= Math.PI*2 ){
                newRotationY -= Math.PI*2;
            }
        }
        
        this._torsoRotation.y = newRotationY;

        this.object3D.setRotationFromEuler( this._torsoRotation );
    }

    /** returns the distance traveled */
    walk({
        normalizedDirection,
        walkSpeed,
        runSpeed,
        run,
        deltaTime
    }:{
        normalizedDirection:Vector3,
        walkSpeed:number,
        runSpeed:number,
        run:boolean,
        deltaTime:number
    }){
        if( normalizedDirection.manhattanLength()===0 ){
            this._currentWalkSpeed = WALK_SPEED_MIN_UNITS_PER_SECOND;
            this._latestSpeed = 0;
            return 0;
        }

        this._raycaster.far = COLLISION_DISK_RADIUS+runSpeed;

        this._currentWalkSpeed = Math.min(
            this._currentWalkSpeed+WALK_SPEED_ACCELERATION_UNITS_PER_SECOND_SQUARED * deltaTime,
            run ? runSpeed : walkSpeed
        );

        const movementXYZ = new Vector3().copy(
            normalizedDirection
        ).multiplyScalar(
            this._currentWalkSpeed
        ).multiplyScalar(
            deltaTime
        );

        const totalMove = this._move(movementXYZ);
        this._latestSpeed = totalMove/deltaTime;

        return totalMove;
    }

    /** Returns the total amount (in units) that the person moved. */
    private _move(movementXYZ:Vector3) {
        const {scene} = this.systemContext.threeSystem;
        this._desiredTorsoRotation.setFromVector3(new Vector3(
            0,
            Math.atan2(movementXYZ.x, movementXYZ.z),
            0
        ));

        let totalMoved = 0;

        for (const axis of [
            [1, 0, 0],
            [0, 0, 1],
        ]) {
            let movementOnAxis = new Vector3(...axis).multiply(movementXYZ);
            this._raycaster.set(
                new Vector3(0, COLLISION_DISK_Y, 0).add(this._entityNode.object3D.position),
                movementOnAxis
            );
            const hits = this._useCollisions ? this._raycaster.getIntersections(scene) : [];
            const movementIsBlocked = (
                hits.length &&
                hits[0].distance <= COLLISION_DISK_RADIUS
            );

            if (!movementIsBlocked) {
                this._entityNode.object3D.position.add(movementOnAxis);
                totalMoved += movementOnAxis.manhattanLength();
            }
        }

        return totalMoved;
    }

}



function getArmatureMeshNames(armature:any,lod:string){
    const meshNames:string[] = armature.children.map((a:any)=>a.name);
    return meshNames.filter(name=>{
        const [modelName,partName,lodLayer] = name.split("--");
        return lodLayer===lod;
    });
}


function MakeRandomCostume(){
    let modelName = "businessMan4";
    let visibleMeshes = [];

    if(modelName==="businessMan4"){
        visibleMeshes.push(
            // "belt",
            "pants",
            // "pantsLoops",//belt loops
            "eyes",
            "hand",
            "shoes",
            // "butterflyTie",// a bowtie
            // "closedTie",//a striped tie, cut off at the bottom to save polygons
            // "openTieW",//appears to be no different from "closedTie", at least at lod0 -- I think this is supposed to be tie with open jacket and closed waistcoat
            // "tie",// a complete striped tie
            // "closedHandkerchief",// a small pocket square that peeks out of the jacket pocket
            // "openHandkerchief",//appears to be no different from "closedHandkerchief", at least at lod0
            // "closedJacket",//a jacket that is buttoned closed
            // "openJacket",// a jacket that is open
            // "closedShirt",// a partial shirt to be used with closedJacket
            // "openShirt",// a partial shirt to be used with openJacket
            // "shirt",// a shirt (to be used without a jacket/waistcoat)
            // "shirtW",// a partial shirt to be used with "waistcoat"
            // "closedWaistcoat",// a partial waistcoat, to be used with "closedJacket"
            // "openWaistcoat",// a partial waistcoat, to be used with "closedJacket"
            // "waistcoat",// a waistcoat (to be used without a jacket)
        );

        visibleMeshes.push(
            "face"+GetRandomArrayElement("ABCDEF".split(""))
        );

        visibleMeshes.push(
            "hair"+GetRandomArrayElement("ABCDEFGHI".split(""))
        );

        const useGlasses = Math.random()<.2;
        if(useGlasses){
            visibleMeshes.push(
                "glasses"+GetRandomArrayElement("ABCDEF".split(""))
            );
        }

        let useJacket = Math.random() < .5;
        let jacketIsClosed = Math.random() < .9;
        let useWaistcoat = Math.random() < .1;
        let useTie = Math.random() < .8;

        if(useJacket){
            visibleMeshes.push(
                jacketIsClosed ? "closedJacket" : "openJacket"
            );
        }

        if(useWaistcoat){
            if(!useJacket){
                visibleMeshes.push("waistcoat");
            }else{
                visibleMeshes.push(
                    jacketIsClosed ? "closedWaistcoat" : "openWaistcoat"
                );
            }
        }

        if(useTie){
            if(!useJacket){
                visibleMeshes.push("tie");
            }else{
                visibleMeshes.push(
                    jacketIsClosed ? "closedTie" : "tie"
                );
            }
        }

        // shirt
        if(!useJacket && !useWaistcoat){
            visibleMeshes.push("shirt");
        }
        if(useJacket && !useWaistcoat){
            visibleMeshes.push(
                jacketIsClosed ? "closedShirt" : "openShirt"
            );
        }
        if(useWaistcoat){
            visibleMeshes.push(
                "shirtW"
            );
        }

        const useBowtie = !useTie && Math.random() < .5;
        if(useBowtie ){
            visibleMeshes.push(
                "butterflyTie"
            );
        }
    }

    return {modelName,visibleMeshes};

}