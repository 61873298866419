
/**
 * TODO: MOVE THESE FUNCTIONS INTO L2-COMMON    
 * TODO: create a generic class "ValueWithTranslations" and have these 2 inherit from it
 */

import { StringArrayWithTranslations } from "../../definitions/general/StringArrayWithTranslations";
import { StringWithTranslations } from "../../definitions/general/StringWithTranslations";

/**
 * Returns undefined if the translations were empty.
 */
export function MaybeResolveStringWithTranslations(stringWithTranslations:StringWithTranslations, preferredLanguageIds:string[]){
    for(const languageId of [...preferredLanguageIds,"en"]){
        const maybePreferredEntry = stringWithTranslations[languageId];
        if(maybePreferredEntry!==undefined){
            return maybePreferredEntry;
        }
    }
    return Object.values(stringWithTranslations)[0];
}
/**
 * Returns undefined if the translations were empty.
 */
export function MaybeResolveStringArrayWithTranslations(stringArrayWithTranslations:StringArrayWithTranslations, preferredLanguageIds:string[]){
    for(const languageId of [...preferredLanguageIds,"en"]){
        const maybePreferredEntry = stringArrayWithTranslations[languageId];
        if(maybePreferredEntry!==undefined){
            return maybePreferredEntry;
        }
    }
    return Object.values(stringArrayWithTranslations)[0];
}