import { useState } from "react";
import {  DoesNotExistResult, NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import LibertyClient from "../../libraries/l2-common/l2-client-library-ts/source/LibertyClient";
import TokenDaoInterface from "../../libraries/l2-common/l2-client-library-ts/source/TokenDaoInterface";
import ApiError from "../../libraries/l2-common/l2-common-ts/common/errors/ApiError";
import ApiErrors from "../../libraries/l2-common/l2-common-ts/common/errors/ApiErrors";
import { ExpoAccountAnnexRecordMergePatchDocument, ExpoAccountAnnexRecordPartForPut } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoAccountAnnexRecord";
import { SystemAccountRecordPartForOwner } from "../../libraries/l2-common/l2-common-ts/definitions/system/SystemAccountRecord";
import { useAsyncEffect } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import SystemContext from "../SystemContext";
import { useSystemContext } from "../SystemContextComponents";

/**
 * TODO: Rename this to something better
 * Provides logic to show whether the user is logged in or not
 * Has utility functions for logging the user in
 * Can tell the user info about their session
 */
export default class SessionState {
    private readonly _client;
    private readonly _tokenDao;
    private readonly _systemContext;
    private _accountIdCache = { token: "", id: "" };
    public onLoggedInListeners:(()=>void|Promise<void>)[] = [];

    constructor(client: LibertyClient, tokenDao: TokenDaoInterface, systemContext:SystemContext) {
        this._client = client;
        this._tokenDao = tokenDao;
        this._systemContext = systemContext;
    }

    get isLoggedIn() {
        return this._tokenDao.hasToken();
    }

    async sendMagicLinkAsync(emailAddress: string, verification: string) {
        await this._client.api.system.sessions.postAsync({
            emailAddress
        }, verification);
    }

    async loginWithPasswordAsync({
        emailAddress,
        password,
        recaptcha
    }: {
        emailAddress: string,
        password: string,
        recaptcha:{
            token:string,
            version:"v3"|"v2"
        },
    }) {
        const endpoint = this._client.api.system.sessions;
        const loginResult = await endpoint.postAsync({
            emailAddress,
            basicAuthentication: {
                email: emailAddress,
                password
            }
        }, recaptcha.version+":"+recaptcha.token );
        if ("token" in loginResult && loginResult.token) {
            await this._loginWithTokenAsync(loginResult.token);
        }
    }

    loginWithMagicLinkContents({
        emailAddress,
        tokenFromMagicLink,
    }: {
        emailAddress: string,
        tokenFromMagicLink: string
    }) {
        this._loginWithTokenAsync(tokenFromMagicLink);
    }

    private async _loginWithTokenAsync(token:string){
        this._tokenDao.setToken(token);

        for(const listener of this.onLoggedInListeners){
            await listener();
        }
        
        this._systemContext.forceReactUpdate();
    }

    async getMyAccountIdAsync() {
        if (!this.isLoggedIn) {
            return undefined;
        }
        const isCacheHit = (
            this._accountIdCache.id &&
            this._accountIdCache.token === this._tokenDao.getToken()
        );
        if (!isCacheHit) {
            const accountRecord = await this.getMyAccountRecordAsync_();
            if(!accountRecord){
                return undefined;
            }
            this._accountIdCache.id = accountRecord.id;
            this._accountIdCache.token = this._tokenDao.getToken();
        }
        return this._accountIdCache.id;
    }

    async getMyAccountRecordAsync_() {
        if (!this.isLoggedIn) {
            return undefined;
        }
        const token = this._tokenDao.getToken();
        try{
            const accountRecord = await this._client.api.system.accounts.bySystemSessionsToken(
                token
            ).get().partForOwnerAsync();
            if( accountRecord instanceof NotFoundResult ){
                this.logOut();
                return undefined;
            }
            return accountRecord;
        }catch(e){
            if(isErrorWhereSessionShouldLogOut(e)){
                this.logOut();
                return undefined;
            }
            throw e;
        }
    }

    async getMyAccountAnnexRecordAsync_() {
        if (!this.isLoggedIn) {
            return undefined;
        }
        const myId = await this.getMyAccountIdAsync();
        if(!myId){
            throw new Error("Not logged in.");
        }
        const api = this._systemContext.client.api;
        const result = await api.expo.accountAnnexes.bySystemAccountsId(
            myId
        ).getAsync();
        if(result instanceof NotFoundResult){
            return undefined;
        }
        return result;
    }
    
    async patchMyAccountAnnexRecordAsync(patch:ExpoAccountAnnexRecordMergePatchDocument){
        const myId = await this.getMyAccountIdAsync();
        if(!myId){
            throw new Error("Not logged in.");
        }
        const api = this._systemContext.client.api;
        try{
            await api.expo.accountAnnexes.bySystemAccountsId(
                myId
            ).patchAsync(patch);
        }catch(e){
            const isDoesNotExistError = e instanceof ApiError && e.httpStatus===404;
            if(!isDoesNotExistError){
                throw e;
            }
            const newAnnex:ExpoAccountAnnexRecordPartForPut = {
                accountId: myId,
                settings: {},
                ...patch
            };
            await api.expo.accountAnnexes.bySystemAccountsId(myId).putAsync(newAnnex);
        }
    }

    logOut() {
        this._tokenDao.clearToken();
        this._systemContext.forceReactUpdate();
    }
}


function isErrorWhereSessionShouldLogOut(e:any){
    return (e instanceof ApiError) && [
        ApiErrors.SessionRefreshExpired,
    ].map(
        a=>a.typeId
    ).includes(e.typeId);
}

export function useMySystemAccount(){
    const {sessionState} = useSystemContext();
    const [record, setRecord] = useState<SystemAccountRecordPartForOwner>();
    
    useAsyncEffect( async ()=>{
        if( sessionState.isLoggedIn && !record ){
            const record = await sessionState.getMyAccountRecordAsync_();
            setRecord(record);
        }
    } );

    return record;
}


export function useIsLoggedIn(){
    const {sessionState} = useSystemContext();
    return sessionState.isLoggedIn;
}
