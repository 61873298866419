import { StringWithTranslations } from "../../libraries/l2-common/l2-common-ts/definitions/general/StringWithTranslations";

import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useSystemContext } from "../../logic/SystemContextComponents";
import { TextLineInput } from "./TextLineInput";
import DropdownInput from "./DropdownInput";
import Button from "./Button";
import { MakeRenamedObjectkey } from "../../libraries/l2-common/l2-common-ts/common/utility/Objects";

// TODO: move elsewhere
const ALL_LANGUAGE_IDS = [
    "en",
    "ja",
    "kana",
    "ko",
    "zh",
];

export function StringWithTranslationsEditBox({
    value:externalValue,
    setValue,
    layout
}:{
    value?:StringWithTranslations,
    setValue:(value:StringWithTranslations|undefined)=>void,
    layout?:CSSProp
}){
    const {translate} = useSystemContext();

    const value = externalValue || {};


    const usedLanguageIds = Object.keys(value||{});
    const nextUnusedLanguageId = ALL_LANGUAGE_IDS.find(a=>!usedLanguageIds.includes(a) );

    const AddButton = ()=> nextUnusedLanguageId ? <Button
            layout={Layout.AddButton}
            onClick={()=>{
                setValue({
                    ...value,
                    [nextUnusedLanguageId]: "",
                })
            }}
        >
        {translate("t$addTranslationButton")}
    </Button> : <></>;

    if(!usedLanguageIds.length && nextUnusedLanguageId){
        value[nextUnusedLanguageId] = "";
    }

    const css = Layout.Root+''+layout;
    return <div css={css}>
        {usedLanguageIds.map((languageId,index)=>
            <SingleLanguageKeyEditor
                    key={index+languageId}
                    value={value[languageId]}
                    setValue={(subValue)=>{
                        setValue({
                            ...value,
                            [languageId]: subValue
                        })
                    }}
                    languageId={languageId}
                    setLanguageId={(newLanguageId)=>{
                        const newValue = MakeRenamedObjectkey(value, languageId, newLanguageId);
                        setValue({...newValue});
                    }}
                    languageIdBlacklist={usedLanguageIds}
                    remove={()=>{
                        delete value[languageId];
                        setValue({...value});
                    }}
            />
        )}
        <AddButton/>
    </div>
}

const Layout={
    Root: css`
        display: flex;
        flex-direction: column;
    `,
    AddButton: css`
        height: 1.5em;
        width: 10ch;
        margin: 1em 0;
        margin-left: auto;
        margin-right: auto;
    `,
}

function SingleLanguageKeyEditor({
    languageId,
    value,
    setLanguageId,
    setValue,
    languageIdBlacklist,
    remove,
}:{
    languageId:string,
    value:string,
    setLanguageId:(languageId:string)=>void,
    setValue:(value:string)=>void,
    languageIdBlacklist?:string[],
    remove:()=>void,
}){
    const Layout = SingleLanguageKeyEditorLayout;
    const {translate} = useSystemContext();

    return <div css={Layout.Root}>
        <TextLineInput
                type="text"
                value={value}
                setValue={value=>setValue(value)}
                layout={Layout.TextInput}
        />
        <div css={Layout.Part2}>
            <DropdownInput
                    valueToText={languageId=>translate(`t$translationName[${languageId}]`)||languageId}
                    allValues={ALL_LANGUAGE_IDS}
                    value={languageId}
                    setValue={setLanguageId}
                    valuesBlacklist={languageIdBlacklist?.filter(a=>a!==languageId)}
                    layout={Layout.LanguageDropdown}
            />
            <Button
                    onClick={remove}
                    layout={Layout.DeleteButton}
            >
                X
            </Button>
        </div>
    </div>;
}

const SingleLanguageKeyEditorLayout = {
    Root: css`
        /* display:grid; */
        /* grid-template-columns: 1fr 6ch 2em; */
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        /* justify-content:flex-end; */
        
    `,
    TextInput: css`
        min-width: min(14ch, 100%);
        width:14ch;
        flex-grow: 999;
    `,
    Part2: css`
        min-width: calc( 5ch + 3em );
        flex-grow: 1;
        display:flex;
        flex-direction:row;
    `,
    LanguageDropdown: css`
        margin-left: .5em;
        width: 5ch;
        flex-grow: 1;
    `,
    DeleteButton: css`
        margin-left: .5em;
        width: 2em;
        height: 2em;
        padding: 0;
    `,
};