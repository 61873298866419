

/** @returns a copy of the object, with the key renamed */
export function MakeRenamedObjectkey(object:any, keyToRename:string, newKeyName:string){
    const result:any = {};
    for(let key of Object.keys(object) ){
        if( key===keyToRename ){
            key = newKeyName;
        }
        result[key] = object[key];
    }
    return result;
}

/** Makes a deep copy of a record (aka a "plain old javascript object") */
export function MakeCopyOfRecordObject(record:any){
    return JSON.parse(JSON.stringify(record));
}

/** Checks if the records (aka "pojos") are EXACTLY the same, including key order. */
export function AreRecordsEqual(a:any, b:any){
    return JSON.stringify(a)===JSON.stringify(b);
}