import * as Three from "three";
import { Camera, Euler, Object3D, Quaternion, Vector2, Vector3 } from "three";

import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import InputMap from "../input/InputMap";
import { MOUSE_BUTTON_RIGHT } from "../input/InputSystem";

const ROTATE_CAMERA_MOUSE_BUTTON = MOUSE_BUTTON_RIGHT;
const MOUSE_SENSITIVITY = new Vector2(5, 5);
/**
 * 
 * TODO: Rewrite this to use the code from FirstPersonCameraBehaviorNode
 * 
 * 
 */

/** Causes the threejs camera to follow whatever the parent of this node is. */
export default class ThirdPersonFollowCameraBehaviorNode extends Object3DNode{

    _rotationOffsetEuler:Euler;

    _desiredCameraPosition = new Vector3();

    _cameraZoom = 3.0;


    /** The rotation of the scene camera, y-axis only. Useful for figuring out which direction the player should move in.  */
    get cameraRotationYAxis(){ return Math.PI+this._rotationOffsetEuler.y }
    set cameraRotationYAxis(value:number){
        this._rotationOffsetEuler.y = value-Math.PI;
    }

    constructor( parent:Object3DNode, systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );

        const parentWorldRotationQuaternion = new Quaternion();
        parent.object3D.getWorldQuaternion( parentWorldRotationQuaternion );
        this._rotationOffsetEuler = new Euler().setFromQuaternion( parentWorldRotationQuaternion );
    }

    updateHook( deltaTime:number ){
        if( !this._parent || !(this._parent instanceof Object3DNode) ){
            return;
        }

        this._updateInput( this._parent );

        this._updateCameraTransform( this._parent );
        
    }

    // TODO: change mouse coordinates to be 0 to 1 instead, and change coefficient thusly
    _updateInput( target:Object3DNode ){
        const mouseDragDelta = this.systemContext.inputSystem.maybeGetMouseDragDelta( ROTATE_CAMERA_MOUSE_BUTTON );
        if( ! mouseDragDelta ){
            return;
        }

        this.systemContext.inputSystem.clearMouseDragDelta( ROTATE_CAMERA_MOUSE_BUTTON );

        this._rotationOffsetEuler.y -= mouseDragDelta.x*MOUSE_SENSITIVITY.x;
        this._rotationOffsetEuler.z -= mouseDragDelta.y*MOUSE_SENSITIVITY.y;
    }

    _updateCameraTransform( target:Object3DNode ){
        const camera:Camera = this.systemContext.threeSystem.camera;

        const targetWorldPosition:Vector3 = new Vector3();
        target.object3D.getWorldPosition( targetWorldPosition );

        const targetWorldQuaternion:Quaternion = new Quaternion();
        target.object3D.getWorldQuaternion( targetWorldQuaternion );

        const desiredCameraRotationQuaternion = new Quaternion().setFromEuler(
            this._rotationOffsetEuler
        );
        

        const cameraOffset = new Vector3(0,1,-2).multiplyScalar(
            this._cameraZoom
        );
        cameraOffset.applyQuaternion( desiredCameraRotationQuaternion );
        cameraOffset.add( targetWorldPosition );

        this._desiredCameraPosition.copy( cameraOffset );     

        camera.position.copy( this._desiredCameraPosition );
        
        if( camera.position.y < 1 ){
            camera.position.y = 1;
            // This is the value of _rotationOffsetEuler.z that will make camera.position.y go below 1.
            this._rotationOffsetEuler.z = 1.232;
        }


        camera.lookAt( targetWorldPosition );
    }

}