import "styled-components/macro";

import React from "react";
import { CSSProp } from "styled-components/macro";
import { useComponentDidMount, useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";

export default function DivThatAutoScrollsToBottom( props:{
    children?:any,
    layout?:CSSProp,
} ){

    const [emptyElementAtBottom,setEmptyElementAtBottom] = React.useState<HTMLDivElement|null>();

    const maybeScrollToBottom = ()=>{

        const doScroll = ()=>{
            emptyElementAtBottom?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        };

        doScroll();

        // sometimes the div height changes slightly during population,
        // so we try to scroll a couple more times to make sure we get all the way to the end
        setTimeout( doScroll, 100 );
        setTimeout( doScroll, 500 );

    }
    
    useComponentDidMount( ()=>{
        maybeScrollToBottom();
    } );

    useComponentDidUpdate( ()=>{
        maybeScrollToBottom();
    } );

    return <div css={props.layout}>
        {props.children}
        <div ref={element=>setEmptyElementAtBottom(element)}/>
    </div>
}