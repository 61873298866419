import { useState } from "react";
import { DoesNotExistResult, NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { ExpoAdRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoAdRecord";
import { FileMetadataRecord } from "../../libraries/l2-common/l2-common-ts/definitions/storage/FileMetadataRecord";
import { useAsyncEffect } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import SystemContext from "../SystemContext";
import { useSystemContext } from "../SystemContextComponents";

export class FileRepository{
    private _api;

    private _metadataByIdCache = new Map<string, FileMetadataRecord>();
    
    constructor( systemContext:SystemContext ){
        this._api = systemContext.api;
    }

    async getMetadataByIdAsync( id:string ){
        if( this._metadataByIdCache.has(id) ){
            const record = this._metadataByIdCache.get(id);
            return record;
        }
        const record = await this._api.storage.files.byId(id).metadata.getAsync();
        if( record instanceof DoesNotExistResult ){
            return undefined;
        }
        return record;
    }

    async cacheManyByIdsAsync( ids:string[] ){
        await this.maybeGetManyMetadatasByIdsAsync(ids);
    }

    /**
     * 
     * @param ids 
     * @returns A Map of File, mapping each id to each boothEntity. 
     */
    async maybeGetManyMetadatasByIdsAsync( ids:string[] ){
        const result = new Map<string, FileMetadataRecord>();
        for(const id of ids ){
            const record = await this._api.storage.files.byId(id).metadata.getAsync();
            if(record instanceof NotFoundResult){
                continue;
            }
            this._metadataByIdCache.set( id, record );
            result.set( id, record );
        }
        return result;
    }

}