import Time from "../../libraries/l2-common/l2-common-ts/common/utility/Time";
import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { ExpoBoothRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecord";
import { ExpoBoothRecordObjectRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import { ExpoBoothType } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothTypes";
import { PREFERRED_LANGUAGE_IDS_TODO } from "../messages/ChatRoomEntity";
import {BoothSubObjectEntity} from "./BoothSubObjectEntity";

export class BoothEntity{
    readonly record;

    private _subObjects?:BoothSubObjectEntity[];

    constructor(record:ExpoBoothRecord){
        this.record = record;
    }

    static MakePlaceholder( typeId:ExpoBoothType ){
        return new BoothEntity({
            typeId,
            creationTimeSeconds: Time.CurrentUnixSeconds(),
            id: "",
            ownerAccountId: "",
            slug:"",
        });
    }

    get subObjects() {
        if(!this._subObjects){
            this._subObjects = (
                this.record.objects||[]
            ).map(
                a=>new BoothSubObjectEntity(a)
            );
        }
        return this._subObjects; 
    }
    /**
     * 
     * @param record 
     * @returns The entity of the newly created record.
     */
    addSubObjectByRecord(record:ExpoBoothRecordObjectRecord){
        const entity = new BoothSubObjectEntity(record);
        this._subObjects?.push(entity);
        if( this.record.objects ){
            this.record.objects.push(record);
        }else{
            this.record.objects = this.record.objects || [record];
        }
        return entity;
    }

    get resolvedDescription(){
        if(this.record.description===undefined){
            return undefined;
        }
        return MaybeResolveStringWithTranslations(
            this.record.description,
            PREFERRED_LANGUAGE_IDS_TODO
        );
    }

    get resolvedTitle(){
        if(!this.record.title){
            return undefined;
        }
        return MaybeResolveStringWithTranslations(
            this.record.title,
            PREFERRED_LANGUAGE_IDS_TODO
        );
    }

}