import { BoxGeometry, Color, DoubleSide, Mesh, MeshPhongMaterial, MeshStandardMaterial, NormalAnimationBlendMode, Object3D, PlaneGeometry, Texture } from "three";
import Object3DNode from "../logic/nodes/Object3DNode";
import Resources from "../logic/Resources";
import SystemContext from "../logic/SystemContext";
import textureUrlNormal from "../images/exterior/circle--liberty-business-exhibition--5555ff--1080.png";
import textureUrlThinner from "../images/exterior/circle--liberty-business-exhibition--thinner--5555ff--1080.png";
import textureUrlNormalLines from "../images/exterior/circle--lines--5555ff--1080.png";
import textureUrlThinnerLines from "../images/exterior/circle--lines--thinner--5555ff--1080.png";
import RotateParentBehaviorNode from "../logic/effects/RotateParentBehaviorNode";

type Thickness = "normal" | "thinner";

type TextType = "Liberty Business Exhibition" | "|||";

interface Options{
    thickness:Thickness
    rotateRadiansPerSecond:number,
    text:TextType
}

function MakeTextureUrl(thickness:Thickness,text:TextType){
    return {
        "Liberty Business Exhibition":{
            normal:textureUrlNormal,
            thinner:textureUrlThinner
        },
        "|||":{
            normal:textureUrlNormalLines,
            thinner:textureUrlThinnerLines
        }
    }[text][thickness];
}


/** A circle that spins and glows, placed around the building exterior so that it looks "high tech" */
export class IntroTechCircleNode extends Object3DNode{
    private readonly options;
    private _displayMesh;
    private _displayNode;
    private _loadedTexture?:Texture;

    constructor( systemContext:SystemContext, options?:Options ){
        super(
            new Object3D(),
            systemContext
        );
        this.options=options||{
            rotateRadiansPerSecond:Math.PI/8,
            thickness:"normal",
            text:"|||"
        };

        this._displayMesh = new Mesh(
            new PlaneGeometry( 1,1 ),
            new MeshStandardMaterial({
                transparent:true,
            })
            // new MeshPhongMaterial({
            //     // emissive: new Color("#5555ff"),
            //     // emissiveIntensity: 10,
            //     transparent:true,
            // })
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
        );
        this._displayNode.object3D.rotateX(-Math.PI/2);

        this.addChild(
            new RotateParentBehaviorNode(
                this.options.rotateRadiansPerSecond,
                this.systemContext,
                this
            ),
        );

        this._loadAsync();
    }

    async _loadAsync(){
        const url = MakeTextureUrl(this.options.thickness,this.options.text);
        const texture = await Resources.LoadTextureAsync( url );
        this._displayMesh.material.map = texture;
        this._displayMesh.material.needsUpdate = true;
        this.addChild( this._displayNode );
    }

    disposeHook(){
        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }
    }
}