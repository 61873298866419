import "styled-components/macro";

import { css, CSSProp } from "styled-components/macro";
import UserAvatarComponent from "../account/UserAvatarComponent";

/**
 * Shows a chat message
 * for ease of composition,
 * the component boundary is unstyled
 */
export function ChatMessageDisplayComponent({
    avatarFileId,
    ownerName,
    messageBody,
    layout,
}:{
    avatarFileId?:string,
    ownerName?:string,
    messageBody?:string,
    layout?:CSSProp,
}){
    return <div css={Layout.Root+''+layout}>
        <div css={Layout.LeftColumn}>
        <UserAvatarComponent avatarFileId={avatarFileId}/>
        </div>
        <div css={Layout.RightColumn}>
            <b>{ownerName}: </b><br/>
            <div css={Layout.BodyText}>
                {messageBody}
            </div>
        </div>
    </div>
}



const Layout = {
    Root: css`
        display: flex;
        flex-direction: row;
    `,
    LeftColumn: css`
        width: max( 1em, 12% );
        display:flex;
        align-items:flex-start;
        justify-content:center;
        & * {
            margin: .1em;
            margin-top: .2em;
            width: 100%;
            border-radius: 100vmin;
        }
    `,
    RightColumn: css`
        width: calc(100% - max( 1em, 12% ) );
        padding-top: .5vmin;
        padding-bottom: .5vmin;
        padding-left: 1vmin;
        padding-right: 1vmin;
    `,
    BodyText: css`
        max-width: 45ch;
        overflow-wrap: break-word;
    `,
}