import "styled-components/macro";

import UserAvatarComponent from "../../account/UserAvatarComponent";
import { css } from "styled-components/macro";
import ChatMessageEntity from "../ChatMessageEntity";
import { useResultOnMount } from "../../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useAccountPublic } from "../../../logic/accounts/AccountRepositoryUtilities";
import { NotFoundResult } from "../../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { ChatMessageDisplayComponent } from "../ChatMessageDisplayComponent";

/**
 * A single message shown in a conversation
*/
export function ChatRoomMessageItemComponent( {
    message
}: {
    message:ChatMessageEntity
} ){
    
    let ownerRecord = useAccountPublic(message.record.ownerAccountId);
    if( ownerRecord instanceof NotFoundResult ){
        ownerRecord = undefined;
    }
    const resolvedOwnerName = useResultOnMount(()=>message.makeResolvedOwnerNameAsync());

    return <div css={Layout.Root}>
        <ChatMessageDisplayComponent
            avatarFileId={ownerRecord?.avatarFileId}
            messageBody={message.resolvedBody}
            ownerName={resolvedOwnerName}
        />
    </div>;
}


const Layout = {
    Root: css`
        padding: .5vmin;
    `,
}