import * as Three from "three";
import { Euler, Object3D, Vector3 } from "three";
import { degToRad } from "three/src/math/MathUtils";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";
import SystemContext from "../SystemContext";

import Node from "./Node";

/* If this key is defined on a Threejs Object3D, it will be an instance of Object3DNode */
export const OBJECT3DNODE_OF_OBJECT3D_KEY = "__OBJECT_3D_NODE__";

/**
 * A wrapper to fit three.js's Object3D system into our Node system.
* */
export default class Object3DNode extends Node{
    readonly object3D:Three.Object3D

    collisionInfo?:{
        ignoreCollisions:boolean
    }

    /*
        Used for determining where to send a message.
        (if undefined, the message will be sent to this node)
        For example, if this node is for something like "PlayerShoelace", you might want a message to be router to "PlayerNode" or "PlayerLeg", depending on your use case.
    */
    readonly messageTargetNode;

    constructor( object3D:Three.Object3D, systemContext:SystemContext, messageTargetNode?:Node ){
        super( systemContext );
        this.object3D = object3D;
        this.messageTargetNode = messageTargetNode;

        (object3D as any)[OBJECT3DNODE_OF_OBJECT3D_KEY]=this;
    }

    addedChildHook( child:Node ){
        if( child instanceof Object3DNode ){
            this.object3D.add( child.object3D );
        }
    }
    removedChildHook( child:Node ){
        if( child instanceof Object3DNode ){
            this.object3D.remove( child.object3D );
        }
    }

    maybeSetTransform(transform?:RightHanded3DTransform){
        this.maybeSetPosition(transform);
        this.maybeSetRotation(transform);
        this.maybeSetScale(transform);
    }

    maybeSetPosition(transform?:RightHanded3DTransform){
        if( !transform ){
            return;
        }
        if(!transform.position){
            return;
        }
        const vector3 = new Vector3(...transform.position);
        this.object3D.position.copy( vector3 );
    }

    maybeSetRotation(transform?:RightHanded3DTransform){
        if( !transform ){
            return;
        }
        if(!transform.rotation){
            return;
        }
        const rotationRadians = (
            transform.isRotationRadians ? transform.rotation :
            transform.rotation.map( a=>degToRad(a) )
        );
        const euler = new Euler(...rotationRadians);
        this.object3D.rotation.copy( euler );
    }

    maybeSetScale(transform?:RightHanded3DTransform){
        if( !transform ){
            return;
        }
        if(!transform.scale){
            return;
        }
        const vector3 = new Vector3(...transform.scale);
        this.object3D.scale.copy( vector3 );
    }

    static MakeEmptyNode( systemContext:SystemContext ){
        const Node = new Object3DNode(
            new Three.Object3D(),
            systemContext
        );
        return Node;
    }

    /** A shiny rainbow cube that spins around */
    static MakeTestMeshNode( systemContext:SystemContext ){
        const geometry = new Three.BoxGeometry( 1,1,1 );
        const material = new Three.MeshNormalMaterial();
        const Node = new Object3DNode(
            new Three.Mesh( geometry, material ),
            systemContext
        );
        Node.updateHook = (deltaTime:number)=>{
            Node.object3D.rotation.x += deltaTime / 2;
            Node.object3D.rotation.y += deltaTime;
        }
        return Node;
    }

    /** An empty node that spins around, you can add a child to it to make it spin. */
    static MakeTestEmptySpinnerNode( systemContext:SystemContext ){
        const geometry = new Three.BoxGeometry( 0,0,0 );
        const material = new Three.MeshNormalMaterial();
        const Node = new Object3DNode(
            new Three.Mesh( geometry, material ),
            systemContext
        );
        Node.updateHook = (deltaTime:number)=>{
            Node.object3D.rotation.x += deltaTime / 2;
            Node.object3D.rotation.y += deltaTime;
        }
        return Node;
    }

    /** A shiny rainbow cube. */
    static MakePlaceholderNode( systemContext:SystemContext, associatedNodeForMesh?:Node ){
        const geometry = new Three.BoxGeometry( 1,1,1 );
        const material = new Three.MeshNormalMaterial();
        const mesh = new Three.Mesh( geometry, material );
        return new Object3DNode(
            mesh,
            systemContext,
            associatedNodeForMesh
        );
    }

}