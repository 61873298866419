import assert from "assert";
import { NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { SystemAccountRecordPartForOwner, SystemAccountRecordPartForPublic } from "../../libraries/l2-common/l2-common-ts/definitions/system/SystemAccountRecord";

import SystemContext from "../SystemContext";
import UserData from "./UserData";


export default class AccountRepository{
    _publicRecordsCache:any = {};
    _myRecordCache?:SystemAccountRecordPartForOwner;
    _systemContext:SystemContext;
    
    constructor( systemContext:SystemContext ){
        this._systemContext = systemContext;
    }

    /**
     * Throws an error if you aren't logged in
     */
    async getSelfAsync() {
        if( this._myRecordCache ){
            return this._myRecordCache;
        }

        const account = await this._systemContext.sessionState.getMyAccountRecordAsync_();

        if(account instanceof NotFoundResult){
            return account;
        }

        this._myRecordCache = account;

        return account;
    }

    async getPublicRecordsByIdsAsync(ids:string[]){
        // TODO: optimize
        const result = [];
        for(const id of ids){
            const info = await this.getPublicRecordByIdAsync(id);
            result.push(info);
        }
        return result;
    }

    async getPublicRecordByIdAsync( id:string ): Promise<SystemAccountRecordPartForPublic|NotFoundResult> {
        if( this._publicRecordsCache[id] ){
            return this._publicRecordsCache[id];
        }

        const account = await this._systemContext.api.system.accounts.byId(id).get({}).partForPublicAsync();

        if(account instanceof NotFoundResult){
            return account;
        }

        this._publicRecordsCache[id] = account;

        return account;
    }

}