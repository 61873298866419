
import en from "./translations/en.json";
import ja from "./translations/ja.json";
import zh from "./translations/zh.json";
import ko from "./translations/ko.json";

interface Language{
    id: string,
    text: string,
    translations: Map<string,string>,
}

function makeTranslationsMapFromJson( json:any ){
  const map = new Map<string,string>();
  for(const key of Object.keys(json) ){
    map.set( key, json[key] );
  }
  return map;
}

export const LANGUAGES:Language[] = [
// The first langauge in this array is the default language.
  { id: "en", translations: makeTranslationsMapFromJson(en), text: "English" },
  { id: "ja", translations: makeTranslationsMapFromJson(ja), text: "日本語" },
  { id: "zh", translations: makeTranslationsMapFromJson(zh), text: "中文" },
  { id: "ko", translations: makeTranslationsMapFromJson(ko), text: "한국어" },
];

const DEFAULT_LANGUAGE = LANGUAGES[0];

export default class Translations{

  private _language = DEFAULT_LANGUAGE;
  get languageId(){ return this._language.id }
  set languageId(languageId){
    const language = LANGUAGES.find( language=>language.id===languageId );
    if( !language ){
      throw new Error(`Language with id ${languageId} does not exist.`);
    }
    this._language = language;
  }

  constructor(){

  }

  get( translationKey:string ){
    if( ! this._language.translations.has(translationKey) ){
      return `{{${translationKey}}}`;
    }
    return this._language.translations.get(translationKey);
  }
}