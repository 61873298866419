import "styled-components/macro";

import {useSystemContext} from "../../logic/SystemContextComponents";
import MapSelectPanelItemComponent from "./MapSelectPanelItemComponent";
import LoadingWidgetPanelContents from "../common/LoadingWidgetPanelContents";
import {MenuHeader} from "../common/MenuHeader";
import { css } from "styled-components/macro";
import { useLiveMaps } from "../../logic/maps/MapRepositoryUtilities";

export function MapSelectPanelContentsComponent(){
    const {translate} = useSystemContext();

    const liveMapEntities = useLiveMaps();

    if( !liveMapEntities ){
        return <LoadingWidgetPanelContents/>
    }

    return <div css={Layout}>
        <MenuHeader>{translate("t$exhibitions")}</MenuHeader>
        {
            liveMapEntities.map(
                a=><MapSelectPanelItemComponent key={a.record.id} mapEntity={a} />
            )
        }
    </div>
}

const Layout = css`
    height:100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
    & > * {
        margin-bottom: 1em;
    }
`;