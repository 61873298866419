import "styled-components/macro";

import React from "react";
import { Link } from "react-router-dom";

import {GET_BY_BOOTHTYPEID_AND_VIEW, VIEW_FRONT} from "../../images/booth-backgrounds";

import {useSystemContext, useTheme} from "../SystemContextComponents";

import { css } from "styled-components";
import { BoothEntity } from "../booths/BoothEntity";
import { LinkAnchor } from "../../components/common/LinkAnchor";

/** Displays one booth in a list of booths for an exhibition's map. */
export function BoothEditorBoothSelectPanelItemComponent( {
    boothEntity: booth
}:{
    boothEntity:BoothEntity
} ){
    const theme = useTheme();
    const systemContext = useSystemContext();
    const {client} = useSystemContext();

    const [boothSignImageFailedToLoad, setBoothSignImageFailedToLoad] = React.useState(false);

    /**
     * We'll either show
     *  - booth's sign image
     *  - booth's name on top of a generic booth background image
     */

    const {signageFileId} = booth.record;

    const showBoothSign = ( signageFileId && !boothSignImageFailedToLoad );

    const boothSignageImageUrl = client.urls.storage.files.byId(signageFileId||"").get();

    const backgroundImageCss = css`

        background-position: center;
        background-size: cover;

        ${ `; background-image: url("${(
            showBoothSign ? 
            boothSignageImageUrl :
            GET_BY_BOOTHTYPEID_AND_VIEW(booth.record.typeId, VIEW_FRONT)
        )}");` } ;

    `;

    const themeCss = theme.Card.Image;

    return <div css={Layout.Root+''+themeCss+backgroundImageCss}>
        {
            // If we're loading a signage file, we use this img to detect a 404
            signageFileId &&
            <img
                style={{display:"none"}}
                src={boothSignageImageUrl}
                onError={e=>setBoothSignImageFailedToLoad(true)}
                alt="signage"
            />
        }
        <LinkAnchor
            to={"/editor/booths/"+booth.record.id}
            navigateScene
            css={Layout.Link}
        >
            {booth.resolvedTitle}
        </LinkAnchor>
    </div>
}

const Layout = {
    Root: css`
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        
        min-height: 5vmax;
        width: 90%;

        margin-top: 1vmin;
        margin-bottom: 1vmin;


        & *{
        }

        & a {
            // TODO: move responsibility
            
            display:flex;
            align-items: center;
            justify-content: center;

        }
    `,
    Link:css`
        width: 100%;
        height: 100%;
    `,
}