import BoothRepository from "./booths/BoothRepository";
import MapRepository from "./maps/MapRepository";
import AccountRepository from "./accounts/AccountRepository";
import MessageSystem from "./messages/MessageSystem";
import DialogSystem from "./dialogs/DialogSystem";
import ThreeSystem from "./ThreeSystem";

import {PlayerNode} from "./player/PlayerNode";
import Translations from "./translation/Translations";
import LibertyClient from "../libraries/l2-common/l2-client-library-ts/source/LibertyClient";
import SimpleLocalStorageTokenDao from "../libraries/l2-common/l2-client-library-ts/examples/SimpleLocalstorageTokenDao";
import SessionState from "./accounts/SessionState";
import { AdRepository } from "./ads/AdRepository";
import { FileRepository } from "./files/FileRepository";
import { FloorSceneNode } from "./scenes/FloorSceneNode";
import { SceneNode } from "./scenes/SceneNode";
import { UrlToSceneNode } from "./Routing";
import { NotFoundSceneNode } from "./scenes/NotFoundSceneNode";
import { Settings } from "./Settings";
import InputSystem from "./input/InputSystem";
import { AudioSystem } from "./audio/AudioSystem";
import { MarketplaceOfferRepository } from "../marketplace/MarketplaceOfferRepository";

import config from "../config/config";

export default class SystemContext{
    readonly sessionState;
    // readonly urls = new URLs();
    readonly tokenDao = new SimpleLocalStorageTokenDao({});
    readonly client = new LibertyClient({
        webClientConfig: {
            tokenDao: this.tokenDao,
            baseUrl: config.apiUrl
        }
    });
    get api(){ return this.client.api; }
    readonly accountRepository = new AccountRepository( this );
    readonly mapRepository = new MapRepository( this );
    readonly boothRepository = new BoothRepository( this );
    readonly adRepository = new AdRepository( this );
    readonly fileRepository = new FileRepository( this );
    readonly offerRepository = new MarketplaceOfferRepository( this );
    readonly messageSystem;
    readonly dialogSystem = new DialogSystem( this );
    readonly translations = new Translations();
    readonly settings;
    readonly inputSystem;
    readonly audioSystem;
    /**
     * Shortuct for calling this.translations.get
     * */
    readonly translate = ( translationKey:string )=>this.translations.get(translationKey)

    readonly threeSystem;

    private _sceneIsLoading:boolean=false;
    get sceneIsLoading(){ return this._sceneIsLoading; }

    private _scene?:SceneNode;
    get scene(){ return this._scene; }

    get player(){ return this._findPlayerNode(); }
    /**
     * This callback is set by the SystemContextComponent, then called in logic when the UI needs to be refreshed
     * Default value is a no-op
    */
    public forceReactUpdate = ()=>{};

    constructor( window:Window ){
        this.sessionState = new SessionState( this.client, this.tokenDao, this );
        this.settings = new Settings(this, this.sessionState);
        this.messageSystem = new MessageSystem( this );
        this.threeSystem = new ThreeSystem( window, this );
        this.audioSystem = new AudioSystem( this.threeSystem, this.settings );
        this.inputSystem = new InputSystem(this, this.threeSystem);
        const scene = UrlToSceneNode( ""+window.location, this ) || new NotFoundSceneNode(this);
        
        this.loadSceneAsync( scene );
    }

    async loadSceneAsync( scene:SceneNode, options?:{
        /** By default, loading one scene overwrites the old one. */
        loadAdditive?:boolean
    } ){
        this._clearCache();
        this._sceneIsLoading = true;
        this.forceReactUpdate();

        if(!options?.loadAdditive){
            await this.unloadAllScenesAsync();
        }
        
        this.threeSystem.rootNode.addChild( scene );
        this._scene = scene;

        await scene.loadAsync();

        this._sceneIsLoading = false;
        this.forceReactUpdate();
    }

    private async unloadAllScenesAsync() {
        const topNodes = this.threeSystem.rootNode.children;
        const scenes = topNodes.filter(a => a instanceof SceneNode) as SceneNode[];
        for (const scene of scenes) {
            await this.unloadSceneAsync(scene);
        }
    }

    async unloadSceneAsync( scene:SceneNode ){
        this._clearCache();
        await scene.unloadAsync();
        scene.destroy();
    }

    private _clearCache(){
        delete this._findPlayerNode__resultCache;
        delete this._findFloorSceneNode__resultCache;
    }

    private _findPlayerNode__resultCache?:PlayerNode;
    private _findPlayerNode(){
        if( ! this._findPlayerNode__resultCache ){
            this._findPlayerNode__resultCache = this.threeSystem.rootNode.findOneInDescendants(
                a=>a instanceof PlayerNode
            ) as PlayerNode|undefined;
        }
        return this._findPlayerNode__resultCache;
    }

    private _findFloorSceneNode__resultCache?:FloorSceneNode;
    findFloorSceneNode(){
        if( ! this._findFloorSceneNode__resultCache ){
            this._findFloorSceneNode__resultCache = this.threeSystem.rootNode.findOneInDescendants(
                a=>a instanceof FloorSceneNode
            ) as FloorSceneNode|undefined;
        }
        return this._findFloorSceneNode__resultCache;
    }
}