import assert from "assert";
import { Euler, Mesh, Object3D, Texture } from "three";
import * as Three from "three";
import Object3DNode from "../../../nodes/Object3DNode";
import SystemContext from "../../../SystemContext";
import NodeMessageControls from "../../../nodes/NodeMessageControls";
import Node from "../../../nodes/Node";
import Resources from "../../../Resources";

interface FileInfo{
    id: string,
    name: string,
}

/** Indicates that a file displayer is still loading. This is spawned by the FileDisplayerNode while it is loading the file's preview. */
export default class FileDisplayerNodeLoadingWidget extends Object3DNode{
    _displayMesh;
    _displayNode;

    private _accumulatedAnimationTime = 0;

    
    constructor( systemContext:SystemContext ){
        super(
            new Three.Object3D(),
            systemContext
        );

        this._displayMesh = new Mesh(
            // new Three.BoxGeometry( .11,.33,.33 ),
            new Three.BoxGeometry( .2,1,1 ),
            // new Three.MeshStandardMaterial()
            // glass material
            new Three.MeshPhongMaterial( { color: "gray", opacity: .5, transparent:true } )
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
            this
        );
        this._displayMesh.position.x = .1;
        this.addChild( this._displayNode );

    }

    updateHook( deltaTime:number ){
        this._accumulatedAnimationTime += 5*deltaTime;
        this._displayMesh.position.x = .025*Math.sin(this._accumulatedAnimationTime);

        // this._displayMesh.rotateX( 5 * deltaTime );
    }
    
}