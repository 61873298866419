import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { ExpoMapRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoMapRecord";
import { PREFERRED_LANGUAGE_IDS_TODO } from "../messages/ChatRoomEntity";

export class MapEntity{
    public readonly record;

    constructor(record:ExpoMapRecord){
        this.record=record;
    }

    get resolvedTitle(){
        if(!this.record.title){
            return undefined;
        }
        return MaybeResolveStringWithTranslations(
            this.record.title,
            PREFERRED_LANGUAGE_IDS_TODO
        )||undefined;
    }

    getMappingsByFloorAndType(floor:number,type:"booth"|"ad"){
        const targetAddressPrefix = floor+"_";

        if(!this.record.mappings){
            return [];
        }
        return this.record.mappings.filter(
            a=>(
                a.address.startsWith(targetAddressPrefix) &&
                a.typeId===type
            )
        );
    }

    static readonly DEMO = new MapEntity({
            id:"demo",
            title:{en:"Demo Map"},
            venueId: "liberty",
            creationTimeSeconds:0,
            ownerAccountId:"",
            slug:"",
            visibility: "public",
    });

    

}