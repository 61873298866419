import * as Three from "three";
import { Vector3 } from "three";
import Object3DNode from "../logic/nodes/Object3DNode";
import PersonNode from "../logic/player/PersonNode";
import SystemContext from "../logic/SystemContext";
import { introAvatarWalkSpeed, libertyVenueBuildingDoorPosition } from "./IntroConstants";

const WALK_SPEED = introAvatarWalkSpeed;

export class IntroAvatarNode extends Object3DNode{
    private _personNode:PersonNode;
    private _move_destination:Vector3 = new Vector3(
        ...libertyVenueBuildingDoorPosition
    );

    private _shouldMove = false;
    startMoving(){
        this._shouldMove = true;
    }

    constructor( systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );

        this._personNode = new PersonNode(
            systemContext,
            this,
            {
                useCollisions: false,
            }
        );
        this.addChild( this._personNode );

    }

    updateHook( deltaTime:number ){
        if(!this._shouldMove){
            return false;
        }
        const direction = new Vector3().subVectors(
            this._move_destination,
            this.object3D.position
        );

        const walkDirection = new Vector3(
            direction.x,
            0,
            direction.z
        ).normalize();

        const run = false;
        this._move(walkDirection,run,deltaTime);
    }

    private _move(normalizedDirection:Vector3, run:boolean, deltaTime:number){
        this._personNode?.walk({
            normalizedDirection,
            walkSpeed:WALK_SPEED,
            runSpeed:WALK_SPEED*2,
            run,
            deltaTime
        });
    }
    
}