import { degToRad } from "three/src/math/MathUtils";
import { ExpoBoothObjectType } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothObjectTypes";
import { ExpoBoothRecordObjectRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import LocalFileWrapper from "../files/FileWrapper";
import { FileLike } from "./subObjects/types/FileLike";

export class BoothSubObjectEntity{
    readonly record;
    /** For local files that have been put onto the object but aren't uploaded yet, thus don't have an ID and can't be placed on the object's record. */
    localFiles:LocalFileWrapper[]=[];

    constructor(record:ExpoBoothRecordObjectRecord){
        this.record=record;
    }
    get transformPosition(){
        return this.record.transform.position as [number,number,number]|undefined;
    }
    get transformRotationDegrees(){
        return this.record.transform.rotation as [number,number,number]|undefined;
    }
    get transformRotationRadians() {
        return this.record.transform.rotation?.map(
            a=>degToRad(a)
        ) as [number,number,number]|undefined;
    }
    get transformScale() {
        return this.record.transform.scale as [number,number,number]|undefined;
    }

    get usesFiles(){
        return BoothSubObjectTypeUsesFiles(this.record.typeId);
    }

    get primaryFile():FileLike|undefined{
        return this.localFiles[0]||(this.record.fileIds||[])[0];
    }

}

export function BoothSubObjectTypeUsesFiles(subObjectType:ExpoBoothObjectType){
    return ["modelViewer","fileViewer","cutout"].includes(subObjectType)
}