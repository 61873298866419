
let PUBLIC_URL = process.env.PUBLIC_URL;
// For an unknown reason, PUBLIC_URL was getting set to "" in dev mode, so we allow it to alteratively be defined with a custom variable (REACT_APP__PUBLIC_URL).
if( process.env.REACT_APP__PUBLIC_URL ){
    PUBLIC_URL = process.env.REACT_APP__PUBLIC_URL;
}
if( PUBLIC_URL==="" ){
    throw new Error("PUBLIC_URL is set to an empty string.")
}
if( PUBLIC_URL.endsWith("/") ){
    throw new Error("Expected PUBLIC_URL to not end with slash.")
}
export const PUBLIC_URL_NO_SLASH = PUBLIC_URL;
export const PUBLIC_URL_WITH_SLASH = PUBLIC_URL+"/";

export const HOMEPAGE_URL = PUBLIC_URL.includes("localhost") ? "http://localhost:3000/" : "https://liberty-hjc.com/";