const scale = 700;

export const libertyVenueBuildingScale = scale;

export const introAnimationDuration = 5.5;
export const introAvatarWalkSpeed = 5;

export const libertyVenueBuildingDoorPosition = [
    0,
    .098*scale,
    .04*scale
] as [number,number,number];
 

export const libertyVenueBuildingAvatarStartPosition = [
    0,
    .095*scale,
    // makes it so that the player walks into the door just as the animation ends
    libertyVenueBuildingDoorPosition[2]+introAvatarWalkSpeed*introAnimationDuration
    // .062*scale
] as [number,number,number];