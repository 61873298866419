import { z } from "zod";


export const AccessGroupSchema = z.enum([
    "system", "admin", "staff", "verified", "account"
]);
export type AccessGroup = z.infer<typeof AccessGroupSchema>;


/**
 * Groups provide permissions but never withhold permissions. Thus, the more groups you are in, the more permissions you have.
 * For example, the admin account should also be in the staff group, so that it can perform staff actions.
 * 
 * New accounts start with the group list: ["account"].
 * 
 * */
export const AccessGroups = {
    /** Used to make internal API calls. There are no accounts in this group.*/
    System: "system",
    Admin: "admin",
    /** Accounts in this group have some moderation and light admin privileges. */
    Staff: "staff",
    /** Account has been manually reviewed and verified as legit by staff, etc... */
    Verified: "verified",
    /** All accounts are in this group by default. */
    Account: "account",
    All: ["system", "admin", "staff", "verified", "account"] as const,
} as const;

