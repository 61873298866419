import { Vector2 } from "three";
import { MakeMouseNormalizedDeviceCoordinate } from "../../libraries/l2-common/l2-common-ts/common/utility/Dom";
import SystemContext from "../SystemContext";
import ThreeSystem from "../ThreeSystem";
import InputMap from "./InputMap";


// TODO: We have set tabIndex of the canvas so that it can be focused, but this might not work in some browsers.
const ADD_INPUT_EVENTS_TO_CANVAS_INSTEAD_OF_DOCUMENT = true;

const CONSOLE_LOG_INPUT = false;

export const MOUSE_BUTTON_LEFT = "left";
export const MOUSE_BUTTON_RIGHT = "right";
export const MOUSE_BUTTON_MIDDLE = "center";

export const INPUT_FORWARD = "forward";
export const INPUT_BACKWARD = "backward";
export const INPUT_LEFT = "left";
export const INPUT_RIGHT = "right;"
export const INPUT_RUN = "run";

export default class InputSystem{
    _map:InputMap = new InputMap({
        "ArrowUp":INPUT_FORWARD,
        // "JoystickUp":INPUT_FORWARD,
        "w":INPUT_FORWARD,
        "W":INPUT_FORWARD,
        "ArrowDown":INPUT_BACKWARD,
        // "JoystickDown":INPUT_BACKWARD,
        "s":INPUT_BACKWARD,
        "S":INPUT_BACKWARD,
        "ArrowLeft":INPUT_LEFT,
        // "JoystickLeft":INPUT_LEFT,
        "a":INPUT_LEFT,
        "A":INPUT_LEFT,
        "ArrowRight":INPUT_RIGHT,
        // "JoystickRight":INPUT_RIGHT,
        "d":INPUT_RIGHT,
        "D":INPUT_RIGHT,
        "Space":INPUT_RUN,
        "Spacebar":INPUT_RUN,
        " ":INPUT_RUN,
    });
    _allInputKeys:string[];
    _inputKeyStates = new Map<string,boolean>();
    _mouseButtonStates:{[index: string]: boolean} = { [MOUSE_BUTTON_LEFT]: false, [MOUSE_BUTTON_RIGHT]: false, [MOUSE_BUTTON_MIDDLE]: false };
    /** two snapshots to watch the mouse button state, so that we can detect "just down" and "just up" */
    /** a snapshot of mousebuttonstates, as captured during this update frame */
    _mouseButtonStatesSnapshotCurrent:{[index: string]: boolean} = { [MOUSE_BUTTON_LEFT]: false, [MOUSE_BUTTON_RIGHT]: false, [MOUSE_BUTTON_MIDDLE]: false };
    /** a snapshot of mousebuttonstates, as captured during the previous update frame */
    _mouseButtonStatesSnapshotPrevious:{[index: string]: boolean} = { [MOUSE_BUTTON_LEFT]: false, [MOUSE_BUTTON_RIGHT]: false, [MOUSE_BUTTON_MIDDLE]: false };
    // TODO: rewrite to track per-button
    _mouseDownLocation?:Vector2;
    _mouseCurrentLocation?:Vector2;
    systemContext:SystemContext;
    
    constructor( systemContext:SystemContext, threeSystem:ThreeSystem){
        this.systemContext = systemContext;
        this._allInputKeys = this._map.getAllInputKeys();
        for( const inputKey of this._allInputKeys ){
            this._inputKeyStates.set( inputKey, false );
        }

        threeSystem.addSetRendererContainerDivListener(()=>{
            const {domElement} = threeSystem.renderer;
            const container = threeSystem.rendererContainerDiv;
            if(!container){
                throw Error();
            }
            // TS error if you try to call keyEventsTarget.addEventListener
            // const keyEventsTarget = ADD_INPUT_EVENTS_TO_CANVAS_INSTEAD_OF_DOCUMENT ? domElement : document;
            if( ADD_INPUT_EVENTS_TO_CANVAS_INSTEAD_OF_DOCUMENT ){
                domElement.addEventListener( "keydown",   this._onKeyDown     );
                domElement.addEventListener( "keyup",     this._onKeyUp       );
            } else {
                document.addEventListener( "keydown",   this._onKeyDown     );
                document.addEventListener( "keyup",     this._onKeyUp       );
            }

            domElement.addEventListener( "mousedown", this._onMouseDown   );
            domElement.addEventListener( "mousemove", this._onMouseMove   );
            document.addEventListener( "mouseup",   this._onMouseUp     );
        });
    }

    /** Call this in the game loop, exactly once per frame. */
    update(){
        for(const buttonId of Object.keys(this._mouseButtonStates)){
            this._mouseButtonStatesSnapshotPrevious[buttonId] = this._mouseButtonStatesSnapshotCurrent[buttonId];
            this._mouseButtonStatesSnapshotCurrent[buttonId] = this._mouseButtonStates[buttonId];
        }
    }

    _onMouseDown = (event:MouseEvent)=>{
        const buttonId = MaybeGetMouseButtonIdFromMouseEvent( event );
        if( buttonId ){
            this._mouseButtonStates[ buttonId ] = true;
        }
        this._mouseDownLocation = this._getMouseNormalizedDeviceCoordinateFromEvent(event);
        
    }

    // TODO: find a way to cache these mouse movement lookups, as there are several instances of this class, and they all have listeners that get called on mousemove
    _onMouseMove = (event:MouseEvent)=>{
        this._mouseCurrentLocation = this._getMouseNormalizedDeviceCoordinateFromEvent(event);
    }

    /** Normalized Device Coordinates ( Component values range from -1 to +1. Top left coordinate is (-1,+1) )*/
    _getMouseNormalizedDeviceCoordinateFromEvent(event:MouseEvent){
        const {domElement} = this.systemContext.threeSystem.renderer;
        return new Vector2(
            ...MakeMouseNormalizedDeviceCoordinate(event,domElement)
        );
    }

    _onMouseUp = (event:MouseEvent)=>{
        const buttonId = MaybeGetMouseButtonIdFromMouseEvent( event );
        if( buttonId ){
            this._mouseButtonStates[ buttonId ] = false;
        }
        this._mouseDownLocation = undefined;
    }

    _onKeyDown = (event:KeyboardEvent)=>{
        const {key} = event;
        if( CONSOLE_LOG_INPUT ){
            console.log(key);
        }
        const inputKey = this._map.getInputKeyByKeyboardEventKey( key );
        this.triggerInputKeyDown(inputKey);
    }

    _onKeyUp = (event:KeyboardEvent)=>{
        const {key} = event;
        const inputKey = this._map.getInputKeyByKeyboardEventKey( key );
        this.triggerInputKeyUp(inputKey);
    }

    isInputKeyDown( inputKey:string ){
        return this._inputKeyStates.get(inputKey);
    }

    isMouseButtonDown( mouseButtonId:string ){
        return this._mouseButtonStates[ mouseButtonId ];
    }

    isMouseButtonJustDown( mouseButtonId:string ){
        const isCurrentlyDown = this._mouseButtonStatesSnapshotCurrent[ mouseButtonId ];
        const wasPreviouslyDown = this._mouseButtonStatesSnapshotPrevious[ mouseButtonId ];
        return isCurrentlyDown && !wasPreviouslyDown;
    }

    isMouseButtonJustUp( mouseButtonId:string ){
        const isCurrentlyDown = this._mouseButtonStatesSnapshotCurrent[ mouseButtonId ];
        const wasPreviouslyDown = this._mouseButtonStatesSnapshotPrevious[ mouseButtonId ];
        return wasPreviouslyDown && !isCurrentlyDown;
    }

    /** Returns undefined if the selected button isn't down, or if the drag would be 0,0. */
    maybeGetMouseDragDelta( mouseButtonId:string ){
        if(
            ! this.isMouseButtonDown(mouseButtonId) ||
            ! this._mouseCurrentLocation ||
            ! this._mouseDownLocation ||
            this._mouseCurrentLocation.equals(this._mouseDownLocation)
        ){
            return undefined;
        }
        const result = new Vector2();
        result.copy( this._mouseCurrentLocation );
        result.sub( this._mouseDownLocation );
        return result;
    }

    /**
     * Resets the mouseDragDelta so that it is as if you haven't dragged at all again
     * Use this function after getting mouseDragDelta via maybeGetMouseDragDelta()
     * so that you can clear any amount of mouse drag that you've already parsed.
     * */
    clearMouseDragDelta( mouseButtonId:string ){
        this._mouseDownLocation = this._mouseCurrentLocation;
    }

    /** Normalized Device Coordinates ( Component values range from -1 to +1. Top left coordinate is (-1,+1) )*/
    getMousePosition(){
        return this._mouseCurrentLocation;
    }

    /** force a input press, useful for mapping joysticks (which don't have an associated event key*/
    triggerInputKeyDown(inputKey?:string){
        if(inputKey===undefined){
            return;
        }
        this._inputKeyStates.set(inputKey,true);
    }

    triggerInputKeyUp(inputKey?:string){
        if(inputKey===undefined){
            return;
        }
        this._inputKeyStates.set(inputKey,false);
    }
    
}


// TODO: account for old versions of Internet Explorer
/** Returns undefined if it's not one of these buttons. */
export function MaybeGetMouseButtonIdFromMouseEvent( mouseEvent:MouseEvent ){
    return [
        MOUSE_BUTTON_LEFT, MOUSE_BUTTON_MIDDLE, MOUSE_BUTTON_RIGHT
    ][ mouseEvent.button ];
}