import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";
import { useState } from "react";

export function NumberInput(
    {
        value,
        setValue,
        disabled,
        layout,
        /** eg if set to 2, a component 1.234 becomes 1.23 */
        decimalPlaces,
        defaultValue,
        type,
        allowZero,
        allowNegative,
    }:
    {
        value?:number,
        setValue:(value:number)=>void,
        disabled?:boolean,
        layout?:CSSProp,
        decimalPlaces?:number,
        defaultValue:number,
        type?:"number"|"integer",
        allowZero?:boolean,
        allowNegative?:boolean,
    }
){
    type = type ?? "number";
    allowZero = allowZero ?? true;
    allowNegative = allowNegative ?? true;
    value = value ?? defaultValue;
    let [localStringValue,setLocalStringValue] = useState<string>(
        maybeTruncateDecimalDigits(""+value,decimalPlaces) || ""
    )
    const theme = useTheme();

    return <div css={Layout.Root+''+layout}>
        <input
            // type="number"
            css={Layout.Component}
            placeholder={""+defaultValue}
            value={localStringValue}
            disabled={disabled}
            onChange={e=>{
                let nextStringValue = maybeTruncateDecimalDigits(
                    e.target.value,
                    decimalPlaces
                );
                setLocalStringValue(nextStringValue);

                if(nextStringValue.length===0){
                    setValue(defaultValue);
                    return;
                }

                let nextNumberValue = parseFloat(nextStringValue);
                if(type==="integer"){
                    nextNumberValue=parseInt(nextStringValue);
                }

                let isUsable = !isNaN(nextNumberValue);
                if( !allowNegative && nextNumberValue<0 ){
                    isUsable = false;
                }
                if( !allowZero && nextNumberValue===0 ){
                    isUsable = false;
                }
                if( !isUsable ){
                    return;
                }
                
                setValue(nextNumberValue);
            }}
        />
    </div>
  
}

/** Because input is a "text-like" element (not a component), we, by default, size it based on local text, rather than relying on extenral sizing. */
const Layout = {
    Root: css`
        /* min-width: 3em; */
        height: 2em;
        padding: 0 1em;
    `,
    Component: css`
        width: 5ch;
    `,
}

function maybeTruncateDecimalDigits(
    value:string,
    truncateDecimalDigitsCount?:number,
){
    if(truncateDecimalDigitsCount===undefined){
        return value;
    }
    // if there's exacltly one ".", truncate any excess digits
    const split = value.split(".");
    if( split.length===2 ){
        split[1] = split[1].slice(
            0,
            truncateDecimalDigitsCount
        )
    }
    value = split.join(".");
    return value;
}