import {GLTF, GLTFLoader as GltfLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
import {FBXLoader as FbxLoader} from "three/examples/jsm/loaders/FBXLoader.js";
import * as Three from "three";
import { Audio, AudioLoader, sRGBEncoding, Texture } from "three";
import {IsProbablyPromise} from "../logic/utility/DuckTyping";
import { ExpoBoothType } from "../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothTypes";
import { THREE_SYSTEM_RENDERER_ENCODING } from "./ThreeSystem";
import config from "../config/config";

const gltfLoader = new GltfLoader();
const fbxLoader = new FbxLoader();
const audioLoader = new AudioLoader();

const MODELS_PATH = `${config.staticContentUrl}models/`;

/**
 * Caches an arbitrary resource
 * will throw an error if the resource type cannot be differentiated from a Promise
 * */
class ResourceCache<T>{
    /**
        * If the key has already been requested, cache contains a promise instead of the resource.
        * When that promise resolves, it will return the resource.
    */
    private _cache = new Map<string, T|Promise<T>>();

    /**
     * 
     * @param key The unique identifier for the resource used by the cache and passed to the loader function
     * @param loaderFunctionAsync A function that loads the resource in question in the case of a cache miss.
     * @returns 
     */
    async getAsync( key:string, loaderFunctionAsync:(id:string)=>Promise<T> ){

        if( this._cache.has(key) ){
            // We type value as unknown, and then T, this will work so long as the type T doesn't look like a promise according to IsProbablyPromise
            let value:unknown = this._cache.get(key);
            if( IsProbablyPromise(value) ){
                // If the resource is already in the middle of loading, then the value will be a Promise & we can await it to get the resource
                return <T>(await value);
            }else{
                // If the resource has already finished loading, then simply return it
                return <T>value;
            }
        }

        // 1.
        // Set up a promise that will resolve when the resource finishes loading
        // and put it into the cache

        let resolveCompletionPromise=(gltf:T)=>{};
        let completionPromise = new Promise<T>(
            (resolve,reject)=>{
                resolveCompletionPromise=resolve;
            }
        );
        this._cache.set( key, completionPromise );

        // 2.
        // Load the resource and resolve the promise in case we were awaiting it in another call

        const resource = await loaderFunctionAsync(key);

        // Avoid the dangerous situation where we're working with a resource that cannot be told apart from a promise
        if( IsProbablyPromise(resource) ){
            throw new Error("Cannot cache a resource that looks like a Promise.");
        }

        this._cache.set(key, resource);
        resolveCompletionPromise(resource);
        return resource;
    }
}

const gltfCache = new ResourceCache<GLTF>();
const textureCache = new ResourceCache<Texture>();
const audioCache = new ResourceCache<AudioBuffer>();


export default class Resources{

    public static async LoadTextureAsync( textureUrl:string ){

        const texture = await textureCache.getAsync(
            textureUrl,
            async (textureUrl:string)=>{
                const textureLoader = new Three.TextureLoader();
                textureLoader.crossOrigin = "anonymous";
                const texture = await textureLoader.loadAsync( textureUrl );
                return texture;
            }
        );
        texture.encoding = THREE_SYSTEM_RENDERER_ENCODING;
        return texture;

    }

    /* TODO: count references */
    public static MaybeDisposeTexture(texture:Texture){
        // NOTE: Hopefully this texture isn't also being used elsewhere
        // TODO: count references to each texture and only dispose when all references are freed
        texture.dispose();
    }

    public static async LoadGLTFAsync(
        gltfUrl:string,
        onProgressCallback?:(event: ProgressEvent) => void
    ){

        return await gltfCache.getAsync(
            gltfUrl,
            async (gltfUrl:string)=>{
                const gltf = await gltfLoader.loadAsync( gltfUrl, onProgressCallback );
                return gltf;
            }
        );

    }

    public static async LoadFBXAsync(
        fbxUrl:string,
        onProgressCallback?:(event: ProgressEvent) => void
    ){
        const fbx = await fbxLoader.loadAsync( fbxUrl, onProgressCallback );
        return fbx;
    }

    public static async LoadBoothGLTFByBoothIdAsync( boothType:ExpoBoothType ){
        const modelName = {
            simpleBlue:     "Booth01_Blue.gltf",
            simpleRed:      "Booth02_Red.gltf",
            simpleYellow:   "Booth03_Yellow.gltf",
            simpleWhite:    "Booth04_White.gltf",
            custom: undefined,
        }[ boothType ];
        if( !modelName ){
            throw new Error("unsupported boothType: "+boothType);
        }
        const gltfUrl = MODELS_PATH + modelName;
        
        const gltf = await Resources.LoadGLTFAsync( gltfUrl );

        return gltf;
    }

    public static async LoadBoothGLTFByVenueIdAndFloorAsync( venueId:string, floor:number ){
        const modelName = {
            liberty_hall:     "Hall_Lowpoly_03.gltf",
        }[ venueId+"_"+(floor>0?"hall":"lobby") ];
        if( !modelName ){
            throw new Error("invalid venueId/floor: "+venueId+", "+floor);
        }
        const gltfUrl = MODELS_PATH + modelName;
        return await Resources.LoadGLTFAsync( gltfUrl );
    }

    public static async LoadAudioAsync(
        audioUrl:string,
        onProgressCallback?:(event: ProgressEvent) => void
    ){
        return await audioCache.getAsync(
            audioUrl,
            async (audioUrl:string)=>{
                const buffer = await audioLoader.loadAsync( audioUrl, onProgressCallback );
                return buffer;
            }
        );
    }
}