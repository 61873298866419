
import BACKGROUND_IMG_BLUE_FRONT      from "./simpleBlue--front.jpg";
import BACKGROUND_IMG_RED_FRONT       from "./simpleRed--front.jpg";
import BACKGROUND_IMG_WHITE_FRONT     from "./simpleWhite--front.jpg";
import BACKGROUND_IMG_YELLOW_FRONT    from "./simpleYellow--right.jpg";

export const VIEW_FRONT = "front";
export const VIEW_LEFT = "left";
export const VIEW_RIGHT = "right";

export function GET_BY_BOOTHTYPEID_AND_VIEW(
    typeId:string|undefined,
    view?:string,
) {
    switch( view ){
        default: return "";
        case VIEW_FRONT:
            switch( typeId ){
                default: return "";
                case "simpleBlue":  return BACKGROUND_IMG_BLUE_FRONT;
                case "simpleRed":   return BACKGROUND_IMG_RED_FRONT;
                case "simpleWhite": return BACKGROUND_IMG_WHITE_FRONT;
                case "simpleYellow":return BACKGROUND_IMG_YELLOW_FRONT;
            }
        case VIEW_RIGHT:
        case VIEW_LEFT:
            throw new Error("TODO: Not yet defined.");
        
    }
};