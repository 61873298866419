import Extensions from "../../logic/utility/Extensions";
import "styled-components/macro";
import { css } from "styled-components";
import ModelViewerComponent from "./ModelViewerComponent";
import { FileNameToExtension, MakeFileUrlWithExtension } from "../../logic/utility/Files";
import { useFileMetadata, useFileRenderUrls } from "../../logic/files/FileRepositoryUtilities";
import { BoothObjectLightItem } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import { useSystemContext } from "../../logic/SystemContextComponents";

/* The contents of the dialog's items, when showing a file. */
export function DialogComponentFileItemsContents({
    fileId,
    lights,
}:{
    fileId?:string,
    lights?:BoothObjectLightItem[],
}) {
    const {translate} = useSystemContext();
    const pageUrls = useFileRenderUrls(fileId);
    const metadata = useFileMetadata(fileId);

    if(!metadata){
        return <></>;
    }

    const fileName = metadata.filename;
    const extension = FileNameToExtension(fileName);
    const fileUrl = fileId && MakeFileUrlWithExtension(fileId,fileName);

    const isVideo = !!extension && Extensions.IsVideo( extension );
    if( isVideo ){
        return <>
            <video
                    width="1920"
                    height="1080"
                    controls
                    css={Layout.VideoItem}
            >
                <source
                        src={fileUrl}
                        type={"video/"+extension.split(".").join("")}
                />
            </video>
        </>;
    }

    const isModel = !!extension && Extensions.IsModel( extension );
    if( isModel && fileUrl ){
        return <ModelViewerComponent modelFileUrl={fileUrl} lights={lights}/>;
    }

    if( pageUrls ){
        const pagesElements = pageUrls.map(
            (url, index) => <img
                src={url}
                css={Layout.ImageItem}
                alt={"page " + (index + 1)} />
        );
        return <>{pagesElements}</>;
    }

    return <>{translate("t$loadingDotDotDot")}</>;
}


const Layout = {
    ImageItem: css`
        width: calc( 120rem * .85 );
        height: auto;
    `,
    VideoItem: css`
        min-height:80vh;
        max-height:150vh;
        max-width: 100%;
        width: auto;
    `,
}