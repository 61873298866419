import { useState } from "react";
import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents"
import { useComponentDidMount, useComponentDidUnmount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";

/**
 * Shows a keyboard key and pops up/down when the key is pressed/released.
 */
export function HelpMenuDisplayButton({
    keyboardEventKey,
    keyboardEventKeys,
    layout,
    text,
}:{
    keyboardEventKey?:string,
    keyboardEventKeys?:string[],
    layout?:CSSProp,
    text:string,
}){
    const theme = useTheme();
    const [pushed,setPushed] = useState(false);

    keyboardEventKeys = keyboardEventKeys || [];
    if( keyboardEventKey ){
        keyboardEventKeys.push(keyboardEventKey);
    }

    function onKeyDown(event:KeyboardEvent){
        if( (keyboardEventKeys||[]).includes(event.key) ){
            setPushed(true);
        }
    }
    function onKeyUp(event:KeyboardEvent){
        if( (keyboardEventKeys||[]).includes(event.key) ){
            setPushed(false);
        }
    }

    useComponentDidMount( ()=>{
        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);
    } );

    useComponentDidUnmount( ()=>{
        document.removeEventListener("keydown", onKeyDown);
        document.removeEventListener("keyup", onKeyUp);
    } );

    const css = Layout+''+(
        pushed?theme.KeyboardKey.Pushed:theme.KeyboardKey.Normal
    )+layout;
    return <div css={css}>
        {text}
    </div>
}

const Layout = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;