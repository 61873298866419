
import Time from "../../libraries/l2-common/l2-common-ts/common/utility/Time";
import { ChatMessageRecord, ChatMessageRecordPartForPost } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";
import SystemContext from "../SystemContext";


export default class MessageRepository{
    _systemContext;
    private get _client(){ return this._systemContext.client};
    private get _session(){ return this._systemContext.sessionState};

    constructor( systemContext:SystemContext ){
        this._systemContext = systemContext;
    }

    async getAllAsync(){
        if( !this._systemContext.tokenDao.hasToken() ){
            return undefined;
        }
        const ownId = await this._session.getMyAccountIdAsync();
        if(!ownId){
            return undefined;
        }

        const messages = await this._client.api.chat.messages.getAsync({
            roomParticipantAccountId: ownId
        });
        return messages.sort(
            (a,b)=>a.creationTimeSeconds-b.creationTimeSeconds
        );
    }

    async getManyByRoomIdAsync(roomId:string){
        const all = await this.getAllAsync();
        return all?.filter(a=>a.roomId===roomId);
    }

    async addByPartAsync_( part:ChatMessageRecordPartForPost ){
        const result = await this._client.api.chat.messages.postAsync(
            part
        );
        return result;
    }

    async updateWasReadAsync( message:ChatMessageRecord ){
        throw 501;
        // await this._systemContext.apiClient.postAsync(
        //     this._systemContext.urls.messages_sourceId_messageId_wasRead(
        //         this._sourceId,
        //         messageData.id
        //     ),
        //     {
        //         // (this endpoint doesn't take a body)
        //     }
        // );
    }

    async updateWasReadManyAsync( messageDatas:ChatMessageRecord[] ){
        for( const messageData of messageDatas ){
            await this.updateWasReadAsync( messageData );
        }
    }

}