import { DoesNotExistResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { ExpoBoothRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecord";
import SystemContext from "../SystemContext";
import {BoothEntity} from "./BoothEntity";

export default class BoothRepository{
    private _api;

    private _recordByIdCache = new Map<string, ExpoBoothRecord>();
    
    constructor( systemContext:SystemContext ){
        this._api = systemContext.api;
    }

    async getByIdAsync( id:string ){
        if( this._recordByIdCache.has(id) ){
            const record = this._recordByIdCache.get(id);
            return new BoothEntity(
                record as ExpoBoothRecord
            );
        }
        const record = await this._api.expo.booths.byId(id).getAsync();
        if( record instanceof DoesNotExistResult ){
            return undefined;
        }
        return new BoothEntity( record );
    }

    
    async getManyByOwnerAsync( ownerAccountId:string ){
        const records = await this._api.expo.booths.getAsync({
            ownerAccountId
        });
        if( records instanceof DoesNotExistResult ){
            return undefined;
        }
        return records.map(
            a=> new BoothEntity(a)
        );
    }

    

    async cacheManyByIdsAsync( ids:string[] ){
        await this.maybeGetManyByIdsAsync(ids);
    }

    /**
     * 
     * @param ids 
     * @returns A Map of BoothEntitys, mapping each id to each boothEntity. 
     */
    async maybeGetManyByIdsAsync( ids:string[] ){
        const result = new Map<string, BoothEntity>();
        const records = await this._api.expo.booths.getAsync({
            ids
        });
        for(const record of records ){
            const booth = new BoothEntity( record );
            if( record.id ){
                this._recordByIdCache.set( record.id, record );
                result.set( record.id, booth );
            }
        }
        return result;
    }

}