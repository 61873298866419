import "styled-components/macro";
import { css } from "styled-components/macro";

import { useTheme } from "../../logic/SystemContextComponents";

export function MenuSubHeader(
    props:any
){
    const theme = useTheme();
    return <div css={Layout+''+theme.MenuSubHeader} {...props}/>
}


const Layout = css`
    font-size: 1.304em;
    word-wrap: break-word;
    text-align: center;
    hyphens: auto;
`;