import "styled-components/macro";

export default function Spacer(){

    return <div
        css={`
            width: 1vmin;
            height: 1vmin;
        `}
    />
}