import "styled-components/macro";
import { useTheme } from "../logic/SystemContextComponents";
import { css } from "styled-components/macro";

export function Background(){
    const theme = useTheme();

    return (
        <div css={theme.Background+''+Layout.Root} className="(Background)">
        </div>
    );
}


const Layout = {
    Root: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    `,
}