import assert from "assert";
import { ChatMessageRecordPartForPost } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";
import { ChatRoomRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatRoomRecord";

import SystemContext from "../SystemContext";
import MessageSystem from "./MessageSystem";

/**
 * We have a repository for drafts so that the user can switch between conversations & not lose their work-in-progress.
 */
export default class DraftMessageRepository{
    _drafts = new Map<string,ChatMessageRecordPartForPost>();
    _systemContext;
    
    constructor( systemContext:SystemContext ){
        this._systemContext = systemContext;
    }

    /**
     * Gets the draft message, and will initialize a new message to the repository if one doesn't exist, yet.
     * */ 
    getDraftForRoomId( roomId:string ){
        this._maybeInitializeDraftMessageByRoomId( roomId );
        const result = this._drafts.get( roomId );
        assert( result );
        return result;
    }
    /** initializes the draft message for this room if it doesn't exist yet */
    _maybeInitializeDraftMessageByRoomId(roomId:string){
        if( this._drafts.has(roomId) ){
            return;
        }

        const newDraft:ChatMessageRecordPartForPost = {
            roomId,
        };
        
        this._drafts.set(
            roomId,
            newDraft
        );
    }

    async deleteByRoomId( roomId:string ){
        this._drafts.delete( roomId );
    }
}