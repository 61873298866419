import "styled-components/macro";

import  { useState } from "react";
import {  useSystemContext, useTheme } from "../../logic/SystemContextComponents";


import { css } from "styled-components";
import { useMySystemAccount } from "../../logic/accounts/SessionState";
import { ChatRoomPanelComponent } from "./ChatRoomPanelComponent";
import { ChatRoomsPanelComponent } from "./ChatRoomsPanelComponent";


export function ChatPanelContentsComponent(){
    const systemContext = useSystemContext();
    const {translate} = systemContext;
    const {messageRepository} = systemContext.messageSystem;
    const {sessionState} = systemContext;
    const theme = useTheme();
    const systemAccount = useMySystemAccount();

    const [activeRoomId,setActiveRoomId] = useState<string>();
    
    if( !sessionState.isLoggedIn ){
        return <div>({translate("t$loggedOutOfChat")})</div>
    }

    return <div css={Layout.Root} >
        <ChatRoomsPanelComponent
            setActiveRoomId={setActiveRoomId}
        />
        <ChatRoomPanelComponent
            myId={systemAccount?.id}
            roomId={activeRoomId}
            messageRepository={messageRepository}
        />
    </div>;
}

const Layout = {
    Root: css`
        flex-direction: column;
        align-items: center;
        justify-content: flex;
        padding: 1vmin;
        & > * {
            width: 100%;
        }
        & > *:first-child {
            height: 33%;
        }
        & > *:not(:first-child){
            height: 67%;
        }
    `,
    ChatTypeSelectionBar: css`
        display: flex;
        justify-content: space-around;
        width: 100%;
        & button {
            width: 33%;
        }
    `
}