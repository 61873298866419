import * as Three from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import Node from "../nodes/Node";
import KioskNode from "../booths/KioskNode";
import NodeMessageControls from "../nodes/NodeMessageControls";
import FileDisplayerNode from "../booths/subObjects/types/FileDisplayerNode";
import ModelViewerNode from "../booths/subObjects/types/ModelViewerNode";
import { IsRemoteFileId, RemoteFileId } from "../booths/subObjects/types/FileLike";
import BoothSubObjectNode from "../booths/subObjects/BoothSubObjectNode";
import { BoothSubObjectEntity } from "../booths/BoothSubObjectEntity";

/**
 * The basic behaviors associated with walking around the expo
 * When you click on objects, will inspect them, etc..
 */
export class ExpoBehaviorNode extends Object3DNode{

    
    constructor( systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );
    }

    Message__ActOnObject(controls:NodeMessageControls, target:Node){
        controls.stop();

        // TODO: move these into separate behaviors, as children of this behavior

        if(target instanceof KioskNode){
            const {boothEntity} = target;
            if( boothEntity.resolvedDescription!==undefined ){
                this.systemContext.dialogSystem.showDialog({boothEntity});
            }
        }

        if(
            (target instanceof ModelViewerNode) ||
            (target instanceof FileDisplayerNode)
        ){
            const {file} = target;
            if(!IsRemoteFileId(file)){
                return;
            }
            const fileId = file as RemoteFileId;

            let boothSubObjectEntity:BoothSubObjectEntity|undefined;
            const maybeBoothSubObjectNode = target.messageTargetNode;
            if( maybeBoothSubObjectNode instanceof BoothSubObjectNode ){
                boothSubObjectEntity = maybeBoothSubObjectNode.boothSubObjectEntity;
            }
            const lights = boothSubObjectEntity?.record.modelViewerLighting;
            this.systemContext.dialogSystem.showDialog({ fileId , boothSubObjectEntity, lights });
        }
    }
    
}