import "styled-components/macro";

import { css } from "styled-components";
import Button from "../../components/common/Button";
import { MenuHeader } from "../../components/common/MenuHeader";
import { MarketplaceOfferRecord } from "../../libraries/l2-common/l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
import { OfferEntity } from "../../marketplace/OfferEntity";
import { useSystemContext, useTheme } from "../SystemContextComponents";

export function Offer({includes,offerRecord,onLink}:{
    includes?:boolean,
    offerRecord:MarketplaceOfferRecord,
    onLink:(offer:OfferEntity)=>any
}){
    const offer = new OfferEntity(offerRecord);
    const theme = useTheme();
    const {client} = useSystemContext();

    return <div css={Layout.Item+""+theme.Card.Normal}>
    <div css={Layout.ItemTextBlock}>
        <MenuHeader layout={Layout.MenuHeader}>
        {offer.title}
        </MenuHeader>
    </div>
    {offer.record.attachmentFileIds.map(attribute=>{
        const src = client.urls.storage.files.byId(attribute).get();
        return <img css={Layout.ItemImage} src={src} />;
    })}
    {/* <div css={Layout.ItemTextBlock}>
        Title: {offer.title} 
    </div> */}
    {/* {offer.attributes.map(attribute=>
    <div css={Layout.ItemTextBlock}>
        {attribute.name}: {attribute.value}
    </div>)} */}
    {/* <div css={Layout.ItemTextBlock}>
        Description: {offer.description}
    </div> */}
    {
        includes ? 
        <Button onClick={()=>onLink(offer)}>Unlink</Button> : 
        <Button onClick={()=>onLink(offer)}>Link</Button>
    }
    {/* <Button>
    <a href={offer.marketplaceUrl} target="_blank">
        Open in Marketplace
    </a></Button> */}
    {/* <div css={Layout.ItemTextBlock}>
        Data: {JSON.stringify(offer.record)}
    </div> */}
</div>;
}



const Layout = {
    MenuHeader: css`
        font-size: 1em;
    `,
    Item: css`
        margin: 1em;
        padding: .5em;
        /* width: 100%; */
        width: 15em;
        height: 15em;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* cursor:pointer; */
        &:hover{
            /* background-color: #ffffff44; */
        }
    `,
    ItemImage: css`
        height: 5em;
        width: auto;
        border-radius: 1em;
        margin-bottom: 2em;
    `,
    ItemTextBlock: css`
        width:100%;
        max-width: 45%;
        margin-bottom: 1em;
    `,
}