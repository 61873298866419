import { AlwaysStencilFunc, Box3, Euler, Mesh, Object3D, Scene, Vector3 } from "three";
import * as Three from "three";
import Object3DNode from "../../../nodes/Object3DNode";
import SystemContext from "../../../SystemContext";
import NodeMessageControls from "../../../nodes/NodeMessageControls";
import Resources from "../../../Resources";
import Node from "../../../nodes/Node";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { BoothSubObjectEntity } from "../../BoothSubObjectEntity";
import { ExpoBoothRecordObjectRecord } from "../../../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import { FileLike, IsRemoteFileId, RemoteFileId } from "./FileLike";


const MODEL_ROTATION_SPEED_RADIANS_PER_SECOND = .3;
// made so that if the model is rotated exactly 45 degrees, it'll still fit inside the 1x1x1 glass case
const MODEL_SCALE_COMPARED_TO_GLASS_CASE = Math.sin(Math.PI/4);

const TEST_MODE = true;

/** Currently will always be podium-style. */
export default class ModelViewerNode extends Object3DNode{
    
    readonly file?:FileLike;

    private _modelNode?:Object3DNode;
    // TODO: dispose this
    private _loadedGLTF?:GLTF;

    constructor( file:FileLike|undefined, systemContext:SystemContext, messageTargetNode?:Node ){
        super(
            new Three.Object3D(),
            systemContext,
            messageTargetNode
        );

        // if( TEST_MODE ){
        //     // TODO: remove this, this is just for testing
        //     // fileInfo.id = "611a1b9e630bcfb64baaecdd"; // non-model file id
        //     fileInfo.id = "61948138731147972b636855";
        //     fileInfo.name = "Booth4_White_LowPoly.gltf"
        //     fileInfo.id = "619482d0731147972b636857";
        //     fileInfo.name = "Liberty_Bld_6.gltf"
        // }

        this.file = file;

        this._addPodiumModel(systemContext);

        this._refreshAsync();
    }

    _addPodiumModel( systemContext:SystemContext ){
        const podiumMaterial = new Three.MeshStandardMaterial({
            color: "#8d8d8d"
        });
        
        const podiumBottomMesh = new Mesh(
            new Three.BoxGeometry( .85,.8,.85 ),
            podiumMaterial
        );
        const podiumBottomNode = new Object3DNode(
            podiumBottomMesh,
            systemContext,
            this.messageTargetNode
        );
        podiumBottomNode.object3D.position.set(0,.8/2,0);
        this.addChild( podiumBottomNode );

        const podiumTopMesh = new Mesh(
            new Three.BoxGeometry( 1,.2,1 ),
            podiumMaterial
        );
        const podiumTopNode = new Object3DNode(
            podiumTopMesh,
            systemContext,
            this.messageTargetNode
        );
        podiumTopNode.object3D.position.set(0,.8+.2/2,0);
        this.addChild( podiumTopNode );

        // The purpose of this is to capture mouse clicks on the model.
        // You can alternatively make this competely transparent.
        const glassCaseModel = new Mesh(
            new Three.BoxGeometry( 1,1,1 ),
            new Three.MeshPhongMaterial( { color: "white", opacity: .2, transparent:true } )
        );
        const glassCaseNode = new Object3DNode(
            glassCaseModel,
            systemContext,
            this.messageTargetNode,
        );
        glassCaseNode.object3D.position.set(0,1.5,0);
        this.addChild( glassCaseNode );

        this.object3D.scale.setScalar(1.2);
    }

    async _refreshAsync(){
        if( !IsRemoteFileId(this.file) ){
            return;
        }
        const fileId = this.file as RemoteFileId;
        const {urls} = this.systemContext.client;
        const gltfUrl = urls.storage.files.byId( fileId ).get();

        try{
            const gltf = await Resources.LoadGLTFAsync(gltfUrl);
            this._loadedGLTF = gltf;
            const gltfScene = gltf.scene.clone();

            // Get the extents of the model so that we can scale & center it
            const boundingBox = new Box3().setFromObject( gltfScene );
            const boundingBoxSize = boundingBox.getSize( new Vector3() );
            const boundingBoxSizeLargestDimension = Math.max( ...boundingBoxSize.toArray() );
            const modelScale = MODEL_SCALE_COMPARED_TO_GLASS_CASE / boundingBoxSizeLargestDimension;
            const modelHeight = boundingBoxSize.y * modelScale;
            
            const gltfNode = new Object3DNode( gltfScene, this.systemContext );
            gltfNode.object3D.scale.setScalar( modelScale );
            gltfNode.object3D.position.set( 0, 1+modelHeight/2 ,0 );

            this._modelNode = gltfNode;

            this.addChild( gltfNode );
        }catch( error ){
            console.log("ModelViewerNode: Unable to load GLTF file: "+JSON.stringify(fileId) );
        }
    }

    updateHook( deltaTime:number ){
        this._modelNode?.object3D.rotateY( deltaTime * MODEL_ROTATION_SPEED_RADIANS_PER_SECOND );
    }

    Message__Hover( messageControls:NodeMessageControls ){
        // this._lightUp();
        this.systemContext.threeSystem.setCanvasCursor("pointer");
    }

    Message__Unhover( messageControls:NodeMessageControls ){
        // this._dim();
        this.systemContext.threeSystem.setCanvasCursor("auto");
    }

    disposeHook(){
        // TODO: dispose of the loaded model
    }
    
}