import assert from "assert";
import SystemContext from "../SystemContext";

import MessageRepository from "./MessageRepository";
import MessageWatcher from "./MessageWatcher";
import DraftMessageRepository from "./DraftMessageRepository";
import RoomRepository from "./RoomRepository";
import { useSystemContext } from "../SystemContextComponents";


export default class MessageSystem{
    static readonly CONVERSATION_ID_SEPARATOR = ",";
    readonly systemContext;
    readonly roomRepository;
    readonly messageRepository;
    readonly messageWatcher;
    readonly draftMessageRepository;
    
    constructor( systemContext:SystemContext ){
        this.systemContext = systemContext;
        this.roomRepository = new RoomRepository( systemContext );
        this.messageRepository = new MessageRepository( systemContext );
        this.draftMessageRepository = new DraftMessageRepository( systemContext );
        this.messageWatcher = new MessageWatcher( this );
        this.messageWatcher.start();
    }
    
}



export function useMessageSystem(){
    return useSystemContext().messageSystem;
}

