import Object3DNode from "../../../nodes/Object3DNode";
import SystemContext from "../../../SystemContext";
import NodeMessageControls from "../../../nodes/NodeMessageControls";
import FileDisplayerNode from "./FileDisplayerNode";
import Node from "../../../nodes/Node";
import { Object3D } from "three";
import LocalFileWrapper from "../../../files/FileWrapper";
import { FileLike } from "./FileLike";

/** A FileDisplayerNode that can be acted on by the player. */
export default class FileViewerNode extends Object3DNode{

    private _displayNode;

    constructor(
        file:FileLike|undefined,
        textureMaxHeight:number,
        systemContext:SystemContext,
        messageTargetNode?:Node
    ){
        super(
            new Object3D(),
            systemContext,
            messageTargetNode
        );

        this._displayNode = new FileDisplayerNode(
            file,
            textureMaxHeight,
            systemContext,
            messageTargetNode
        );
        this.addChild( this._displayNode );
        
        this._displayNode.dim();
    }

    Message__Hover( messageControls:NodeMessageControls ){
        this._displayNode.lightUp();
        // TODO: move this to the player's code
        this.systemContext.threeSystem.setCanvasCursor("pointer");
        messageControls.stop();
    }

    Message__Unhover( messageControls:NodeMessageControls ){
        this._displayNode.dim();
        // TODO: move this to the player's code
        this.systemContext.threeSystem.setCanvasCursor("auto");
        messageControls.stop();
    }
    
}