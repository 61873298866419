import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";


    /** If provided, lookAt will be applied after the transform. */
export interface CameraAnimationFrame extends RightHanded3DTransform{
    lookAt?:[number,number,number]
}

export class CameraAnimation{
    readonly duration;

    constructor(duration:number){
        this.duration=duration;
    }
    elapsedTimeToTransform(elapsedTime:number):CameraAnimationFrame{
        throw new Error("Not implemented.")
    }
}