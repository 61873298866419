import Object3DNode from "../../../nodes/Object3DNode";
import SystemContext from "../../../SystemContext";
import { BoxGeometry, DoubleSide, Mesh, MeshStandardMaterial, Object3D, PlaneGeometry, Texture } from "three";
import { FileLike, IsRemoteFileId, RemoteFileId } from "./FileLike";
import { TextureUrlToCanvasImageDataAsync } from "../../../utility/Images";
import NodeMessageControls from "../../../nodes/NodeMessageControls";
import Node from "../../../nodes/Node";
import LocalFileWrapper from "../../../files/FileWrapper";
import FileDisplayerNodeLoadingWidget from "./FileDisplayerNodeLoadingWidget";
import Resources from "../../../Resources";
type ImageData = [Uint8ClampedArray, number, number];

const DEBUG_SHOW_COLLISION_BOXES = false;

/** Shows the file as a wall-like cutout object. */
export default class CutoutNode extends Object3DNode{

    private readonly _displayNode:Object3DNode;
    private readonly _displayMesh;
    private readonly _file;
    private _loadedTexture?:Texture;
    public hideUntilLoaded(){
        this._hideUntilLoaded = true;
        if( !this._loadedTexture ){
            this._displayNode.object3D.visible = false;
        }
    }
    private _hideUntilLoaded:boolean = false;
    private readonly _textureMaxHeight:number = 512;
    private _collisionNodes:Node[] = [];

    constructor(
        file:FileLike|undefined,
        textureMaxHeight:number,
        systemContext:SystemContext,
        messageTargetNode?:Node
    ){
        super(
            new Object3D(),
            systemContext,
            messageTargetNode,
        );
        this._file = file;

        this._displayMesh = new Mesh(
            new PlaneGeometry( 1,1 ),
            new MeshStandardMaterial({
                transparent:true,
                side: DoubleSide,
            })
        );
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
        );
        this._displayNode.collisionInfo = {
            ignoreCollisions:true,
        };
        this._displayNode.object3D.rotateY(-Math.PI/2);
        
        this.addChild( this._displayNode );

        this._displayNode.object3D.position.y += .5;

        this._loadAsync();

        this.dim();
    }

    async setTextureUrlAsync(textureUrl:string){
        await this.loadTextureByUrlAsync(textureUrl);
        await this.loadCollisionsByUrlAsync(textureUrl);
    }

    private async _loadAsync(){
        if(!this._file){
            return;
        }
        const textureUrlTransparent = await this.getTextureUrlWithTransparencyAsync();
        await this.loadCollisionsByUrlAsync(textureUrlTransparent);
        await this.loadTextureByUrlAsync(textureUrlTransparent);
    }

    private async loadCollisionsByUrlAsync(textureUrlTransparent: string) {
        for(const node of this._collisionNodes){
            node.destroy();
        }
        const imageData = await this.getImageDataByUrlAsync(textureUrlTransparent);
        const segments = ImageDataToCollisionSegments(imageData);
        for (const segment of segments) {
            const collisionMesh = new Mesh(
                new BoxGeometry(.01, 1, 1),
                new MeshStandardMaterial({
                    visible: DEBUG_SHOW_COLLISION_BOXES,
                })
            );
            const collisionNode = new Object3DNode(
                collisionMesh,
                this.systemContext,
                this.messageTargetNode || this
            );
            const width = segment.end - segment.start;
            collisionNode.object3D.scale.z = width;
            collisionNode.object3D.position.z = -.5 + segment.start + width / 2;
            collisionNode.object3D.position.y = .5;
            this.addChild(collisionNode);
            this._collisionNodes.push(collisionNode);
        }
    }

    private async getTextureUrlWithTransparencyAsync(){
        if( IsRemoteFileId(this._file) ){
            const fileId = this._file as RemoteFileId;
            const {urls} = this.systemContext.client;
            return urls.storage.files.byId( fileId ).get();
        }else if(this._file instanceof LocalFileWrapper){
            return await this._file.maybeGetThumbnailUrlAsync();
        }else{
            throw Error("Not a remote or local file: "+JSON.stringify(this._file));
        }
    }

    private async getImageDataByUrlAsync(url:string){
        const [array,width,height] = await TextureUrlToCanvasImageDataAsync(url,64,64);
        return [array,width,height] as ImageData;
    }

    private async loadTextureByUrlAsync(textureUrl:string){
        const loadingWidget = new FileDisplayerNodeLoadingWidget( this.systemContext );
        this._displayNode.addChild( loadingWidget );

        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }

        try{
            const texture = await Resources.LoadTextureAsync( textureUrl );
            
            this._loadedTexture = texture;
            
            this._displayMesh.material.map = texture;
            this._displayMesh.material.needsUpdate = true;

            this._updateScaleAccordingToAspectRatio(
                texture.image.width / texture.image.height
            );

            if( this._hideUntilLoaded ){
                this._displayNode.object3D.visible = true;
            }
        }catch(error){
            // Sometimes the fileId will no longer be on the server, eg it was deleted.
            return;
        }finally{
            loadingWidget.destroy();
        }
    }

    _updateScaleAccordingToAspectRatio( aspectRatio:number ){
        // if enabling this, you'll need to scale the collisions accordingly
        return;
        if( aspectRatio < 1 ){
            this._displayNode.object3D.scale.set(
                1, 1, aspectRatio
            );
        }else if( aspectRatio > 1 ){
            this._displayNode.object3D.scale.set(
                1, 1/aspectRatio, 1
            );
        }else{
            this._displayNode.object3D.scale.set(
                1, 1, 1
            );
        }
    }

    disposeHook(){
        if( this._loadedTexture ){
            Resources.MaybeDisposeTexture(this._loadedTexture);
        }
    }


    Message__Hover( messageControls:NodeMessageControls ){
        this.lightUp();
        // TODO: move this to the player's code
        this.systemContext.threeSystem.setCanvasCursor("pointer");
        messageControls.stop();
    }

    Message__Unhover( messageControls:NodeMessageControls ){
        this.dim();
        // TODO: move this to the player's code
        this.systemContext.threeSystem.setCanvasCursor("auto");
        messageControls.stop();
    }

    /** Makes the node a bit darker. */
    dim(){
        this._displayMesh.material.color.set(0xDDDDDD);
    }

    /** If previously dimmed, makes the node light again. */
    lightUp(){
        this._displayMesh.material.color.set(0xFFFFFF);
    }
}

type Segment = {
    start:number,
    end:number,
}
function ImageDataToCollisionSegments(imageData: ImageData) {
    const [array,width,height] = imageData;
    const ys = [];
    for(let i=Math.ceil(height*.45);i>=1;--i){
        ys.push(height-i);
    }
    const threshold = 128;
    const segments:Segment[]=[];
    let wipSegment:Segment|undefined;
    const bottomRow = [];
    for(let x=0;x<width;++x){
        const alpha = Math.max(...ys.map(y=>array[y*width*4+x*4+3]));
        bottomRow.push(alpha);
        const solidPixel = alpha >= threshold;
        const pixelStartX = (x/width);
        const pixelEndX = ((x+1)/width);

        if( solidPixel ){
            if(!wipSegment){
                wipSegment = {
                    start:pixelStartX,
                    end:pixelEndX,
                }
            }
            wipSegment.end = pixelEndX;
        }else{
            if(wipSegment){
                segments.push(wipSegment);
            }
            wipSegment = undefined;
        }
    }
    if(wipSegment){
        segments.push(wipSegment);
    }
    return segments;
}
