import "styled-components/macro";

import React from "react";
import { Link } from "react-router-dom";

import {GET_BY_BOOTHTYPEID_AND_VIEW, VIEW_FRONT} from "../../images/booth-backgrounds";

import {useSystemContext, useTheme} from "../../logic/SystemContextComponents";

import { css } from "styled-components";
import { MapEntity } from "../../logic/maps/MapEntity";
import { BoothEntity } from "../../logic/booths/BoothEntity";
import { VenueBoothMapping } from "../../libraries/l2-common/l2-common-ts/resources/exhibition/Venues";
import { ExpoMapRecordMappingItem } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoMapRecord";
import { MaybeTeleportPlayerToBoothById } from "../../logic/utility/GameLogicHelpers";
import SystemContext from "../../logic/SystemContext";

/** Displays one booth in a list of booths for an exhibition's map. */
export default function BoothItemComponent( {
    mapping,
    map,
    booth
}:{
    mapping:ExpoMapRecordMappingItem,
    map:MapEntity,
    booth:BoothEntity
} ){
    const theme = useTheme();
    const systemContext = useSystemContext();
    const {client} = useSystemContext();

    const [boothSignImageFailedToLoad, setBoothSignImageFailedToLoad] = React.useState(false);

    /**
     * We'll either show
     *  - booth's sign image
     *  - booth's name on top of a generic booth background image
     */

    const {signageFileId} = booth.record;

    const showBoothSignInsteadOfName = ( signageFileId && !boothSignImageFailedToLoad );

    const boothSignageImageUrl = client.urls.storage.files.byId(signageFileId||"").get();

    const backgroundImageCss = css`

        background-position: center;
        background-size: cover;

        ${ `; background-image: url("${(
            showBoothSignInsteadOfName ? 
            boothSignageImageUrl :
            GET_BY_BOOTHTYPEID_AND_VIEW(booth.record.typeId, VIEW_FRONT)
        )}");` } ;

    `;

    const themeCss = showBoothSignInsteadOfName ? theme.Card.Normal : theme.Card.Image;

    return <div css={Layout.Root+''+themeCss+backgroundImageCss}>
        {
            // If we're loading a signage file, we use this img to detect a 404
            signageFileId &&
            <img
                style={{display:"none"}}
                src={boothSignageImageUrl}
                onError={e=>setBoothSignImageFailedToLoad(true)}
                alt="signage"
            />
        }
        <Link
            to={"/maps/"+map.record.id+"/booths/"+booth.record.id}
            css={Layout.Link}
            onClick={()=>{
                MaybeTeleportPlayerToBoothById( booth.record.id, systemContext );
            }}
        >
            { showBoothSignInsteadOfName ? "" : booth.resolvedTitle}
        </Link>
    </div>
}

const Layout = {
    Root: css`
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        
        min-height: 5vmax;
        width: 90%;

        margin-top: 1vmin;
        margin-bottom: 1vmin;


        & *{
        }

        & a {
            // TODO: move responsibility
            
            display:flex;
            align-items: center;
            justify-content: center;

        }
    `,
    Link:css`
        width: 100%;
        height: 100%;
    `,
}


            /* color: ${({theme}:{theme:any})=> theme.color.contrastOverImageColor }!important; */
            /* text-decoration: none; */
            /* font-size: 1vmax; */
            /* text-shadow: 2px 2px 5px black; */
            /* text-transform: uppercase; */