import { useResult, useResultOnMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useMySystemAccount } from "../accounts/SessionState";
import { useSystemContext } from "../SystemContextComponents";

export function useBoothRepository(){
    const context = useSystemContext();
    return context.boothRepository;
}

export function useMyBooths(){
    const myAccount = useMySystemAccount();
    const repository = useBoothRepository();
    return useResult(
        ()=> myAccount ? repository.getManyByOwnerAsync(myAccount.id) : undefined,
        [myAccount?.id]
    );
}

