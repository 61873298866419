import "styled-components/macro";


import React from "react";
import { useSystemContext } from "../../logic/SystemContextComponents";
import { css, CSSProp } from "styled-components";

import DEFAULT_AVATAR_IMAGE from "../../images/avatars/default.png";

const Layout = css`
    /* width: 2vmin;
    height: 2vmin;
    border-radius: 1vmin;
    padding: 0vmin .5vmin; */
`;

export default function UserAvatarComponent( {
    avatarFileId,
    layout,
}: {
    avatarFileId?:string
    layout?:CSSProp,
} ){
    const {client} = useSystemContext();

    const [src, setSrc] = React.useState( 
        avatarFileId ? 
        client.urls.storage.files.byId(avatarFileId).get() :
        DEFAULT_AVATAR_IMAGE
    );

    return <img
            css={Layout+''+layout}
            onError={ ()=>setSrc(DEFAULT_AVATAR_IMAGE) }
            src={src}
            alt="avatar"
        />
}