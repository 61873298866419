/** Pulls the ID from the URL. For a REST-like API.
 * Example:
 * UrlToResourceId("https://api.com/foos/123/bars/456?q=baz","foos") returns "123"
  */
export function UrlToResourceId(url:string,resourceName:string){
    if( url.includes("/"+resourceName+"/") ){
        return url.split("/"+resourceName+"/")[1].split("/")[0];
    }
    return undefined;
}

/** Pulls the IDs from the URL. For a REST-like API.
 * Example: 
 * const [fooId,barId] = UrlToResourceIds("api.com/foos/123/bars/456", ["foos","bars"] )
  */
export function UrlToResourceIds(url:string,resourceNames:string[]){
    return resourceNames.map( a=>UrlToResourceId( url, a ) );
}

/** Pulls the ID from the URL. For a REST-like API.
 * Example (window url is "https://api.com/foos/123/bars/456?q=baz"):
 * useUrlResourceId("foos") returns "123"
 */
export function UseUrlResourceId(resourceName:string){
    const url = (""+window.location);
    return UrlToResourceId(url,resourceName);
}