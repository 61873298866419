import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { ExpoAdRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoAdRecord";
import { PREFERRED_LANGUAGE_IDS_TODO } from "../messages/ChatRoomEntity";

export class AdEntity{
    readonly record;

    constructor(record:ExpoAdRecord){
        this.record = record;
    }

    get resolvedTitle(){
        if(!this.record.title){
            return undefined;
        }
        return MaybeResolveStringWithTranslations(
            this.record.title,
            PREFERRED_LANGUAGE_IDS_TODO
        );
    }

}