import { BoxGeometry, Euler, Material, Mesh, MeshBasicMaterial, Object3D, RectAreaLight, RepeatWrapping, Texture, Vector2, Vector3 } from "three";
import * as Three from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import Resources from "../Resources";

/** Just hard-baking the images in for now, we can dynamically load them later if needed. */
import back from "../../images/skyboxes/day_sky/day_sky_back.jpg";
import front from "../../images/skyboxes/day_sky/day_sky_front.jpg";
import left  from "../../images/skyboxes/day_sky/day_sky_left.jpg";
import right from "../../images/skyboxes/day_sky/day_sky_right.jpg";
import up    from "../../images/skyboxes/day_sky/day_sky_up.jpg";
import down  from "../../images/skyboxes/day_sky/day_sky_down.jpg";
const sideTextureUrls = [front, back, up , down , left, right];

interface Options{
    /** default: true */
    rotating:boolean,
}
export default class SkyboxNode extends Object3DNode{
    
    readonly options;
    constructor( systemContext:SystemContext, options?:Options ){
        super(
            new Three.Object3D(),
            systemContext
        );
        this.options=options||{
            rotating:true
        };
        this._loadAsync();
    }

    updateHook( deltaTime:number ){
        if(this.options.rotating){
            // rotate very, very slowly
            this.object3D.rotateY( deltaTime * .001 );
        }
    }
    
    async _loadAsync(){
        const materials:Material[] = [];
        for(const textureUrl of sideTextureUrls){
            const texture = await Resources.LoadTextureAsync( textureUrl );
            
            /* Because we're putting the texture on the backside, we have to flip it */
            texture.wrapS = Three.RepeatWrapping;
            texture.repeat.x = -1;

            /* Also, the top of the box has to be rotated 90 degrees*/
            if( textureUrl===up){
                texture.center = new Vector2(.5,.5);
                texture.rotation = Math.PI/2;
            }

            const material = new MeshBasicMaterial({
                map: texture,
                side: Three.BackSide /* so that the sides of the cube face inward */,
            });
            materials.push( material );
        }

        const cubeWidth = 300;
        const mesh = new Mesh(
            new BoxGeometry(cubeWidth,cubeWidth,cubeWidth),
            materials
        );
        
        const node = new Object3DNode(
            mesh,
            this.systemContext
        );

        this.addChild( node );

    }



    

}