import "styled-components/macro";

import { css } from "styled-components";
import Button from "../common/Button";
import { MenuHeader } from "../common/MenuHeader";
import { MenuSubHeader } from "../common/MenuSubHeader";
import { useConditionalComponent, useForceUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext } from "../../logic/SystemContextComponents";
import { useDialogSystem } from "../../logic/dialogs/DialogSystem";
import { TextLineInput } from "../common/TextLineInput";
import { SettingId, Settings } from "../../logic/Settings";
import { useState } from "react";
import { NumberInput } from "../common/NumberInput";
import { Checkbox } from "../common/Checkbox";

/**
 * A dialog that lets you change settings for the app.
 */
export function SettingsDialogContents({
}:{
}){
    const {translate,settings} = useSystemContext();

    const forceUpdate = useForceUpdate();
    const dialogSystem = useDialogSystem();

    const hasChanges = settings.localSettingsAreDifferentFromCachedRemote;
    
    const [waitingComponent,setWaiting] = useConditionalComponent(
        <>Working...</>,
        false
    );

    return <><div css={Layout.Root}>
        <MenuHeader>{translate("t$settingsDialogTitle")}</MenuHeader>
        {settings.all.map( ({id,type,value})=><>
            <MenuSubHeader>{translate("t$settingName["+id+"]")}</MenuSubHeader>
            {
                type==="string"?(
                    <TextLineInput
                        value={value as String}
                        setValue={(value)=>{
                            settings.update(id as SettingId,value);
                            forceUpdate();
                        }}
                    />
                ):type==="number"?(
                    <NumberInput
                        defaultValue={Settings.Defaults[id] as number}
                        allowNegative={false}
                        allowZero={true}
                        value={value as number}
                        setValue={(value)=>{
                            settings.update(id as SettingId,value);
                            forceUpdate();
                        }}
                    />
                ):type==="boolean"?(
                    <Checkbox
                        value={value as boolean}
                        setValue={(value)=>{
                            settings.update(id as SettingId,value);
                            forceUpdate();
                        }}
                    />
                ):<></>
            }
        </>)}

        {
            waitingComponent ? waitingComponent : <>
                <Button
                    disabled={!hasChanges}
                    onClick={async ()=>{
                        setWaiting(true);
                        await settings.saveAsync();
                        setWaiting(false);
                }}>{translate("t$save")}</Button>

                <Button onClick={()=>{
                    dialogSystem.closeDialog();
                }}>{translate("t$close")}</Button>
            </>
        }
        
    </div></>;
}

const Layout = {
    Root: css`
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > *{
            margin-bottom: 1em;
        }
    `,
}