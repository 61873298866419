import "styled-components/macro";

import React from "react";
import { WebGLRenderer } from "three";
import {SystemContextReactContext} from "../../logic/SystemContextComponents";
import * as Three from "three";
import Resources from "../../logic/Resources";
// import TEXTURE_URL from "../../images/logos/liberty-logo--square.png";
import Spacer from "./Spacer";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { css } from "styled-components";
import { Theme } from "../../theme/Theme";
import config from "../../config/config";

const VIDEO = `${config.staticContentUrl}videos/liberty_skyview_source.mp4`;

const GLTF_URL = `${config.staticContentUrl}models/Liberty_Logo_03.gltf`;

// TODO: get this dynamically
const size = [400,400];




/** Will fill the panel (div) it's placed in with a fancy loading message. */
// export default function LoadingWidgetPanelContents(){
//     const {systemContext:{translate}} = React.useContext( SystemContextReactContext );

    

//     return <div className="MapSelectPanelContentsComponent">
//         {translate("t$loadingDotDotDot")}
//     </div>
// }

/** Will fill the panel (div) it's placed in with a fancy loading message. */
export default class LoadingWidgetPanelContents extends React.Component{
    containerElement?:HTMLDivElement|null;

    renderer?:WebGLRenderer;
    scene = new Three.Scene();
    camera = new Three.PerspectiveCamera( 70, 1 );
    
    horizontalRotationHelper = new Three.Object3D();
    verticalRotationHelper = new Three.Object3D();

    // TODO: dispose this
    private _loadedGLTF?:GLTF;

    static contextType=SystemContextReactContext;

    threeJsAnimationLoop( time:number ){
        this.update( time );
        this.renderer?.render( this.scene, this.camera );
    }

    update( time:number ){
        // Spin the logo around
        // this.horizontalRotationHelper.rotation.y = Math.PI/2 + Math.PI * Math.sin( time * .001 );
        this.horizontalRotationHelper.rotation.y = Math.PI/2 + (time*.002) % (Math.PI*2);
    }

    async componentDidMount(){
        await this.loadTextureAsync();
        this.initializeThreeJs();
    }

    async loadTextureAsync(){
        // const texture = await Resources.LoadTextureAsync( TEXTURE_URL );

        
        // const mesh = new Three.Mesh(
        //     new Three.BoxGeometry( .01,1,1 ),
        //     new Three.MeshStandardMaterial({transparent:true})
        // );

        
        // mesh.material.map = texture;
        // mesh.material.needsUpdate = true;
        
        const gltf = await Resources.LoadGLTFAsync( GLTF_URL );
        this._loadedGLTF = gltf;
        const gltfScene = gltf.scene.clone();
        gltfScene.scale.setScalar(.1);
        // gltfScene.position.y = -.1;

        this.horizontalRotationHelper.add( gltfScene );
        this.verticalRotationHelper.add( this.horizontalRotationHelper );
        this.scene.add( this.verticalRotationHelper );

        // Start with a bit of rotation
        // this.horizontalRotationHelper.rotateY( Math.PI/4 );
        // this.verticalRotationHelper.rotateX( Math.PI/4 );
     
        const ambientLight = new Three.AmbientLight(0xFFFFFF, .8);
        this.scene.add( ambientLight );

        const directionalLight = new Three.DirectionalLight(0xFFFFFF, .8);
        directionalLight.position.set(0,0,4);
        directionalLight.target.position.set(0,0,0)
        this.scene.add( directionalLight );
        this.scene.add( directionalLight.target );

    }

    initializeThreeJs(){
        // TODO: get this dynamically (clientWidth is 0 when this code runs, currently)
        // const size = [this.containerElement?.clientWidth||0,this.containerElement?.clientHeight||0];

        this.camera = new Three.PerspectiveCamera( 70, size[0]/size[1] );
        this.camera.position.z = 1.5;

        const renderer = new Three.WebGLRenderer({alpha:true,antialias:true});
        renderer.setSize( size[0],size[1] );
        // renderer.setClearColor("#000000", .2);

        this.containerElement?.appendChild( renderer.domElement );

        // Disables the browser's right click menu from appearing when we click the threejs container element.
        // renderer.domElement.oncontextmenu = ()=>false;

        this.renderer = renderer;

        renderer.setAnimationLoop( time=>this.threeJsAnimationLoop(time) )

        const light = new Three.AmbientLight(0xFFFFFF, 2);
        this.scene.add(light);   
    }
    
    
    render(){
        const theme:Theme = this.context.theme;

        //     return <video src={VIDEO} css={VideoLayout} autoPlay={true} />

        return <div
            css={Layout}
            ref={ ref=>this.containerElement=ref }
        >
            <div>{this.context.systemContext.translate("t$loadingDotDotDot")}</div>
            <Spacer/>
        </div>
    }
}

const Layout = css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & canvas {
        // TODO: integrate into theme
        border-radius: 2rem;
    }
`;


const VideoLayout = css`
    width:100%;
    height:100%
`;