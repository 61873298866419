import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents";
import { RightHanded3DTransform } from "../../libraries/l2-common/l2-common-ts/definitions/general/RightHanded3DTransform";
import { useState } from "react";

export function Vector3Input(
    {
        value,
        setValue: setVectorValue,
        disabled,
        layout,
        /** eg if set to 2, a component 1.234 becomes 1.23 */
        decimalPlaces,
        /** for position, probably 0, for scale, probably 1 */
        defaultComponentValue,
    }:
    {
        value?:[number,number,number],
        setValue:(value:[number,number,number])=>void,
        disabled?:boolean,
        layout?:CSSProp,
        decimalPlaces?:number,
        defaultComponentValue:number,
    }
){
    const vectorValue = value || [defaultComponentValue,defaultComponentValue,defaultComponentValue];
    let [localStringValue,setLocalStringValue] = useState<[string,string,string]>(
        value?.map(a=>maybeTruncateDecimalDigits(""+a,decimalPlaces)) as [string,string,string] || ["","",""]
    )
    const theme = useTheme();

    return <div css={Layout.Root+''+layout}>
        {localStringValue.map((localStringValueComponent,index)=>(
            <input
                key={index}
                css={Layout.Component}
                placeholder={""+defaultComponentValue}
                value={localStringValueComponent}
                disabled={disabled}
                onChange={e=>{
                    let nextStringValue = maybeTruncateDecimalDigits(
                        e.target.value,
                        decimalPlaces
                    );
                    localStringValue[index] = nextStringValue;
                    setLocalStringValue([...localStringValue]);

                    const nextNumberValue = parseFloat(nextStringValue||"0");
                    const isUsable = !isNaN(nextNumberValue);
                    if( !isUsable ){
                        return;
                    }
                    vectorValue[index] = nextNumberValue;
                    setVectorValue([...vectorValue]);
                }}
            />
        ))}
    </div>
  
}

/** Because input is a "text-like" element (not a component), we, by default, size it based on local text, rather than relying on extenral sizing. */
const Layout = {
    Root: css`
        /* min-width: 3em; */
        height: 2em;
        padding: 0 1em;
    `,
    Component: css`
        width: 5ch;
    `,
}

function maybeTruncateDecimalDigits(
    value:string,
    truncateDecimalDigitsCount?:number,
){
    if(truncateDecimalDigitsCount===undefined){
        return value;
    }
    // if there's exacltly one ".", truncate any excess digits
    const split = value.split(".");
    if( split.length===2 ){
        split[1] = split[1].slice(
            0,
            truncateDecimalDigitsCount
        )
    }
    value = split.join(".");
    return value;
}