import SystemContext from "../SystemContext";
import { SceneNode } from "./SceneNode";
import assert from "assert";
import { AnimationMixer, Euler, Object3D, RectAreaLight, Vector3 } from "three";
import * as Three from "three";
import Object3DNode from "../nodes/Object3DNode";
import Resources from "../Resources";

import { degToRad } from "three/src/math/MathUtils";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import config from "../../config/config";

export class ModelTestSceneNode extends SceneNode{

    private readonly _modelUrl:string;

    /** Creates a new ModelTestingNode. Note that it will be empty until you call this.loadAsync() */
    constructor( modelUrl:string, systemContext:SystemContext ){
        super(systemContext);

        if(! modelUrl.includes("://") ){
            const MODELS_PATH = `${config.staticContentUrl}models/`;
            modelUrl = MODELS_PATH + modelUrl;
        }

        assert( modelUrl.toLowerCase().endsWith(".gltf") );

        this._modelUrl = modelUrl;
    }


    async loadAsync(){

        this._addLights();

        const {threeSystem} = this.systemContext;

        threeSystem.camera.position.set(0,3,3);
        threeSystem.camera.lookAt(0,0,0);
        

        this._addGrid();
        
        console.log( "LOADING TEST MODEL: "+this._modelUrl );

        
        const gltf = await Resources.LoadGLTFAsync(this._modelUrl);
        const object3dNode = new Object3DNode(
            gltf.scene,
            this.systemContext
        );
        this.addChild( object3dNode );
        

        // object3dNode.object3D.scale.setScalar(30);
        // object3dNode.object3D.position.setY(-2);

        console.log("LOADED MODEL. RUNNING CUSTOM TEST CODE...")

        await this._testCodeGoesHere(this._modelUrl, gltf, object3dNode);

        console.log("MODEL TEST COMPLETE.")

    }
    async unloadAsync(){
        
    }

    private async _testCodeGoesHere( modelUrl:string, gltf:GLTF, node:Object3DNode ){


        const {threeSystem} = this.systemContext;

        if( modelUrl.endsWith("Floor_Checkers.gltf")){
            console.log("RUNING CUSTOM TEST CODE FOR Floor_Checkers.gltf")
            // // console.log(wingsGltf.scene.children[0].children);
            const primary:any = gltf.scene.children[0].children.find(
                (child:any)=>child.material.name.includes("Primary_Color_Checkers")
            );
            const secondary:any = gltf.scene.children[0].children.find(
                (child:any)=>child.material.name.includes("Secondary_Color")
            );
            // console.log( primary );
            // console.log( secondary );
            primary.material.color.set(0x00FF00);
            secondary.material.color.set(0x0000FF);

            (<any>gltf.scene.children[0].children[1]).material.color.set(0x00FF00);
        }
        

        const meshes = gltf.scene.children[0].children;
        if( meshes.length ){
            console.log("DETECTED MESHES: "+meshes.map(_=>_.name).join(","));
        }

        if( modelUrl.endsWith("Business_Woman_01.gltf") ){
            console.log("RUNING CUSTOM TEST CODE FOR Business_Woman_01.gltf")

            // note: these meshes were missing textures in the original FBX, so we don't use them:
            // faceA--lod1, faceA--load2, faceB--lod1, faceB--lod2, faceE


            const targetLodLayer = "lod2";
            const targetMeshes = ["Jacket","shoes","skirt","body","legs","eyes","faceC","hairA"];
            console.log("SETTING MESHES: "+targetLodLayer+": "+targetMeshes.join(", "))
            for( const mesh of meshes ){
                const [modelName,partName,lodLayer] = mesh.name.split("--");
                mesh.visible = (
                    lodLayer===targetLodLayer &&
                    targetMeshes.some(_=>_===partName)
                );
            }
        }

        if( gltf.animations.length ){
            
            console.log("DETECTED ANIMATIONS: "+gltf.animations.map(_=>_.name).join(", ") );

            console.log( gltf.scene===node.object3D );
            const mixer = new AnimationMixer(node.object3D);
            const action = mixer.clipAction( gltf.animations[0] );
            action.setLoop( Three.LoopRepeat, 99999999 );

            action.play();
            console.log( action );

            for(let i=0; i<1000; ++i){
                setTimeout(() => {
                    mixer.update( .1 );
                }, (i*100));
            }
            


            threeSystem.camera.position.set(0,1,2);
            threeSystem.camera.lookAt(0,.75,0);

        }
    }

    


    /** Note: these are lights specifically for the "liberty" venue */
    private _addLights(){

        const lightColor = 0xFFFFFF;

        const directionalLight = new Three.DirectionalLight(0xFFFFFF, .8);
        directionalLight.position.set(5,30,1);
        directionalLight.target.position.set(0,0,0)
        const light = new Object3DNode( directionalLight, this.systemContext );
        this.addChild( light );
        const lightTarget = new Object3DNode( directionalLight.target, this.systemContext );
        this.addChild( lightTarget );
        
        const ambientLight = new Object3DNode(
            new Three.AmbientLight(lightColor, .8),
            this.systemContext
        );
        this.addChild( ambientLight );

        // Light up the people in the center
        for(let angleDegrees=0; angleDegrees<360; angleDegrees+=360/5){
            const lightDistance = 20;
            const pointLight = new Object3DNode(
                new Three.PointLight(lightColor, 30, lightDistance),
                this.systemContext
            );
            const position = new Vector3(0,20,-20);
            position.applyEuler( new Euler(0,degToRad(angleDegrees),0) );
            pointLight.object3D.position.copy( position );
            this.addChild(pointLight);


            const preview = Object3DNode.MakePlaceholderNode( this.systemContext );
            preview.object3D.scale.setScalar( lightDistance );
            preview.object3D.position.copy( position );
            // this.addChild( preview );
        }

    }

    private _addGrid(){

        const gridDimension = 20;
        for( let x=-gridDimension; x<=gridDimension; ++x){
            for( let z=-gridDimension; z<=gridDimension; ++z){
                const isAxis = x==0||z==0;
                const isOrigin = x==0&&z==0;
                if(isOrigin){
                    continue;
                }

                const node = Object3DNode.MakePlaceholderNode(this.systemContext);
                node.object3D.scale.setScalar( isAxis ? .1 : .05 );
                // node.object3D.scale.setScalar(1);
                node.object3D.position.set(x, 0, z);

                this.addChild( node );
            }
        }

    }
}