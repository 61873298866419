/**
    * WARNING: THIS IS AN AUTOMATICALLY-GENERATED FILE. DO NOT EDIT THIS FILE
    * */
       
    import WebClient from "./WebClient";

    import ApiError from "../../l2-common-ts/common/errors/ApiError";
   
    import { NotFoundResult,GoneResult } from "./ClientApiResults";
   
    import { AnalyticsAccessRecordPartForPost } from "../../l2-common-ts/definitions/analytics/AnalyticsAccessRecord";
    import { IpInfoRecordPartForPost } from "../../l2-common-ts/definitions/analytics/IpInfoRecord";
    import { ChatInviteRecordPartForPost } from "../../l2-common-ts/definitions/chat/ChatInviteRecord";
    import { ChatMessageRecord } from "../../l2-common-ts/definitions/chat/ChatMessageRecord";
    import { ChatMessageRecordPartForPost } from "../../l2-common-ts/definitions/chat/ChatMessageRecord";
    import { ChatRoomRecord } from "../../l2-common-ts/definitions/chat/ChatRoomRecord";
    import { ChatRoomRecordPartForPost } from "../../l2-common-ts/definitions/chat/ChatRoomRecord";
    import { ChatRoomRecordPartForNonParticipant } from "../../l2-common-ts/definitions/chat/ChatRoomRecord";
    import { ChatRoomRecordPatchDocument } from "../../l2-common-ts/definitions/chat/ChatRoomRecord";
    import { ExpoAccountAnnexRecord } from "../../l2-common-ts/definitions/expo/ExpoAccountAnnexRecord";
    import { ExpoAccountAnnexRecordPartForPut } from "../../l2-common-ts/definitions/expo/ExpoAccountAnnexRecord";
    import { ExpoAdRecord } from "../../l2-common-ts/definitions/expo/ExpoAdRecord";
    import { ExpoAdRecordPartForPost } from "../../l2-common-ts/definitions/expo/ExpoAdRecord";
    import { ExpoAdRecordPatchDocument } from "../../l2-common-ts/definitions/expo/ExpoAdRecord";
    import { ExpoBoothRecord } from "../../l2-common-ts/definitions/expo/ExpoBoothRecord";
    import { ExpoBoothRecordPartForPost } from "../../l2-common-ts/definitions/expo/ExpoBoothRecord";
    import { ExpoBoothRecordPatchDocument } from "../../l2-common-ts/definitions/expo/ExpoBoothRecord";
    import { ExpoMapRecord } from "../../l2-common-ts/definitions/expo/ExpoMapRecord";
    import { ExpoMapRecordPartForPost } from "../../l2-common-ts/definitions/expo/ExpoMapRecord";
    import { ExpoMapRecordPatchDocument } from "../../l2-common-ts/definitions/expo/ExpoMapRecord";
    import { FeedbackRecordPostBody } from "../../l2-common-ts/definitions/feedback/FeedbackRecord";
    import { MarketplaceAccountAnnexRecord } from "../../l2-common-ts/definitions/marketplace/MarketplaceAccountAnnexRecord";
    import { MarketplaceAccountAnnexRecordPartForPut } from "../../l2-common-ts/definitions/marketplace/MarketplaceAccountAnnexRecord";
    import { EmailSubscriptionRecordPartForPatch } from "../../l2-common-ts/definitions/system/EmailSubscriptionRecord";
    import { MarketplaceOfferInquiryRecord } from "../../l2-common-ts/definitions/marketplace/MarketplaceOfferInquiryRecord";
    import { MarketplaceOfferInquiryRecordPartForPost } from "../../l2-common-ts/definitions/marketplace/MarketplaceOfferInquiryRecord";
    import { SendEmailPost } from "../../l2-common-ts/definitions/marketplace/MarketplaceOfferInquiryRecord";
    import { MarketplaceOfferRecord } from "../../l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
    import { MarketplaceOfferRecordPartForPost } from "../../l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
    import { MarketplaceOfferRecordMergePatchDocument } from "../../l2-common-ts/definitions/marketplace/MarketplaceOfferRecord";
    import { OperationRecordPatchBody } from "../../l2-common-ts/definitions/operation/OperationRecord";
    import { OperationRecordPostBody } from "../../l2-common-ts/definitions/operation/OperationRecord";
    import { SystemBookmarkRecord } from "../../l2-common-ts/definitions/system/SystemBookmarkRecord";
    import { SocialCardRecord } from "../../l2-common-ts/definitions/social/SocialCardRecord";
    import { SocialCardRecordPartForPost } from "../../l2-common-ts/definitions/social/SocialCardRecord";
    import { SocialCardRecordPatchDocument } from "../../l2-common-ts/definitions/social/SocialCardRecord";
    import { FileMetadataRecordPartForFilePost } from "../../l2-common-ts/definitions/storage/FileMetadataRecord";
    import { FileMetadataRecord } from "../../l2-common-ts/definitions/storage/FileMetadataRecord";
    import { FileMetadataRecordMergePatchDocument } from "../../l2-common-ts/definitions/storage/FileMetadataRecord";
    import { SubscriptionTaskRecordPatchBody } from "../../l2-common-ts/definitions/subscription/SubscriptionTaskRecord";
    import { SubscriptionTaskRecordPostBody } from "../../l2-common-ts/definitions/subscription/SubscriptionTaskRecord";
    import { CollectionOfferInfo } from "../../l2-common-ts/definitions/subscription/collection/SubscriptionCollectionRecord";
    import { SystemAccountRecord } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountRecordPartForUsers } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountRecordPartForOwner } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountPostObject } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountRecordPartForPublic } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountRecordMergePatchDocument } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountAgreementEntry } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemAccountPasswordPut } from "../../l2-common-ts/definitions/system/SystemAccountRecord";
    import { SystemBookmarkRecordPartForPost } from "../../l2-common-ts/definitions/system/SystemBookmarkRecord";
    import { EmailSubscriptionRecord } from "../../l2-common-ts/definitions/system/EmailSubscriptionRecord";
    import { SystemSessionRecord } from "../../l2-common-ts/definitions/system/SystemSessionRecord";
    import { SystemSessionRecordPartForOwner } from "../../l2-common-ts/definitions/system/SystemSessionRecord";

    export const MakeClientApiObject = (webClient:WebClient)=>( {

    analytics: {
    
        access: {
        
            getAsync: /**
            * Accessible by anyone on the web.
            * 
            * GET:///analytics/access
            */
            async ()=>{
                const result = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `analytics/access/`,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Creates an access record
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///analytics/access
            */
            async (body:AnalyticsAccessRecordPartForPost)=>{
                const result = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `analytics/access/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
        },
    
        ipInfo: {
        
            getAsync: /**
            * Get all ipInfo record
            * 
            * Accessible by anyone on the web.
            * 
            * GET:///analytics/ipInfo
            */
            async ()=>{
                const result = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `analytics/ipInfo/`,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Creates ipInfo record
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///analytics/ipInfo
            */
            async (body:IpInfoRecordPartForPost)=>{
                const result = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `analytics/ipInfo/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byIp:  (ip:string|number)=>({
                
                    getAsync: /**
                    * Get ip info by ip
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * GET:///analytics/ipInfo/ip/${ip}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `analytics/ipInfo/ip/${ip}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
    },

    chat: {
    
        invites: {
        
            getAsync: /**
            * Accessible by anyone with an account.
            * 
            * GET:///chat/invites
            */
            async (query?:{recipientAccountId?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.recipientAccountId){
                    queryString += "&recipientAccountId="+query.recipientAccountId;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:({
            /** Unique immutable identifier, generated internally. */
            id: string, creationTimeSeconds: number, 
            /** Unique mutable identifier, for example "riktrix". When used to refer to an account record, this has the purpose of a "handle" (also known as a "username"). But non-account records may also have slugs, and they have the same exact purpose, so we're using a term that isn't specific to the concept of an account. Note that when patching a record's slug, you may receive a 4xx error if the slug is already in use, or if the slug is the ID of another resource (IDs are reserved for the object they are associated with). */
            slug: string, recipientAccountId: string, senderAccountId: string, roomId: string})[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `chat/invites/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Invites a user to a chat room.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///chat/invites
            */
            async (body:ChatInviteRecordPartForPost)=>{
                const result = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `chat/invites/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
        },
    
        messages: {
        
            getAsync: /**
            * Accessible by anyone with an account.
            * 
            * GET:///chat/messages
            */
            async (query?:{roomParticipantAccountId?:string, limit?:number})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.roomParticipantAccountId){
                    queryString += "&roomParticipantAccountId="+query.roomParticipantAccountId;
                    }
                if(query?.limit){
                    queryString += "&limit="+query.limit;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:ChatMessageRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `chat/messages/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Posts a message.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///chat/messages
            */
            async (body:ChatMessageRecordPartForPost)=>{
                const result:ChatMessageRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `chat/messages/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    deleteAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///chat/messages/id/${id}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `chat/messages/id/${id}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
        rooms: {
        
            getAsync: /**
            * Accessible by anyone with an account.
            * 
            * GET:///chat/rooms
            */
            async (query?:{withParticipantId?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.withParticipantId){
                    queryString += "&withParticipantId="+query.withParticipantId;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:ChatRoomRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `chat/rooms/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Creates a new chat room.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///chat/rooms
            */
            async (body:ChatRoomRecordPartForPost)=>{
                const result:ChatRoomRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `chat/rooms/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///chat/rooms/id/${id}
                    */
                    ()=>{
                    return {
                            fullAsync:async ()=>{
                    
                        try{
                                        const result:ChatRoomRecord  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `chat/rooms/id/${id}/`,
                                        additionalHeaders: {Accept: "application/liberty.l2.ChatRoomRecord+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForNonParticipantAsync:async ()=>{
                    
                        try{
                                        const result:ChatRoomRecordPartForNonParticipant  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `chat/rooms/id/${id}/`,
                                        additionalHeaders: {Accept: "application/liberty.l2.ChatRoomRecordPartForNonParticipant+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                    };
                    }
                    ,
                
                    joinAsync:  /**
                        * Joins this room. If the room is invite-only, you'll need an invitation.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///chat/rooms/id/${id}/--join
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `chat/rooms/id/${id}/--join/`,
                                })
                            );
                        
                                    return result;}
                        ,
                
                    leaveAsync:  /**
                        * Makes it so that you are no longer a participant in this room.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///chat/rooms/id/${id}/--leave
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `chat/rooms/id/${id}/--leave/`,
                                })
                            );
                        
                                    return result;}
                        ,
                
                    patchAsync: /**
                    * You must own this room to modify it
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///chat/rooms/id/${id}
                    */
                    async (body:ChatRoomRecordPatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `chat/rooms/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
    },

    expo: {
    
        accountAnnexes: {
        
            getAsync: /**
            * Only accessible by: [admin]
            * 
            * GET:///expo/accountAnnexes
            */
            async ()=>{
                const result:ExpoAccountAnnexRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `expo/accountAnnexes/`,
                    })
                );
            
                        return result;}
            ,
        
            bySystemAccountsId:  (systemAccountsId:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///expo/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    async ()=>{
                    
                        try{
                                const result:ExpoAccountAnnexRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `expo/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/accountAnnexes/system_accounts_id/${systemAccountsId}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (
                    /** A "merge patch document". */
                    body: {settings?: {[key: string]: ((((((string | boolean))) | number))), }})=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `expo/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    putAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///expo/accountAnnexes/system_accounts_id/${systemAccountsId}
                    * @param body Used to create (or overwrite) the annex record.
                    * 
                    */
                    async (body:ExpoAccountAnnexRecordPartForPut)=>{
                        const result:{
                    /** Unique immutable identifier, generated internally. */
                    id: string, 
                    /** The system account id of the account that this record is associated with. */
                    accountId: string, settings: {[key: string]: ((((((string | boolean))) | number))), }}  = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `expo/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
            bySystemAccountsSlug:  (systemAccountsSlug:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    async ()=>{
                    
                        try{
                                const result:ExpoAccountAnnexRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (
                    /** A "merge patch document". */
                    body: {settings?: {[key: string]: ((((((string | boolean))) | number))), }})=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    putAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    * @param body Used to create (or overwrite) the annex record.
                    * 
                    */
                    async (body:ExpoAccountAnnexRecordPartForPut)=>{
                        const result:{
                    /** Unique immutable identifier, generated internally. */
                    id: string, 
                    /** The system account id of the account that this record is associated with. */
                    accountId: string, settings: {[key: string]: ((((((string | boolean))) | number))), }}  = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
            bySystemSessionsToken:  (systemSessionsToken:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///expo/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    async ()=>{
                    
                        try{
                                const result:ExpoAccountAnnexRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `expo/accountAnnexes/system_sessions_token/${systemSessionsToken}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (
                    /** A "merge patch document". */
                    body: {settings?: {[key: string]: ((((((string | boolean))) | number))), }})=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `expo/accountAnnexes/system_sessions_token/${systemSessionsToken}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    putAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///expo/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    * @param body Used to create (or overwrite) the annex record.
                    * 
                    */
                    async (body:ExpoAccountAnnexRecordPartForPut)=>{
                        const result:{
                    /** Unique immutable identifier, generated internally. */
                    id: string, 
                    /** The system account id of the account that this record is associated with. */
                    accountId: string, settings: {[key: string]: ((((((string | boolean))) | number))), }}  = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `expo/accountAnnexes/system_sessions_token/${systemSessionsToken}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
        ads: {
        
            getAsync: /**
            * Accessible by anyone on the web.
            * 
            * GET:///expo/ads
            */
            async (query?:{ownerAccountId?:string, ids?:string[]})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ownerAccountId){
                    queryString += "&ownerAccountId="+query.ownerAccountId;
                    }
                if(query?.ids){
                    queryString += "&ids="+query.ids.join(",");
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:ExpoAdRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `expo/ads/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Accessible by anyone with an account.
            * 
            * POST:///expo/ads
            */
            async (body:ExpoAdRecordPartForPost)=>{
                const result:ExpoAdRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `expo/ads/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///expo/ads/id/${id}
                    */
                    async ()=>{
                    
                        try{
                                const result:ExpoAdRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `expo/ads/id/${id}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * You must own this record (or be staff) to modify it.
                    * You must be staff to change this record's ownership.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/ads/id/${id}
                    */
                    async (body:ExpoAdRecordPatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `expo/ads/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
        booths: {
        
            getAsync: /**
            * Accessible by anyone on the web.
            * 
            * GET:///expo/booths
            */
            async (query?:{ownerAccountId?:string, ids?:string[]})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ownerAccountId){
                    queryString += "&ownerAccountId="+query.ownerAccountId;
                    }
                if(query?.ids){
                    queryString += "&ids="+query.ids.join(",");
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:ExpoBoothRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `expo/booths/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Accessible by anyone with an account.
            * 
            * POST:///expo/booths
            */
            async (body:ExpoBoothRecordPartForPost)=>{
                const result:ExpoBoothRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `expo/booths/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///expo/booths/id/${id}
                    */
                    async ()=>{
                    
                        try{
                                const result:ExpoBoothRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `expo/booths/id/${id}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * You must own this record (or be staff) to modify it.
                    * You must be staff to change this record's ownership.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/booths/id/${id}
                    */
                    async (body:ExpoBoothRecordPatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `expo/booths/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
        maps: {
        
            getAsync: /**
            * Accessible by anyone on the web.
            * 
            * GET:///expo/maps
            */
            async (query?:{ownerAccountId?:string, visibility?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ownerAccountId){
                    queryString += "&ownerAccountId="+query.ownerAccountId;
                    }
                if(query?.visibility){
                    queryString += "&visibility="+query.visibility;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:ExpoMapRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `expo/maps/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Posts a map.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///expo/maps
            */
            async (body:ExpoMapRecordPartForPost)=>{
                const result = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `expo/maps/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///expo/maps/id/${id}
                    */
                    async ()=>{
                    
                        try{
                                const result:ExpoMapRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `expo/maps/id/${id}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * You must own this record to modify it
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/maps/id/${id}
                    */
                    async (body:ExpoMapRecordPatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `expo/maps/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
    },

    feedback: {
    
        getAsync: /**
        * Get all feedback record
        * 
        * Only accessible by: [admin, staff]
        * 
        * GET:///feedback
        */
        async ()=>{
            const result = (
                await webClient.requestAsync({
                    method: "get",
                    url: `feedback/`,
                })
            );
        
                    return result;}
        ,
    
        postAsync: /**
        * Create new feedback record
        * 
        * Accessible by anyone on the web.
        * 
        * POST:///feedback
        */
        async (body:FeedbackRecordPostBody)=>{
            const result = (
                await webClient.requestAsync({
                    method: "post",
                    url: `feedback/`,
                    body,
                })
            );
        
                    return result;}
        ,
    
    },

    marketplace: {
    
        accountAnnexes: {
        
            getAsync: /**
            * Accessible by anyone with an account.
            * 
            * GET:///marketplace/accountAnnexes
            */
            async ()=>{
                const result:MarketplaceAccountAnnexRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `marketplace/accountAnnexes/`,
                    })
                );
            
                        return result;}
            ,
        
            bySystemAccountsId:  (systemAccountsId:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    async ()=>{
                    
                        try{
                                const result:MarketplaceAccountAnnexRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (
                    /** A "merge patch document". */
                    body: {attributes?: ({
                    /** The keys of this object are LanguageIds */
                    name: {[key: string]: string, }, 
                    /** The keys of this object are LanguageIds */
                    value: {[key: string]: string, }})[], bio?: {[key: string]: string, }, subscription?: {
                    /** Member type */
                    type: "MEMBER"|"PREMIUM_MEMBER", 
                    /** Send subscription content. */
                    sendSubscription: boolean}})=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    putAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}
                    * @param body Used to create (or overwrite) the annex record.
                    * 
                    */
                    async (body:MarketplaceAccountAnnexRecordPartForPut)=>{
                        const result:{
                    /** Unique immutable identifier, generated internally. */
                    id: string, 
                    /** The id of the account that this record is associated with. The same as the associated AccountRecord's id property. */
                    accountId: string, 
                    /** Structured info about the account, provided by the author. */
                    attributes: ({
                    /** The keys of this object are LanguageIds */
                    name: {[key: string]: string, }, 
                    /** The keys of this object are LanguageIds */
                    value: {[key: string]: string, }})[], 
                    /** Freeform description of the account, shown in the marketplace. May have formatting such as bbcode, it's up to the client to determine how to display it. */
                    bio: {[key: string]: string, }, 
                    /** Attribute and detail control of subscription function */
                    subscription: {
                    /** Member type */
                    type: "MEMBER"|"PREMIUM_MEMBER", 
                    /** Send subscription content. */
                    sendSubscription: boolean}}  = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
            bySystemAccountsSlug:  (systemAccountsSlug:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    async ()=>{
                    
                        try{
                                const result:MarketplaceAccountAnnexRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (
                    /** A "merge patch document". */
                    body: {attributes?: ({
                    /** The keys of this object are LanguageIds */
                    name: {[key: string]: string, }, 
                    /** The keys of this object are LanguageIds */
                    value: {[key: string]: string, }})[], bio?: {[key: string]: string, }, subscription?: {
                    /** Member type */
                    type: "MEMBER"|"PREMIUM_MEMBER", 
                    /** Send subscription content. */
                    sendSubscription: boolean}})=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    putAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    * @param body Used to create (or overwrite) the annex record.
                    * 
                    */
                    async (body:MarketplaceAccountAnnexRecordPartForPut)=>{
                        const result:{
                    /** Unique immutable identifier, generated internally. */
                    id: string, 
                    /** The id of the account that this record is associated with. The same as the associated AccountRecord's id property. */
                    accountId: string, 
                    /** Structured info about the account, provided by the author. */
                    attributes: ({
                    /** The keys of this object are LanguageIds */
                    name: {[key: string]: string, }, 
                    /** The keys of this object are LanguageIds */
                    value: {[key: string]: string, }})[], 
                    /** Freeform description of the account, shown in the marketplace. May have formatting such as bbcode, it's up to the client to determine how to display it. */
                    bio: {[key: string]: string, }, 
                    /** Attribute and detail control of subscription function */
                    subscription: {
                    /** Member type */
                    type: "MEMBER"|"PREMIUM_MEMBER", 
                    /** Send subscription content. */
                    sendSubscription: boolean}}  = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
            bySystemSessionsToken:  (systemSessionsToken:string|number)=>({
                
                    getAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    async ()=>{
                    
                        try{
                                const result:MarketplaceAccountAnnexRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (
                    /** A "merge patch document". */
                    body: {attributes?: ({
                    /** The keys of this object are LanguageIds */
                    name: {[key: string]: string, }, 
                    /** The keys of this object are LanguageIds */
                    value: {[key: string]: string, }})[], bio?: {[key: string]: string, }, subscription?: {
                    /** Member type */
                    type: "MEMBER"|"PREMIUM_MEMBER", 
                    /** Send subscription content. */
                    sendSubscription: boolean}})=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    putAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    * @param body Used to create (or overwrite) the annex record.
                    * 
                    */
                    async (body:MarketplaceAccountAnnexRecordPartForPut)=>{
                        const result:{
                    /** Unique immutable identifier, generated internally. */
                    id: string, 
                    /** The id of the account that this record is associated with. The same as the associated AccountRecord's id property. */
                    accountId: string, 
                    /** Structured info about the account, provided by the author. */
                    attributes: ({
                    /** The keys of this object are LanguageIds */
                    name: {[key: string]: string, }, 
                    /** The keys of this object are LanguageIds */
                    value: {[key: string]: string, }})[], 
                    /** Freeform description of the account, shown in the marketplace. May have formatting such as bbcode, it's up to the client to determine how to display it. */
                    bio: {[key: string]: string, }, 
                    /** Attribute and detail control of subscription function */
                    subscription: {
                    /** Member type */
                    type: "MEMBER"|"PREMIUM_MEMBER", 
                    /** Send subscription content. */
                    sendSubscription: boolean}}  = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
        emails: {
        
            byAddress:  (address:string|number)=>({
                
                    subscriptions: {
                    
                        getAsync: /**
                        * Only accessible by: [admin]
                        * 
                        * GET:///marketplace/emails/address/${address}/subscriptions
                        */
                        async ()=>{
                        
                            try{
                                    const result:{
                        /** Unique immutable identifier, generated internally. */
                        id: string, 
                        /** Email for the notification to be sent to, for example blackhole@liberty-hjc.com */
                        emailAddress: string, 
                        /** If this is turned off, the user won't receive any mails no matter what. */
                        shouldReceiveMails: boolean, 
                        /** Details about the types of email the user wants to receive. */
                        subscriptions: ({
                        /** eg "marketplace__news", "marketplace__someoneLookedAtMyPost", "system__accountAddedToVerifiedGroup", "system__accountAddedToStaffGroup", "system__serverStarted" */
                        typeId: string, 
                        /** The notification will not be sent more frequently than this amount. (It might be sent less frequently, though. Most typeIds will default to min of 60*10.) Not applicable to all types (for example, "onServerStart" will always send). */
                        minFrequencySeconds: number, 
                        /** Used in conjunction with minFrequencySeconds. */
                        lastSentTimeSeconds: number, modificationTimeSeconds: number})[], 
                        /** The types of items that the user doesn't want to receive any more. You can move entries between "subscriptions" and "unsubscriptions" */
                        unsubscriptions: ({
                        /** eg "marketplace__news", "marketplace__someoneLookedAtMyPost", "system__accountAddedToVerifiedGroup", "system__accountAddedToStaffGroup", "system__serverStarted" */
                        typeId: string, 
                        /** The notification will not be sent more frequently than this amount. (It might be sent less frequently, though. Most typeIds will default to min of 60*10.) Not applicable to all types (for example, "onServerStart" will always send). */
                        minFrequencySeconds: number, 
                        /** Used in conjunction with minFrequencySeconds. */
                        lastSentTimeSeconds: number, modificationTimeSeconds: number})[]}  = (
                                await webClient.requestAsync({
                                    method: "get",
                                    url: `marketplace/emails/address/${address}/subscriptions/`,
                                })
                            );
                        
                                    return result;
                            }catch(e:any){
                                if( e instanceof ApiError && e.httpStatus===404){
                                        return new NotFoundResult(e);
                                }
                                throw e;
                            }
                            }
                        ,
                    
                        patchAsync: /**
                        * Only accessible by: [admin]
                        * 
                        * PATCH:///marketplace/emails/address/${address}/subscriptions
                        * @param body The values of the record that a typical user may freely edit.
                        * 
                        */
                        async (body:EmailSubscriptionRecordPartForPatch)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "patch",
                                    url: `marketplace/emails/address/${address}/subscriptions/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                        putAsync: /**
                        * Only accessible by: [admin]
                        * 
                        * PUT:///marketplace/emails/address/${address}/subscriptions
                        * @param body The values of the record that a typical user may freely edit.
                        * 
                        */
                        async (body:EmailSubscriptionRecordPartForPatch)=>{
                            const result:{
                        /** Unique immutable identifier, generated internally. */
                        id: string, 
                        /** Email for the notification to be sent to, for example blackhole@liberty-hjc.com */
                        emailAddress: string, 
                        /** If this is turned off, the user won't receive any mails no matter what. */
                        shouldReceiveMails: boolean, 
                        /** Details about the types of email the user wants to receive. */
                        subscriptions: ({
                        /** eg "marketplace__news", "marketplace__someoneLookedAtMyPost", "system__accountAddedToVerifiedGroup", "system__accountAddedToStaffGroup", "system__serverStarted" */
                        typeId: string, 
                        /** The notification will not be sent more frequently than this amount. (It might be sent less frequently, though. Most typeIds will default to min of 60*10.) Not applicable to all types (for example, "onServerStart" will always send). */
                        minFrequencySeconds: number, 
                        /** Used in conjunction with minFrequencySeconds. */
                        lastSentTimeSeconds: number, modificationTimeSeconds: number})[], 
                        /** The types of items that the user doesn't want to receive any more. You can move entries between "subscriptions" and "unsubscriptions" */
                        unsubscriptions: ({
                        /** eg "marketplace__news", "marketplace__someoneLookedAtMyPost", "system__accountAddedToVerifiedGroup", "system__accountAddedToStaffGroup", "system__serverStarted" */
                        typeId: string, 
                        /** The notification will not be sent more frequently than this amount. (It might be sent less frequently, though. Most typeIds will default to min of 60*10.) Not applicable to all types (for example, "onServerStart" will always send). */
                        minFrequencySeconds: number, 
                        /** Used in conjunction with minFrequencySeconds. */
                        lastSentTimeSeconds: number, modificationTimeSeconds: number})[]}  = (
                                await webClient.requestAsync({
                                    method: "put",
                                    url: `marketplace/emails/address/${address}/subscriptions/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                }),
        
        },
    
        offerInquiry: {
        
            getAsync: /**
            * Get all offerInquiry to the marketplace.
            * 
            * Only accessible by: [admin, staff]
            * 
            * GET:///marketplace/offerInquiry
            */
            async ()=>{
                const result = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `marketplace/offerInquiry/`,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Posts an offerInquiry to the marketplace.
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///marketplace/offerInquiry
            */
            async (body:MarketplaceOfferInquiryRecordPartForPost)=>{
                const result:MarketplaceOfferInquiryRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `marketplace/offerInquiry/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            id: (id:string|number)=>({
            
                postAsync: /**
                * Respond to inquiries
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///marketplace/offerInquiry/${id}
                */
                async (body:SendEmailPost)=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "post",
                            url: `marketplace/offerInquiry/${id}/`,
                            body,
                        })
                    );
                
                            return result;}
                ,
            
            }),
        
            slug: (slug:string|number)=>({
            
                postAsync: /**
                * Respond to inquiries
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///marketplace/offerInquiry/${slug}
                */
                async (body:SendEmailPost)=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "post",
                            url: `marketplace/offerInquiry/${slug}/`,
                            body,
                        })
                    );
                
                            return result;}
                ,
            
            }),
        
        },
    
        offers: {
        
            getAsync: /**
            * Finds offers from the marketplace.
            * 
            * Accessible by anyone on the web.
            * 
            * GET:///marketplace/offers
            */
            async (query?:{type?:string, limit?:number, beforeId?:string, afterId?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.type){
                    queryString += "&type="+query.type;
                    }
                if(query?.limit){
                    queryString += "&limit="+query.limit;
                    }
                if(query?.beforeId){
                    queryString += "&beforeId="+query.beforeId;
                    }
                if(query?.afterId){
                    queryString += "&afterId="+query.afterId;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:MarketplaceOfferRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `marketplace/offers/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Posts an offer to the marketplace.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///marketplace/offers
            */
            async (body:MarketplaceOfferRecordPartForPost)=>{
                const result:MarketplaceOfferRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `marketplace/offers/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    deleteAsync: /**
                    * Deletes an offer from the marketplace. You must be the owner of the offer OR part of the staff access group.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///marketplace/offers/id/${id}
                    */
                    async ()=>{
                        const result:MarketplaceOfferRecord  = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `marketplace/offers/id/${id}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                    getAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///marketplace/offers/id/${id}
                    */
                    async ()=>{
                    
                        try{
                                const result:MarketplaceOfferRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `marketplace/offers/id/${id}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/offers/id/${id}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (body:MarketplaceOfferRecordMergePatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `marketplace/offers/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    contactForm: {
                    
                        submitAsync:  /**
                            * Submits a contact form to the owner of the post. This usually results in a message being sent to the owner.
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///marketplace/offers/id/${id}/contactForm/--submit
                            */
                            async (body: {subject: string, 
                            /** The name of the submitter who sent the message. */
                            name: string, 
                            /** An email that the submitter can be replied to at. */
                            email: string, body: string, 
                            /** The ID of the language that the message is written in. */
                            languageId: string})=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "post",
                                        url: `marketplace/offers/id/${id}/contactForm/--submit/`,
                                        body,
                                    })
                                );
                            
                                        return result;}
                            ,
                    
                    },
                
                }),
        
            bySlug:  (slug:string|number)=>({
                
                    deleteAsync: /**
                    * Deletes an offer from the marketplace. You must be the owner of the offer OR part of the staff access group.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///marketplace/offers/slug/${slug}
                    */
                    async ()=>{
                        const result:MarketplaceOfferRecord  = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `marketplace/offers/slug/${slug}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                    getAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///marketplace/offers/slug/${slug}
                    */
                    async ()=>{
                    
                        try{
                                const result:MarketplaceOfferRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `marketplace/offers/slug/${slug}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/offers/slug/${slug}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (body:MarketplaceOfferRecordMergePatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `marketplace/offers/slug/${slug}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    contactForm: {
                    
                        submitAsync:  /**
                            * Submits a contact form to the owner of the post. This usually results in a message being sent to the owner.
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///marketplace/offers/slug/${slug}/contactForm/--submit
                            */
                            async (body: {subject: string, 
                            /** The name of the submitter who sent the message. */
                            name: string, 
                            /** An email that the submitter can be replied to at. */
                            email: string, body: string, 
                            /** The ID of the language that the message is written in. */
                            languageId: string})=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "post",
                                        url: `marketplace/offers/slug/${slug}/contactForm/--submit/`,
                                        body,
                                    })
                                );
                            
                                        return result;}
                            ,
                    
                    },
                
                }),
        
            import: {
            
                postAsync: /**
                * Upload offers zip to import marketplace.
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///marketplace/offers/import
                */
                async (body:MarketplaceOfferRecordPartForPost)=>{
                    const result:MarketplaceOfferRecord  = (
                        await webClient.requestAsync({
                            method: "post",
                            url: `marketplace/offers/import/`,
                            body,
                        })
                    );
                
                            return result;}
                ,
            
            },
        
        },
    
    },

    operation: {
    
        getAsync: /**
        * Get all operation config info
        * 
        * Only accessible by: [admin, staff]
        * 
        * GET:///operation
        */
        async (query?:{limit?:number, offset?:number})=>{
            // Build a query string for the URL 
            let queryString="";
            if(query?.limit){
                queryString += "&limit="+query.limit;
                }
            if(query?.offset){
                queryString += "&offset="+query.offset;
                }
            // Replace the first & of the query string with a ?
            if(queryString){
                queryString = "?"+queryString.substring(1)
            }
        
            const result:({
        /** Unique immutable identifier, generated internally. */
        id: string, creationTimeSeconds: number, 
        /** Subscription task name */
        title: string, 
        /** Member type */
        type: "MEMBER"|"PREMIUM_MEMBER", 
        /** Member mailboxes */
        members: (string)[], 
        /** Upload file */
        file: string, 
        /** file Name */
        fileName: string, status: "CREATE"|"SENT"|"FAILED"})[]  = (
                await webClient.requestAsync({
                    method: "get",
                    url: `operation/`+queryString,
                })
            );
        
                    return result;}
        ,
    
        patchAsync: /**
        * Update Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * PATCH:///operation
        */
        async (body:OperationRecordPatchBody)=>{
            const result = (
                await webClient.requestAsync({
                    method: "patch",
                    url: `operation/`,
                    body,
                })
            );
        
                    return result;}
        ,
    
        postAsync: /**
        * Create new operation config record
        * 
        * Only accessible by: [admin, staff]
        * 
        * POST:///operation
        */
        async (body:OperationRecordPostBody)=>{
            const result = (
                await webClient.requestAsync({
                    method: "post",
                    url: `operation/`,
                    body,
                })
            );
        
                    return result;}
        ,
    
        account: {
        
            getAsync: /**
            * Get user service usage control data
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///operation/account
            */
            async ()=>{
                const result = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `operation/account/`,
                    })
                );
            
                        return result;}
            ,
        
            patchAsync: /**
            * Update user service usage control data
            * 
            * Accessible by anyone with an account.
            * 
            * PATCH:///operation/account
            */
            async (body: {
            /** Unique immutable identifier, generated internally. */
            id: string, 
            /** Maximum number of offers that can be created per month */
            offerCount: number, 
            /** The number of other users you can communicate with each month */
            chatCount: number, 
            /** Chat history deletion time setting (day) */
            historyDeleteCycle: number, 
            /** User account ID */
            accountId: string, 
            /** Data expiry time */
            expirationDate: number, 
            /** The users who have been contacted through chat each month */
            chatIds: (string)[]})=>{
                const result = (
                    await webClient.requestAsync({
                        method: "patch",
                        url: `operation/account/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
        },
    
    },

    social: {
    
        cards: {
        
            getAsync: /**
            * Returns bookmarks by owner.
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///social/cards
            */
            async (query:{ownerAccountId:string})=>{
                // Build a query string for the URL 
                let queryString="";
                queryString += "&ownerAccountId="+query.ownerAccountId;
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:SystemBookmarkRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `social/cards/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Creates a new card.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///social/cards
            */
            async (body:SocialCardRecordPartForPost)=>{
                const result:SocialCardRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `social/cards/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    copyAsync:  /**
                        * Makes a copy of the card for yourself.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///social/cards/id/${id}/--copy
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `social/cards/id/${id}/--copy/`,
                                })
                            );
                        
                                    return result;}
                        ,
                
                    deleteAsync: /**
                    * Deletes the card.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///social/cards/id/${id}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `social/cards/id/${id}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                    patchAsync: /**
                    * Makes changes to the card.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///social/cards/id/${id}
                    */
                    async (body:SocialCardRecordPatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `social/cards/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
    },

    stats: {
    
        homepageViewCount: {
        
            getAsync: /**
            * Returns the number of homepage views.
            * 
            * Accessible by anyone on the web.
            * 
            * GET:///stats/homepageViewCount
            */
            async ()=>{
                const result:number  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `stats/homepageViewCount/`,
                    })
                );
            
                        return result;}
            ,
        
            incrementAsync:  /**
                * Increases the number of homepage views.
                * 
                * Accessible by anyone on the web.
                * 
                * POST:///stats/homepageViewCount/--increment
                */
                async ()=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "post",
                            url: `stats/homepageViewCount/--increment/`,
                        })
                    );
                
                            return result;}
                ,
        
        },
    
    },

    storage: {
    
        accountAnnexes: {
        
            bySystemAccountsId:  (systemAccountsId:string|number)=>({
                
                    getAsync: /**
                    * returns info about your file storage
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * GET:///storage/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    async ()=>{
                    
                        try{
                                const result:{
                    /** The id of the account that this record is associated with. The same as the associated AccountRecord's id property. */
                    accountId: string, bytesAvailable: number, bytesUsed: number}  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `storage/accountAnnexes/system_accounts_id/${systemAccountsId}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                }),
        
        },
    
        files: {
        
            postAsync: /**
            * Expects metadata in the header, via Content-Disposition. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///storage/files
            * @param blobOrBlobsOrLocalFilePathOrPaths Provide one or more files.
            * 
            * @param body An initial set of metadata provided when the file is created.
            * 
            */
            async (blobOrBlobsOrLocalFilePathOrPaths: Blob|Blob[]|string|string[], 
            /** An initial set of metadata provided when the file is created. */
            body: {
            /** Includes file extension. */
            filename: string, 
            /** Defines who can access the record. */
            permissions: ((("anyone" | {
            /** A list of account ids that can access the record. The owner of a record can always access the record, so they don't need to be in this list. */
            accountIds: (string)[]})))})=>{
                const result:string  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `storage/files/`,
                        body,
                        blobOrBlobsOrLocalFilePathOrPaths,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    deleteAsync: /**
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///storage/files/id/${id}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `storage/files/id/${id}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                    getAsync: /**
                    * Returns metadata in the header, via Content-Disposition. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * GET:///storage/files/id/${id}
                    */
                    async ()=>{
                    
                        try{
                                const result:string  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `storage/files/id/${id}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                    putAsync: /**
                    * replaces the file, also puts any new metadata you provide - note this will affect modification time, but not creation time of the file
                    * 
                    * Only accessible by: [verified]
                    * 
                    * PUT:///storage/files/id/${id}
                    * @param blobOrlocalFilePath Provide one file.
                    * 
                    */
                    async (blobOrlocalFilePath: Blob|string)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "put",
                                url: `storage/files/id/${id}/`,
                                blobOrlocalFilePath,
                            })
                        );
                    
                                return result;}
                    ,
                
                    metadata: {
                    
                        getAsync: /**
                        * Useful if you only want to operate on the file's metadata, without using excess traffic for the binary.
                        * 
                        * Accessible by anyone on the web.
                        * 
                        * GET:///storage/files/id/${id}/metadata
                        */
                        async ()=>{
                        
                            try{
                                    const result:FileMetadataRecord  = (
                                await webClient.requestAsync({
                                    method: "get",
                                    url: `storage/files/id/${id}/metadata/`,
                                })
                            );
                        
                                    return result;
                            }catch(e:any){
                                if( e instanceof ApiError && e.httpStatus===404){
                                        return new NotFoundResult(e);
                                }
                                throw e;
                            }
                            }
                        ,
                    
                        patchAsync: /**
                        * Only accessible by: [verified]
                        * 
                        * PATCH:///storage/files/id/${id}/metadata
                        */
                        async (body:FileMetadataRecordMergePatchDocument)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "patch",
                                    url: `storage/files/id/${id}/metadata/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    renders: {
                    
                        count: {
                        
                            getAsync: /**
                            * Returns the number of renders available for this file.
                            * 
                            * Accessible by anyone on the web.
                            * 
                            * GET:///storage/files/id/${id}/renders/count
                            */
                            async ()=>{
                                const result:number  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `storage/files/id/${id}/renders/count/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                        
                        },
                    
                        byIndex:  (index:string|number)=>({
                            
                                getAsync: /**
                                * Returns an image representing one page of the file. The size of the render is specified in the query.
                                * 
                                * Accessible by anyone on the web.
                                * 
                                * GET:///storage/files/id/${id}/renders/index/${index}
                                */
                                async (query:{maxHeight:number})=>{
                                    // Build a query string for the URL 
                                    let queryString="";
                                    queryString += "&maxHeight="+query.maxHeight;
                                    // Replace the first & of the query string with a ?
                                    if(queryString){
                                        queryString = "?"+queryString.substring(1)
                                    }
                                
                                
                                    try{
                                            const result:string  = (
                                        await webClient.requestAsync({
                                            method: "get",
                                            url: `storage/files/id/${id}/renders/index/${index}/`+queryString,
                                        })
                                    );
                                
                                            return result;
                                    }catch(e:any){
                                        if( e instanceof ApiError && e.httpStatus===404){
                                                return new NotFoundResult(e);
                                        }
                                        throw e;
                                    }
                                    }
                                ,
                            
                            }),
                    
                    },
                
                }),
        
            metadata: {
            
                getAsync: /**
                * Returns metadata about all files on the server.
                * 
                * Only accessible by: [admin]
                * 
                * GET:///storage/files/metadata
                */
                async ()=>{
                    const result:({
                /** Unique immutable identifier, generated internally. */
                id: string, creationTimeSeconds: number, 
                /** Unique mutable identifier, for example "riktrix". When used to refer to an account record, this has the purpose of a "handle" (also known as a "username"). But non-account records may also have slugs, and they have the same exact purpose, so we're using a term that isn't specific to the concept of an account. Note that when patching a record's slug, you may receive a 4xx error if the slug is already in use, or if the slug is the ID of another resource (IDs are reserved for the object they are associated with). */
                slug: string, 
                /** Includes file extension. */
                filename: string, ownerAccountId: string, 
                /** Defines who can access the record. */
                permissions: ((("anyone" | {
                /** A list of account ids that can access the record. The owner of a record can always access the record, so they don't need to be in this list. */
                accountIds: (string)[]}))), modificationTimeSeconds: number, sizeBytes: number, storage: {hostId: "l2"|"s3"}})[]  = (
                        await webClient.requestAsync({
                            method: "get",
                            url: `storage/files/metadata/`,
                        })
                    );
                
                            return result;}
                ,
            
            },
        
        },
    
    },

    subscription: {
    
        getAsync: /**
        * Get all Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * GET:///subscription
        */
        async (query?:{limit?:number, offset?:number})=>{
            // Build a query string for the URL 
            let queryString="";
            if(query?.limit){
                queryString += "&limit="+query.limit;
                }
            if(query?.offset){
                queryString += "&offset="+query.offset;
                }
            // Replace the first & of the query string with a ?
            if(queryString){
                queryString = "?"+queryString.substring(1)
            }
        
            const result:({
        /** Unique immutable identifier, generated internally. */
        id: string, creationTimeSeconds: number, 
        /** Subscription task name */
        title: string, 
        /** Member type */
        type: "MEMBER"|"PREMIUM_MEMBER", 
        /** Member mailboxes */
        members: (string)[], 
        /** Upload file */
        file: string, 
        /** file Name */
        fileName: string, status: "CREATE"|"SENT"|"FAILED"})[]  = (
                await webClient.requestAsync({
                    method: "get",
                    url: `subscription/`+queryString,
                })
            );
        
                    return result;}
        ,
    
        patchAsync: /**
        * Update Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * PATCH:///subscription
        */
        async (body:SubscriptionTaskRecordPatchBody)=>{
            const result = (
                await webClient.requestAsync({
                    method: "patch",
                    url: `subscription/`,
                    body,
                })
            );
        
                    return result;}
        ,
    
        postAsync: /**
        * Create new Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * POST:///subscription
        */
        async (body:SubscriptionTaskRecordPostBody)=>{
            const result = (
                await webClient.requestAsync({
                    method: "post",
                    url: `subscription/`,
                    body,
                })
            );
        
                    return result;}
        ,
    
        id: (id:string|number)=>({
        
            deleteAsync: /**
            * Create new Subscription task
            * 
            * Only accessible by: [admin, staff]
            * 
            * DELETE:///subscription/${id}
            */
            async ()=>{
                const result = (
                    await webClient.requestAsync({
                        method: "delete",
                        url: `subscription/${id}/`,
                    })
                );
            
                        return result;}
            ,
        
        }),
    
        slug: (slug:string|number)=>({
        
            deleteAsync: /**
            * Create new Subscription task
            * 
            * Only accessible by: [admin, staff]
            * 
            * DELETE:///subscription/${slug}
            */
            async ()=>{
                const result = (
                    await webClient.requestAsync({
                        method: "delete",
                        url: `subscription/${slug}/`,
                    })
                );
            
                        return result;}
            ,
        
        }),
    
        collection: {
        
            offer: {
            
                getAsync: /**
                * Add a new offer to the favorites, if there is no favorite, it will be newly generated, if there is a favorite, it will be added directly
                * 
                * Accessible by anyone with an account.
                * 
                * GET:///subscription/collection/offer
                */
                async ()=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "get",
                            url: `subscription/collection/offer/`,
                        })
                    );
                
                            return result;}
                ,
            
                postAsync: /**
                * Add a new offer to the favorites, if there is no favorite, it will be newly generated, if there is a favorite, it will be added directly
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///subscription/collection/offer
                */
                async (body:CollectionOfferInfo)=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "post",
                            url: `subscription/collection/offer/`,
                            body,
                        })
                    );
                
                            return result;}
                ,
            
                id: (id:string|number)=>({
                
                    deleteAsync: /**
                    * delete collection by offer id  
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///subscription/collection/offer/${id}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `subscription/collection/offer/${id}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
            
                slug: (slug:string|number)=>({
                
                    deleteAsync: /**
                    * delete collection by offer id  
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///subscription/collection/offer/${slug}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `subscription/collection/offer/${slug}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
            
            },
        
        },
    
        email: {
        
            id: (id:string|number)=>({
            
                getAsync: /**
                * Send affiliate promotion emails to affiliate users
                * 
                * Only accessible by: [admin, staff]
                * 
                * GET:///subscription/email/${id}
                */
                async ()=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "get",
                            url: `subscription/email/${id}/`,
                        })
                    );
                
                            return result;}
                ,
            
            }),
        
            slug: (slug:string|number)=>({
            
                getAsync: /**
                * Send affiliate promotion emails to affiliate users
                * 
                * Only accessible by: [admin, staff]
                * 
                * GET:///subscription/email/${slug}
                */
                async ()=>{
                    const result = (
                        await webClient.requestAsync({
                            method: "get",
                            url: `subscription/email/${slug}/`,
                        })
                    );
                
                            return result;}
                ,
            
            }),
        
        },
    
    },

    system: {
    
        accounts: {
        
            get: /**
            * Returns all accounts.
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///system/accounts
            */
            (query?:{ids?:string[], limit?:number, offset?:number})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ids){
                    queryString += "&ids="+query.ids.join(",");
                    }
                if(query?.limit){
                    queryString += "&limit="+query.limit;
                    }
                if(query?.offset){
                    queryString += "&offset="+query.offset;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
            return {
                    fullAsync:async ()=>{
                        const result:SystemAccountRecord[]  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `system/accounts/`+queryString,
                                additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecord.array+json"},
                            })
                        );
                        return result;
                    },
                    partForUsersArrayAsync:async ()=>{
                        const result:SystemAccountRecordPartForUsers[]  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `system/accounts/`+queryString,
                                additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForUsers.array+json"},
                            })
                        );
                        return result;
                    },
            };
            }
            ,
        
            postAsync: /**
            * Creates a new account
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///system/accounts
            * @param body The format used to POST a new account.
            * 
            * @param recaptcha - a string such as "v2:FOO" or "v3:FOO" 
            * 
            */
            async (body:SystemAccountPostObject, recaptcha:string)=>{
                const result:SystemAccountRecordPartForOwner  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `system/accounts/`,
                        body,
                        additionalHeaders: {Verification: "ReCaptcha "+recaptcha},
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/accounts/id/${id}
                    */
                    (query?:{fields?:string[]})=>{
                        // Build a query string for the URL 
                        let queryString="";
                        if(query?.fields){
                            queryString += "&fields="+query.fields.join(",");
                            }
                        // Replace the first & of the query string with a ?
                        if(queryString){
                            queryString = "?"+queryString.substring(1)
                        }
                    
                    return {
                            partForOwnerAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForOwner  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/id/${id}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForOwner+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForUsersAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForUsers  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/id/${id}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForUsers+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForPublicAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForPublic  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/id/${id}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForPublic+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                    };
                    }
                    ,
                
                    patchAsync: /**
                    * Allows you to change your own account info. Some fields (slug, groups, etc...) have special rules on what can be changed.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///system/accounts/id/${id}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (body:SystemAccountRecordMergePatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `system/accounts/id/${id}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    agreements: {
                    
                        postAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/id/${id}/agreements
                        */
                        async (body:SystemAccountAgreementEntry)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/accounts/id/${id}/agreements/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    deletionTime: {
                    
                        deleteAsync: /**
                        * Cancels the accounts pending deletion. If you call this endpoint, your account will no longer be deleted as was scheduled.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/id/${id}/deletionTime
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "delete",
                                    url: `system/accounts/id/${id}/deletionTime/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                        requestAsync:  /**
                            * Requests that your want to be set password. Requires a verification code
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///system/accounts/id/${id}/deletionTime/--request
                            */
                            async ()=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "post",
                                        url: `system/accounts/id/${id}/deletionTime/--request/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                    
                    },
                
                    emailAddresses: {
                    
                        emailAddress: (emailAddress:string|number)=>({
                        
                            deleteAsync: /**
                            * lets you remove your confirmed email address from the account - you cannot remove your last confirmed address, though!
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * DELETE:///system/accounts/id/${id}/emailAddresses/${emailAddress}
                            */
                            async ()=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "delete",
                                        url: `system/accounts/id/${id}/emailAddresses/${emailAddress}/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                        
                        }),
                    
                    },
                
                    password: {
                    
                        putAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * PUT:///system/accounts/id/${id}/password
                        */
                        async (body:SystemAccountPasswordPut)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "put",
                                    url: `system/accounts/id/${id}/password/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    passwordVerificationrequest: {
                    
                        postAsync: /**
                        * Requests that your account be deleted in the future. Requires a verification code
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/id/${id}/passwordVerification:request
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/accounts/id/${id}/passwordVerification:request/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    relationships: {
                    
                        getAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * GET:///system/accounts/id/${id}/relationships
                        */
                        async ()=>{
                            const result:({
                        /** Unique immutable identifier, generated internally. */
                        id: string, creationTimeSeconds: number, 
                        /** Unique mutable identifier, for example "riktrix". When used to refer to an account record, this has the purpose of a "handle" (also known as a "username"). But non-account records may also have slugs, and they have the same exact purpose, so we're using a term that isn't specific to the concept of an account. Note that when patching a record's slug, you may receive a 4xx error if the slug is already in use, or if the slug is the ID of another resource (IDs are reserved for the object they are associated with). */
                        slug: string, 
                        /** For example "system__employeeOfAccount" (note that the domain of the relationship, "system" is prefixed to the id to prevent collisions.) */
                        typeId: string, 
                        /** The account that this relationship is for. */
                        accountId: string, 
                        /** The resource at the other end of the relationship will usually be another account, but it may be something else as well. */
                        resourceId: string})[]  = (
                                await webClient.requestAsync({
                                    method: "get",
                                    url: `system/accounts/id/${id}/relationships/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    tokens: {
                    
                        deleteAsync: /**
                        * Deletes/revokes all tokens related to the account, effectively logging you out from all places.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/id/${id}/tokens
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "delete",
                                    url: `system/accounts/id/${id}/tokens/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                }),
        
            bySlug:  (slug:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/accounts/slug/${slug}
                    */
                    (query?:{fields?:string[]})=>{
                        // Build a query string for the URL 
                        let queryString="";
                        if(query?.fields){
                            queryString += "&fields="+query.fields.join(",");
                            }
                        // Replace the first & of the query string with a ?
                        if(queryString){
                            queryString = "?"+queryString.substring(1)
                        }
                    
                    return {
                            partForOwnerAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForOwner  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/slug/${slug}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForOwner+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForUsersAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForUsers  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/slug/${slug}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForUsers+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForPublicAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForPublic  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/slug/${slug}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForPublic+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                    };
                    }
                    ,
                
                    patchAsync: /**
                    * Allows you to change your own account info. Some fields (slug, groups, etc...) have special rules on what can be changed.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///system/accounts/slug/${slug}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (body:SystemAccountRecordMergePatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `system/accounts/slug/${slug}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    agreements: {
                    
                        postAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/slug/${slug}/agreements
                        */
                        async (body:SystemAccountAgreementEntry)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/accounts/slug/${slug}/agreements/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    deletionTime: {
                    
                        deleteAsync: /**
                        * Cancels the accounts pending deletion. If you call this endpoint, your account will no longer be deleted as was scheduled.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/slug/${slug}/deletionTime
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "delete",
                                    url: `system/accounts/slug/${slug}/deletionTime/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                        requestAsync:  /**
                            * Requests that your want to be set password. Requires a verification code
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///system/accounts/slug/${slug}/deletionTime/--request
                            */
                            async ()=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "post",
                                        url: `system/accounts/slug/${slug}/deletionTime/--request/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                    
                    },
                
                    emailAddresses: {
                    
                        emailAddress: (emailAddress:string|number)=>({
                        
                            deleteAsync: /**
                            * lets you remove your confirmed email address from the account - you cannot remove your last confirmed address, though!
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * DELETE:///system/accounts/slug/${slug}/emailAddresses/${emailAddress}
                            */
                            async ()=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "delete",
                                        url: `system/accounts/slug/${slug}/emailAddresses/${emailAddress}/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                        
                        }),
                    
                    },
                
                    password: {
                    
                        putAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * PUT:///system/accounts/slug/${slug}/password
                        */
                        async (body:SystemAccountPasswordPut)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "put",
                                    url: `system/accounts/slug/${slug}/password/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    passwordVerificationrequest: {
                    
                        postAsync: /**
                        * Requests that your account be deleted in the future. Requires a verification code
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/slug/${slug}/passwordVerification:request
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/accounts/slug/${slug}/passwordVerification:request/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    relationships: {
                    
                        getAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * GET:///system/accounts/slug/${slug}/relationships
                        */
                        async ()=>{
                            const result:({
                        /** Unique immutable identifier, generated internally. */
                        id: string, creationTimeSeconds: number, 
                        /** Unique mutable identifier, for example "riktrix". When used to refer to an account record, this has the purpose of a "handle" (also known as a "username"). But non-account records may also have slugs, and they have the same exact purpose, so we're using a term that isn't specific to the concept of an account. Note that when patching a record's slug, you may receive a 4xx error if the slug is already in use, or if the slug is the ID of another resource (IDs are reserved for the object they are associated with). */
                        slug: string, 
                        /** For example "system__employeeOfAccount" (note that the domain of the relationship, "system" is prefixed to the id to prevent collisions.) */
                        typeId: string, 
                        /** The account that this relationship is for. */
                        accountId: string, 
                        /** The resource at the other end of the relationship will usually be another account, but it may be something else as well. */
                        resourceId: string})[]  = (
                                await webClient.requestAsync({
                                    method: "get",
                                    url: `system/accounts/slug/${slug}/relationships/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    tokens: {
                    
                        deleteAsync: /**
                        * Deletes/revokes all tokens related to the account, effectively logging you out from all places.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/slug/${slug}/tokens
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "delete",
                                    url: `system/accounts/slug/${slug}/tokens/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                }),
        
            bySystemSessionsToken:  (systemSessionsToken:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/accounts/system_sessions_token/${systemSessionsToken}
                    */
                    (query?:{fields?:string[]})=>{
                        // Build a query string for the URL 
                        let queryString="";
                        if(query?.fields){
                            queryString += "&fields="+query.fields.join(",");
                            }
                        // Replace the first & of the query string with a ?
                        if(queryString){
                            queryString = "?"+queryString.substring(1)
                        }
                    
                    return {
                            partForOwnerAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForOwner  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/system_sessions_token/${systemSessionsToken}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForOwner+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForUsersAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForUsers  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/system_sessions_token/${systemSessionsToken}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForUsers+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                            partForPublicAsync:async ()=>{
                    
                        try{
                                        const result:SystemAccountRecordPartForPublic  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/accounts/system_sessions_token/${systemSessionsToken}/`+queryString,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemAccountRecordPartForPublic+json"},
                                    })
                                );
                                return result;
                    
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                                },
                    };
                    }
                    ,
                
                    patchAsync: /**
                    * Allows you to change your own account info. Some fields (slug, groups, etc...) have special rules on what can be changed.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///system/accounts/system_sessions_token/${systemSessionsToken}
                    * @param body A "merge patch document".
                    * 
                    */
                    async (body:SystemAccountRecordMergePatchDocument)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `system/accounts/system_sessions_token/${systemSessionsToken}/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                    agreements: {
                    
                        postAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/system_sessions_token/${systemSessionsToken}/agreements
                        */
                        async (body:SystemAccountAgreementEntry)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/accounts/system_sessions_token/${systemSessionsToken}/agreements/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    deletionTime: {
                    
                        deleteAsync: /**
                        * Cancels the accounts pending deletion. If you call this endpoint, your account will no longer be deleted as was scheduled.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "delete",
                                    url: `system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                        requestAsync:  /**
                            * Requests that your want to be set password. Requires a verification code
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime/--request
                            */
                            async ()=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "post",
                                        url: `system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime/--request/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                    
                    },
                
                    emailAddresses: {
                    
                        emailAddress: (emailAddress:string|number)=>({
                        
                            deleteAsync: /**
                            * lets you remove your confirmed email address from the account - you cannot remove your last confirmed address, though!
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * DELETE:///system/accounts/system_sessions_token/${systemSessionsToken}/emailAddresses/${emailAddress}
                            */
                            async ()=>{
                                const result = (
                                    await webClient.requestAsync({
                                        method: "delete",
                                        url: `system/accounts/system_sessions_token/${systemSessionsToken}/emailAddresses/${emailAddress}/`,
                                    })
                                );
                            
                                        return result;}
                            ,
                        
                        }),
                    
                    },
                
                    password: {
                    
                        putAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * PUT:///system/accounts/system_sessions_token/${systemSessionsToken}/password
                        */
                        async (body:SystemAccountPasswordPut)=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "put",
                                    url: `system/accounts/system_sessions_token/${systemSessionsToken}/password/`,
                                    body,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    passwordVerificationrequest: {
                    
                        postAsync: /**
                        * Requests that your account be deleted in the future. Requires a verification code
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/system_sessions_token/${systemSessionsToken}/passwordVerification:request
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/accounts/system_sessions_token/${systemSessionsToken}/passwordVerification:request/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    relationships: {
                    
                        getAsync: /**
                        * Accessible by anyone with an account.
                        * 
                        * GET:///system/accounts/system_sessions_token/${systemSessionsToken}/relationships
                        */
                        async ()=>{
                            const result:({
                        /** Unique immutable identifier, generated internally. */
                        id: string, creationTimeSeconds: number, 
                        /** Unique mutable identifier, for example "riktrix". When used to refer to an account record, this has the purpose of a "handle" (also known as a "username"). But non-account records may also have slugs, and they have the same exact purpose, so we're using a term that isn't specific to the concept of an account. Note that when patching a record's slug, you may receive a 4xx error if the slug is already in use, or if the slug is the ID of another resource (IDs are reserved for the object they are associated with). */
                        slug: string, 
                        /** For example "system__employeeOfAccount" (note that the domain of the relationship, "system" is prefixed to the id to prevent collisions.) */
                        typeId: string, 
                        /** The account that this relationship is for. */
                        accountId: string, 
                        /** The resource at the other end of the relationship will usually be another account, but it may be something else as well. */
                        resourceId: string})[]  = (
                                await webClient.requestAsync({
                                    method: "get",
                                    url: `system/accounts/system_sessions_token/${systemSessionsToken}/relationships/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                    tokens: {
                    
                        deleteAsync: /**
                        * Deletes/revokes all tokens related to the account, effectively logging you out from all places.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/system_sessions_token/${systemSessionsToken}/tokens
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "delete",
                                    url: `system/accounts/system_sessions_token/${systemSessionsToken}/tokens/`,
                                })
                            );
                        
                                    return result;}
                        ,
                    
                    },
                
                }),
        
        },
    
        bookmarks: {
        
            getAsync: /**
            * Returns bookmarks by owner.
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///system/bookmarks
            */
            async (query:{ownerAccountId:string})=>{
                // Build a query string for the URL 
                let queryString="";
                queryString += "&ownerAccountId="+query.ownerAccountId;
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                const result:SystemBookmarkRecord[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `system/bookmarks/`+queryString,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Adds a new bookmark.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///system/bookmarks
            */
            async (body:SystemBookmarkRecordPartForPost)=>{
                const result:SystemBookmarkRecord  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `system/bookmarks/`,
                        body,
                    })
                );
            
                        return result;}
            ,
        
            byId:  (id:string|number)=>({
                
                    deleteAsync: /**
                    * Deletes the bookmark.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///system/bookmarks/id/${id}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `system/bookmarks/id/${id}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                }),
        
        },
    
        emails: {
        
            emailAddress: (emailAddress:string|number)=>({
            
                magicLink: {
                
                    sendAsync:  /**
                        * (emails a signed JWT (10 day magic token containing email address and account ID) to the user, this can be used to post an auth token)
                        * 
                        * Accessible by anyone on the web.
                        * 
                        * POST:///system/emails/${emailAddress}/magicLink/--send
                        */
                        async ()=>{
                            const result = (
                                await webClient.requestAsync({
                                    method: "post",
                                    url: `system/emails/${emailAddress}/magicLink/--send/`,
                                })
                            );
                        
                                    return result;}
                        ,
                
                },
            
                subscriptions: {
                
                    getAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/emails/${emailAddress}/subscriptions
                    */
                    async ()=>{
                        const result:EmailSubscriptionRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `system/emails/${emailAddress}/subscriptions/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                    patchAsync: /**
                    * Accessible by anyone on the web.
                    * 
                    * PATCH:///system/emails/${emailAddress}/subscriptions
                    * @param body An email and its associated notification preferences. If an email typeId isn't found in this record, it will be sent in the default manner.
                    * 
                    */
                    async (body:EmailSubscriptionRecord)=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "patch",
                                url: `system/emails/${emailAddress}/subscriptions/`,
                                body,
                            })
                        );
                    
                                return result;}
                    ,
                
                },
            
            }),
        
        },
    
        sessions: {
        
            getAsync: /**
            * Returns all sessions on the server.
            * 
            * Only accessible by: [admin]
            * 
            * GET:///system/sessions
            */
            async ()=>{
                const result:({id: string, 
            /** System policy determines the deadline to verify a session, and also determines the expiration time. */
            creationTimeSeconds: number, 
            /** The account ID that this session will authenticate as. */
            accountId: string, 
            /** A part of the session record that notes who made the HTTP request to create or verify the session. */
            createdBy: {
            /** The IP of where the session was created or verified. */
            atIp?: string, headers: {
            /** As you probably know, this is client-provided and might be empty. */
            userAgent: string}}, 
            /** May be different than createdBy, eg if the user sent a magic link and opened it on another computer. */
            loggedInBy?: {
            /** The IP of where the session was created or verified. */
            atIp?: string, headers: {
            /** As you probably know, this is client-provided and might be empty. */
            userAgent: string}}, 
            /** The email address used to log in to this session, via password, magic link, etc... If this email is no longer associated with the account, this session will become invalid. */
            emailAddress: string, 
            /** A token used to authenticate via this session. The user receives this token when they create the session. */
            opaqueToken: string, 
            /** If the session token was emailed, then we can know that the user logge */
            opaqueTokenWasSentToEmailAddress?: boolean})[]  = (
                    await webClient.requestAsync({
                        method: "get",
                        url: `system/sessions/`,
                    })
                );
            
                        return result;}
            ,
        
            postAsync: /**
            * Creates a session that is stored on the server. You will need to verify the session by clicking the link in your email.
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///system/sessions
            * @param recaptcha - a string such as "v2:FOO" or "v3:FOO" 
            * 
            */
            async (body: {
            /** The email address of the account you want to create a session. Doesn't necessarily have to be the account you are authenticating the request as. (ie you might be able to create a session for someone else if you have an admin account) */
            emailAddress: string, 
            /** If provided, will override the "Authorization" header of the request. */
            basicAuthentication?: {email: string, password: string}}, recaptcha:string)=>{
                const result:((({
            /** The token that can be used to authenticate with this session. */
            token?: string} | {
            /** Check this email address to get the token. */
            emailAddressTokenWasSentTo: string})))  = (
                    await webClient.requestAsync({
                        method: "post",
                        url: `system/sessions/`,
                        body,
                        additionalHeaders: {Verification: "ReCaptcha "+recaptcha},
                    })
                );
            
                        return result;}
            ,
        
            systemAccountId: {
            
                id: (id:string|number)=>({
                
                    get: /**
                    * Returns all sessions related to the account. You can only see your own sessions, unless you are admin.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * GET:///system/sessions/system_account_id/${id}
                    */
                    ()=>{
                    return {
                            fullAsync:async ()=>{
                                const result:SystemSessionRecord  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/sessions/system_account_id/${id}/`,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemSessionRecord+json"},
                                    })
                                );
                                return result;
                            },
                            partForOwnerAsync:async ()=>{
                                const result:SystemSessionRecordPartForOwner  = (
                                    await webClient.requestAsync({
                                        method: "get",
                                        url: `system/sessions/system_account_id/${id}/`,
                                        additionalHeaders: {Accept: "application/liberty.l2.SystemSessionRecordPartForOwner+json"},
                                    })
                                );
                                return result;
                            },
                    };
                    }
                    ,
                
                }),
            
            },
        
            byToken:  (token:string|number)=>({
                
                    deleteAsync: /**
                    * Revokes the token / logs out of the session.
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * DELETE:///system/sessions/token/${token}
                    */
                    async ()=>{
                        const result = (
                            await webClient.requestAsync({
                                method: "delete",
                                url: `system/sessions/token/${token}/`,
                            })
                        );
                    
                                return result;}
                    ,
                
                    getAsync: /**
                    * Returns information about the session.
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/sessions/token/${token}
                    */
                    async ()=>{
                    
                        try{
                                const result:SystemSessionRecord  = (
                            await webClient.requestAsync({
                                method: "get",
                                url: `system/sessions/token/${token}/`,
                            })
                        );
                    
                                return result;
                        }catch(e:any){
                            if( e instanceof ApiError && e.httpStatus===404){
                                    return new NotFoundResult(e);
                            }
                            throw e;
                        }
                        }
                    ,
                
                }),
        
        },
    
    },

} );
    export type ClientApiObjectType = ReturnType<typeof MakeClientApiObject>;
   
   
    export const MakeClientUrlsObject = (webClient:WebClient)=>( {

    analytics: {
    
        access: {
        
            get: /**
            * Accessible by anyone on the web.
            * 
            * GET:///analytics/access
            */
            ()=>{
                return webClient.baseUrl+`analytics/access`;
            }
            ,
        
            post: /**
            * Creates an access record
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///analytics/access
            */
            ()=>{
                return webClient.baseUrl+`analytics/access`;
            }
            ,
        
        },
    
        ipInfo: {
        
            get: /**
            * Get all ipInfo record
            * 
            * Accessible by anyone on the web.
            * 
            * GET:///analytics/ipInfo
            */
            ()=>{
                return webClient.baseUrl+`analytics/ipInfo`;
            }
            ,
        
            post: /**
            * Creates ipInfo record
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///analytics/ipInfo
            */
            ()=>{
                return webClient.baseUrl+`analytics/ipInfo`;
            }
            ,
        
            byIp:  (ip:string|number)=>({
                
                    get: /**
                    * Get ip info by ip
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * GET:///analytics/ipInfo/ip/${ip}
                    */
                    ()=>{
                        return webClient.baseUrl+`analytics/ipInfo/ip/${ip}`;
                    }
                    ,
                
                }),
        
        },
    
    },

    chat: {
    
        invites: {
        
            get: /**
            * Accessible by anyone with an account.
            * 
            * GET:///chat/invites
            */
            (query?:{recipientAccountId?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.recipientAccountId){
                    queryString += "&recipientAccountId="+query.recipientAccountId;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`chat/invites`+queryString;
            }
            ,
        
            post: /**
            * Invites a user to a chat room.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///chat/invites
            */
            ()=>{
                return webClient.baseUrl+`chat/invites`;
            }
            ,
        
        },
    
        messages: {
        
            get: /**
            * Accessible by anyone with an account.
            * 
            * GET:///chat/messages
            */
            (query?:{roomParticipantAccountId?:string, limit?:number})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.roomParticipantAccountId){
                    queryString += "&roomParticipantAccountId="+query.roomParticipantAccountId;
                    }
                if(query?.limit){
                    queryString += "&limit="+query.limit;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`chat/messages`+queryString;
            }
            ,
        
            post: /**
            * Posts a message.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///chat/messages
            */
            ()=>{
                return webClient.baseUrl+`chat/messages`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    delete: /**
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///chat/messages/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`chat/messages/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
        rooms: {
        
            get: /**
            * Accessible by anyone with an account.
            * 
            * GET:///chat/rooms
            */
            (query?:{withParticipantId?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.withParticipantId){
                    queryString += "&withParticipantId="+query.withParticipantId;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`chat/rooms`+queryString;
            }
            ,
        
            post: /**
            * Creates a new chat room.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///chat/rooms
            */
            ()=>{
                return webClient.baseUrl+`chat/rooms`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///chat/rooms/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`chat/rooms/id/${id}`;
                    }
                    ,
                
                    join:  /**
                        * Joins this room. If the room is invite-only, you'll need an invitation.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///chat/rooms/id/${id}/--join
                        */
                        ()=>{
                            return webClient.baseUrl+`chat/rooms/id/${id}/--join`;
                        }
                        ,
                
                    leave:  /**
                        * Makes it so that you are no longer a participant in this room.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///chat/rooms/id/${id}/--leave
                        */
                        ()=>{
                            return webClient.baseUrl+`chat/rooms/id/${id}/--leave`;
                        }
                        ,
                
                    patch: /**
                    * You must own this room to modify it
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///chat/rooms/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`chat/rooms/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
    },

    expo: {
    
        accountAnnexes: {
        
            get: /**
            * Only accessible by: [admin]
            * 
            * GET:///expo/accountAnnexes
            */
            ()=>{
                return webClient.baseUrl+`expo/accountAnnexes`;
            }
            ,
        
            bySystemAccountsId:  (systemAccountsId:string|number)=>({
                
                    get: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///expo/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                    put: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///expo/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                }),
        
            bySystemAccountsSlug:  (systemAccountsSlug:string|number)=>({
                
                    get: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}`;
                    }
                    ,
                
                    put: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_accounts_slug/${systemAccountsSlug}`;
                    }
                    ,
                
                }),
        
            bySystemSessionsToken:  (systemSessionsToken:string|number)=>({
                
                    get: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///expo/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                    put: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///expo/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/accountAnnexes/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                }),
        
        },
    
        ads: {
        
            get: /**
            * Accessible by anyone on the web.
            * 
            * GET:///expo/ads
            */
            (query?:{ownerAccountId?:string, ids?:string[]})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ownerAccountId){
                    queryString += "&ownerAccountId="+query.ownerAccountId;
                    }
                if(query?.ids){
                    queryString += "&ids="+query.ids.join(",");
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`expo/ads`+queryString;
            }
            ,
        
            post: /**
            * Accessible by anyone with an account.
            * 
            * POST:///expo/ads
            */
            ()=>{
                return webClient.baseUrl+`expo/ads`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///expo/ads/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/ads/id/${id}`;
                    }
                    ,
                
                    patch: /**
                    * You must own this record (or be staff) to modify it.
                    * You must be staff to change this record's ownership.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/ads/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/ads/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
        booths: {
        
            get: /**
            * Accessible by anyone on the web.
            * 
            * GET:///expo/booths
            */
            (query?:{ownerAccountId?:string, ids?:string[]})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ownerAccountId){
                    queryString += "&ownerAccountId="+query.ownerAccountId;
                    }
                if(query?.ids){
                    queryString += "&ids="+query.ids.join(",");
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`expo/booths`+queryString;
            }
            ,
        
            post: /**
            * Accessible by anyone with an account.
            * 
            * POST:///expo/booths
            */
            ()=>{
                return webClient.baseUrl+`expo/booths`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///expo/booths/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/booths/id/${id}`;
                    }
                    ,
                
                    patch: /**
                    * You must own this record (or be staff) to modify it.
                    * You must be staff to change this record's ownership.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/booths/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/booths/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
        maps: {
        
            get: /**
            * Accessible by anyone on the web.
            * 
            * GET:///expo/maps
            */
            (query?:{ownerAccountId?:string, visibility?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ownerAccountId){
                    queryString += "&ownerAccountId="+query.ownerAccountId;
                    }
                if(query?.visibility){
                    queryString += "&visibility="+query.visibility;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`expo/maps`+queryString;
            }
            ,
        
            post: /**
            * Posts a map.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///expo/maps
            */
            ()=>{
                return webClient.baseUrl+`expo/maps`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///expo/maps/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/maps/id/${id}`;
                    }
                    ,
                
                    patch: /**
                    * You must own this record to modify it
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///expo/maps/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`expo/maps/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
    },

    feedback: {
    
        get: /**
        * Get all feedback record
        * 
        * Only accessible by: [admin, staff]
        * 
        * GET:///feedback
        */
        ()=>{
            return webClient.baseUrl+`feedback`;
        }
        ,
    
        post: /**
        * Create new feedback record
        * 
        * Accessible by anyone on the web.
        * 
        * POST:///feedback
        */
        ()=>{
            return webClient.baseUrl+`feedback`;
        }
        ,
    
    },

    marketplace: {
    
        accountAnnexes: {
        
            get: /**
            * Accessible by anyone with an account.
            * 
            * GET:///marketplace/accountAnnexes
            */
            ()=>{
                return webClient.baseUrl+`marketplace/accountAnnexes`;
            }
            ,
        
            bySystemAccountsId:  (systemAccountsId:string|number)=>({
                
                    get: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                    put: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                }),
        
            bySystemAccountsSlug:  (systemAccountsSlug:string|number)=>({
                
                    get: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}`;
                    }
                    ,
                
                    put: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_accounts_slug/${systemAccountsSlug}`;
                    }
                    ,
                
                }),
        
            bySystemSessionsToken:  (systemSessionsToken:string|number)=>({
                
                    get: /**
                    * Accessible by anyone with an account.
                    * 
                    * GET:///marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                    put: /**
                    * Accessible by anyone with an account.
                    * 
                    * PUT:///marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/accountAnnexes/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                }),
        
        },
    
        emails: {
        
            byAddress:  (address:string|number)=>({
                
                    subscriptions: {
                    
                        get: /**
                        * Only accessible by: [admin]
                        * 
                        * GET:///marketplace/emails/address/${address}/subscriptions
                        */
                        ()=>{
                            return webClient.baseUrl+`marketplace/emails/address/${address}/subscriptions`;
                        }
                        ,
                    
                        patch: /**
                        * Only accessible by: [admin]
                        * 
                        * PATCH:///marketplace/emails/address/${address}/subscriptions
                        */
                        ()=>{
                            return webClient.baseUrl+`marketplace/emails/address/${address}/subscriptions`;
                        }
                        ,
                    
                        put: /**
                        * Only accessible by: [admin]
                        * 
                        * PUT:///marketplace/emails/address/${address}/subscriptions
                        */
                        ()=>{
                            return webClient.baseUrl+`marketplace/emails/address/${address}/subscriptions`;
                        }
                        ,
                    
                    },
                
                }),
        
        },
    
        offerInquiry: {
        
            get: /**
            * Get all offerInquiry to the marketplace.
            * 
            * Only accessible by: [admin, staff]
            * 
            * GET:///marketplace/offerInquiry
            */
            ()=>{
                return webClient.baseUrl+`marketplace/offerInquiry`;
            }
            ,
        
            post: /**
            * Posts an offerInquiry to the marketplace.
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///marketplace/offerInquiry
            */
            ()=>{
                return webClient.baseUrl+`marketplace/offerInquiry`;
            }
            ,
        
            id: (id:string|number)=>({
            
                post: /**
                * Respond to inquiries
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///marketplace/offerInquiry/${id}
                */
                ()=>{
                    return webClient.baseUrl+`marketplace/offerInquiry/${id}`;
                }
                ,
            
            }),
        
            slug: (slug:string|number)=>({
            
                post: /**
                * Respond to inquiries
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///marketplace/offerInquiry/${slug}
                */
                ()=>{
                    return webClient.baseUrl+`marketplace/offerInquiry/${slug}`;
                }
                ,
            
            }),
        
        },
    
        offers: {
        
            get: /**
            * Finds offers from the marketplace.
            * 
            * Accessible by anyone on the web.
            * 
            * GET:///marketplace/offers
            */
            (query?:{type?:string, limit?:number, beforeId?:string, afterId?:string})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.type){
                    queryString += "&type="+query.type;
                    }
                if(query?.limit){
                    queryString += "&limit="+query.limit;
                    }
                if(query?.beforeId){
                    queryString += "&beforeId="+query.beforeId;
                    }
                if(query?.afterId){
                    queryString += "&afterId="+query.afterId;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`marketplace/offers`+queryString;
            }
            ,
        
            post: /**
            * Posts an offer to the marketplace.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///marketplace/offers
            */
            ()=>{
                return webClient.baseUrl+`marketplace/offers`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    delete: /**
                    * Deletes an offer from the marketplace. You must be the owner of the offer OR part of the staff access group.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///marketplace/offers/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/offers/id/${id}`;
                    }
                    ,
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///marketplace/offers/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/offers/id/${id}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/offers/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/offers/id/${id}`;
                    }
                    ,
                
                    contactForm: {
                    
                        submit:  /**
                            * Submits a contact form to the owner of the post. This usually results in a message being sent to the owner.
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///marketplace/offers/id/${id}/contactForm/--submit
                            */
                            ()=>{
                                return webClient.baseUrl+`marketplace/offers/id/${id}/contactForm/--submit`;
                            }
                            ,
                    
                    },
                
                }),
        
            bySlug:  (slug:string|number)=>({
                
                    delete: /**
                    * Deletes an offer from the marketplace. You must be the owner of the offer OR part of the staff access group.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///marketplace/offers/slug/${slug}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/offers/slug/${slug}`;
                    }
                    ,
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///marketplace/offers/slug/${slug}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/offers/slug/${slug}`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///marketplace/offers/slug/${slug}
                    */
                    ()=>{
                        return webClient.baseUrl+`marketplace/offers/slug/${slug}`;
                    }
                    ,
                
                    contactForm: {
                    
                        submit:  /**
                            * Submits a contact form to the owner of the post. This usually results in a message being sent to the owner.
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///marketplace/offers/slug/${slug}/contactForm/--submit
                            */
                            ()=>{
                                return webClient.baseUrl+`marketplace/offers/slug/${slug}/contactForm/--submit`;
                            }
                            ,
                    
                    },
                
                }),
        
            import: {
            
                post: /**
                * Upload offers zip to import marketplace.
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///marketplace/offers/import
                */
                ()=>{
                    return webClient.baseUrl+`marketplace/offers/import`;
                }
                ,
            
            },
        
        },
    
    },

    operation: {
    
        get: /**
        * Get all operation config info
        * 
        * Only accessible by: [admin, staff]
        * 
        * GET:///operation
        */
        (query?:{limit?:number, offset?:number})=>{
            // Build a query string for the URL 
            let queryString="";
            if(query?.limit){
                queryString += "&limit="+query.limit;
                }
            if(query?.offset){
                queryString += "&offset="+query.offset;
                }
            // Replace the first & of the query string with a ?
            if(queryString){
                queryString = "?"+queryString.substring(1)
            }
        
            return webClient.baseUrl+`operation`+queryString;
        }
        ,
    
        patch: /**
        * Update Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * PATCH:///operation
        */
        ()=>{
            return webClient.baseUrl+`operation`;
        }
        ,
    
        post: /**
        * Create new operation config record
        * 
        * Only accessible by: [admin, staff]
        * 
        * POST:///operation
        */
        ()=>{
            return webClient.baseUrl+`operation`;
        }
        ,
    
        account: {
        
            get: /**
            * Get user service usage control data
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///operation/account
            */
            ()=>{
                return webClient.baseUrl+`operation/account`;
            }
            ,
        
            patch: /**
            * Update user service usage control data
            * 
            * Accessible by anyone with an account.
            * 
            * PATCH:///operation/account
            */
            ()=>{
                return webClient.baseUrl+`operation/account`;
            }
            ,
        
        },
    
    },

    social: {
    
        cards: {
        
            get: /**
            * Returns bookmarks by owner.
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///social/cards
            */
            (query:{ownerAccountId:string})=>{
                // Build a query string for the URL 
                let queryString="";
                queryString += "&ownerAccountId="+query.ownerAccountId;
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`social/cards`+queryString;
            }
            ,
        
            post: /**
            * Creates a new card.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///social/cards
            */
            ()=>{
                return webClient.baseUrl+`social/cards`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    copy:  /**
                        * Makes a copy of the card for yourself.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///social/cards/id/${id}/--copy
                        */
                        ()=>{
                            return webClient.baseUrl+`social/cards/id/${id}/--copy`;
                        }
                        ,
                
                    delete: /**
                    * Deletes the card.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///social/cards/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`social/cards/id/${id}`;
                    }
                    ,
                
                    patch: /**
                    * Makes changes to the card.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///social/cards/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`social/cards/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
    },

    stats: {
    
        homepageViewCount: {
        
            get: /**
            * Returns the number of homepage views.
            * 
            * Accessible by anyone on the web.
            * 
            * GET:///stats/homepageViewCount
            */
            ()=>{
                return webClient.baseUrl+`stats/homepageViewCount`;
            }
            ,
        
            increment:  /**
                * Increases the number of homepage views.
                * 
                * Accessible by anyone on the web.
                * 
                * POST:///stats/homepageViewCount/--increment
                */
                ()=>{
                    return webClient.baseUrl+`stats/homepageViewCount/--increment`;
                }
                ,
        
        },
    
    },

    storage: {
    
        accountAnnexes: {
        
            bySystemAccountsId:  (systemAccountsId:string|number)=>({
                
                    get: /**
                    * returns info about your file storage
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * GET:///storage/accountAnnexes/system_accounts_id/${systemAccountsId}
                    */
                    ()=>{
                        return webClient.baseUrl+`storage/accountAnnexes/system_accounts_id/${systemAccountsId}`;
                    }
                    ,
                
                }),
        
        },
    
        files: {
        
            post: /**
            * Expects metadata in the header, via Content-Disposition. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///storage/files
            * @param blobOrBlobsOrLocalFilePathOrPaths Provide one or more files.
            * 
            */
            (blobOrBlobsOrLocalFilePathOrPaths: Blob|Blob[]|string|string[])=>{
                return webClient.baseUrl+`storage/files`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    delete: /**
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///storage/files/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`storage/files/id/${id}`;
                    }
                    ,
                
                    get: /**
                    * Returns metadata in the header, via Content-Disposition. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * GET:///storage/files/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`storage/files/id/${id}`;
                    }
                    ,
                
                    put: /**
                    * replaces the file, also puts any new metadata you provide - note this will affect modification time, but not creation time of the file
                    * 
                    * Only accessible by: [verified]
                    * 
                    * PUT:///storage/files/id/${id}
                    * @param blobOrlocalFilePath Provide one file.
                    * 
                    */
                    (blobOrlocalFilePath: Blob|string)=>{
                        return webClient.baseUrl+`storage/files/id/${id}`;
                    }
                    ,
                
                    metadata: {
                    
                        get: /**
                        * Useful if you only want to operate on the file's metadata, without using excess traffic for the binary.
                        * 
                        * Accessible by anyone on the web.
                        * 
                        * GET:///storage/files/id/${id}/metadata
                        */
                        ()=>{
                            return webClient.baseUrl+`storage/files/id/${id}/metadata`;
                        }
                        ,
                    
                        patch: /**
                        * Only accessible by: [verified]
                        * 
                        * PATCH:///storage/files/id/${id}/metadata
                        */
                        ()=>{
                            return webClient.baseUrl+`storage/files/id/${id}/metadata`;
                        }
                        ,
                    
                    },
                
                    renders: {
                    
                        count: {
                        
                            get: /**
                            * Returns the number of renders available for this file.
                            * 
                            * Accessible by anyone on the web.
                            * 
                            * GET:///storage/files/id/${id}/renders/count
                            */
                            ()=>{
                                return webClient.baseUrl+`storage/files/id/${id}/renders/count`;
                            }
                            ,
                        
                        },
                    
                        byIndex:  (index:string|number)=>({
                            
                                get: /**
                                * Returns an image representing one page of the file. The size of the render is specified in the query.
                                * 
                                * Accessible by anyone on the web.
                                * 
                                * GET:///storage/files/id/${id}/renders/index/${index}
                                */
                                (query:{maxHeight:number})=>{
                                    // Build a query string for the URL 
                                    let queryString="";
                                    queryString += "&maxHeight="+query.maxHeight;
                                    // Replace the first & of the query string with a ?
                                    if(queryString){
                                        queryString = "?"+queryString.substring(1)
                                    }
                                
                                    return webClient.baseUrl+`storage/files/id/${id}/renders/index/${index}`+queryString;
                                }
                                ,
                            
                            }),
                    
                    },
                
                }),
        
            metadata: {
            
                get: /**
                * Returns metadata about all files on the server.
                * 
                * Only accessible by: [admin]
                * 
                * GET:///storage/files/metadata
                */
                ()=>{
                    return webClient.baseUrl+`storage/files/metadata`;
                }
                ,
            
            },
        
        },
    
    },

    subscription: {
    
        get: /**
        * Get all Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * GET:///subscription
        */
        (query?:{limit?:number, offset?:number})=>{
            // Build a query string for the URL 
            let queryString="";
            if(query?.limit){
                queryString += "&limit="+query.limit;
                }
            if(query?.offset){
                queryString += "&offset="+query.offset;
                }
            // Replace the first & of the query string with a ?
            if(queryString){
                queryString = "?"+queryString.substring(1)
            }
        
            return webClient.baseUrl+`subscription`+queryString;
        }
        ,
    
        patch: /**
        * Update Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * PATCH:///subscription
        */
        ()=>{
            return webClient.baseUrl+`subscription`;
        }
        ,
    
        post: /**
        * Create new Subscription task
        * 
        * Only accessible by: [admin, staff]
        * 
        * POST:///subscription
        */
        ()=>{
            return webClient.baseUrl+`subscription`;
        }
        ,
    
        id: (id:string|number)=>({
        
            delete: /**
            * Create new Subscription task
            * 
            * Only accessible by: [admin, staff]
            * 
            * DELETE:///subscription/${id}
            */
            ()=>{
                return webClient.baseUrl+`subscription/${id}`;
            }
            ,
        
        }),
    
        slug: (slug:string|number)=>({
        
            delete: /**
            * Create new Subscription task
            * 
            * Only accessible by: [admin, staff]
            * 
            * DELETE:///subscription/${slug}
            */
            ()=>{
                return webClient.baseUrl+`subscription/${slug}`;
            }
            ,
        
        }),
    
        collection: {
        
            offer: {
            
                get: /**
                * Add a new offer to the favorites, if there is no favorite, it will be newly generated, if there is a favorite, it will be added directly
                * 
                * Accessible by anyone with an account.
                * 
                * GET:///subscription/collection/offer
                */
                ()=>{
                    return webClient.baseUrl+`subscription/collection/offer`;
                }
                ,
            
                post: /**
                * Add a new offer to the favorites, if there is no favorite, it will be newly generated, if there is a favorite, it will be added directly
                * 
                * Accessible by anyone with an account.
                * 
                * POST:///subscription/collection/offer
                */
                ()=>{
                    return webClient.baseUrl+`subscription/collection/offer`;
                }
                ,
            
                id: (id:string|number)=>({
                
                    delete: /**
                    * delete collection by offer id  
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///subscription/collection/offer/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`subscription/collection/offer/${id}`;
                    }
                    ,
                
                }),
            
                slug: (slug:string|number)=>({
                
                    delete: /**
                    * delete collection by offer id  
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///subscription/collection/offer/${slug}
                    */
                    ()=>{
                        return webClient.baseUrl+`subscription/collection/offer/${slug}`;
                    }
                    ,
                
                }),
            
            },
        
        },
    
        email: {
        
            id: (id:string|number)=>({
            
                get: /**
                * Send affiliate promotion emails to affiliate users
                * 
                * Only accessible by: [admin, staff]
                * 
                * GET:///subscription/email/${id}
                */
                ()=>{
                    return webClient.baseUrl+`subscription/email/${id}`;
                }
                ,
            
            }),
        
            slug: (slug:string|number)=>({
            
                get: /**
                * Send affiliate promotion emails to affiliate users
                * 
                * Only accessible by: [admin, staff]
                * 
                * GET:///subscription/email/${slug}
                */
                ()=>{
                    return webClient.baseUrl+`subscription/email/${slug}`;
                }
                ,
            
            }),
        
        },
    
    },

    system: {
    
        accounts: {
        
            get: /**
            * Returns all accounts.
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///system/accounts
            */
            (query?:{ids?:string[], limit?:number, offset?:number})=>{
                // Build a query string for the URL 
                let queryString="";
                if(query?.ids){
                    queryString += "&ids="+query.ids.join(",");
                    }
                if(query?.limit){
                    queryString += "&limit="+query.limit;
                    }
                if(query?.offset){
                    queryString += "&offset="+query.offset;
                    }
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`system/accounts`+queryString;
            }
            ,
        
            post: /**
            * Creates a new account
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///system/accounts
            * @param recaptcha - a string such as "v2:FOO" or "v3:FOO" 
            * 
            */
            (recaptcha:string)=>{
                return webClient.baseUrl+`system/accounts`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/accounts/id/${id}
                    */
                    (query?:{fields?:string[]})=>{
                        // Build a query string for the URL 
                        let queryString="";
                        if(query?.fields){
                            queryString += "&fields="+query.fields.join(",");
                            }
                        // Replace the first & of the query string with a ?
                        if(queryString){
                            queryString = "?"+queryString.substring(1)
                        }
                    
                        return webClient.baseUrl+`system/accounts/id/${id}`+queryString;
                    }
                    ,
                
                    patch: /**
                    * Allows you to change your own account info. Some fields (slug, groups, etc...) have special rules on what can be changed.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///system/accounts/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/accounts/id/${id}`;
                    }
                    ,
                
                    agreements: {
                    
                        post: /**
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/id/${id}/agreements
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/id/${id}/agreements`;
                        }
                        ,
                    
                    },
                
                    deletionTime: {
                    
                        delete: /**
                        * Cancels the accounts pending deletion. If you call this endpoint, your account will no longer be deleted as was scheduled.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/id/${id}/deletionTime
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/id/${id}/deletionTime`;
                        }
                        ,
                    
                        request:  /**
                            * Requests that your want to be set password. Requires a verification code
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///system/accounts/id/${id}/deletionTime/--request
                            */
                            ()=>{
                                return webClient.baseUrl+`system/accounts/id/${id}/deletionTime/--request`;
                            }
                            ,
                    
                    },
                
                    emailAddresses: {
                    
                        emailAddress: (emailAddress:string|number)=>({
                        
                            delete: /**
                            * lets you remove your confirmed email address from the account - you cannot remove your last confirmed address, though!
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * DELETE:///system/accounts/id/${id}/emailAddresses/${emailAddress}
                            */
                            ()=>{
                                return webClient.baseUrl+`system/accounts/id/${id}/emailAddresses/${emailAddress}`;
                            }
                            ,
                        
                        }),
                    
                    },
                
                    password: {
                    
                        put: /**
                        * Accessible by anyone with an account.
                        * 
                        * PUT:///system/accounts/id/${id}/password
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/id/${id}/password`;
                        }
                        ,
                    
                    },
                
                    passwordVerificationrequest: {
                    
                        post: /**
                        * Requests that your account be deleted in the future. Requires a verification code
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/id/${id}/passwordVerification:request
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/id/${id}/passwordVerification:request`;
                        }
                        ,
                    
                    },
                
                    relationships: {
                    
                        get: /**
                        * Accessible by anyone with an account.
                        * 
                        * GET:///system/accounts/id/${id}/relationships
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/id/${id}/relationships`;
                        }
                        ,
                    
                    },
                
                    tokens: {
                    
                        delete: /**
                        * Deletes/revokes all tokens related to the account, effectively logging you out from all places.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/id/${id}/tokens
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/id/${id}/tokens`;
                        }
                        ,
                    
                    },
                
                }),
        
            bySlug:  (slug:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/accounts/slug/${slug}
                    */
                    (query?:{fields?:string[]})=>{
                        // Build a query string for the URL 
                        let queryString="";
                        if(query?.fields){
                            queryString += "&fields="+query.fields.join(",");
                            }
                        // Replace the first & of the query string with a ?
                        if(queryString){
                            queryString = "?"+queryString.substring(1)
                        }
                    
                        return webClient.baseUrl+`system/accounts/slug/${slug}`+queryString;
                    }
                    ,
                
                    patch: /**
                    * Allows you to change your own account info. Some fields (slug, groups, etc...) have special rules on what can be changed.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///system/accounts/slug/${slug}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/accounts/slug/${slug}`;
                    }
                    ,
                
                    agreements: {
                    
                        post: /**
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/slug/${slug}/agreements
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/slug/${slug}/agreements`;
                        }
                        ,
                    
                    },
                
                    deletionTime: {
                    
                        delete: /**
                        * Cancels the accounts pending deletion. If you call this endpoint, your account will no longer be deleted as was scheduled.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/slug/${slug}/deletionTime
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/slug/${slug}/deletionTime`;
                        }
                        ,
                    
                        request:  /**
                            * Requests that your want to be set password. Requires a verification code
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///system/accounts/slug/${slug}/deletionTime/--request
                            */
                            ()=>{
                                return webClient.baseUrl+`system/accounts/slug/${slug}/deletionTime/--request`;
                            }
                            ,
                    
                    },
                
                    emailAddresses: {
                    
                        emailAddress: (emailAddress:string|number)=>({
                        
                            delete: /**
                            * lets you remove your confirmed email address from the account - you cannot remove your last confirmed address, though!
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * DELETE:///system/accounts/slug/${slug}/emailAddresses/${emailAddress}
                            */
                            ()=>{
                                return webClient.baseUrl+`system/accounts/slug/${slug}/emailAddresses/${emailAddress}`;
                            }
                            ,
                        
                        }),
                    
                    },
                
                    password: {
                    
                        put: /**
                        * Accessible by anyone with an account.
                        * 
                        * PUT:///system/accounts/slug/${slug}/password
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/slug/${slug}/password`;
                        }
                        ,
                    
                    },
                
                    passwordVerificationrequest: {
                    
                        post: /**
                        * Requests that your account be deleted in the future. Requires a verification code
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/slug/${slug}/passwordVerification:request
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/slug/${slug}/passwordVerification:request`;
                        }
                        ,
                    
                    },
                
                    relationships: {
                    
                        get: /**
                        * Accessible by anyone with an account.
                        * 
                        * GET:///system/accounts/slug/${slug}/relationships
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/slug/${slug}/relationships`;
                        }
                        ,
                    
                    },
                
                    tokens: {
                    
                        delete: /**
                        * Deletes/revokes all tokens related to the account, effectively logging you out from all places.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/slug/${slug}/tokens
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/slug/${slug}/tokens`;
                        }
                        ,
                    
                    },
                
                }),
        
            bySystemSessionsToken:  (systemSessionsToken:string|number)=>({
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/accounts/system_sessions_token/${systemSessionsToken}
                    */
                    (query?:{fields?:string[]})=>{
                        // Build a query string for the URL 
                        let queryString="";
                        if(query?.fields){
                            queryString += "&fields="+query.fields.join(",");
                            }
                        // Replace the first & of the query string with a ?
                        if(queryString){
                            queryString = "?"+queryString.substring(1)
                        }
                    
                        return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}`+queryString;
                    }
                    ,
                
                    patch: /**
                    * Allows you to change your own account info. Some fields (slug, groups, etc...) have special rules on what can be changed.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * PATCH:///system/accounts/system_sessions_token/${systemSessionsToken}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}`;
                    }
                    ,
                
                    agreements: {
                    
                        post: /**
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/system_sessions_token/${systemSessionsToken}/agreements
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/agreements`;
                        }
                        ,
                    
                    },
                
                    deletionTime: {
                    
                        delete: /**
                        * Cancels the accounts pending deletion. If you call this endpoint, your account will no longer be deleted as was scheduled.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime`;
                        }
                        ,
                    
                        request:  /**
                            * Requests that your want to be set password. Requires a verification code
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * POST:///system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime/--request
                            */
                            ()=>{
                                return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/deletionTime/--request`;
                            }
                            ,
                    
                    },
                
                    emailAddresses: {
                    
                        emailAddress: (emailAddress:string|number)=>({
                        
                            delete: /**
                            * lets you remove your confirmed email address from the account - you cannot remove your last confirmed address, though!
                            * 
                            * Accessible by anyone with an account.
                            * 
                            * DELETE:///system/accounts/system_sessions_token/${systemSessionsToken}/emailAddresses/${emailAddress}
                            */
                            ()=>{
                                return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/emailAddresses/${emailAddress}`;
                            }
                            ,
                        
                        }),
                    
                    },
                
                    password: {
                    
                        put: /**
                        * Accessible by anyone with an account.
                        * 
                        * PUT:///system/accounts/system_sessions_token/${systemSessionsToken}/password
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/password`;
                        }
                        ,
                    
                    },
                
                    passwordVerificationrequest: {
                    
                        post: /**
                        * Requests that your account be deleted in the future. Requires a verification code
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * POST:///system/accounts/system_sessions_token/${systemSessionsToken}/passwordVerification:request
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/passwordVerification:request`;
                        }
                        ,
                    
                    },
                
                    relationships: {
                    
                        get: /**
                        * Accessible by anyone with an account.
                        * 
                        * GET:///system/accounts/system_sessions_token/${systemSessionsToken}/relationships
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/relationships`;
                        }
                        ,
                    
                    },
                
                    tokens: {
                    
                        delete: /**
                        * Deletes/revokes all tokens related to the account, effectively logging you out from all places.
                        * 
                        * Accessible by anyone with an account.
                        * 
                        * DELETE:///system/accounts/system_sessions_token/${systemSessionsToken}/tokens
                        */
                        ()=>{
                            return webClient.baseUrl+`system/accounts/system_sessions_token/${systemSessionsToken}/tokens`;
                        }
                        ,
                    
                    },
                
                }),
        
        },
    
        bookmarks: {
        
            get: /**
            * Returns bookmarks by owner.
            * 
            * Accessible by anyone with an account.
            * 
            * GET:///system/bookmarks
            */
            (query:{ownerAccountId:string})=>{
                // Build a query string for the URL 
                let queryString="";
                queryString += "&ownerAccountId="+query.ownerAccountId;
                // Replace the first & of the query string with a ?
                if(queryString){
                    queryString = "?"+queryString.substring(1)
                }
            
                return webClient.baseUrl+`system/bookmarks`+queryString;
            }
            ,
        
            post: /**
            * Adds a new bookmark.
            * 
            * Accessible by anyone with an account.
            * 
            * POST:///system/bookmarks
            */
            ()=>{
                return webClient.baseUrl+`system/bookmarks`;
            }
            ,
        
            byId:  (id:string|number)=>({
                
                    delete: /**
                    * Deletes the bookmark.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * DELETE:///system/bookmarks/id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/bookmarks/id/${id}`;
                    }
                    ,
                
                }),
        
        },
    
        emails: {
        
            emailAddress: (emailAddress:string|number)=>({
            
                magicLink: {
                
                    send:  /**
                        * (emails a signed JWT (10 day magic token containing email address and account ID) to the user, this can be used to post an auth token)
                        * 
                        * Accessible by anyone on the web.
                        * 
                        * POST:///system/emails/${emailAddress}/magicLink/--send
                        */
                        ()=>{
                            return webClient.baseUrl+`system/emails/${emailAddress}/magicLink/--send`;
                        }
                        ,
                
                },
            
                subscriptions: {
                
                    get: /**
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/emails/${emailAddress}/subscriptions
                    */
                    ()=>{
                        return webClient.baseUrl+`system/emails/${emailAddress}/subscriptions`;
                    }
                    ,
                
                    patch: /**
                    * Accessible by anyone on the web.
                    * 
                    * PATCH:///system/emails/${emailAddress}/subscriptions
                    */
                    ()=>{
                        return webClient.baseUrl+`system/emails/${emailAddress}/subscriptions`;
                    }
                    ,
                
                },
            
            }),
        
        },
    
        sessions: {
        
            get: /**
            * Returns all sessions on the server.
            * 
            * Only accessible by: [admin]
            * 
            * GET:///system/sessions
            */
            ()=>{
                return webClient.baseUrl+`system/sessions`;
            }
            ,
        
            post: /**
            * Creates a session that is stored on the server. You will need to verify the session by clicking the link in your email.
            * 
            * Accessible by anyone on the web.
            * 
            * POST:///system/sessions
            * @param recaptcha - a string such as "v2:FOO" or "v3:FOO" 
            * 
            */
            (recaptcha:string)=>{
                return webClient.baseUrl+`system/sessions`;
            }
            ,
        
            systemAccountId: {
            
                id: (id:string|number)=>({
                
                    get: /**
                    * Returns all sessions related to the account. You can only see your own sessions, unless you are admin.
                    * 
                    * Accessible by anyone with an account.
                    * 
                    * GET:///system/sessions/system_account_id/${id}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/sessions/system_account_id/${id}`;
                    }
                    ,
                
                }),
            
            },
        
            byToken:  (token:string|number)=>({
                
                    delete: /**
                    * Revokes the token / logs out of the session.
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * DELETE:///system/sessions/token/${token}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/sessions/token/${token}`;
                    }
                    ,
                
                    get: /**
                    * Returns information about the session.
                    * 
                    * Accessible by anyone on the web.
                    * 
                    * GET:///system/sessions/token/${token}
                    */
                    ()=>{
                        return webClient.baseUrl+`system/sessions/token/${token}`;
                    }
                    ,
                
                }),
        
        },
    
    },

} );
    export type ClientUrlsObjectType = ReturnType<typeof MakeClientUrlsObject>;

   