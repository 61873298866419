import assert from "assert";
import { ApiResource } from "../../definitions/ApiResource";
import { AccessGroup } from "../../definitions/system/AccessGroup";
import ApiError from "./ApiError";
import { HttpStatusCodes as _ } from "./HttpStatusCodes";


const ApiErrors = {

    

    ResourceClosed: new ApiError( _.Locked423, {
        en: "Because this resource has been closed, you cannot perform this operation."
    }, "ResourceClosed" ),

    UnableToContactRecipient: new ApiError( _.Forbidden403, {
        en: "Unable to contact the recipient. This is an issue with the recipient's configuration, so there's nothing you can do about this error."
    }, "UnableToContactRecipient" ),

    ForbiddenOperationByGroup: (yourGroups:AccessGroup[],allowedGroup:AccessGroup)=>new ApiError( _.Unauthorized403, {
        en: `You must be in the ${allowedGroup} group to perform this operation.`
    }, "ForbiddenOperationByGroup" ),


    ForbiddenOperationByGroups: (yourGroups:AccessGroup[],allowedGroups:AccessGroup[])=>new ApiError( _.Unauthorized403, {
        en: `You must be in one of these groups to perform this operation: ${allowedGroups.join(", ")}`
    }, "ForbiddenOperationByGroups" ),

    NoDefaultResultFormat: new ApiError( _.NotAcceptable406, {
        en:`You didn't provide a response format in the "Accept" header, and this endpoint has no default response format.`
    }, "NoDefaultResultFormat"  ),

    InvalidAcceptHeader: (providedMimeTypes:string[],availableMimeTypes:string[])=>new ApiError( _.NotAcceptable406, {
        en: `Cannot provide an acceptable response format. You asked for ${providedMimeTypes.join(" or ")}, but the only available types are: ${availableMimeTypes.join(" or ")}.`
    }, "InvalidAcceptHeader" ),

    ForbiddenResultFormat: (format:ApiResource,reason:string)=>new ApiError( _.Forbidden403, {
        en: `You cannot view this resource in the ${format.mimeTypeJson} format. Reason: ${reason}`
    }, "ForbiddenResultFormat" ),

    ForbiddenResultFormatByGroups: (format:ApiResource)=>new ApiError( _.Forbidden403, {
        en: `You must be in one of these groups to see this resource in the ${format.mimeTypeJson} format: ${format.resultFormatAccessGroupList?.join(", ")}`
    }, "ForbiddenResultFormatByGroups" ),

    ForbiddenResultFormatByOwnership: (format:ApiResource)=>new ApiError( _.Forbidden403, {
        en: `You cannot view this resource in the ${format.mimeTypeJson} format unless you are the owner of the resource (your accountId must be the same as the resource's ${format.resultFormatRequiredFieldMatchingAccountId})`
    }, "ForbiddenResultFormatByOwnership" ),

    DoesNotOwnResource: new ApiError( _.Forbidden403, {
        en: "You must be the owner of this resource in order to perform this operation."
    }, "DoesNotOwnResource"  ),

    IsNotMemberOfResource: new ApiError( _.Forbidden403, {
        en: "You must be a member/participant of this resource before you can perform this operation."
    }, "IsNotMemberOfResource"  ),

    ForbiddenByRulesOfResource: new ApiError( _.Forbidden403, {
        en: "Do to the rules set on this resource, you cannot perform this operation."
    }, "ForbiddenByRulesOfResource"  ),

    ForbiddenQuery: new ApiError( _.Forbidden403, {
        en: "Forbidden query. You aren't allowed to search for the provided query value.",
    }, "ForbiddenQuery" ),

    PasswordAuthenticationDisabled: new ApiError( _.Unauthenticated401, {
        en: "You provided a password, but password authentication has been disabled for this account."
    }, "PasswordAuthenticationDisabled" ),

    IncorrectPassword: new ApiError(_.Unauthenticated401, {
        en: "Incorrect password."
    }, "IncorrectPassword" ),

    SessionEmailOutdated: new ApiError(_.Unauthenticated401, {
        en: "The account associated with this session changed their email address, so this session is no longer valid. Please create a new session."
    }, "SessionEmailOutdated" ),

    SessionVerificationWindowExpired: new ApiError(_.Gone410, {
        en: "Verification window for session expired. Please create a new session and verify that one instead."
    }, "SessionVerificationWindowExpired" ),

    SessionAuthenticationExpired: new ApiError(_.Unauthenticated401, {
        en: "Authentication for session expired. Please refresh the session or create a new one."
    }, "SessionAuthenticationExpired" ),

    SessionRefreshExpired: new ApiError(_.Unauthenticated401, {
        en: "Refresh window for session expired. Please create a new session."
    }, "SessionRefreshExpired" ),

    SessionIsAlreadyVerified: new ApiError(_.Conflict409, {
        en: "This session has already been verified."
    }, "SessionIsAlreadyVerified" ),

    UnverifiedSession: new ApiError(_.Unauthenticated401, {
        en: "The session hasn't been verified, so it can't be used, yet."
    }, "UnverifiedSession" ),

    EmailAlreadyInUse: new ApiError(_.Conflict409, {
        en: "This email address is already in use."
    }, "EmailAlreadyInUse" ),

    SlugAlreadyInUse: new ApiError(_.Conflict409, {
        en: "This username/handle is already in use."
    }, "SlugAlreadyInUse" ),

    NoAuthenticationInAuthorizationHeader: new ApiError(_.Unauthenticated401, {
        en: `No authentication provided. Please place authentication credentials in the "Authorization" header.`
    }, "NoAuthenticationInAuthorizationHeader" ),

    MalformedAuthentication: new ApiError(_.Unauthenticated401, {
        en: "Authentication was malformed."
    }, "MalformedAuthentication" ),
    
    MalformedVerification: new ApiError(_.Unauthenticated401, {
        en: "Verification was malformed."
    }, "MalformedVerification"),

    MissingVerification: new ApiError(_.Unauthenticated401, {
        en: "Verification was not provided."
    }, "MissingVerification"),

    ReCaptchaV3VerificationFailed: new ApiError(_.Unauthenticated401, {
        en: "Verification with ReCaptchaV3 failed."
    }, "ReCaptchaV3VerificationFailed"),

    ReCaptchaV2VerificationFailed: new ApiError(_.Unauthenticated401, {
        en: "Verification with ReCaptchaV2 failed. Please try verification again."
    }, "ReCaptchaV2VerificationFailed"),

    NotImplemented: new ApiError(_.NotImplemented501, {
        en: "Not implemented."
    }, "NotImplemented" ),

    InsufficientPermissions: new ApiError(_.Unauthorized403, {
        en: "Authentication token isn't part of an access group with sufficient permissions for this request."
    }, "InsufficientPermissions" ),

    EndpointNotFound: new ApiError(_.NotFound404, {
        en: "Endpoint not found.",
    }, "EndpointNotFound" ),

    InvalidPhoneNumberError: new ApiError(_.BadRequest400, {
        en: "Invalid phone number.",
    }, "InvalidPhoneNumberError" ),

    MalformedData: new ApiError(_.BadRequest400, {
        en: "Malformed data.",
    }, "MalformedData" ),

    MalformedBody: new ApiError( _.BadRequest400, {
        en: "Malformed body.",
    }, "MalformedBody" ),

    MalformedQuery: new ApiError( _.BadRequest400, {
        en: "Malformed query.",
    }, "MalformedQuery" ),

    EmailIsNotVerified: new ApiError( _.BadRequest400, {
        en: "Email is not verified.",
    }, "EmailIsNotVerified" ),

    MalformedUrlParameters: new ApiError(_.BadRequest400, {
        en: "Malformed URL parameters.",
    }, "MalformedUrlParameters" ),

    RecordNotFound: new ApiError(_.NotFound404, {
        en: "Record not found."
    }, "RecordNotFound" ),

    BadGateway: new ApiError(_.BadGateway502, {
        en: "Bad gateway",
        ja: "..."
    }, "BadGateway" ),
    VerificationCodeIsExpired: new ApiError(_.BadRequest400, {
        en: "Verification code is expired.",
    }, "VerificationCodeIsExpired"),
    VerificationCodeIsFailed: new ApiError(_.BadRequest400, {
        en: "Verification code is failed.",
    }, "VerificationCodeIsFailed"),
    UsageLimitExceeded: new ApiError(_.BadRequest400, {
        en: "Usage limit exceeded.",
    }, "UsageLimitExceeded"),
};

// Check ApiErrors for mistakes
// not a perfect check
for(const key of Object.keys(ApiErrors)){
    const specimen = (ApiErrors as any)[key];
    let good = true;
    if( specimen instanceof ApiError ){
        good = specimen.typeId===key;
    }else{
        // note, there's no easy way to validate against a function that needs various parameters
        // good = specimen().id===key;
    }
    assert(good, "Incorrect id for ApiErrors."+key);
}

export default ApiErrors;