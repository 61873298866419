import { MakeClientApiObject, MakeClientUrlsObject, ClientApiObjectType, ClientUrlsObjectType } from "./ClientApiObject--generated";
import WebClient, { WebClientConfig } from "./WebClient";

export default class LibertyClient{
    private _webClient:WebClient;

    public readonly api:ClientApiObjectType;
    public readonly urls:ClientUrlsObjectType;
    

    constructor({
        webClientConfig
    }:{
        webClientConfig:WebClientConfig
    }){
        webClientConfig.baseUrl = webClientConfig.baseUrl||"https://accounts.liberty-hjc.com:8001/";

        this._webClient = new WebClient( webClientConfig );

        /** Use this to make API calls. */
        this.api = MakeClientApiObject( this._webClient );

        /**
         * Use this to get the URLs for various API calls.
         * For example, if you're assigning a URL to an <img src=FOO>, you can use this to get a file's url
         */
        this.urls = MakeClientUrlsObject( this._webClient );

    }
}
