import "styled-components/macro";
import { css } from "styled-components/macro";



import * as Three from "three";
import React from "react";
import { Box3, DirectionalLight, Material, PointLight, Vector3, WebGLRenderer } from "three";
import Resources from "../../logic/Resources";
import { lerp } from "three/src/math/MathUtils";
import { BoothObjectLightItem, ExpoBoothRecordObjectRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import { MakeFileUrlWithExtension } from "../../logic/utility/Files";
import SystemContext from "../../logic/SystemContext";
import {GUI }from "dat.gui";
import Button from "../common/Button";
import { MenuHeader } from "../common/MenuHeader";
import { MenuSubHeader } from "../common/MenuSubHeader";

// TODO: get this dynamically
const size = [800,800];

const defaultCameraDistanceFromOrigin = 1.5;

const MAX_LIGHTS = 3;

type T = {
    objectRecord: ExpoBoothRecordObjectRecord;
    save: (lights: BoothObjectLightItem[]) => any;
    systemContext:SystemContext,
};



/* Given a model via URL, renders a canvas that renders the model. The user can drag/mousewheel to inspect the model. */
export default class ModelViewerComponent extends React.Component<T>{


    containerElement?:HTMLDivElement|null;

    renderer?:WebGLRenderer;
    scene = new Three.Scene();
    camera = new Three.PerspectiveCamera( 70, 1 );
    
    horizontalRotationHelper = new Three.Object3D();
    verticalRotationHelper = new Three.Object3D();

    isMouseDown = false;
    /** A number between -1 and 1, so the user can zoom in /out of the model. A higher value means you're zoomed in closer. */
    zoom = 0;

    readonly modelFileUrl;
    lights:BoothObjectLightItem[];

    readonly systemContext;
    constructor(props:T){
        super(props);
        this.systemContext=props.systemContext;
        this.modelFileUrl = props.systemContext.client.urls.storage.files.byId((props.objectRecord.fileIds||[])[0]||"").get()+(".gltf");
        if(!this.modelFileUrl){
            throw new Error(JSON.stringify(props.objectRecord));
        }
        this.lights = props.objectRecord.modelViewerLighting||[];
        // this.lights  = [this.lights[0]||{
        //     type:"point",
        //     intensity: 1,
        //     transform:{
        //         position:[0,0,0]
        //     }
        // }];

        console.log(this.modelFileUrl,this.lights);

    }

    state = {
        loading:true,
    }

    threeJsAnimationLoop( time:number ){
        this.renderer?.render( this.scene, this.camera );
    }

    gui?:GUI;
    async componentDidMount(){
        await this.loadModelAsync();
        this.initializeThreeJs();
    }

    
    async loadModelAsync(){
        try{
            const gltf = await Resources.LoadGLTFAsync( this.modelFileUrl );
            const gltfScene = gltf.scene.clone();

            // Get the extents of the model so that we can scale & center it
            const boundingBox = new Box3().setFromObject( gltfScene );
            const boundingBoxSize = boundingBox.getSize( new Vector3() );
            const boundingBoxSizeLargestDimension = Math.max( ...boundingBoxSize.toArray() );
            const modelScale = 1.0 / boundingBoxSizeLargestDimension;
            
            gltfScene.scale.setScalar( modelScale );

            this.horizontalRotationHelper.add( gltfScene );
            this.verticalRotationHelper.add( this.horizontalRotationHelper );
            this.scene.add( this.verticalRotationHelper );

            // Start with a bit of rotation
            this.horizontalRotationHelper.rotateY( Math.PI/4 );
            this.verticalRotationHelper.rotateX( Math.PI/4 );

        
        }catch( error ){
            console.log("ModelViewerComponent: Unable to load GLTF file: "+this.modelFileUrl );
        }

        this.setState({loading:false});
    }

    initializeThreeJs(){

        this.refreshDatGui();

        // TODO: get this dynamically (clientWidth is 0 when this code runs, currently)
        // const size = [this.containerElement?.clientWidth||0,this.containerElement?.clientHeight||0];

        this.camera = new Three.PerspectiveCamera( 70, size[0]/size[1] );
        this.camera.position.z = defaultCameraDistanceFromOrigin;

        const renderer = new Three.WebGLRenderer({alpha:true,antialias:true});
        renderer.setSize( size[0],size[1] );
        renderer.setClearColor("#ffffff", .2);


        // const renderer = new Three.WebGLRenderer({alpha:false,antialias:true});
        // renderer.setSize( size[0],size[1] );
        // renderer.setClearColor("#ffffff");

        this.containerElement?.appendChild( renderer.domElement );

        // Disables the browser's right click menu from appearing when we click the threejs container element.
        renderer.domElement.oncontextmenu = ()=>false;

        this.renderer = renderer;

        renderer.setAnimationLoop( time=>this.threeJsAnimationLoop(time) )

        this.refreshLights();

        // for(const r of [.5,2,4]){
        //     const delta = Math.PI/(2*r);
        //     for(let t=0;t<Math.PI*2;t+=delta){
        //         const x = r*Math.cos(t);
        //         const y = r*Math.sin(t);
        //         const pointLightIntensity = .3;//lerp(.3,1,delta/(Math.PI*2));
        //         const pointLightDistance = 10;
        //         const pointLight = new PointLight(0xFFFFFF,pointLightIntensity,pointLightDistance);
        //         pointLight.position.set(x,y,2);
        //         this.scene.add(pointLight);
        //     }
        // }

        // const pointLightIntensity = .5;//lerp(.3,1,delta/(Math.PI*2));
        // const pointLightDistance = 10;
        // const pointLight = new PointLight(0xFFFFFF,pointLightIntensity,pointLightDistance);
        // pointLight.position.set(0,0,2);
        // this.scene.add(pointLight);

        // const directionalLightIntensity = 1;//lerp(.3,1,delta/(Math.PI*2));
        // const directionalLight = new DirectionalLight(0xFFFFFF,directionalLightIntensity);

        // directionalLight.position.set(0,0,2);
        // directionalLight.rotateX(-Math.PI/4);
        // this.scene.add(directionalLight);
    }

    refreshDatGui(){
        this.gui?.destroy();
        const gui = this.gui = new GUI();

        for(const light of this.lights){

            const folder = gui.addFolder("Light "+this.lights.indexOf(light));
            folder.open();  

            if(!light.transform?.position){
                throw new Error();
            }
            
            const a = 3;

            if( light.type!=="ambient")
            for(const c of ["0X","1Y","2Z"])
            folder.add(
                light.transform?.position,
                c[0],
                -a,a,.1,
            ).name(
                c[1]
            ).onChange(
                value=>{
                    this.refreshLights();
                }
            ).onFinishChange(
                value=>{
                    this.refreshLights();
                    this.props.save(this.lights);
                }
            );
            if( light.type==="directional"){
                light.pointAt = light.pointAt||[0,0,0];
                for(const c of ["0@X","1@Y","2@Z"])
                folder.add(
                    light.pointAt,
                    c[0],
                    -a,a,.1,
                ).name(
                    c.slice(1)
                ).onChange(
                    value=>{
                        this.refreshLights();
                    }
                ).onFinishChange(
                    value=>{
                        this.refreshLights();
                        this.props.save(this.lights);
                    }
                );
            }
            light.intensity=light.intensity===undefined?1:light.intensity;
            folder.add(
                light,
                "intensity",
                .1,4,.1,
            ).name(
                "Intensity"
            ).onChange(
                value=>{
                    this.refreshLights();
                }
            ).onFinishChange(
                value=>{
                    this.refreshLights();
                    this.props.save(this.lights);
                }
            );
            folder.add(
                light,
                "type",
                {"Point":"point","Ambient":"ambient","Directional":"directional"}
            ).name(
                "Type"
            ).onChange(
                value=>{
                    this.refreshLights();
                    this.refreshDatGui();
                }
            ).onFinishChange(
                value=>{
                    this.refreshLights();
                    this.props.save(this.lights);
                    this.refreshDatGui();   
                }
            );
        }
        
    }

    addedLights=[] as Three.Light[];
    refreshLights(){
        while( this.addedLights.length ){
            const l = this.addedLights.shift();
            if(l){
                this.scene.remove(l);
                l?.dispose();
            }
        }

        const lights = this.lights;
        for(const light of lights){
            const color = light.color ? light.color : 0xFFFFFF;
            const {intensity,distance,transform} = light;
            const pointAt = light.pointAt as [number,number,number]|undefined;
            const decay = undefined;
            let threeLight;
            switch(light.type){
                case "ambient":
                    threeLight = new Three.AmbientLight(color,intensity);
                    break;
                case "point":
                    threeLight = new Three.PointLight(color,intensity,distance,decay);
                    break;
                case "directional": 
                    threeLight = new Three.DirectionalLight(color,intensity);
                    break;
            }
            this.scene.add(threeLight);   
            if(transform?.position){
                threeLight.position.set(...transform.position as [number,number,number]);
            }
            if( pointAt && "target" in threeLight ){
                threeLight.target.position.set(...pointAt);
                threeLight.target.updateMatrixWorld();
            }
            this.addedLights.push(threeLight);
        }

        this.scene.traverse(
            x=>{
                if( x&& x instanceof Material){
                    x.needsUpdate=true;
                }
            }
        );
        // if(!lights.length){
        //     const light = new Three.AmbientLight(0xFFFFFF, 3);
        //     this.scene.add(light);   
        //     this.addedLights.push(light);
        // }
    }

    onMouseDown( event:React.MouseEvent ){
        this.isMouseDown = true;
    }
    onMouseMove( event:React.MouseEvent ){
        if( !this.isMouseDown ){
            return;
        }

        this.horizontalRotationHelper.rotateY(
            10 * event.movementX / window.innerWidth
        );
        this.verticalRotationHelper.rotateX(
            10 * event.movementY / window.innerHeight
        );
        // this.scene.rotateY(
        //     event.movementY / window.innerHeight
        // );
    }
    onMouseUp( event:React.MouseEvent ){
        this.isMouseDown = false;
    }
    onWheel( event:React.WheelEvent ){
        
        const zoomSpeed = .001;
        this.zoom += -event.deltaY * zoomSpeed;
        this.zoom = Math.max(-1, this.zoom );
        this.zoom = Math.min(1, this.zoom );
        this.camera.position.z = defaultCameraDistanceFromOrigin - defaultCameraDistanceFromOrigin * this.zoom;

        event.stopPropagation();
    }

    render(){
        // const theme =this.systemContext.
        return this.state.loading ? <>Loading...</> : <>
        <div css={Layout.LightTray}>
            <div css={Layout.LightHeader}>
                <MenuSubHeader>Lighting</MenuSubHeader>
                <Button layout={Layout.LightTrayButton} disabled={this.lights.length>=MAX_LIGHTS} onClick={()=>{
                    this.lights.push({
                        type:"point",
                        intensity: 1,
                        transform:{
                            position:[0,0,0]
                        }
                    });
                    this.refreshLights();
                    this.props.save(this.lights);
                    this.forceUpdate();
                    this.refreshDatGui();
                }}>New Light</Button>
            </div>
            <div css={Layout.LightItems}>
            {
                this.lights.map((a,i)=><div css={Layout.LightItem}>
                    <div>Light {i}</div>
                    <Button onClick={()=>{
                        this.lights = this.lights.filter(
                            b=>this.lights.indexOf(b)!==i
                        )
                        this.props.save(this.lights);
                        this.refreshDatGui();
                        this.refreshLights();
                        this.forceUpdate();
                    }}>
                        Delete
                    </Button>
                </div>)
            }
            </div>
        </div>
        <div
            style={{
                width:"auto",
                height:"100vh",
            }}
            ref={ ref=>this.containerElement=ref }
            onMouseDown={ event=>this.onMouseDown(event) }
            onMouseMove={ event=>this.onMouseMove(event) }
            onMouseUp={ event=>this.onMouseUp(event) }
            onWheel={ event=>this.onWheel(event) }
        /></>
    }

    componentWillUnmount(){
        this.gui?.destroy();
    }
}

const Layout = {
    LightTray: css`
        display: flex;
        flex-direction:row;
        background-color: #f2f2f2;
        width: 80vw;
        align-items: center;
        justify-content: center;
    `,
    LightHeader:css`
        /* width: 40vw; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;
        & > * {
            margin: 1em;
        }
    `,
    LightTrayButton:css`
        width: 10vw;
    `,
    LightItems: css`
        max-width: 60vw;
        overflow-x:scroll;
        margin: 1em;
        background-color: #f2f2f2;
        border: 1px solid #f2f2f2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: .5em;
        ;
    `,
    LightItem: css`
        display: flex;
        flex-direction:column;
        justify-content: space-around;
        align-items:center;
        margin: 1em;
        height: 5em;
        & > * {
            margin: .5em;
        }
    `,
}