import { BoothObjectLightItem, ExpoBoothRecordObjectRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoBoothRecordObject";
import { BoothEntity } from "../booths/BoothEntity";
import { BoothSubObjectEntity } from "../booths/BoothSubObjectEntity";
import SystemContext from "../SystemContext";
import { useSystemContext } from "../SystemContextComponents";

export interface DialogueData{
    contents?:JSX.Element|JSX.Element[],
    fileId?:string,
    lights?:BoothObjectLightItem[],
    text?:string,
    boothEntity?:BoothEntity,
    boothSubObjectEntity?:BoothSubObjectEntity,
    /** Used when the user needs to clearly see what's going on behind the dialog. eg when using the dialogue to make changes to the 3D world. */
    seeThrough?:boolean,

    editLights?:{
        objectRecord:ExpoBoothRecordObjectRecord,
        save:(lights:BoothObjectLightItem[])=>any
    }
}

export default class DialogSystem{
    readonly systemContext;

    private _data?:DialogueData;
    /** Holds the data of the currently opened dialog. (undefined if no currently opened dialog) */
    get data(){return this._data;};
    
    constructor( systemContext:SystemContext ){
        this.systemContext = systemContext;
    }

    showDialog( data:DialogueData ){
        this._data = data;
        this.systemContext.forceReactUpdate();
    }

    closeDialog(){
        this._data = undefined;
        this.systemContext.forceReactUpdate();
    }
}

export function useDialogSystem(){
    const context = useSystemContext();
    return context.dialogSystem;
}