import { DoesNotExistResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { ExpoAdRecord } from "../../libraries/l2-common/l2-common-ts/definitions/expo/ExpoAdRecord";
import SystemContext from "../SystemContext";
import { AdEntity } from "./AdEntity";

export class AdRepository{
    private _api;

    private _recordByIdCache = new Map<string, ExpoAdRecord>();
    
    constructor( systemContext:SystemContext ){
        this._api = systemContext.api;
    }

    async getByIdAsync( id:string ){
        if( this._recordByIdCache.has(id) ){
            const record = this._recordByIdCache.get(id);
            return new AdEntity(
                record as ExpoAdRecord
            );
        }
        const record = await this._api.expo.ads.byId(id).getAsync();
        if( record instanceof DoesNotExistResult ){
            return undefined;
        }
        return new AdEntity( record );
    }

    async cacheManyByIdsAsync( ids:string[] ){
        await this.maybeGetManyByIdsAsync(ids);
    }

    /**
     * 
     * @param ids 
     * @returns A Map of AdEntities, mapping each id to each adEntity. 
     */
    async maybeGetManyByIdsAsync( ids:string[] ){
        const result = new Map<string, AdEntity>();
        const records = await this._api.expo.ads.getAsync({
            ids
        });
        for(const record of records ){
            const ad = new AdEntity( record );
            if( record.id ){
                this._recordByIdCache.set( record.id, record );
                result.set( record.id, ad );
            }
        }
        return result;
    }

}