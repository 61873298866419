import { useState } from "react";
import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents"

export type ButtonType = "Normal"|"Quiet"|"Loud"|"Icon";

export default function Button({
    children,
    disabled,
    onClick,
    layout,
    type,
}:{
    children:any,
    onClick?:()=>void,
    disabled?:boolean,
    layout?:CSSProp,
    type?:ButtonType,
}){
    const theme = useTheme();
    const [pushed,setPushed] = useState(false);

    const buttonThemeBase = theme.Button[type||"Normal"](disabled);
    
    const css = Layout+''+(
        pushed?buttonThemeBase.Pushed:buttonThemeBase.Default
    )+layout;
    return <div css={css}
            onMouseDown={()=>{
                if(disabled){
                    return;
                }
                setPushed(true);
            }}
            onMouseUp={()=>{
                setPushed(false);
            }}
            /** use this instead of onMouseUp, otherwise you get issues where a popup dialog's buttons may be clicked when you let go of the mouse after pushing the button that opened the dialog */
            onClick={()=>{
                if( !disabled && onClick ){
                    onClick();
                }
            }}
            onMouseOut={()=>{
                setPushed(false);
            }}
    >
        {children}
    </div>
}

/** Because button is a "text-like" element (not a component), we, by default, size it based on local text, rather than relying on extenral sizing. */
const Layout = css`
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 3em; */
    height: 2em;
    padding: 0 3em;
`;