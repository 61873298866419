import * as Three from "three";
import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import NodeMessageControls from "../nodes/NodeMessageControls";
import Resources from "../Resources";
import FloatParentBehaviorNode from "../effects/FloatParentBehaviorNode";

import TEXTURE_PANEL from "../../images/panels/Panel--HiTech--Light(LoginBoxBackground).png";
import TEXTURE_ICON from "../../images/icons/question.png";
import TEXTURE_ICON_HIGHLIGHTED from "../../images/icons/question--highlighted.png";
import TEXTURE_FACE from "../../images/icons/face--wink.png";
import { BoothEntity } from "./BoothEntity";
import { Mesh } from "three";


/** Displays a file in a panel. If clicked on,  */
export default class KioskNode extends Object3DNode{
    _displayMesh;
    _displayNode;    

    _iconMesh;
    _iconNode;

    public boothEntity;
    
    constructor( boothEntity:BoothEntity, systemContext:SystemContext ){
        super(
            new Three.Object3D(),
            systemContext
        );

        this.boothEntity = boothEntity;

        this._displayMesh =  new Three.Mesh(
            new Three.BoxGeometry( .01,1.5,1.5/1.61 ),
            new Three.MeshStandardMaterial({transparent:true})
        );;
        this._displayNode = new Object3DNode(
            this._displayMesh,
            systemContext,
            this
        );
        this.addChild( this._displayNode );

        this._iconMesh = new Mesh(
            new Three.BoxGeometry( .011,.33,.33 ),
            new Three.MeshStandardMaterial({transparent:true})
        );
        this._iconNode = new Object3DNode(
            this._iconMesh,
            systemContext,
            this
        );
        this.addChild( this._iconNode );

        this.addChild(
            new FloatParentBehaviorNode( 0.1, 10.0, systemContext )
        );

        /** Start dim, we light it up on mouseover */
        this._dim();

        this._setTexturesAsync();
    }

    // TODO: Adjust the aspect ratio of the texture to match the scale of this object
    async _setTexturesAsync(){
        const texture = await Resources.LoadTextureAsync( TEXTURE_PANEL );
        this._displayMesh.material.map = texture;
        this._displayMesh.material.needsUpdate = true;
        
        const iconTexture = await Resources.LoadTextureAsync( TEXTURE_FACE );
        this._iconMesh.material.map = iconTexture;
        this._iconMesh.material.needsUpdate = true;
    }

    Message__Hover( messageControls:NodeMessageControls ){
        if( this.boothEntity.resolvedDescription!==undefined ){
            this._lightUp();
            this.systemContext.threeSystem.setCanvasCursor("pointer");
        }
    }

    Message__Unhover( messageControls:NodeMessageControls ){
        if( this.boothEntity.resolvedDescription!==undefined ){
            this._dim();
            this.systemContext.threeSystem.setCanvasCursor("auto");
        }
    }

    _dim(){
        this._displayMesh.material.color.set(0xBBBBBB);
    }

    _lightUp(){
        this._displayMesh.material.color.set(0xFFFFFF);
    }
}