import { useComponentDidMount, useComponentDidUpdate } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";

/**
 * Sets the document title when present.
 */
export default function DocumentTitle({
    title
}:{
    title:string
}){
    useComponentDidUpdate(()=>{
        document.title = title;
    })
    return <></>;
}