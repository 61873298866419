import * as Three from "three";
import { Euler, Quaternion, Vector3 } from "three";

import Object3DNode from "../nodes/Object3DNode";
import SystemContext from "../SystemContext";
import InputMap from "../input/InputMap";
import ThirdPersonFollowCameraBehaviorNode from "../camera/ThirdPersonFollowCameraBehaviorNode";
import FirstPersonCameraBehaviorNode from "../camera/FirstPersonCameraBehaviorNode";
import PersonNode from "./PersonNode";
import { ClickToActOnObjectBehaviorNode } from "./ClickToActOnObjectBehaviorNode";
import { INPUT_LEFT, INPUT_RIGHT, INPUT_FORWARD, INPUT_BACKWARD, INPUT_RUN } from "../input/InputSystem";
import { FILENAME_FOOTSTEP } from "../audio/AudioSystem";


const POSITION_SAVE_INTERVAL = 5;

const CAMERA_TYPE:"first"|"third"="first";

const HEAD_HEIGHT = 1.6;

const FOOTSTEP_INTERVAL = 6;
const FOOTSTEP_INTERVAL_RANDOM = .5;
const FOOTSTEP_BASE_VOLUME_SCALE = 2.0;

export class PlayerNode extends Object3DNode{
    private _personNode;
    private _cameraBehavior;
    private _timeToNextTransformSave = POSITION_SAVE_INTERVAL;
    private _distanceUntilNextFootstep = FOOTSTEP_INTERVAL;

    constructor( systemContext:SystemContext ){
        super( new Three.Object3D(), systemContext );

        this._personNode = new PersonNode(systemContext, this,{
            hideModel: CAMERA_TYPE==="first"
        } );
        this.addChild( this._personNode );

        if( CAMERA_TYPE==="first"){
            this._cameraBehavior = new FirstPersonCameraBehaviorNode( this, new Vector3(0,HEAD_HEIGHT,0), systemContext );
        }else{
            this._cameraBehavior = new ThirdPersonFollowCameraBehaviorNode( this, systemContext );
        }
        this.addChild( this._cameraBehavior );

        this.addChild( new ClickToActOnObjectBehaviorNode(systemContext,this) );

        // this._TEMP_maybeLoadTransformFromCache();
    }

    updateHook( deltaTime:number ){
        this._updateMovement(deltaTime);
        this._updateSavedTransform(deltaTime);
    }

    private _updateMovement( deltaTime:number ){
        const input = this.systemContext.inputSystem;
        const inputXY = new Three.Vector2(
            ( input.isInputKeyDown(INPUT_LEFT)?-1:0 ) + ( input.isInputKeyDown(INPUT_RIGHT)?1:0 ) ,
            ( input.isInputKeyDown(INPUT_FORWARD)?-1:0 ) + ( input.isInputKeyDown(INPUT_BACKWARD)?1:0 )
        );

        // Quick normalize
        if( inputXY.x!==0 && inputXY.y!==0 ){
            inputXY.multiplyScalar( 0.707106781 );
        }
        
        const walkDirection = new Vector3(
            inputXY.x,
            0,
            inputXY.y
        )
        const rotationToApplyToXYZ = new Euler(
            0,
            this._cameraBehavior.cameraRotationYAxis,
            0  
        );
        walkDirection.applyQuaternion( new Quaternion().setFromEuler(rotationToApplyToXYZ) );

        const walkSpeed = this.systemContext.settings.getNumber(
            "walkSpeedUnitsPerSecond"
        );
        const run = !!input.isInputKeyDown(INPUT_RUN)
        const distanceTraveled = this._personNode.walk({
            normalizedDirection: walkDirection,
            run,
            walkSpeed,
            runSpeed: walkSpeed * 2,
            deltaTime
        });
        this._updateFootstepsAsync(distanceTraveled);
    }

    // TODO: factor into separate class
    private _updateFootstepsAsync(distanceTraveled:number){
        // prevent continuing to step while the previous step hasn't finished yet
        // (this prevents a bunch of footsteps from queueing to play at the beginning)
        if( this._distanceUntilNextFootstep<0){
            return;
        }
        this._distanceUntilNextFootstep -= distanceTraveled;
        if( this._distanceUntilNextFootstep < 0 ){
            let detuneCents = 25+50*Math.random();
            // // occasionally lower pitch a major third
            // if( Math.random() < .2 ){
            //     detuneCents -= 400;
            // }
            const volumeScale = (.8+.2*Math.random())*FOOTSTEP_BASE_VOLUME_SCALE;
            this.systemContext.audioSystem.playAsync({
                fileName: FILENAME_FOOTSTEP,
                volumeScale,
                detuneCents,
            });
            this._distanceUntilNextFootstep = FOOTSTEP_INTERVAL + FOOTSTEP_INTERVAL_RANDOM*Math.random();
        }
    }

    private _updateSavedTransform( deltaTime:number ){
        this._timeToNextTransformSave -= deltaTime;
        if( this._timeToNextTransformSave <= 0 ){
            this._TEMP_saveTransformToCache();
            this._timeToNextTransformSave = POSITION_SAVE_INTERVAL;
        }
    }

    private _TEMP_saveTransformToCache(){
        window.localStorage.setItem("TEST__playerPosition",JSON.stringify(this.object3D.position.toArray()));
    }
    private _TEMP_maybeLoadTransformFromCache(){
        const maybePositionString = window.localStorage.getItem("TEST__playerPosition");
        if( maybePositionString ){
            const positionArray:[number,number,number] = JSON.parse( maybePositionString );
            this.object3D.position.set(...positionArray);
        }
    }

}