
/*
    The type of modulo used in modular arithmetic
    negative input produces non-negative output
*/
export function Modulo(a:number,modulus:number){
    return ( (a%modulus)+modulus ) % modulus;
}

export function Remainder(dividend:number,divisor:number){
    return dividend%divisor;
}