import { StringWithTranslations } from "../../definitions/general/StringWithTranslations";
import  { HttpStatusCode } from "./HttpStatusCodes";

/**
 * WARNING: DO NOT TRY TO MAKE THIS CLASS EXTEND JAVASCRIPT'S BUILT-IN ERROR!
 * Otherwise, it will fail instanceof checks. For example,
 * (new StatusError()) instanceof StatusError
 * will return false.
 * 
 */

/** An error that has an HTTP status code and a translateable message that will be shown to the client. */
export default class ApiError{
    readonly httpStatus;
    readonly message:StringWithTranslations;
    /**
     * A unique identifier associated with this error.
     * Sometimes, for convenience, this is set immediately after instantiation.
    * */
    readonly typeId;
    get information(){ return this._information; };
    private _information?:any;
    readonly originalError?:any;
/**
 * 
 * @param httpStatus 
 * @param message 
 * @param typeId 
 * @param originalError Used client-side.
 */
    constructor( httpStatus:HttpStatusCode, message:StringWithTranslations, typeId:string, originalError?:any ){
        this.typeId=typeId;
        this.httpStatus = httpStatus;
        this.message = message;
        this.originalError = originalError;

    }

    withMoreInformation(information:any){
        const copy = new ApiError(this.httpStatus,this.message,this.typeId);
        copy._information = information;
        return copy;
    }
    
}
