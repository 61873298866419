import { useState } from "react";
import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents"
import LinkButton from "./LinkButton";
import { useNavigate } from "react-router";
import { useNavigateWithScene } from "../../logic/Routing";

export type BreadcrumbTrailCrumbData = {
    to?:string,
    /** If set, will also change the scene. */
    navigateScene?:boolean,
    text:string,
    onClick?:()=>void|Promise<void>,

};

export function BreadcrumbTrailCrumb(
    {data,layout}:
    {
        data:BreadcrumbTrailCrumbData,
        layout?:CSSProp,
    }
){
    const theme = useTheme();
    const navigateWithRouterOnly = useNavigate();
    const navigateWithScene = useNavigateWithScene();

    return <div css={Layout+''+theme.Breadcrumb+layout}
            onClick={async ()=>{
                if( data.onClick ){
                    await data.onClick();
                }
                if( data.to ){
                    if( data.navigateScene ){
                        navigateWithScene(data.to);
                    }else{
                        navigateWithRouterOnly(data.to)
                    }
                }
            }}
        >
        {data.text}
    </div>
}

const Layout = css`
    display: flex;
    align-items:center;
    justify-content:center;
    padding: 0 1em;
`;