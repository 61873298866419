import "styled-components/macro";
import { css, CSSProp } from "styled-components";

import { useTheme } from "../../logic/SystemContextComponents";

export function TextLineInput(
    {
        type,
        value,
        setValue,
        disabled,
        onEnterKeyDown,
        layout,
        placeholder,
    }:
    {
        value:any,
        type?:"text"|"password",
        setValue:(value:string)=>void,
        disabled?:boolean,
        onEnterKeyDown?:()=>void,
        layout?:CSSProp,
        placeholder?:string,
    }
){
    const theme = useTheme();
  
    return <input
        css={Layout+''+theme.Input+layout}
        type={type||"text"}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={e=>setValue(e.target.value)}
        onKeyDown={e=>{
            if( e.key==="Enter" || (e.which||e.keyCode)===13 ){
                e.preventDefault();
                onEnterKeyDown?.call(null);
            }
        }}
    />
}

/** Because input is a "text-like" element (not a component), we, by default, size it based on local text, rather than relying on extenral sizing. */
const Layout = css`
    /* min-width: 3em; */
    height: 2em;
    padding: 0 1em;
`;