
import { NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { ChatMessageRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatMessageRecord";
import SystemContext from "../SystemContext";
import ChatRoomEntity from "./ChatRoomEntity";



export default class RoomRepository{
    private _systemContext;
    private get _client(){return this._systemContext.client};
    private get _session(){return this._systemContext.sessionState};
    
    constructor( systemContext:SystemContext ){
        this._systemContext = systemContext;
    }

    /* Returns any rooms that the user is participating in */
    async getMyRoomsAsync(){
        if( !this._systemContext.tokenDao.hasToken() ){
            return undefined;
        }

        const myAccountId = await this._session.getMyAccountIdAsync();
        const rooms = await this._client.api.chat.rooms.getAsync({
            withParticipantId: myAccountId
        });

        return rooms;
    }

    async getFullRoomByIdAsync(id:string){
        if( !this._systemContext.tokenDao.hasToken() ){
            return undefined;
        }
        
        try{
            const room = await this._client.api.chat.rooms.byId(id).get().fullAsync();
            return room;
        }catch(e){
            return undefined;
        }
    }

    async makeResolvedTitleByIdAsync(id:string){
        const room = await this.getFullRoomByIdAsync(id);
        if( room instanceof NotFoundResult ){
            return "(room not found)";
        }else if( room ){
            const entity = new ChatRoomEntity(room, this._systemContext);
            return await entity.resolveTitleAsync();
            
        }else{
            return "(private room)";
        }
    }
}