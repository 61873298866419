import { Euler, Object3D } from "three";
import { TEXTURE_HEIGHT_CUTOUT_BOOTH_OBJECT, TEXTURE_HEIGHT_FILE_VIEWER_BOOTH_OBJECT } from "../../../config/TextureHeights";
import Object3DNode from "../../nodes/Object3DNode";
import SystemContext from "../../SystemContext";
import { BoothSubObjectEntity } from "../BoothSubObjectEntity";
import CutoutNode from "./types/CutoutNode";
import FileViewerNode from "./types/FileViewerNode";
import ModelViewerNode from "./types/ModelViewerNode";

export default class BoothSubObjectNode extends Object3DNode{
    public boothSubObjectEntity:BoothSubObjectEntity;

    private constructor(entity:BoothSubObjectEntity,systemContext:SystemContext){
        super(new Object3D(),systemContext)
        this.boothSubObjectEntity=entity;
    }

    /** Only returns undefined if the boothData can't be parsed at all. */
    static async MaybeMakeFromEntityAsync( entity:BoothSubObjectEntity, systemContext:SystemContext ){
        
        let node = new BoothSubObjectNode(entity,systemContext);


        switch( entity.record.typeId ){
            case "modelViewer":
                node.addChild(
                    new ModelViewerNode( entity.primaryFile, systemContext, node )
                );
                break;
            case "fileViewer":
                node.addChild(
                    new FileViewerNode( entity.primaryFile, TEXTURE_HEIGHT_FILE_VIEWER_BOOTH_OBJECT, systemContext, node )
                );
                break;
            case "cutout":
                node.addChild(
                    new CutoutNode( entity.primaryFile, TEXTURE_HEIGHT_CUTOUT_BOOTH_OBJECT, systemContext, node )
                )
                break;
            case "table":
                const mesh = Object3DNode.MakeTestMeshNode(systemContext);

                // node.addChild(mesh);
                break;
            default:
                return undefined;
        }

        if(entity.transformScale ){
            node.object3D.scale.set(     ...entity.transformScale     );
        }
        if( entity.transformPosition ){
            node.object3D.position.set(  ...entity.transformPosition  );
        }
        if( entity.transformRotationRadians ){
            // subObjectNode.object3D.rotation.set(  ...subObjectData.transformPosition  );
            node.object3D.setRotationFromEuler( new Euler(...entity.transformRotationRadians) );
            // subObjectNode.object3D.setRotationFromAxisAngle(  );
        }           

        return node;
    }

    updateViaSubObjectEntity(entity:BoothSubObjectEntity){
        if(entity.transformScale ){
            this.object3D.scale.set(     ...entity.transformScale     );
        }
        if( entity.transformPosition ){
            this.object3D.position.set(  ...entity.transformPosition  );
        }
        if( entity.transformRotationRadians ){
            this.object3D.setRotationFromEuler( new Euler(...entity.transformRotationRadians) );
        }
    }

}