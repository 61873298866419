import { useState } from "react";
import { NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { SystemAccountRecordPartForPublic } from "../../libraries/l2-common/l2-common-ts/definitions/system/SystemAccountRecord";
import { useComponentDidMount, useResult, useResultOnMount } from "../../libraries/l2-common/l2-common-ts/react/ReactHelpers";
import { useSystemContext } from "../SystemContextComponents";

export function useAccountRepository(){
    return useSystemContext().accountRepository;
}

export function useAccountPublic(accountId?:string){
    const accountRepository = useAccountRepository();
    return useResult(
        async ()=>{
            if(!accountId){
                return undefined;
            }
            return await accountRepository.getPublicRecordByIdAsync( accountId );
        }
    );
}

export function useAccountsPublic(accountIds:string[]){
    const accountRepository = useAccountRepository();
    return useResultOnMount(
        ()=>accountRepository.getPublicRecordsByIdsAsync( accountIds )
    );
}