import SystemContext from "../SystemContext";
import { SceneNode } from "./SceneNode";

/* The scene that's loaded while the app is on the "main menu" */
export class MainMenuSceneNode extends SceneNode{

    constructor(context:SystemContext){
        super(context);
    }

    async loadAsync(){
    }

    async unloadAsync(){
    }

}