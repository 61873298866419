import { NotFoundResult } from "../../libraries/l2-common/l2-client-library-ts/source/ClientApiResults";
import { MaybeResolveStringWithTranslations } from "../../libraries/l2-common/l2-common-ts/common/utility/Translation";
import { ChatRoomRecord } from "../../libraries/l2-common/l2-common-ts/definitions/chat/ChatRoomRecord";
import { SystemAccountRecordPartForPublic } from "../../libraries/l2-common/l2-common-ts/definitions/system/SystemAccountRecord";
import SystemContext from "../SystemContext";


export const PREFERRED_LANGUAGE_IDS_TODO = ["en"];


export default class ChatRoomEntity{
    private readonly _systemContext;
    readonly record;
    constructor(record:ChatRoomRecord,systemContext:SystemContext){
        this.record=record;
        this._systemContext = systemContext;
    }

    /** Will either return the actual title of the room, or generate a decent title. */
    async resolveTitleAsync(){
        const accountRepository = this._systemContext.accountRepository;
        const myId = await this._systemContext.sessionState.getMyAccountIdAsync();
        const others = (await accountRepository.getPublicRecordsByIdsAsync(
            this.record.participantAccountIds.filter(
                id=>id!==myId
            )
        )).filter(
            a=>!(a instanceof NotFoundResult)
        ).slice(0,3) as SystemAccountRecordPartForPublic[];

        if(!others.length){
            return "Chat with yourself";
        }

        return "Chat with: "+others.map(
            a=>(
                a.properName ?
                MaybeResolveStringWithTranslations(
                    a.properName,
                    PREFERRED_LANGUAGE_IDS_TODO
                )||a.slug :
                a.slug
            )
        ).join(", ");
    }
}