
import assert from "assert";

const DEFAULT_TOKEN_STORAGE_KEY = "libertyAuthenticationToken";


/**
 Holds exactly 1 Liberty authorization token.
 */
export default class SimpleLocalStorageTokenDao{
    private _token?:string = undefined;
    private readonly _tokenStorageKey:string;

    constructor({
        tokenStorageKeyOptional
    }:{
        tokenStorageKeyOptional?:string
    }){
        this._tokenStorageKey = tokenStorageKeyOptional||DEFAULT_TOKEN_STORAGE_KEY;
        this._maybeLoadTokenFromLocalstorage();
    }

    setToken( token:string ){
        assert( token, "token cannot be falsy" );
        assert( typeof(token)==="string", "token must be a string" );
        this._token = token;
        this._saveTokenToLocalStorage();
    }

    clearToken(){
        this._token = undefined;
        window.localStorage.removeItem( this._tokenStorageKey );
    }

    getToken():string {
        assert( this._token, "Cannot call getToken() if there is no token to get. Use hasToken() to check if there is a token." );
        return this._token;
    }

    /** Returns true if there is a saved token. (Note that the token might be too old to use.) */
    hasToken():boolean {
        return this._token!==undefined;
    }

    private _maybeLoadTokenFromLocalstorage(){
        if ( window.localStorage.getItem(this._tokenStorageKey) ){
            this._loadTokenFromLocalStorageKey(this._tokenStorageKey)
        }
    }
    private _loadTokenFromLocalStorageKey( key:string ){
        const token = window.localStorage.getItem( key );
        assert( token != null );
        this.setToken( token );
    }

    private _saveTokenToLocalStorage(){
        window.localStorage.setItem( this._tokenStorageKey, this.getToken() );
    }

}
