import "styled-components/macro";

import { useSystemContext, useTheme } from "../logic/SystemContextComponents";
import LoginPanelContentsComponent from "./account/LoginPanelContentsComponent";
import MapPanelContentsComponent from "./map/MapPanelContentsComponent";
import { BrowserRouter } from "react-router-dom";
import LoadingWidgetPanelContents from "./common/LoadingWidgetPanelContents";
import TopMenuPanelContentsComponent from "./topmenu/TopMenuPanelContentsComponent";
import { css } from "styled-components/macro";
import { CssLines } from "../theme/CssPropHelpers";
import HelpMenuPanelContentsComponent from "./help/HelpMenuPanelContentsComponent";
import { MainMenuSceneNode } from "../logic/scenes/MainMenuSceneNode";
import { FloorSceneNode } from "../logic/scenes/FloorSceneNode";
import { BoothEditorSceneNode } from "../logic/scenes/BoothEditorSceneNode";
import { BoothEditorMenuComponent } from "../logic/boothEditor/BoothEditorMenuComponent";
import { DialogComponent } from "./dialog/DialogComponent";
import { BoothEditorMainMenuSceneNode } from "../logic/scenes/BoothEditorMainMenuSceneNode";
import { BoothEditorBoothSelectPanelContentsComponent } from "../logic/boothEditor/BoothEditorBoothSelectPanelContentsComponent";
import { MapSelectPanelContentsComponent } from "./map/MapSelectPanelContentsComponent";
import { ChatPanelContentsComponent } from "./messages/ChatPanelContentsComponent";
import { useBooleanSetting } from "../logic/Settings";
import MobileControlsMenuPanelContentsComponent from "../mobile/MobileControlsMenuPanelContentsComponent";
import { useIsMobileDevice } from "../mobile/MobileDeviceDetection";
import { IntroSceneOverlayContentsComponent } from "../intro/IntroSceneOverlayContentsComponent";
import { IntroSceneNode } from "../intro/IntroSceneNode";
import config from "../config/config";

/* We provide the site's public url as the basename, so that BrowserRouter knows to ignore the beginning part of the URL 
(we're hosting at liberty-hjc.com/app instead of at liberty-hjc.com, so the router needs to ignore the "/app" part of the URL) */
// this pulls the "/app" part of the end of the URL, or whatever else we might change it to in the build settings
const APP_BASENAME = process.env.PUBLIC_URL.split(".com")[1]||"";
// TODO: make the above work with other TLD addresses such as our .xyz staging server address

export default function UI(){
    const systemContext = useSystemContext();
    const theme = useTheme();
    const {dialogSystem,sessionState,scene} = systemContext;
    
    let [showHelpPanel,setShowHelpPanel] = useBooleanSetting("showControlsHelpUi");

    let centerPanelContents:JSX.Element|undefined;
    let showTopPanel = true;
    let showLeftPanel = true;
    let showRightPanel = true;
    /** A big transparent section over the canvas where you can add buttons, etc... */
    let overlayContents:JSX.Element|undefined;
    let leftPanelContents:JSX.Element|undefined;
    if( scene instanceof MainMenuSceneNode ){
        centerPanelContents = <MapSelectPanelContentsComponent/>;
    }
    if( scene instanceof IntroSceneNode ){
        overlayContents = <IntroSceneOverlayContentsComponent/>;
        // showLeftPanel = false;
        // showRightPanel = false;
        // showTopPanel = false;
        // showHelpPanel = false;
    }
    if( scene instanceof FloorSceneNode){
        leftPanelContents = <MapPanelContentsComponent/>;
    }
    if( scene instanceof BoothEditorSceneNode ){
        leftPanelContents = <BoothEditorMenuComponent/>;
    }
    if( scene instanceof BoothEditorMainMenuSceneNode ){
        leftPanelContents = <BoothEditorBoothSelectPanelContentsComponent/>;
    }
    if( systemContext.sceneIsLoading ){
        centerPanelContents = <LoadingWidgetPanelContents/>;
    }

    const layout = MakeLayout(showHelpPanel);

    const showMobileControls = useIsMobileDevice();

    return (
    <BrowserRouter basename={APP_BASENAME}>
        <div css={layout.Root} className="(UI.Root)">
            {
                dialogSystem.data &&
                <DialogComponent
                    data={dialogSystem.data}
                    close={()=>{dialogSystem.closeDialog()}}
                />
            }
            {
                centerPanelContents ? (
                    <div css={layout.CenterPanel+''+theme.CenterPanel} className="(centerPanel)">
                        {centerPanelContents}
                    </div>
                ): <></>
            }
            {
                overlayContents ? (
                    <div css={layout.OverlayPanel+''} className="(overlayPanel)">
                        {overlayContents}
                    </div>
                ): <></>
            }
            {
                showTopPanel &&
                <div css={layout.TopPanel+''+theme.Panel} className="(topPanel)">
                    <TopMenuPanelContentsComponent/>
                </div>
            }
            {
                showLeftPanel && 
                <div css={layout.LeftPanel+''+theme.Panel} className="(leftPanel)">
                    {leftPanelContents}
                </div>
            }
            {
                showRightPanel &&
                <div css={layout.RightPanel+''+theme.Panel} className="(rightPanel)">
                    {
                        sessionState.isLoggedIn ? 
                        <ChatPanelContentsComponent/> :
                        <LoginPanelContentsComponent/>
                    }
                </div>
}
            {
                showMobileControls 
                ? <div css={layout.BottomPanel+''+theme.Panel}>
                    <MobileControlsMenuPanelContentsComponent/>
                </div>
                : showHelpPanel &&
                <div css={layout.BottomPanel+''+theme.Panel}>
                    <HelpMenuPanelContentsComponent close={()=>{setShowHelpPanel(false)}}/>
                </div>
            }
        </div>
    </BrowserRouter>
);
}

const PanelBase = `
    & > * {
        width: 100%;
        height: 100%;
    }
`;

const MakeLayout = (includeBottomRow:boolean)=>({
    Root: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        display: grid;
        grid-template-columns: 15fr 70fr 15fr;
        
        ${
            CssLines(
                "grid-template-rows: 5em 1fr 1fr"
                +(includeBottomRow?" 10em":"")
                +";"
            )
        };
        
        /* Allow clicks to pass through to the threejs canvas */
        pointer-events: none;
        
        & > * {
            /* Allow right clicking in the individual panels. */
            pointer-events: all;
            overflow: hidden;
        }
    `,
    TopPanel: css`
        grid-column: 1/4;
        grid-row: 1;
    `,
    LeftPanel: css`
        grid-column: 1;
        grid-row: 2/5;
    `,
    RightPanel: css`
        grid-column: 3;
        grid-row: 2/5;
        ${CssLines(PanelBase)};
    `,
    CenterPanel: css`
        grid-column: 2/3;
        grid-row: 2/4;
        margin: 5% 12%;
    `,
    OverlayPanel: css`
        grid-column: 2/3;
        grid-row: 2/4;
    `,
    BottomPanel: css`
        grid-column: 2/3;
        grid-row: 4/5;
        padding: 1em;
    `,
});