import { css } from "styled-components";
import { useForceSystemContextUpdate, useSystemContext, useTheme } from '../../logic/SystemContextComponents';
import {TextLineInput} from "../common/TextLineInput";
import Button from "../common/Button";
import { useState } from "react";
import useReCaptcha from "../../logic/accounts/useReCaptcha";
import "styled-components/macro";
import { PanelContentsComponentLayout } from "../../layouts/PanelContentsComponentLayout";
import { MenuHeader } from "../common/MenuHeader";

export default function LoginPanelContentsComponent(){
    const theme = useTheme();
    const systemContext = useSystemContext();
    const {translate,sessionState} = systemContext;
    const forceSystemContextUpdate = useForceSystemContextUpdate();
    
    const {api} = systemContext;

    const [waiting,setWaiting] = useState(false);
    const [emailAddress, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const {ReCaptchaComponent,getTokenV3} = useReCaptcha("ExhibitionLogin");

    async function loginAsync(){
        setWaiting(true);

        const recaptchaToken = await getTokenV3();

        systemContext.sessionState.loginWithPasswordAsync({
            emailAddress,
            password,
            recaptcha:{
                token:recaptchaToken,
                version:"v3"
            },
        });

        setWaiting(false);
    }

    if( waiting ){
        return <div css={Layout}>
            Loading...
        </div>
    }

    const loginButtonEnabled = !waiting && emailAddress.length && password.length;

    return <div css={Layout}>
        <MenuHeader>Login</MenuHeader>
        <TextLineInput
            value={emailAddress}
            setValue={setEmail}
            disabled={waiting}
            placeholder="Email"
        />
        {/* <div>Email</div> */}
        <TextLineInput
            type="password"
            value={password}
            setValue={setPassword}
            onEnterKeyDown={()=>loginAsync()}
            disabled={waiting}
            placeholder="Password"
        />
        <ReCaptchaComponent/>
        <Button
            onClick={()=>loginAsync()}
            disabled={!loginButtonEnabled}
        >
            {translate("t$login")}
        </Button>
    </div>;
}

const Layout = PanelContentsComponentLayout+''+css`
    & > *{
        margin-bottom: 1em;
        max-width: 100%;
    }
`;