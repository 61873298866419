import { Euler, Vector3 } from "three";
import { degToRad } from "three/src/math/MathUtils";

export function RotationDegreesToEuler(rotationDegrees:[number,number,number]){
    const rotationRadians = rotationDegrees.map(
        degrees => degToRad(degrees)
    );
    return new Euler( ...rotationRadians );
}

/** Returns a Vector3 whose components are between -1 and 1 */
export function MakeRandomVector3(){
    return new Vector3(
        Math.random()*2.0-1.0,
        Math.random()*2.0-1.0,
        Math.random()*2.0-1.0
    );
}