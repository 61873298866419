

const codes = {
    GetResource: 200,
    PostedNewResource: 201,
    CustomPostActionSucceeded: 201,
    PutResource: 200,
    PatchedResource: 200,
    DeletedResource: 204,



    Continue100: 100,
    SwitchingProtocols101: 101,
    Processing102: 102,
    EarlyHints103: 103,
    Ok200: 200,
    Created201: 201,
    Accepted202: 202,
    NonAuthoritativeInformation: 203,
    NoContent204: 204,
    ResetContent205: 205,
    PartialContent206: 206,

    // ...

    /** Wikipedia: The server cannot or will not process the request due to an apparent client error (e.g., malformed request syntax, size too large, invalid request message framing, or deceptive request routing). */
    BadRequest400: 400,
    /** Wikipedia:     Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource. See Basic access authentication and Digest access authentication.[31] 401 semantically means "unauthorised",[32] the user does not have valid authentication credentials for the target resource.
    Note: Some sites incorrectly issue HTTP 401 when an IP address is banned from the website (usually the website domain) and that specific address is refused permission to access a website. */
    Unauthenticated401: 401,
    PaymentRequired402: 402,    
    /** Wikipedia: The request contained valid data and was understood by the server, but the server is refusing action. This may be due to the user not having the necessary permissions for a resource or needing an account of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is allowed). This code is also typically used if the request provided authentication by answering the WWW-Authenticate header field challenge, but the server did not accept that authentication. The request should not be repeated. */
    Forbidden403: 403,
    /** (alias to make meaning clear) Wikipedia: The request contained valid data and was understood by the server, but the server is refusing action. This may be due to the user not having the necessary permissions for a resource or needing an account of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is allowed). This code is also typically used if the request provided authentication by answering the WWW-Authenticate header field challenge, but the server did not accept that authentication. The request should not be repeated. */
    Unauthorized403: 403,
    NotFound404: 404,
    /** Wikipedia: A request method is not supported for the requested resource; for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource. */
    MethodNotAllowed405: 405,
    /** Wikipedia: The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request. */
    NotAcceptable406: 406,

    // ...

    /** Wikipedia: Indicates that the request could not be processed because of conflict in the current state of the resource, such as an edit conflict between multiple simultaneous updates. */
    Conflict409: 409,
    Gone410: 410,
    LengthRequired411: 411,
    PreconditionFailed412: 412,
    PayloadTooLarge413: 413,
    UriTooLong414: 414,
    UnsupportedMediaType415: 415,
    /** Wikipedia: The client has asked for a portion of the file (byte serving), but the server cannot supply that portion. For example, if the client asked for a part of the file that lies beyond the end of the file.[48] Called "Requested Range Not Satisfiable" previously. */
    RangeNotSatisfiable416: 416,

    // ...

    /** Wikipedia: The resource that is being accessed is locked. */
    Locked423: 423,
    UpgradeRequired426: 426,

    // ...

    RequestHeaderFieldsToolarge431: 431,
    TooManyRequests429: 429,
    UnavailableForLegalReasons451: 451,

    InternalSeverError500: 500,
    NotImplemented501: 501,

    // ...
    BadGateway502: 502,
    InsufficientStorage507: 507,

} as const;

export const HttpStatusCodes = codes;

const values = [...Object.values(codes)] as const;
export type HttpStatusCode = typeof values[number];