import { DirectionalLight, AmbientLight, PointLight, Vector3, Euler, Mesh, BoxGeometry, MeshBasicMaterial, Object3D } from "three";
import { degToRad } from "three/src/math/MathUtils";
import config from "../../config/config";
import { VenueId } from "../../libraries/l2-common/l2-common-ts/resources/exhibition/Venues";
import FloatParentBehaviorNode from "../effects/FloatParentBehaviorNode";
import Object3DNode from "../nodes/Object3DNode";
import Resources from "../Resources";
import SystemContext from "../SystemContext";
import SkyboxNode from "./SkyboxNode";

export default class FloorEnvironmentNode extends Object3DNode{
    private readonly data;

    constructor(venueId:VenueId,floorIndex:number,systemContext:SystemContext){
        super(
            new Object3D(),
            systemContext
        );
        this.data = {venueId,floorIndex};
    }

    async loadAsync(){
        this._addLights();
        await this._addEnvironmentAsync();


        // Load conditional things on a per-venue basis (TODO: move this code somewhere else)
        if( this.data.venueId==="liberty" ){
            const MODELS_PATH = `${config.staticContentUrl}models/`;
            const wingsGltf = await Resources.LoadGLTFAsync(MODELS_PATH+"Wings_Lowpoly_01.gltf");
            const wingsNode = new Object3DNode(
                wingsGltf.scene,
                this.systemContext
            );
            wingsNode.addChild(
                new FloatParentBehaviorNode( 2.0, 30.0, this.systemContext )
            );
            this.addChild( wingsNode );
        }

    }


    /** Note: these are lights specifically for the "liberty" venue */
    _addLights(){
        const lightColor = 0xFFFFFF;

        const directionalLight = new DirectionalLight(0xFFFFFF, .8);
        directionalLight.position.set(5,30,1);
        directionalLight.target.position.set(0,0,0)
        const light = new Object3DNode( directionalLight, this.systemContext );
        this.addChild( light );
        const lightTarget = new Object3DNode( directionalLight.target, this.systemContext );
        this.addChild( lightTarget );
        
        const ambientLight = new Object3DNode(
            new AmbientLight(lightColor, .5),
            this.systemContext
        );
        this.addChild( ambientLight );

        // Light up the people in the center
        for(let angleDegrees=0; angleDegrees<360; angleDegrees+=360/5){
            const lightDistance = 20;
            const pointLight = new Object3DNode(
                new PointLight(lightColor, 30, lightDistance),
                this.systemContext
            );
            const position = new Vector3(0,20,-20);
            position.applyEuler( new Euler(0,degToRad(angleDegrees),0) );
            pointLight.object3D.position.copy( position );
            this.addChild(pointLight);


            const preview = Object3DNode.MakePlaceholderNode( this.systemContext );
            preview.object3D.scale.setScalar( lightDistance );
            preview.object3D.position.copy( position );
            // this.addChild( preview );
        }
    }



    // adds a temporary plane as a placeholder while we load the hall
    async _addLoadingEnvironent(){
        const environmentNode = new Object3DNode(
            new Mesh(
                new BoxGeometry( 200,.01,200 ),
                new MeshBasicMaterial()
            ),
            this.systemContext
        );
        this.addChild(environmentNode);
    }
    

    // TODO: make this load different environment models depending on the venueId/floor (we don't have the models for that yet)
    async _addEnvironmentAsync(){
        const environmentGltf = await Resources.LoadBoothGLTFByVenueIdAndFloorAsync("liberty",1);
        const environmentNode = new Object3DNode(
            environmentGltf.scene,
            this.systemContext
        );
        this.addChild( environmentNode );

        const skyboxNode = new SkyboxNode(this.systemContext);
        this.addChild( skyboxNode );
    }
}