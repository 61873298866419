import { Audio, AudioListener } from "three";
import config from "../../config/config";
import Resources from "../Resources";
import { Settings } from "../Settings";
import ThreeSystem from "../ThreeSystem";

const AUDIO_PATH = `${config.staticContentUrl}audio/`;

const FILENAME_AMBIENCE = "ambience-museum-cleaned--soft-edges--CC0.ogg";
const AMBIENCE_VOLUME_SCALE = .01;

export const FILENAME_FOOTSTEP = "footstep--A--oneshot--eq--CC0.ogg";

interface PlayingAudio{
    audio:Audio;
    volumeScale:number;
}

export class AudioSystem{
    listener = new AudioListener();
    private _startedOnClick = false;

    private _playingAudio:PlayingAudio[] = [];
    private _globalVolumeScale = 1.0;
    set globalVolumeScale(value:number){
        this._globalVolumeScale = value;
        this._updateVolumes();
    }
    private _loopVolumeScale = 1.0;
    set loopVolumeScale(value:number){
        this._loopVolumeScale = value;
        this._updateVolumes();
    }
    private _oneshotVolumeScale = 1.0;
    set oneshotVolumeScale(value:number){
        this._oneshotVolumeScale = value;
        this._updateVolumes();
    }

    constructor(threeSystem:ThreeSystem,settings:Settings){
        threeSystem.camera.add(
            this.listener
        );
        document.addEventListener("mousedown", ()=>{
            if(!this._startedOnClick){
                this._startedOnClick=true;
                this.startAmbience(FILENAME_AMBIENCE);
            }
        });
        settings.addUpdateListener("oneshotVolumeScale",(newValue)=>{
            this.oneshotVolumeScale=newValue;
        });
        settings.addUpdateListener("loopVolumeScale",(newValue)=>{
            this.loopVolumeScale=newValue;
        });
    }

    async startAmbience(fileName:string){
        this.playAsync({
            fileName,
            volumeScale: AMBIENCE_VOLUME_SCALE,
            loop:true,
        });
    }
    async stopAmbience(){

    }

    async playAsync({
        fileName,
        volumeScale,
        detuneCents,
        loop
    }:{
        fileName:string,
        volumeScale?:number,
        detuneCents?:number,
        loop?:boolean
    }){
        volumeScale = volumeScale ?? 1;
        detuneCents = detuneCents ?? 0;
        const audio = new Audio(this.listener);
        const buffer = await Resources.LoadAudioAsync(
            AUDIO_PATH+fileName
        );
        audio.setBuffer(buffer);
        audio.setLoop(!!loop);
        audio.setVolume(0);
        audio.onEnded = ()=>{
            this._playingAudio = this._playingAudio.filter(a=>a.audio!==audio);
        }
        this._playingAudio.push({volumeScale,audio});
        this._updateVolumes();
        audio.play();
        audio.setDetune(detuneCents);
    }

    private _updateVolumes(){
        for(const audio of this._playingAudio){
            const volume = (
                audio.volumeScale *
                this._globalVolumeScale *
                (
                    audio.audio.getLoop() ? 
                    this._loopVolumeScale :
                    this._oneshotVolumeScale
                )
            );
            audio.audio.setVolume(volume);
        }
    }
}