import { z } from "zod";
import { RightHanded3DTransform } from "../../definitions/general/RightHanded3DTransform";
import { StringWithTranslations } from "../../definitions/general/StringWithTranslations";

// TODO: this was pulled from the file "booth_sizes.js" from the old site, and needs to be updated to reflect the actual size of the footprints
// for now, we're guessing/factoring the sizes - only yellow (sqyare) is guaranteed to be correct
// from booth_sizes.js:
// simpleWhite: "54m²",
// simpleYellow: "64m²",
// simpleBlue: "32m²",
// simpleRed: "92m²",
const FOOTPRINT_SIMPLERED = [11.5,8] as const;
const FOOTPRINT_SIMPLEYELLOW = [8,8] as const;
const FOOTPRINT_SIMPLEWHITE = [9,6] as const;
const FOOTPRINT_SIMPLEBLUE = [8,4] as const;

const LIBERTY_ADDRESSES = makeLibertyAddresses();

export const VenueIds = ["liberty",] as const;
export const VenueIdSchema = z.enum(VenueIds);
export type VenueId = z.infer<typeof VenueIdSchema>;


export type VenueBoothMapping = {
    address: string,
    /** the dimensions of the space (width x height), in meters */
    footprintMeters: readonly [number,number],
    transform: RightHanded3DTransform,
}

export type VenueAdMapping = {
    address: string,
    transform: RightHanded3DTransform,
};

export type Venue = {
    id: VenueId,
    title: StringWithTranslations,
    // maps booths to physical locations
    boothMappings: VenueBoothMapping[],
    // maps ads to physical locations
    adMappings: VenueAdMapping[],
};

export const Venues:Venue[] = [
    {
        id: "liberty",
        title: {
            en: "Liberty"
        },
        boothMappings: BuildLibertyMappings().booths,
        adMappings: BuildLibertyMappings().ads,
    }
];



function BuildLibertyMappings(){
    const mappings:{
        booths: VenueBoothMapping[],
        ads: VenueAdMapping[],
    } = {
        booths: [],
        ads: [],
    }
    for(let floorNumber=1; floorNumber<=1; ++floorNumber){
        // hall booth slots
        for(let boothNumber=1; boothNumber<=96; ++boothNumber){
            const address = `${floorNumber}_${boothNumber}`;
            mappings.booths.push(
                {
                    address,
                    footprintMeters: getLibertyFootprintByNumber(boothNumber),
                    transform: addressToTransform("liberty",address)
                }
            );
        }
        // hall vip booth slots
        for(let vipBoothNumber=1; vipBoothNumber<=8; ++vipBoothNumber){
            const address = `${floorNumber}_V${vipBoothNumber}`;
            mappings.booths.push(
                {
                    address,
                    footprintMeters: FOOTPRINT_SIMPLERED,
                    transform: addressToTransform("liberty",address)
                }
            );
        }
        // hall ad slots
        for(let adNumber=1; adNumber<=55; ++adNumber){
            const address = `${floorNumber}_A${adNumber}`;
            mappings.ads.push(
                {
                    address,
                    transform: addressToTransform("liberty",address)
                }
            );
        }
    }
    // lobby ad slots
    for(let lobbyAdNumber=1; lobbyAdNumber<=26; ++lobbyAdNumber){
        const floorNumber = 0;
        const address = `${floorNumber}_A${lobbyAdNumber}`;
            mappings.ads.push(
                {
                    address,
                    transform: addressToTransform("liberty",address)
                }
            );
    }
    return mappings;
}


function getLibertyFootprintByNumber( boothNumber:number ){
    const pink = FOOTPRINT_SIMPLEBLUE;
    const blue = FOOTPRINT_SIMPLEYELLOW;
    const green = FOOTPRINT_SIMPLEWHITE;
    const orange = FOOTPRINT_SIMPLERED;
    const types = [];
    // there are 6 groups in the venue, this pattern is repeated
    for(let group=0; group<6; ++group){
        // outside ring
        types[ group*16 + 1 ] = pink;   
        types[ group*16 + 2 ] = blue;
        types[ group*16 + 3 ] = green;
        types[ group*16 + 4 ] = orange;
        types[ group*16 + 5 ] = blue;
        types[ group*16 + 6 ] = green;
        types[ group*16 + 7 ] = orange;
        types[ group*16 + 8 ] = blue;
        types[ group*16 + 9 ] = pink;

        // inside ring
        types[ group*16 + 10 ] = green;
        types[ group*16 + 11 ] = blue;
        types[ group*16 + 12 ] = orange;
        types[ group*16 + 13 ] = blue;
        types[ group*16 + 14 ] = orange;
        types[ group*16 + 15 ] = blue;
        types[ group*16 + 16 ] = green;
    }
    if( boothNumber >= types.length){
        throw new Error("bad booth number: "+boothNumber );
    }
    return types[boothNumber];
}

function addressToTransform(venueId:VenueId, address:string){
    let transform:RightHanded3DTransform = {};

    const addresses = {
        liberty: LIBERTY_ADDRESSES
    }[venueId];

    const entry = addresses.find(_=>_.address===address);
    if( entry ){
        transform.position = entry.position;
        transform.rotation = entry.rotationDegrees;
    }
    return transform;
}


// Booth & ad position data dumped from the Unity project
// note that these positions are left-handed

function makeLibertyAddresses(){return`
floor_1_A55	-20.71782	0.015	35.35567	0	67.47507	90
floor_1_A54	-71.15265	0.015	41.08	0	30	90
floor_1_A53	-42.6234	0.015	0.205883	0	7.475085	90
floor_1_A52	-71.15265	0.015	-41.08	0	330	90
floor_1_A51	-20.08	0.015	-35.41	0	307.4751	90
floor_1_A50	0	0.015	-82.16	0	270	90
floor_1_A49	20.84997	0.015	-34.64678	0	247.4751	90
floor_1_A48	71.15264	0.015	-41.08001	0	210	90
floor_1_A47	42.42438	0.015	0.1988258	0	172.475	90
floor_1_A46	71.15265	0.015	41.08	0	150	90
floor_1_A45	20.08	0.015	35.67	0	127.4751	90
floor_1_A44	0	0.015	82.16	0	90	90
floor_1_A42	-42.80613	8.33	54.67574	0	105.3009	0
floor_1_A41	-57.76997	8.33	59.77548	0	105.3009	0
floor_1_A40	-73.92406	8.33	62.27163	0	89.64925	0
floor_1_A39	-89.34395	8.33	54.77369	0	34.96266	0
floor_1_A38	-92.54836	8.33	37.71207	0	344.98	0
floor_1_A37	-84.10664	8.33	24.06822	0	316.98	0
floor_1_A36	-71.27642	8.33	11.96798	0	307.48	0
floor_1_A35	-68.73328	8.33	-9.876122	0	45.1819	0
floor_1_A34	-80.61005	8.33	-20.31001	0	45.1819	0
floor_1_A33	-90.82234	8.33	-33.07302	0	29.53024	0
floor_1_A32	-92.00334	8.33	-50.1785	0	334.8437	0
floor_1_A31	-78.8063	8.33	-61.45702	0	284.861	0
floor_1_A30	-62.77057	8.33	-60.9349	0	256.861	0
floor_1_A29	-45.88689	8.33	-55.83864	0	247.361	0
floor_1_A28	-25.68311	8.33	-64.51496	0	345.0659	0
floor_1_A27	-22.55411	8.33	-80.01119	0	345.0659	0
floor_1_A26	-16.57634	8.33	-95.22473	0	329.4142	0
floor_1_A25	-2.333705	8.33	-104.7714	0	274.7277	0
floor_1_A24	14.02054	8.33	-98.9486	0	224.745	0
floor_1_A23	21.55758	8.33	-84.7849	0	196.745	0
floor_1_A22	25.55116	8.33	-67.60696	0	187.245	0
floor_1_A21	43.02526	8.33	-54.50347	0	285.0709	0
floor_1_A20	58.00945	8.33	-59.5431	0	285.0709	0
floor_1_A19	74.17343	8.33	-61.97438	0	269.4193	0
floor_1_A18	89.5631	8.33	-54.4146	0	214.7327	0
floor_1_A17	92.69899	8.33	-37.34026	0	164.75	0
floor_1_A16	84.20258	8.33	-23.73041	0	136.75	0
floor_1_A15	71.3239	8.33	-11.68176	0	127.25	0
floor_1_A14	68.73998	8.33	9.829319	0	225.2209	0
floor_1_A13	80.62386	8.33	20.25512	0	225.2209	0
floor_1_A12	90.84483	8.33	33.01118	0	209.5692	0
floor_1_A11	92.03748	8.33	50.11586	0	154.8827	0
floor_1_A10	78.84812	8.33	61.40335	0	104.9	0
floor_1_A9	62.81204	8.33	60.89215	0	76.89999	0
floor_1_A8	45.9249	8.33	55.80738	0	67.39999	0
floor_1_A7	25.97	8.33	64.4	0	165.3209	0
floor_1_A6	22.91	8.33	79.91	0	165.3209	0
floor_1_A5	17	8.33	95.15	0	149.6693	0
floor_1_A4	2.8	8.33	104.76	0	94.98266	0
floor_1_A3	-13.58	8.33	99.01	0	45	0
floor_1_A2	-21.18	8.33	84.88	0	17	0
floor_1_A1	-25.25	8.33	67.72	0	7.500003	0
floor_1_V8	-13.64716	0	13.64716	0	225	0
floor_1_V7	-19.3	0	-1.907349E-06	0	180	0
floor_1_V6	-13.64716	0	-13.64717	0	135	0
floor_1_V5	0	0	-19.3	0	90	0
floor_1_V4	13.64716	0	-13.64716	0	45	0
floor_1_V3	19.3	0	-1.907349E-06	0	0	0
floor_1_V2	13.64716	0	13.64716	0	315	0
floor_1_V1	0	0	19.3	0	270	0
floor_1_96	-38.63744	0	13.87802	0	40.82055	0
floor_1_95	-45.08689	0	18.98725	0	130.5581	0
floor_1_94	-54.10964	0	25.81287	0	131.3979	0
floor_1_93	-62.97052	0	35.95189	2.414482E-05	211.9631	4.136757E-07
floor_1_92	-49.46029	0	34.05229	0	288.8878	0
floor_1_91	-38.93385	0	29.76463	0	287.4337	0
floor_1_90	-31.67486	0	26.93721	5.322865E-07	17.13725	-6.675332E-07
floor_1_89	-33.27721	0	44.35374	0	103.4066	0
floor_1_88	-42.38115	0	47.79372	1.12699E-05	105.0343	7.13079E-06
floor_1_87	-54.33685	0	50.88583	0	104.2074	0
floor_1_86	-68.19936	0	53.86755	0	102.5639	0
floor_1_85	-78.86257	0	46.68603	0	30.01069	0
floor_1_84	-81.17072	0	32.94985	3.633818E-06	317.374	-1.172155E-06
floor_1_83	-71.82099	0	24.01886	-2.860115E-06	317.374	-3.891721E-06
floor_1_82	-64.46552	0	14.95663	3.384957E-07	317.374	-2.215446E-06
floor_1_81	-55.58333	0	7.726871	2.579016E-07	317.374	-1.687959E-06
floor_1_80	-31.33743	0	-26.52199	0	340.8206	0
floor_1_79	-38.98689	0	-29.55277	0	70.55804	0
floor_1_78	-49.40942	0	-33.95389	0	71.39787	0
floor_1_77	-62.62051	0	-36.55813	2.414482E-05	151.9631	4.136757E-07
floor_1_76	-54.22029	0	-25.80773	0	228.8878	0
floor_1_75	-45.24384	0	-18.83539	0	227.4337	0
floor_1_74	-39.16574	0	-13.96263	5.322865E-07	317.1373	-6.675331E-07
floor_1_73	-55.05007	0	-6.642044	0	43.40663	0
floor_1_72	-62.58115	0	-12.8063	1.12699E-05	45.03429	7.13079E-06
floor_1_71	-71.23685	0	-21.61418	0	44.20743	0
floor_1_70	-80.75035	0	-32.1286	0	42.56388	0
floor_1_69	-79.86257	0	-44.95397	0	330.0107	0
floor_1_68	-69.12077	0	-53.82098	3.633819E-06	257.374	-1.172155E-06
floor_1_67	-56.71143	0	-50.18938	-2.860115E-06	257.374	-3.891721E-06
floor_1_66	-45.18557	0	-48.35046	3.384958E-07	257.374	-2.215446E-06
floor_1_65	-34.48333	0	-44.27314	2.579016E-07	257.374	-1.687959E-06
floor_1_64	7.3	0	-40.40001	0	280.8206	0
floor_1_63	6.1	0	-48.54002	0	10.55805	0
floor_1_62	4.700216	0	-59.76676	0	11.39786	0
floor_1_61	0.3500094	0	-72.51002	2.414482E-05	91.96312	4.136752E-07
floor_1_60	-4.76	0	-59.86002	0	168.8878	0
floor_1_59	-6.309999	0	-48.60001	0	167.4337	0
floor_1_58	-7.490877	0	-40.89984	5.322865E-07	257.1372	-6.675331E-07
floor_1_57	-21.77286	0	-50.99578	0	343.4066	0
floor_1_56	-20.2	0	-60.60001	1.12699E-05	345.0343	7.13079E-06
floor_1_55	-16.9	0	-72.50002	0	344.2074	0
floor_1_54	-12.55099	0	-85.99615	0	342.5639	0
floor_1_53	-1	0	-91.64001	0	270.0107	0
floor_1_52	12.04995	0	-86.77084	3.633819E-06	197.374	-1.172155E-06
floor_1_51	15.10956	0	-74.20823	-2.860115E-06	197.374	-3.891721E-06
floor_1_50	19.27994	0	-63.30708	3.384959E-07	197.374	-2.215446E-06
floor_1_49	21.1	0	-52.00002	2.579016E-07	197.374	-1.687959E-06
floor_1_48	38.63743	0	-13.87803	0	220.8206	0
floor_1_47	45.08688	0	-18.98726	0	310.558	0
floor_1_46	54.10963	0	-25.81288	0	311.3979	0
floor_1_45	62.97052	0	-35.9519	2.414482E-05	31.96312	4.136749E-07
floor_1_44	49.46029	0	-34.0523	0	108.8878	0
floor_1_43	38.93385	0	-29.76463	0	107.4337	0
floor_1_42	31.67486	0	-26.93721	5.322865E-07	197.1373	-6.675331E-07
floor_1_41	33.27721	0	-44.35374	0	283.4066	0
floor_1_40	42.38115	0	-47.79372	1.12699E-05	285.0343	7.130791E-06
floor_1_39	54.33685	0	-50.88584	0	284.2075	0
floor_1_38	68.19935	0	-53.86757	0	282.5639	0
floor_1_37	78.86256	0	-46.68604	0	210.0107	0
floor_1_36	81.17072	0	-32.94986	3.633818E-06	137.374	-1.172155E-06
floor_1_35	71.82098	0	-24.01887	-2.860114E-06	137.374	-3.891721E-06
floor_1_34	64.46552	0	-14.95663	3.384959E-07	137.374	-2.215446E-06
floor_1_33	55.58333	0	-7.726883	2.579016E-07	137.374	-1.687959E-06
floor_1_32	31.33743	0	26.52199	0	160.8205	0
floor_1_31	38.98689	0	29.55276	0	250.558	0
floor_1_30	49.40942	0	33.95389	0	251.3979	0
floor_1_29	62.62051	0	36.55812	2.414482E-05	331.9631	4.136757E-07
floor_1_28	54.22029	0	25.80773	0	48.88783	0
floor_1_27	45.24384	0	18.83539	0	47.43372	0
floor_1_26	39.16574	0	13.96263	5.322865E-07	137.1373	-6.675331E-07
floor_1_25	55.05007	0	6.642046	0	223.4066	0
floor_1_24	62.58115	0	12.80629	1.12699E-05	225.0343	7.13079E-06
floor_1_23	71.23685	0	21.61418	0	224.2074	0
floor_1_22	80.75035	0	32.1286	0	222.5639	0
floor_1_21	79.86257	0	44.95398	0	150.0107	0
floor_1_20	69.12077	0	53.82099	3.633819E-06	77.37398	-1.172155E-06
floor_1_19	56.71143	0	50.18937	-2.860115E-06	77.37399	-3.891721E-06
floor_1_18	45.18557	0	48.35046	3.384958E-07	77.37398	-2.215446E-06
floor_1_17	34.48333	0	44.27314	2.579016E-07	77.37398	-1.687959E-06
floor_1_16	-7.3	0	40.40001	0	100.8205	0
floor_1_15	-6.1	0	48.54002	0	190.558	0
floor_1_14	-4.700216	0	59.76676	0	191.3979	0
floor_1_13	-0.3500094	0	72.51002	2.414482E-05	271.9631	4.136752E-07
floor_1_12	4.76	0	59.86002	0	348.8878	0
floor_1_11	6.309999	0	48.60001	0	347.4337	0
floor_1_10	7.490877	0	40.89984	5.322865E-07	77.13725	-6.675331E-07
floor_1_9	21.77286	0	50.99578	0	163.4066	0
floor_1_8	20.2	0	60.60001	1.12699E-05	165.0343	7.13079E-06
floor_1_7	16.9	0	72.50002	0	164.2074	0
floor_1_6	12.55099	0	85.99615	0	162.5639	0
floor_1_5	1	0	91.64001	0	90.0107	0
floor_1_4	-12.04995	0	86.77084	3.633819E-06	17.37397	-1.172155E-06
floor_1_3	-15.10956	0	74.20823	-2.860115E-06	17.37397	-3.891721E-06
floor_1_2	-19.27994	0	63.30708	3.384959E-07	17.37397	-2.215446E-06
floor_1_1	-21.1	0	52.00002	2.579016E-07	17.37397	-1.687959E-06

floor_0_A26	-12.62	5.24	15.761	0	0.7071068	0
floor_0_A25	-18.58	5.24	9.67	0	0	0
floor_0_A24	-12.62	5.24	3.9	0	-0.7071068	0
floor_0_A23	-7.138821	6.895424	9.603764	0	1	0
floor_0_A22	-34.122	2.2	13.53	0	0	0
floor_0_A21	-34.122	2.2	9.88	0	0	0
floor_0_A20	-34.122	2.2	6.12	0	0	0
floor_0_A19	-18.76	2.2	0.883	0	0	0
floor_0_A18	-18.76	2.2	-2.868	0	0	0
floor_0_A17	-18.76	2.2	-6.3	0	0	0
floor_0_A16	-18.76	2.2	-8.902	0	0	0
floor_0_A15	-18.76	2.2	-14.33	0	0	0
floor_0_A14	14.21	2.2	-25.45	0	-0.7071068	0
floor_0_A13	24.94	2.2	-25.45	0	-0.7071068	0
floor_0_A12	33.91	2.2	-11.32	0	1	0
floor_0_A11	37.66	2.2	-3.8	0	-0.7071068	0
floor_0_A10	42.68	2.2	-3.8	0	-0.7071068	0
floor_0_A9	46.88	2.2	-1.93	0	1	0
floor_0_A8	46.88	2.2	4.07	0	1	0
floor_0_A7	46.88	2.2	9.7	0	1	0
floor_0_A6	42.93	2.2	13.146	0	0.7071068	0
floor_0_A5	39.99	2.2	15.71	0	1	0
floor_0_A4	39.99	2.2	19.6	0	1	0
floor_0_A3	39.99	2.2	23.5	0	1	0
floor_0_A2	-3.749	2	23.496	0	0.7071068	0
floor_0_A1	-18.636	2	20.07	0	0	0

`.split("\n").filter(
    a => a.length
).map(
    row=>{
        let [locationId, x, y, z, rx, ry, rz] = row.split("\t");
        const address = locationId.slice("floor_".length);

        const position = [x,y,z].map(str=>parseFloat(str)) as [number,number,number];
        const rotationDegrees = [rx,ry,rz].map(str=>parseFloat(str)) as [number,number,number]
        
        // the data has some very small rotations, probably a result of floating point operations when exporting them from Unity
        for(const i in [0,1,2]){
            const isBarelyRotated = Math.abs(rotationDegrees[i]) < 1;
            if(isBarelyRotated){
                rotationDegrees[i]=0;
            }
        }

        // convert from left-handed to right-handed
        position[0] *= -1;
        rotationDegrees[0]*=-1;
        rotationDegrees[1]*=-1;
        rotationDegrees[1]+=180;

        return {
            address,
            position,
            rotationDegrees
        }
    }
);
}