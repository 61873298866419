import assert from "assert";


export function MakeFileFromBlobAndName(from:Blob,fileName:string){
    return new File([from],fileName);
}

export default class Files{

    /**
     * @returns the File (a Blob), or undefined if the user didn't select any files (eg they aborted the dialog)
     */
    static async PromptSingleFileUploadDialogAsync_(){
        const results = await this._promptFileUploadDialogAsArrayAsync_({
            allowMultiple: true
        });
        if(!results){
            return undefined;
        }
        return results[0]||undefined;
    }

    /**
     * @returns an array(0+) of Files representing the files the user selected
     */
    static async PromptMultipleFilesUploadDialogAsync_(){
        return await this._promptFileUploadDialogAsArrayAsync_({
            allowMultiple: true
        })
    }

    /**
     * @returns an array(0+) of Files representing the files the user selected
     */
    private static async _promptFileUploadDialogAsArrayAsync_({
        allowMultiple
    }:{
        allowMultiple:boolean
    }){
        /** The built-in FileList object is not very good, so we convert it to a proper array. */
            
        const fileList = await this._promptFileUploadDialogAsFileListOrUndefinedAsync_({
            allowMultiple
        });
        if(!fileList){
            return [];
        }
        
        const fileArray:File[] = [];
        for(let i=0,n=fileList.length; i<n; ++i){
            const file = fileList.item(i);
            assert(file);
            fileArray.push(
                file
            );
        }

        return fileArray;
    }

    private static async _promptFileUploadDialogAsFileListOrUndefinedAsync_({
        allowMultiple
    }:{
        allowMultiple:boolean
    }):Promise<
        FileList|undefined
    >{
        return new Promise((accept,reject)=>{
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = allowMultiple;
            input.accept = "*/*";
            input.onchange = (e)=> {
                const fileList:FileList = (e?.target as any)?.files;
                accept(fileList);
            }
            input.onabort= (e)=>accept(undefined);
            input.click();
        });
    }
    

}
