import { BlobToDataUrlAsync } from "../../libraries/l2-common/l2-common-ts/common/files/Blobs";
import { MakeFileFromBlobAndName } from "../../libraries/l2-common/l2-common-ts/common/files/Files";

export default class LocalFileWrapper{

    private _file:File;
    public get file(){ return this._file; }
    public get blob(){ return this._file; }
    public set blob(blob:Blob){
        this._file = MakeFileFromBlobAndName(blob,"");
    }
    private _dataUrl?:string;

    private constructor(blob:Blob){
        if(blob instanceof File){
            this._file = blob as File;
        }else{
            this._file = MakeFileFromBlobAndName(blob,"");
        }
    }
   
    static FromFile(file:File){
        return new LocalFileWrapper(file);
    }

    static FromBlob(blob:Blob){
        return new LocalFileWrapper(blob);
    }

    async makeDataUrlAsync(){
        if(!this._dataUrl){
            this._dataUrl = await BlobToDataUrlAsync(this._file);
        }
        return this._dataUrl;
    }

    async maybeGetThumbnailUrlAsync(){
        return await this.makeDataUrlAsync();
    }

}