
type Type = {
    key:string,
    which?:number,
    keyCode?:number
}|{
    key?:string,
    which:number,
    keyCode?:number
}|{
    key?:string,
    which?:number,
    keyCode:number
}

export function IsEventEnterKey(event:Type){
    return (
        event.key==="Enter" ||
        event.which===13 ||
        event.keyCode===13
    );
}

/** Normalized Device Coordinates ( Component values range from -1 to +1. Top left coordinate is (-1,+1) )*/
export function MakeMouseNormalizedDeviceCoordinate(event:{clientX:number,clientY:number},elementRepresentingDevice:HTMLElement){
    const bounds = elementRepresentingDevice.getBoundingClientRect();
    const xInElement = event.clientX - bounds.left;
    const yInElement = event.clientY - bounds.top;
    return [
        -1 + 2 * xInElement / elementRepresentingDevice.clientWidth,
        -( -1 + 2 * yInElement / elementRepresentingDevice.clientHeight )
    ] as [number,number];
}