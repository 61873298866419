import { PerspectiveCamera, Scene, sRGBEncoding, WebGLRenderer } from "three";
import Node from "./nodes/Node";
import Object3DNode from "./nodes/Object3DNode";
import SystemContext from "./SystemContext";

/*
    We export this so that textures, etc... can also use the same encoding,
    meaning the pixel colors we see in the app will be closer
    to the pixel colors in the orignal image files
*/
export const THREE_SYSTEM_RENDERER_ENCODING = sRGBEncoding;

export default class ThreeSystem{
    camera:PerspectiveCamera;
    readonly scene:Scene;
    readonly renderer:WebGLRenderer;
    private _setRendererContainerDivListeners:(()=>any)[] = [];
    /** Set in ThreeSystemComponent.tsx */
    private _rendererContainerDiv:HTMLDivElement|null|undefined;
    get rendererContainerDiv(){
        return this._rendererContainerDiv;
    }
    set rendererContainerDiv(value){
        this._rendererContainerDiv = value;
        for(const callback of this._setRendererContainerDivListeners){
            callback();
        }
    }
    
    readonly rootNode:Node;

    private _lastUpdateTime:number|undefined;
    private _systemContext:SystemContext;

    constructor( window:Window, systemContext:SystemContext ){
        this._systemContext=systemContext;
        this.camera = new PerspectiveCamera(
            70,
            window.innerWidth/window.innerHeight,

        );
        this.camera.position.z = 1;
        this.camera.layers.enableAll();

        this.scene = new Scene();

        const clearColor:(string|undefined) = undefined;//"#002b36";

        this.renderer = new WebGLRenderer({
            antialias: true,
            alpha: !clearColor,
        });
        this.renderer.outputEncoding = THREE_SYSTEM_RENDERER_ENCODING;
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        if(clearColor){
            this.renderer.setClearColor(clearColor);
        }

        this.rootNode = new Object3DNode( this.scene, systemContext );

        this.renderer.setAnimationLoop( time=>this._rendererAnimation(time) )

    }

    /** the callback will be called when the rendererContainerDiv is set */
    addSetRendererContainerDivListener(callback:()=>any){
        this._setRendererContainerDivListeners.push(callback);
    }

    _rendererAnimation( time:number ){

        const deltaTimeSeconds = this._lastUpdateTime?(time-this._lastUpdateTime)/1000:0;
        this._lastUpdateTime = time;
        this._systemContext.inputSystem.update();
        this.rootNode.update( deltaTimeSeconds );

        this.renderer.render( this.scene, this.camera );
    }


    /** Sets the cursor style element of the render's dom element. */
    setCanvasCursor( cssCursorValue:string ){
        this.renderer.domElement.style.cursor = cssCursorValue;
    }


}