import "styled-components/macro";

import { css } from "styled-components/macro";
import { MenuHeader } from "../components/common/MenuHeader";
import { MobileMovementJoystick } from "./MobileMovementJoystick";


export default function MobileControlsMenuPanelContentsComponent(){
    return <div css={Layout.Root}>
        <MobileMovementJoystick layout={Layout.Joystick}/>
        <div css={Layout.Title}>
            <MenuHeader>Move</MenuHeader>
        </div>
    </div>
}

const Layout = {
    Root: css`
        /* display: grid; */
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
        /* grid-template-rows: 1fr 2em; */
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        padding: 1em;
        height: 100%;
        width:100%;

        & > * {
            margin-right: 4em;
            /* height: 100%; */
        }
    `,
    Joystick: css`
        width: 5em;
        height: 5em;
    `,

    Title: css`
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-around;
    `,
};